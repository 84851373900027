import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-gb");

const shirts = {
  Arsenal: {
    name: "Arsenal",
    player: "/img/wsl/shirts/Arsenal.png",
    gk: "/img/wsl/shirts/ArsenalGK.png"
  },
  "Birmingham City": {
    name: "Birmingham City",
    player: "/img/wsl/shirts/Birmingham.png",
    gk: "/img/wsl/shirts/BirminghamGK.png"
  },
  "Brighton & Hove Albion": {
    name: "Brighton & Hove Albion",
    player: "/img/wsl/shirts/Brighton.png",
    gk: "/img/wsl/shirts/BrightonGK.png"
  },
  "Bristol City": {
    name: "Bristol City",
    player: "/img/wsl/shirts/Bristol.png",
    gk: "/img/wsl/shirts/BristolGK.png"
  },
  Chelsea: {
    name: "Chelsea",
    player: "/img/wsl/shirts/Chelsea.png",
    gk: "/img/wsl/shirts/ChelseaGK.png"
  },
  Everton: {
    name: "Everton",
    player: "/img/wsl/shirts/Everton.png",
    gk: "/img/wsl/shirts/EvertonGK.png"
  },
  Liverpool: {
    name: "Liverpool",
    player: "/img/wsl/shirts/Liverpool.png",
    gk: "/img/wsl/shirts/LiverpoolGK.png"
  },
  "Manchester City": {
    name: "Manchester City",
    player: "/img/wsl/shirts/ManCity.png",
    gk: "/img/wsl/shirts/ManCityGK.png"
  },
  "Manchester United": {
    name: "Manchester United",
    player: "/img/wsl/shirts/ManUnited.png",
    gk: "/img/wsl/shirts/ManUnitedGK.png"
  },
  Reading: {
    name: "Reading",
    player: "/img/wsl/shirts/Reading.png",
    gk: "/img/wsl/shirts/ReadingGK.png"
  },
  Tottenham: {
    name: "Tottenham",
    player: "/img/wsl/shirts/Tottenham.png",
    gk: "/img/wsl/shirts/TottenhamGK.png"
  },
  "West Ham United": {
    name: "West Ham United",
    player: "/img/wsl/shirts/WestHam.png",
    gk: "/img/wsl/shirts/WestHamGK.png"
  },
  "Aston Villa": {
    name: "Aston Villa",
    player: "/img/wsl/shirts/AstonVilla.png",
    gk: "/img/wsl/shirts/AstonVillaGK.png"
  },
  "Leicester City": {
    name: "Leicester City",
    player: "/img/wsl/shirts/Leicester.png",
    gk: "/img/wsl/shirts/LeicesterGK.png"
  },
  "Crystal Palace": {
    name: "Crystal Palace",
    player: "/img/wsl/shirts/CrystalPalace.png",
    gk: "/img/wsl/shirts/CrystalPalaceGK.png"
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  },
  "Player Not Available": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
};

const init = () => {
  numeral.locale("en-gb");
  numeral.defaultFormat("$0,0");
};


const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2024-09-20 17:00:00+00")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2024-09-22 18:00:00+00"),
    closes: moment("2024-09-27 17:30:00+00")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2024-09-29 18:30:00+00"),
    closes: moment("2024-10-05 10:30:00+00")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2024-10-07 11:30:00+00"),
    closes: moment("2024-10-12 11:45:00+00")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2024-10-14 12:45:00+00"),
    closes: moment("2024-10-19 10:30:00+00")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2024-10-21 11:30:00+00"),
    closes: moment("2024-11-03 11:30:00+00")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2024-11-05 12:30:00+00"),
    closes: moment("2024-11-08 18:00:00+00")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2024-11-10 19:00:00+00"),
    closes: moment("2024-11-16 11:30:00+00")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2024-11-18 12:30:00+00"),
    closes: moment("2024-12-08 11:00:00+00")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2024-12-10 12:00:00+00"),
    closes: moment("2024-12-14 11:00:00+00")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2024-12-16 12:00:00+00"),
    closes: moment("2025-01-17 18:00:00+00")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2025-01-19 19:00:00+00"),
    closes: moment("2025-01-25 11:00:00+00")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2025-01-27 12:00:00+00"),
    closes: moment("2025-02-02 12:00:00+00")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2025-02-04 13:00:00+00"),
    closes: moment("2025-02-16 11:00:00+00")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2025-02-18 12:00:00+00"),
    closes: moment("2025-03-02 11:00:00+00")
  },
  {
    name: "Round16",
    round: "round-16",
    opens: moment("2025-03-04 12:00:00+00"),
    closes: moment("2025-03-16 13:00:00+00")
  },
  {
    name: "Round17",
    round: "round-17",
    opens: moment("2025-03-18 14:00:00+00"),
    closes: moment("2025-03-22 13:00:00+00")
  },
  {
    name: "Round18",
    round: "round-18",
    opens: moment("2025-03-24 14:00:00+00"),
    closes: moment("2025-03-30 10:00:00+00")
  },
  {
    name: "Round19",
    round: "round-19",
    opens: moment("2025-04-01 11:00:00+00"),
    closes: moment("2025-04-20 11:00:00+00")
  },
  {
    name: "Round20",
    round: "round-20",
    opens: moment("2025-04-22 12:00:00+00"),
    closes: moment("2025-04-27 10:00:00+00")
  },
  {
    name: "Round21",
    round: "round-21",
    opens: moment("2025-04-29 11:00:00+00"),
    closes: moment("2025-05-04 10:00:00+00")
  },
  {
    name: "Round22",
    round: "round-22",
    opens: moment("2025-05-06 11:00:00+00"),
    closes: moment("2025-05-11 13:00:00+00")
  },
  {
    name: "Round23",
    round: "round-23",
    opens: moment("2025-05-13 14:00:00+00"),
    closes: moment("2025-05-18 14:00:00+00")
  }
];
export const wsl = {
  shortName: "wsl",
  competition: "wsl",
  name: "Women's Super League",
  sport: soccer,
  totalBudget: 6000000,
  minPlayerPrice: 150000,
  maxPlayerPrice: 900000,
  perClubLimit: 4,
  hashtag: "ShePlaysWSL",
  shirts,
  init,
  rounds,
  freeTransfers: 1,
  transferCost: 5,
  teams: shirts,
  season: "2024"
};

