import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const LeagueTest = () => (<>
    <div className="container">
        <h1>Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may request 
          your own mini-league by emailing your league name, your fantasy team name, and a description to {" "}
        <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a>. Once we have set up your league, we will 
          email you with a unique code which you can share with your friends!
      </p>
      <img className="responsive" src="/img/mini.JPG" alt="kenned-header-2019-grand-file" />
      <p></p>
     </div>

</>)

export default LeagueTest;
