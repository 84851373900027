import React from 'react';

const Privacy = () => (<>
    <div className="container">
        <h1>Privacy Policy</h1>
	<p>The privacy of your personal information is important to us. This privacy policy applies to all information that is collected or submitted on the ShePlays Fantasy League website.Together with the Terms of Service, this Policy describes your relationship with ShePlays and your use of this Website. If you disagree with any part of this Policy, please do not use our Website.</p>
	<h3>Summary</h3>
	<p><ul>
	<li>We may collect personal information during the registration and fantasy team selection process</li>
	<li>We use this information to help us run the Fantasy League, and to stay in touch with you</li>
	<li>We will never share, sell or distribute your information to any third party</li>
	<li>We may use aggregate demographic information to describe our user base</li>
	<li>If you are a European resident you have rights under the General Data Protection Regulation (GDPR)</li>
	<li>If you wish you modify the information you have provided us or you want to delete your account, you can contact us at  <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a></li>
	<li>We will notify you if there are any substantial changes to this Privacy Policy</li></ul></p>
	<h3>Definitions</h3>
        <p>The term ‘ShePlays’, ‘us’ or ‘we’ refers to the administrators of this website, ShePlays Fantasy League. ShePlays is a registered Not-For-Profit Incorporated Association in NSW Australia (INC2200165). 
        We are registered as a Registrable Australian Body with ASIC, ARBN: 657 721 974, and our Australian   
        Business Number is ABN: 24 657 721 974.
        ShePlays was created in 2016 to address gender inequality in sport by promoting women's professional sporti
ng leagues, increasing fan involvement, and encouraging grass-roots participation.</p>
        <p>The term ‘you’ or ‘the User’ refers to the individual person, company or organisation that has visited or has registered for an account with ShePlays.</p>
        <p>The ‘Service’ refers to the Fantasy League that is managed by ShePlays, and all related software, products and services provided by ShePlays.</p>
        <p>The ‘Agreement’ refers, collectively, to all of the terms, conditions and notices referenced in this document (the ‘Terms of Service’ or ‘Terms’) and the ShePlays Privacy Policy.</p>
        <p>The ‘Website’ refers to the ShePlays website located at <a href="https://www.sheplays.com.au">www.sheplays.com.au</a>, and all content, services and products provided by ShePlays through the Website.</p>
        <p>‘Content’ refers to the content featured of displayed throughout the website, including without limitation text, data, articles, images, photographs, graphics, features and other material available on the Website or otherwise available through the Service.</p>
        <p>Your ‘Account’ represents your legal relationship with ShePlays. It is your authorisation to log into and use the Service, and it serves as your identity on ShePlays.</p>
	
	<h3>The information we collect</h3>
	<p>ShePlays collects personal information from users during the registration and team selection processes. The information collected can include:
	<ul>
	    <li>Email address</li>
	    <li>Password</li>
	    <li>Name or username</li>
	    <li>Country (optional) </li>
	    <li>Postcode (optional) </li>
	    <li>Gender (optional)</li>
	    <li>Team supported (optional)</li>
            <li>Fantasy team name</li>
	</ul>
	Users might also make requests or submit information via forms on our Website. The information collected in these is only relates to the running of the Fantasy League and is not used for any other purposes.</p>
	<p>If you're just browsing the Website, we collect the same basic information that most websites collect. We use common internet technologies, such as cookies and web server logs, to collect technical information whether you have an Account with us or not. This information may include your browser type, language preference, referring site and the date and time of each visitor request. The information we collect in this manner does not make you individually identifiable.</p>
	<h3>The ways we use and share personal information</h3>
	<p>We only use information submitted by users to facilitate the running of the ShePlays Fantasy League. The particular uses of each piece of personal information we collect are described below.</p>
	<h5>Email address</h5>
	<p>From time to time the ShePlays admin team may need to contact you, for example to give updates on the Fantasy League  or to provide reminders of transfer windows. Email addresses are only visible to you and to the admin team. They are never shared, sold, or distributed to any third party. Together with your password, your email address allows you to securely log on to the ShePlays website.</p>
	<h5>Password</h5>
	<p>Your password allows you to securely access your individual ShePlays account. It is known only to you.</p>
	<h5>Name or username</h5>
	<p>If you sign up to our website using Facebook or Google we will receive the name you have associated with that account. If you sign up using an email address and password you are asked to enter a name. This name is visible to other ShePlays users but not the general public. It will never be shared, sold, or distributed to any third party unless you have provided your explicit permission (for example if you win the fantasy league we might ask if we can congratulate you on social media using your real name).</p>
	<h5>Country, postcode and gender</h5>
	<p>We may collect this information during the registration or team selection process. If so, it will be optional to provide and we will collect it for statistical purposes only. ShePlays is a new fantasy league, and we want to make sure that we can reach as many fans as possible so that everyone can have the opportunity to participate. Demographic information such as country, postcode and gender helps us to understand whether we are reaching a wide audience, and where we can best focus our marketing efforts in the future. If you choose to provide this information it will never be shared, sold or distributed to any third party in a way that makes you individually identifiable. We may use aggregate demographic information to describe our user base, for example ‘70% of our users identify as female’ or ‘most of our users are from Australia’.</p>
	<h5>Team supported</h5>
	<p>This one is just for fun! We like to know what teams our members support. We may use this information in a similar way to the other demographic information to help us focus our marketing efforts and make sure we reach a wide fan base.</p>
	<h5>Fantasy team name</h5>
	<p>This is the name you provide for your fantasy team during the team selection process. This name appears on private league pages and the global leaderboard, which is visible to the public. For this reason we recommend that you choose a name that does not individually identify you if you wish to remain anonymous.</p>
	<h3>Compliance with the GDPR</h3>
	<p>The General Data Protection Regulation (GDPR) is a data privacy and security law that applies to all organisations that collect data from people in the European Union. Because some of our users are residents of the EU, we are required to notify you of a few important rights that you have in relation to your personal data:
	<ul>
	<li>You have a right to request from us access to, rectification of, or erasure of your personal data</li>
	<li>You have a right to request that we restrict the processing of your data, and the right to object to us processing your data</li>
	<li>You have a right to obtain a copy of your personal data from us</li>
	<li>You have the right to withdraw your consent to process your personal data at any time</li>
	</ul>
	If you wish to exercise any of these rights, please contact us. We will respond to all requests of this nature and endeavour to resolve them as quickly as possible. You also have the right to lodge a complaint with a supervisory authority. </p>
	<h5>Legal basis for processing your personal information</h5>
	<p>Under the GDPR we are are required to notify you about the legal basis on which we process your personal information. ShePlays processes your personal information because when you register with our Website you give us consent to process your personal information to enable you to participate securely in the ShePlays Fantasy League.</p>
	<h5>Transfer of personal data to a third country or international organisation</h5>
	<p>ShePlays is an Australian-based website hosted on Amazon Web Services (AWS). As such, when you sign up on our Website your personal data (name, email address, password) is transferred to AWS servers. Your name and email address are accessible by our administrators in Australia. AWS is a GDPR-compliant service and has stringent measures in place to protect the information in its databases. You can find out more <a href="https://aws.amazon.com/compliance/gdpr-center" target="_blank">here</a>. ShePlays may also use Gmail (part of G Suite) for occasionally contacting you in relation to the fantasy league. G Suite is also GDPR-compliant. You can find out more <a href="https://cloud.google.com/security/gdpr/" target="_blank">here</a>. Both AWS and G Suite use a geographically-distributed network of data centers to store their data.</p> 

	<h3>Security of your personal information</h3>	
	<p>We have implemented a variety of electronic, physical and administrative controls to ensure that your personal information is kept private. Please contact us if you have any questions or concerns about this.</p>
	<h5>Accessing or modifying your personal information</h5>
	<p>If you wish to change any other information you have provided on our website, get in touch with us at <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a> and we can take care of it for you. We will verify your identity in order to protect your privacy and security before making corrections to your personal information.</p>
	<h5>Deleting your account and retention of data</h5>
	<p>Will we retain your data only as long as you have an account with our website. If you wish to delete your account and have your personal information removed from our database please contact us <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a></p>
	<h5>Cookies</h5>
	<p>You can choose to accept or decline cookies by changing your browser settings.</p>
	<h3>Changes to this policy</h3>
	<p>If we make changes to our Privacy Policy we will update this page. If we make a change that substantially changes the way we use or share your personal information we will inform all users via email at least 30 days prior to the change taking effect.</p>
	<h3>Contact us</h3>
	<p>If you have any questions or concerns about the privacy of your information please contact us at <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a> </p>
	<p align="center"><i>Effective date 31 August 2018</i></p>
	<p align="center"><i>Last updated 31 July 2019</i></p>
	<p>Changes at last update:
	<ul>
		<li>Clarified use of name and username</li>
		<li>Added description of 'fantasy team name' and how it is used on the site</li>
		<li>Added list of GDPR rights</li>
		<li>Added information about how data is stored with service providers</li>
		<li>Added 'Definitions' section to match Terms of Service</li>
	</ul>
	</p>
    </div>

</>)

export default Privacy;
