import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperEuro } from "../../components/layout/CompetionWrapperEuro";
import BottomNavBar from '../../components/layout/MCompetionWrapperEuro.jsx';
import { euro } from "../../data/competitions/euro";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: "aut", name: "Austria"},
                { code: "bel", name: "Belgium"},
                { code: "dnk", name: "Denmark"},
                { code: "eng", name: "England"},
                { code: "fin", name: "Finland"},
                { code: "fra", name: "France"},
                { code: "deu", name: "Germany"},
                { code: "isl", name: "Iceland"},
                { code: "ita", name: "Italy" },
                { code: "nld", name: "Netherlands"},
                { code: "nir", name: "Northern Ireland"},
                { code: "nor", name: "Norway"},
                { code: "prt", name: "Portugal" },
                { code: "esp", name: "Spain"},
                { code: "swe", name: "Sweden"},
                { code: "che", name: "Switzerland"}];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/euro/m/teams/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/RoundFlags/${team.name}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
