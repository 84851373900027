import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const EngvSco = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/England.png" width="50" hspace="20" />England v Scotland<img src="img/RoundFlags/Scotland.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group D</center></h5>
	<p><center><b>2 - 1</b><br/>Stade de Nice<br/>
	Nice<br/>
	{localDate("2019-06-09 18:00+02")}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	            <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>
<tr>
<td>Ellen White</td>
<td>ENG</td>
<td>14</td>
<td>2</td>
<td>5</td>
<td></td>
<td>6</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Claire Emslie</td>
<td>SCO</td>
<td>13</td>
<td>2</td>
<td>5</td>
<td></td>
<td>6</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Nikita Parris</td>
<td>ENG</td>
<td>12</td>
<td>2</td>
<td>5</td>
<td></td>
<td>4</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Alex Greenwood</td>
<td>ENG</td>
<td>7</td>
<td>2</td>
<td></td>
<td></td>
<td>4</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Lucy Bronze</td>
<td>ENG</td>
<td>6</td>
<td>2</td>
<td></td>
<td></td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Fran Kirby</td>
<td>ENG</td>
<td>6</td>
<td>2</td>
<td></td>
<td>3</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Beth Mead</td>
<td>ENG</td>
<td>6</td>
<td>2</td>
<td></td>
<td></td>
<td>3</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Lisa Evans</td>
<td>SCO</td>
<td>5</td>
<td>2</td>
<td></td>
<td>3</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Caroline Weir</td>
<td>SCO</td>
<td>5</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>2</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Jill Scott</td>
<td>ENG</td>
<td>5</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Lee Alexander</td>
<td>SCO</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Kim Little</td>
<td>SCO</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Karen Bardsley</td>
<td>ENG</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Steph Houghton</td>
<td>ENG</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Sophie Howard</td>
<td>SCO</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Rachel Corsie</td>
<td>SCO</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Keira Walsh</td>
<td>ENG</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Erin Cuthbert</td>
<td>SCO</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Chloe Arthur</td>
<td>SCO</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Christie Murray</td>
<td>SCO</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Georgia Stanway</td>
<td>ENG</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Millie Bright</td>
<td>ENG</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Karen Carney</td>
<td>ENG</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Abbie McManus</td>
<td>ENG</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Kirsty Smith</td>
<td>SCO</td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Lizzie Arnot</td>
<td>SCO</td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Jennifer Beattie</td>
<td>SCO</td>
<td>0</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>-2</td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Nicola Docherty</td>
<td>SCO</td>
<td>-1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>-2</td>
<td> </td>
<td> </td>
<td> </td>
</tr>
            </tbody>
	</table>
    </div>	

</>)

export default EngvSco;
