import React from 'react';
import moment from 'moment';
import data from '../../data/matches/calc/CHIvSWE';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const ChivSwe = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/Chile.png" width="50" hspace="20" />Chile v Sweden<img src="img/RoundFlags/Sweden.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group F</center></h5>
	<p><center><b>{data.scoreA} - {data.scoreB}</b><br/>{data.venue}<br/>
	{data.city}<br/>
	{localDate(data.time)}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	                <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>

            {data.points.map(({assists, attack, cards, cleanSheet, country, defense, goalDifference, goals, keeper, matchPlayed, name, playerId, total, win}) => 
                <tr key={playerId}>
                    <td>{name}</td>
                    <td>{country}</td>
                    <td>{total}</td>
                    <td>{matchPlayed}</td>
                    <td>{goals}</td>
                    <td>{assists}</td>
                    <td>{attack}</td>
                    <td>{defense}</td>
                    <td>{keeper}</td>
                    <td>{cards}</td>
                    <td>{cleanSheet}</td>
                    <td>{win}</td>
                    <td>{goalDifference}</td>
                </tr>
            )}
            </tbody>
	</table>
    </div>	
</>)

export default ChivSwe;
