import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const FravKor = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/France.png" width="50" hspace="20" />France v Korea<img src="img/RoundFlags/Korea.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group A</center></h5>
	<p><center><b>4 - 0</b><br/>Parc des Princes<br/>
	Paris<br/>
	{localDate("2019-06-07 21:00+02")}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	            <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Wendie Renard</td><td>FRA</td><td>23</td><td>2</td><td>10</td><td>0</td><td>3</td><td>0</td><td>0</td><td>0</td><td>5</td><td>1</td><td>2</td>
                </tr>
                <tr>
                <td>Amel Majri</td><td>FRA</td><td>21</td><td>2</td><td>0</td><td>3</td><td>5</td><td>3</td><td>0</td><td>0</td><td>5</td><td>1</td><td>2</td>
                 </tr>
                 <tr>
                 <td>Eugenie Le Sommer</td><td>FRA</td><td>21</td><td>2</td><td>5</td><td>3</td><td>7</td><td>1</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                  </tr>
                  <tr>
                  <td>Amandine Henry</td><td>FRA</td><td>18</td><td>2</td><td>5</td><td>3</td><td>4</td><td>1</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                   </tr>
                   <tr>
                   <td>Marion Torrent</td><td>FRA</td><td>15</td><td>2</td><td>0</td><td>0</td><td>4</td><td>1</td><td>0</td><td>0</td><td>5</td><td>1</td><td>2</td>
                    </tr>
                    <tr>
                    <td>Gaetane Thiney</td><td>FRA</td><td>12</td><td>2</td><td>0</td><td>3</td><td>2</td><td>2</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                     </tr>
                     <tr>
                     <td>Griedge Mbock Bathy</td><td>FRA</td><td>12</td><td>2</td><td>0</td><td>0</td><td>1</td><td>1</td><td>0</td><td>0</td><td>5</td><td>1</td><td>2</td>
                      </tr>
                      <tr>
                      <td>Sarah Bouhaddi</td><td>FRA</td><td>10</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>5</td><td>1</td><td>2</td>          
                       </tr>
                        <tr>
                        <td>Elise Bussaglia</td><td>FRA</td><td>8</td><td>2</td><td>0</td><td>0</td><td>2</td><td>1</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                        </tr>
                        <tr>
                        <td>Delphine Cascarino</td><td>FRA</td><td>7</td><td>2</td><td>0</td><td>0</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                         </tr>
                         <tr>
                         <td>Kadidiatou Diani</td><td>FRA</td><td>7</td><td>2</td><td>0</td><td>0</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                          </tr>
                          <tr>
                          <td>Jang Selgi</td><td>KOR</td><td>6</td><td>2</td><td>0</td><td>0</td><td>0</td><td>6</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                          </tr>
                          <tr>
                       <td>Eve Perisset</td><td>FRA</td><td>6</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>2</td><td>1</td><td>2</td>
                        </tr>
                          <tr>
                          <td>Grace Geyoro</td><td>FRA</td><td>4</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                           </tr>
                           <tr>
                           <td>Valerie Gauvin</td><td>FRA</td><td>4</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>0</td><td>1</td><td>2</td>
                            </tr>
                            <tr>
                            <td>Kim Minjung</td><td>KOR</td><td>4</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>4</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                             </tr>
                             <tr>
                             <td>Cho Sohyun</td><td>KOR</td><td>4</td><td>2</td><td>0</td><td>0</td><td>2</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                              </tr>
                              <tr>
                              <td>Kim Doyeon</td><td>KOR</td><td>3</td><td>2</td><td>0</td><td>0</td><td>0</td><td>3</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                               </tr>
                               <tr>
                               <td>Ji Soyun</td><td>KOR</td><td>2</td><td>2</td><td>0</td><td>0</td><td>0</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                                </tr>
                                <tr>
                                <td>Kim Hyeri</td><td>KOR</td><td>2</td><td>2</td><td>0</td><td>0</td><td>1</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                                 </tr>
                                 <tr>
                                 <td>Hwang Boram</td><td>KOR</td><td>2</td><td>2</td><td>0</td><td>0</td><td>0</td><td>2</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                                  </tr>
                                  <tr>
                                  <td>Lee Youngju</td><td>KOR</td><td>1</td><td>2</td><td>0</td><td>0</td><td>0</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                                   </tr>
                                   <tr>
                                   <td>Lee Youngju</td><td>KOR</td><td>1</td><td>2</td><td>0</td><td>0</td><td>0</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                                   </tr>
                                   <tr>
                                   <td>Lee Geummin</td><td>KOR</td><td>1</td><td>2</td><td>0</td><td>0</td><td>0</td><td>1</td><td>0</td><td>0</td><td>0</td><td>0</td><td>-2</td>
                                    </tr>
            </tbody>
	</table>
    </div>	

</>)

export default FravKor;
