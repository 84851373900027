import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../../../components/layout/CompetionWrapperWWC";
import BottomNavBar from '../../../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from "../../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: "database", name: "Player Database"},
                { code: "stats", name: "Stats Center"}];

const Mplayers = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/wwc/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mplayers;
