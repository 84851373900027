import React, {useState} from "react";
import _ from "lodash";
import moment from "moment";
import { useAuth } from "../../util/withAuth";
import numeral from "numeral";
import MyLeagues from "../leagues/MyLeagues";
import { CompetitionWrapperWL } from "../../components/layout/CompetionWrapperWL";
import { wl } from "../../data/competitions/wl-2021";
import BottomNavBar from '../../components/layout/MCompetionWrapperWL.jsx';

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const MyWLeagueLeagues = ({ route }) => {
  return (<>
    <div className="container my-3">
        <h1>A-League Women Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!  
      </p>
      
	  <MyLeagues route={route} competition="wleague" />
    </div>
	 <div className="container">
<h3>Our Friends</h3>
<p>Want to find public mini-leagues to test your fantasy A-League Women's team against? Or want to learn about 
some of the organisations that are doing great work to help grow women's soccer here in Australia? Then check out the open mini-leagues 
from some of our friends below!</p>
</div>

<div className="container">
      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>
<tbody>
<tr>
<td><img hspace="20" src="/img/b90small.png" width="40"/></td>
<td>Beyond 90</td>
<td>B90</td>
<td>The go to place for all your A-League Women's news and analysis</td>
</tr>
<tr>
<td><img hspace="20" src="/img/TLL.png" width="40"/></td>
<td>The Ladies League</td>
<td>TLL</td>
<td>The chaotic energy Australian football media was missing</td>
</tr>
<tr>
<td><img hspace="20" src="/img/MAS.jpg" width="40"/></td>
<td>Matildas Active Support</td>
<td>7g16xw</td>
<td>Active Supporters group of the Matildas</td>
</tr>
<tr>
<td><img hspace="20" src="/img/vvsmall.jpeg" width="40"/></td>
<td>Victory Vikings</td>
<td>2x4oz6</td>
<td>Active Supporters group of the Melbourne Victory</td>
</tr>
<tr>
<td><img hspace="20" src="/img/trcsmall.jpg" width="40"/></td>
<td>The Roar Corps</td>
<td>TRC</td>
<td>Active Supporters group of the Brisbane Roar</td>
</tr>
<tr>
<td><img hspace="20" src="/img/JETS.jpeg" width="40"/></td>
<td>W-Jets Active</td>
<td>JETS</td>
<td>Active Supporters group of the Newcastle Jets</td>
</tr>
<tr>
<td><img hspace="20" src="/img/nixsmall.jpeg" width="40"/></td>
<td>Flight of the Nix!</td>
<td>66hla6</td>
<td>Wellington Phoenix Supporters group</td>
</tr>
<tr>
<td><img hspace="20" src="/img/nix2.jpg" width="40"/></td>
<td>Wellington Phoenix Fans</td>
<td>o8ez79</td>
<td>Wellington Phoenix Supporters group</td>
</tr>
</tbody>
</table>
</div>
</>
  );
};

export default MyWLeagueLeagues

