import { afl } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const teams = {
  ADE: {
    name: "Adelaide Crows",
    player: "/img/aflw/shirts/ADE.png"
  },
  BRL: {
    name: "Brisbane Lions",
    player: "/img/aflw/shirts/BRL.png"
  },
  CAR: {
    name: "Carlton",
    player: "/img/aflw/shirts/CAR.png"
  },
  COL: {
    name: "Collingwood",
    player: "/img/aflw/shirts/COL.png"
  },
  FRE: {
    name: "Fremantle",
    player: "/img/aflw/shirts/FRE.png"
  },
  GEE: {
    name: "Geelong Cats",
    player: "/img/aflw/shirts/GEE.png"
  },
  GCS: {
    name: "Gold Coast Suns",
    player: "/img/aflw/shirts/GCS.png"
  },
  GWS: {
    name: "GWS Giants",
    player: "/img/aflw/shirts/GWS.png"
  },
  MEL: {
    name: "Melbourne",
    player: "/img/aflw/shirts/MEL.png"
  },
  NTH: {
    name: "North Melbourne",
    player: "/img/aflw/shirts/NTH.png"
  },
  RIC: {
    name: "Richmond",
    player: "/img/aflw/shirts/RIC.png"
  },
  STK: {
    name: "St Kilda",
    player: "/img/aflw/shirts/STK.png"
  },
  WCE: {
    name: "West Coast Eagles",
    player: "/img/aflw/shirts/WCE.png"
  },
  WBD: {
    name: "Western Bulldogs",
    player: "/img/aflw/shirts/WBD.png"
  }
};

const rounds = [
  {
    name: "Round 1",
    round: "round-1",
    opens: moment("2020-01-01 00:00+11"),
    closes: moment("2020-02-07 18:40+11")
  },
  {
    name: "Round 2",
    round: "round-2",
    opens: moment("2020-02-08 12:00+11"),
    closes: moment("2020-02-14 18:40+11")
  },
  {
    name: "Round 3",
    round: "round-3",
    opens: moment("2020-02-16 19:40+11"),
    closes: moment("2020-02-21 18:10+11")
  },
  {
    name: "Round 4",
    round: "round-4",
    opens: moment("2020-02-23 19:40+11"),
    closes: moment("2020-02-28 16:40+11")
  },
  {
    name: "Round 5",
    round: "round-5",
    opens: moment("2020-03-01 19:40+11"),
    closes: moment("2020-03-06 18:10+11")
  },
  {
    name: "Round 6",
    round: "round-6",
    opens: moment("2020-03-08 19:40+11"),
    closes: moment("2020-03-13 18:10+11")
  },
  {
    name: "Round 7",
    round: "round-7",
    opens: moment("2020-03-15 18:10+11"),
    closes: moment("2020-03-21 11:40+11")
  },
  {
    name: "Round 8",
    round: "round-8",
    opens: moment("2020-03-22 15:10+11"),
    closes: moment("2020-03-27 16:45+11")
  }
]

const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const aflw = {
  shortName: "aflw",
  name: "AFLW",
  sport: afl,
  totalBudget: 600000,
  minPlayerPrice: 15000,
  maxPlayerPrice: 60000,
  perClubLimit: 10,
  transferCost: 100,
  freeTransfers: 2,
  hashtag: "ShePlaysAFLW",
  init,
  teams,
  rounds,
  shirts: teams
};
