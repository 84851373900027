import React from 'react';




const Header = () => <div className="header">
    <div className="container clearfix">
        <a className="float-right title" href="/">
            <h1>ShePlays</h1>
            <h2>Fantasy League</h2>
        </a>
    </div>
</div>


export default Header

