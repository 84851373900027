import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  "Adelaide United": {
      name: "Adelaide United",
      player: "/img/wl/shirts/ade.png",
      gk: "/img/wl/shirts/adeGK.png",
  },
  "Brisbane Roar": {
      name: "Brisbane Roar",
      player: "/img/wl/shirts/bri.png",
      gk: "/img/wl/shirts/briGK.png",
  },
  "Canberra United": {
      name: "Canberra United",
      player: "/img/wl/shirts/cbr.png",
      gk: "/img/wl/shirts/cbrGK.png",
  },
  "Melbourne City": {
      name: "Melbourne City",
      player: "/img/wl/shirts/mcy.png",
      gk: "/img/wl/shirts/mcyGK.png",
  },
  "Melbourne Victory": {
      name: "Melbourne Victory",
      player: "/img/wl/shirts/mvc.png",
      gk: "/img/wl/shirts/mvcGK.png",
  },
  "Newcastle Jets": {
      name: "Newcastle Jets",
      player: "/img/wl/shirts/new.png",
      gk: "/img/wl/shirts/newGK.png",
  },
  "Perth Glory": {
      name: "Perth Glory",
      player: "/img/wl/shirts/per.png",
      gk: "/img/wl/shirts/perGK.png",
  },
  "Sydney FC": {
      name: "Sydney FC",
      player: "/img/wl/shirts/syd.png",
      gk: "/img/wl/shirts/sydGK.png",
  },
  "Western Sydney Wanderers": {
      name: "Western Sydney Wanderers",
      player: "/img/wl/shirts/wsw.png",
      gk: "/img/wl/shirts/wswGK.png",
  },
  "Wellington Phoenix": {
      name: "Wellington Phoenix",
      player: "/img/wl/shirts/wel.png",
      gk: "/img/wl/shirts/welGK.png",
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2021-08-24 12:00+00"),
    closes: moment("2021-12-03 16:05:00+11")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2021-12-05 18:05:00+11"),
    closes: moment("2021-12-10 16:05:00+11")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2021-12-12 20:05:00+11"),
    closes: moment("2021-12-17 16:05:00+11")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2021-12-19 18:05:00+11"),
    closes: moment("2021-12-23 16:05:00+11")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2021-12-27 20:45:00+11"),
    closes: moment("2021-12-30 16:05:00+11")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2022-01-02 22:15:00+11"),
    closes: moment("2022-01-07 18:45:00+11")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2022-01-09 16:05:00+11"),
    closes: moment("2022-01-15 16:05:00+11")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2022-01-16 16:05:00+11"),
    closes: moment("2022-01-21 17:35:00+11")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2022-01-23 16:05:00+11"),
    closes: moment("2022-01-28 18:45:00+11")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2022-01-30 16:05:00+11"),
    closes: moment("2022-02-02 18:35:00+11")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2022-02-06 18:05:00+11"),
    closes: moment("2022-02-11 18:45:00+11")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2022-02-13 20:20:00+11"),
    closes: moment("2022-02-17 17:35:00+11")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2022-02-20 18:05:00+11"),
    closes: moment("2022-02-24 18:45:00+11")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2022-02-27 18:05:00+11"),
    closes: moment("2022-03-04 16:05:00+11")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2022-03-06 18:05:00+11"),
    closes: moment("2022-03-13 16:05:00+11")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const wl = {
  shortName: "wleague",
  competition: "wleague",
  name: "A-League",
  sport: soccer,
  totalBudget: 5500000,
  minPlayerPrice: 150000,
  maxPlayerPrice: 800000,
  perClubLimit: 5,
  hashtag: "ShePlaysDub",
  shirts,
  init,
  rounds,
  freeTransfers: 1,
  transferCost: 5,
  season: "2021-22",
  teams: shirts
};
