import React from 'react';



const WLtemp = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1>W-League Mini Leagues</h1>
	<p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
                                        join existing leagues or create your own!
      </p>
          <p><b>Note: W-League mini-leagues will be available soon! This is where you will be able to set up your own league, or join a friend's!</b></p>
        <img className="responsive" src="/img/mini.JPG" alt="kenned-header-2019-grand-file" />
      <p></p>

        </div>

</>)

export default WLtemp;
