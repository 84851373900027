import React, {useState} from "react";
import { useAuth } from "../../../util/withAuth";
import { CompetitionWrapperWWC } from "../../../components/layout/CompetionWrapperWWC23";
import BottomNavBar from '../../../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from "../../../data/competitions/wwc-2023";
import _ from "lodash";
import MyLeagues from "../../leagues/MyLeagues";



const MyWWCLeagues = ({ route }) => {
	wwc.init();
  return (
	  <>
    <div className="container my-3">
        <h1>Women's World Cup Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!
      </p>
      
	  <MyLeagues route={route} competition="wwc" />
    
    </div>

<div className="container">
<p>Want to find public mini-leagues to test your fantasy World Cup team against? Check out our public leagues below! If you 
are a supporter group or organisation that would like your own custom public league send us an email at marran@sheplays.com.au.
 
</p>

      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>

<tbody>
<tr>
<td><img hspace="20" src="/img/GreenCircle.png" width="40"/></td>
<td>ShePlays</td>
<td>sheplays</td>
<td>Take on the ShePlays admin team!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/TLL.png" width="40"/></td>
<td>The Ladies League</td>
<td>TLL</td>
<td>"We're not here to mess with spiders" - @LadiesALeague</td>
</tr>
<tr>
<td><img hspace="20" src="/img/gotb2.jpg" width="40"/></td>
<td>Girls on the Ball</td>
<td>GOTB</td>
<td>Online platform promoting UK + Irish women's football.</td>
</tr>
<tr>
<td><img hspace="20" src="/img/stbsmall.jpg" width="40"/></td>
<td>She Talks and Plays Ball</td>
<td>STBWWC</td>
 <td>Women's sports content, coverage & conversation</td>
</tr>
<tr>
<td><img hspace="20" src="/img/NYAFFlogo.jpg" width="40"/></td>
<td>Not Your Average Football Fan</td>
<td>NYAFF</td>
<td>NYAFF is not just for disabled or expat or 'minority' fans — it's for everyone who loves football. What makes you Not Your Average Football Fan?</td>
</tr>
<tr>
<td><img hspace="20" src="/img/wfn.jpg" width="40"/></td>
<td>Women's Football Network</td>
<td>WFN</td>
<td>WFN for managers,coaches and clubs involved in womens football,to share ideas, knowledge and best practice.</td>
</tr>
<tr>
<td><img hspace="20" src="/img/FootballEngine.jpeg" width="40"/></td>
<td>Football Engine</td>
<td>footy23</td>
<td>Come try and beat the members of the Football Engine team!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/MAS.jpg" width="40"/></td>
<td>Matildas Active Support</td>
<td>MAS</td>
<td>Active support group for the Australian women's football team, The Matildas. We also exist to provide a space for all fans to come together, active or not</td>
</tr>
<tr>
<td><img hspace="20" src="/img/hersportlogo.png" width="40"/></td>
<td>Her Sport</td>
<td>hersport</td>
<td>Join the fun and get involved this 2023 FIFA Women's World Cup. Everyone is welcome to join and there will be plenty of prizes to be won!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/b90small.png" width="40"/></td>
<td>Beyond 90</td>
<td>B90</td>
<td>A digital news platform chronicling, promoting and sparking conversation about women’s football!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/SBFC.jpg" width="40"/></td>
<td>Shea Butter FC Women’s World Cup Fantasy League</td>
<td>SBFC</td>
<td>What’s good Squad?! Welcome to the Shea Butter FC Women’s World Cup Fantasy League where you get to set up your World Cup brackets and predict the winner to lift the trophy! Get ready to vibe with us and the squad on this chaotic and amazing tournament ride!</td>
</tr>

</tbody>
</table>
</div>
	  </>
  );
};

export default MyWWCLeagues
