import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import numeral from 'numeral';
import {Button} from 'reactstrap';

const DragHandle = SortableHandle(() => <i className="fa fa-bars drag-handle" aria-hidden="true"></i>);
const SortableItem = SortableElement(({ value, onDrop }) => <tr className={
    (value.injured || value.transferred) ? "player-row table-danger" :
    value.isNew ? "player-row table-warning" :
    "player-row"}>
    <td><DragHandle />{value.name}{value.isNew}</td>
    <td>{value.position}</td>
    <td>{value.club}</td>
    <td>{numeral(value.fantasyPrice).format('$0,0')}</td>
    <td><Button close onClick={onDrop}></Button></td>
</tr>);

const SortableList = SortableContainer(({items, onDropPlayer}) => {
  return (
    <table className="table team-order-table">
    <thead className="thead-dark">
    <tr>
        <th>Name</th>
        <th>Position</th>
        <th>Club</th>
        <th colSpan={2}>Fantasy Price</th>
    </tr>
    </thead>
        <tbody>
	<tr>
	    <th colSpan={5}>Captain</th>
	</tr>
	    {items.slice(0,1).map((value, index) => (
		    <SortableItem key={`item-${index}`} onDrop={() => onDropPlayer(index)} index={index} value={value} />
            ))}
        <tr>
            <th colSpan={5}>Vice Captain</th>
        </tr>
            {items.slice(1,2).map((value, index) => (
                    <SortableItem key={`item-${index+1}`} onDrop={() => onDropPlayer(index+1)} index={index+1} value={value} />
            ))}
        <tr>
            <th colSpan={5}>Starting Team ({Math.min(items.length, 9)}/9)</th>
        </tr>
            {items.slice(2,11).map((value, index) => (
                <SortableItem key={`item-${index+2}`} onDrop={() => onDropPlayer(index+2)} index={index+2} value={value} />
            ))}
        {items.length <= 11 ? null : <>
            <tr>
                <th colSpan={5}>Bench ({Math.min(items.length - 11, 4)}/4)</th>
            </tr>

            {items.slice(11,15).map((value, index) => (
                <SortableItem key={`item-${index+11}`} onDrop={() => onDropPlayer(index+11)} index={index+11} value={value} />
            ))}
        </>}

        {items.length <= 15 ? null : <>
            <tr>
                <th colSpan={5}>Wishlist</th>
            </tr>

            {items.slice(15).map((value, index) => (
                <SortableItem key={`item-${index+15}`} onDrop={() => onDropPlayer(index+15)} index={index+15} value={value} />
            ))}
        </>}
        </tbody>
    </table>
  );
});

export const OrderTeam = ({players, updateTeamOrder, onDropPlayer}) => <SortableList useDragHandle items={players} onSortEnd={updateTeamOrder} onDropPlayer={onDropPlayer}/>;
