import React from "react";
import Flag from "react-world-flags";
import _ from 'lodash';

import moment from "moment";
import { CompetitionWrapperEuro } from "../../components/layout/CompetionWrapperEuro";
import BottomNavBar from "../../components/layout/MCompetionWrapperEuro.jsx";
import { euro } from "../../data/competitions/euro";
import * as numeral from 'numeral';
import data from "../../data/nwsl/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({euroteam}) => {
	if (!euroteam.link1){
		return<span></span>
	}
	return (
	      <div>
	      <img
              className="responsive_2col"
              src={`/img/nwsl/${euroteam.photo}`}
              width="550"
            />
	       <figcaption align="center">
            Photo by <a href={euroteam.link1} >{euroteam.cap1}</a>, <a href={euroteam.link2} title="Creative Commons">{euroteam.cap2}</a>
            </figcaption>
		<p> </p>
	    </div>
	    )

};

const EuroTeam = ({ euroteam }) => ( <>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h2>{euroteam.team}</h2>
        <h6>Group: {euroteam.group} <br/>FIFA Ranking: {euroteam.rank} </h6>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={`/img/RoundFlags/${euroteam.code}.png`}
        height={60}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
	{Image({euroteam})}
      <span style={{textAlign:'justify', fontSize:'0.8rem'}}>{euroteam.bio1}<br/></span>
      <span style={{textAlign:'justify', fontSize:'0.8rem'}}> {euroteam.bio2} <br/></span>
      <span style={{fontSize:'0.8rem'}}>Team bio written by {euroteam.auth} (<a href={euroteam.link}>{euroteam.twit}</a>)</span>
    </div>

    <div className="container clearfix mt-32" style={{overflowX:'scroll'}}>
      <table className="table table-striped" style={{fontSize:'0.8rem'}}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">
              Club Team
            </th>
            <th scope="col" align="center">Age</th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">2022</th>
          </tr>
        </thead>
        <tbody>
          {euroteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
	      Points20,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {euroteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {euroteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {euroteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="6">Team's Total Points:</th>
	<th scope="col">{euroteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
	<BottomNavBar/>
        </>
);

export default EuroTeam;
