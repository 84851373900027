import React from "react";
import _ from "lodash";
import MyLeagues from "../leagues/MyLeagues";
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../data/competitions/aflw-2023";

const MyWLeagueLeagues = ({ route }) => {
  return (
    <CompetitionWrapperAFLW competition={aflw}>
    <div className="container my-3">
        <h1>AFLW</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!
      </p>
      
	  <MyLeagues route={route} competition="aflw" />
    
    </div>
    </CompetitionWrapperAFLW>
  );
};

export default MyWLeagueLeagues;
