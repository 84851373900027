import React from 'react';
import DynamicMatch from '../../components/matches/Match';
import { CompetitionWrapperWSL } from '../../components/layout/CompetionWrapperWSL';
import { wsl } from '../../data/competitions/wsl';

const teams = [
    { code: "ars", name: "Arsenal" },
    { code: "ast", name: "Aston Villa" },
    { code: "brc", name: "Birmingham City" },
    { code: "brh", name: "Brighton & Hove Albion" },
    { code: "bsc", name: "Bristol City" },
    { code: "che", name: "Chelsea" },
    { code: "eve", name: "Everton" },
    { code: "lei", name: "Leicester City" },
    { code: "liv", name: "Liverpool" },
    { code: "mci", name: "Manchester City" },
    { code: "mun", name: "Manchester United" },
    { code: "rdg", name: "Reading" },
    { code: "tot", name: "Tottenham" },
    { code: "whu", name: "West Ham United" }
]

const WSLMatch = ({route}) => {
    return (<CompetitionWrapperWSL competition={wsl}>
        <DynamicMatch {...{route, teams}} comp="wsl" />
    </CompetitionWrapperWSL>)
}
export default WSLMatch;
