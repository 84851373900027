import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import BottomNavBar from '../../components/layout/MCompetionWrapperWSL.jsx';
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: "ars", name: "Arsenal"},
                { code: "ast", name: "Aston Villa"},
                { code: "brh", name: "Brighton & Hove Albion"},
                { code: "che", name: "Chelsea"},
                { code: "cry", name: "Crystal Palace"},
                { code: "eve", name: "Everton"},
                { code: "lei", name: "Leicester City"},
	        { code: "liv", name: "Liverpool"},
                { code: "mci", name: "Manchester City"},
                { code: "mun", name: "Manchester United" },
                { code: "tot", name: "Tottenham Hotspur"},
                { code: "whu", name: "West Ham United"}];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/wsl/teams/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/wsl/badges-team/${team.code}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
