import React from 'react';
import moment from'moment';
import { CompetitionWrapperWWC } from "../components/layout/CompetionWrapperWWC";
import { wwc } from "../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const Feedback = () => (<>
<CompetitionWrapperWWC competition={wwc}>
		
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1>Women's World Cup - Survey Results</h1>
        <p>After the World Cup we sent out a survey to all users asking for feedback on the Fantasy League. Read on below to see the results!</p>
	<p>For the open-ended questions we've summarised the most popular constructive suggestions on this page. We also received lots of lovely positive feedback about the site and how we ran it for the World Cup. We haven't listed that positive feedback here because it would take too long, but we did read and appreciate every one of the responses! We want to say a big thank you to everyone who took the time (either through this survey or by contacting us directly) for your constructive suggestions and your positive feedback. Every bit helps us and motivates us!</p>

	<p><h3>Question 1. What is your nationality?</h3></p>
	<p>Most of the survey respondants were from the UK and USA, which we think is a reasonably accurate representation of our users. There were a few Australians and Canadians among them, and we were happy to see a good representation of users from other parts of the world as well. Our goal is for our fantasy league to be accessible to as many people as possible from all over the world!</p>
</div>
<div align="center"><img className="responsive" src="/img/Nationality.png"  alt="nationalities-graph" />
</div>
<div className="container">
	<p><h3>Question 2. What is your gender?</h3></p>	
	<p>We want ShePlays to be a space where everyone is welcome, so we were happy to see a reasonably even split of people identifying as female and male. Fantasy sport is typically a male-dominated hobby, while supporting women's soccer is more often the domain of women. We love to see lots of women playing fantasy and lots of men interested in following women's sport, so it's a win-win!</p>
</div>

<div align="center"><img className="responsive" src="/img/Gender.png" alt="gender-graph" />
</div>

<div className="container">
        <p><h3>Question 3. Before participating in the ShePlays WWC Fantasy League, had you played fantasy sport before?</h3></p>
	<p>Very few respondants were new to fantasy sport, which was was a little surprising. But in any case, it doesn't matter whether our users are experienced fantasy nerds or complete newbies, everyone is welcome here!</p>
</div>

<div align="center"><img className="responsive" src="/img/Fantasy.png" alt="fantasy-graph" />
</div>

<div className="container">
        <p><h3>Question 4. Before the 2019 FIFA Women's World Cup, to what extend had you watched or followed professional women's football?</h3></p>
	<p>There was a reasonably even spread of answers for this question, with about half of respondants saying they were either regular supporters or diehard fans, and the other half saying they were not regular supporters, had a passing interest or had never followed professional women's football before.</p>
</div>

<div align="center"><img className="responsive" src="/img/Supporter.png" alt="supporter-graph" />
</div>

<div className="container">
        <p><h3>Question 5. Did participating the the ShePlays Fantasy League make you more interested in following the results of Women's World Cup?</h3></p>
	<p>We were pleased to see that almost everyone who participated in the ShePlays Fantasy League did actually follow the results of the World Cup! And happier again to see that most people found themselves more interested in following the results because of ShePlays. That's what we're here for!</p>
</div>

<div align="center"><img className="responsive" src="/img/Results.png" alt="results-graph" />
</div>

<div className="container">
        <p><h3>Question 6. Which other women's leagues or sports would you be interested playing in a fantasy league for?</h3></p>
</div>

<div align="center"><img className="responsive" src="/img/OtherLeagues.png" alt="otherleagues-graph" />
</div>

<div className="container">
	<p>The most popular other responses for this question were (in no particular order):
	<ul>
		<li>Netball</li>
		<li>Volleyball</li>
		<li>Basketball</li>
		<li>Hockey</li>
		<li>Softball</li>
		<li>Swimming</li>
		<li>Gymnastics</li>
		<li>Tennis</li>
	</ul>
	</p>
	<p>We also had suggestions for the top women's football leagues in:
	<ul>
		<li>USA (NWSL)*</li>
		<li>Japan</li>
		<li>France</li>
		<li>Spain</li>
		<li>Sweden</li>
		<li>Norway</li>
		<li>Northern Ireland</li>
	</ul>
	*This will be covered by another great fantasy website, <a href="www.she-plays.com">she-plays.com</a>!
	</p>
	<p>We had several requests for the 2021 UFEA Women's Championship, which we will definitely consider!</p>
<p><h4>Tokyo 2020 Olympics</h4></p>
<p>
	One very interesting suggestion was to cover the Tokyo 2020 Olympics using all the female athletes from all the countries, with points awarded for where the athletes place in their event. Players expected to medal would be given the highest value, those with a chance to medal a lesser value, and those with an outside or no chance the least expensive. This would take a lot of preparation work, given the sheer number of athletes. Balancing the scoring would be a delicate task, due to the different capacity for multiple medals in some sports. However, it looks like a lot of fun! We'd love to do it if we had the capacity.<b> If it was you that suggested it, or you'd be interested in helping us</b> to prepare for such an event (by doing a lot of research!) then please do get in contact with us! We will do what we can to make it happen.
</p>

	<p><h3>Question 7. Feedback on the scoring system</h3></p>
	<p>This was the first of the open-ended questions. Thanks to everyone who took the time to give us feedback on these ones.</p>
<p>Generally the feedback on the extra 'attack' and 'defence' points was very positive. People enjoyed the fact that there were opportunities for players to be rewarded for their performances outside of goal scoring, assists and clean sheets. We were happy to see this because that's exactly what we think makes our scoring system special. The main piece of constructive feedback on this was to detail exactly how those attack and defence points are scored. We hear you! It can be difficult because some leagues and tournament hosts don't make all the detailed statistics available, so the system is slightly different for each league. Generally we use passes, crosses, shots, shots on target, chances created, tackles, intercepts and clearances. The final scores are a function of those statistics. For the World Cup we didn't have access to all of the statistics we normally use, so the system we ended up with is a little convoluted. Here it is!</p></div>
 <div className="container">
	<table className="table table-striped">
	    <thead className="thread-dark">
	        <tr>
	            <th scope="col">Attack Points</th>
	            <th scope="col"></th>
	            <th scope="col"></th>
                    <th scope="col"></th>
	        </tr>
	    </thead>
	    <tbody>
	        <tr>
	            <td>Number of crosses</td>
	            <td>2</td>
	            <td>4</td>
                    <td>7</td>
	        </tr>
	        <tr>
	            <td>Number of points</td>
	            <td>1</td>
	            <td>2</td>
                    <td>4</td>
	        </tr>
	        <tr>
	            <td></td>
	            <td></td>
	            <td></td>
                    <td></td>
	        </tr>
	        <tr>
	            <td>Number of shots</td>
	            <td>2</td>
	            <td>4</td>
                    <td>6</td>
	        </tr>
	        <tr>
	            <td>Number of points</td>
	            <td>1</td>
	            <td>2</td>
                    <td>4</td>
	        </tr>
	    </tbody>
	</table>
    </div>

 <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Defence Points</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of tackles</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Number of points</td>
                    <td>1</td>
                    <td>2</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Number of intercepts</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of points</td>
                    <td>1</td>
                    <td>2</td>
                    <td>4</td>
                </tr>
            </tbody>
        </table>
    </div>

<div className="container">
	<p>This system may look somewhat random, but we used all of our data from W-League matches and recent international friendlies to work out fair and balanced values.</p> 
	<p>Here were some of the other common suggestions for the scoring system:
	<ul>
		<li>Add points for penalty saves and deduct points for penalty misses during regular game time</li>
		<li>Award assist points for winning a penalty</li>
		<li>Award different points for goals, assists and other statistics depending on the player's position (i.e. attacking players rewarded more highly for defensive statistics, defensive players rewarded more highly for attacking statistics)</li>
		<li>Dual position eligibility (e.g. for wing players)</li>
		<li>Award a 'player of the match' bonus</li>
		<li>Increase the weighting of save points for goalkeepers</li>
		<li>Deduct points for own goals</li>
	</ul>
	We'll review all of these suggestions and will look at implementing some of them for our next leagues!
	</p>
	<p><h3>Question 8. Feedback on player transfers and transfer windows</h3></p>
	<p>During the World Cup we had a lot of feedback about transfers and requests for a more generous transfer allowance between rounds. However, in our survey, we had a lot of people saying the transfer allowance was just right and we shouldn't change it at all! Some of the other feedback was:
	<ul>
		<li>Email alerts (perhaps on an opt-in basis) or a more obvious way to show transfer windows</li>
		<li>Give each user a wildcard to be used at any point in the tournament for a full team transfer without penalty</li>
		<li>Be able to make transfer any time during a round, not just between rounds</li>
		<li>Remove players from selection who are no longer in the tournament (e.g. due to injury or being knocked out)</li>
		<li>Don't deduct transfer points from individual round scores (only from total score) so that people can sacrifice their overall score in order to get a good round score</li>
		<li>Have the ability to rearrange the captain and bench during a round (e.g. between games)</li>
		<li>Free transfers for eliminated players</li>
		<li>Be able to save un-used transfers for later rounds</li>
		<li>Leaderboard to show the most transferred players</li>
	</ul>
	Many of these are great suggestions, but unfortunately most of them will take significant coding effort to implement. For that reason we'll start working towards them as long term goals. We will not necessarily be able to implement them in time for our next few leagues.
</p>
	<p><h3>Question 9. New website features</h3></p>
	<p>We asked what features we should focus on adding to the website next. Here's what you said!</p>
</div>

<div> <img className="responsive" src="/img/Features.png" alt="results-graph"/>
</div>
	<div className="container">
	<p>Some of your suggestions for other features were:
	<ul>
		<li>Better mobile responsiveness and/or a mobile app (we'd love to but we're no app developers, let us know if you are one!)</li>
		<li>Easier way to create private leagues</li>
		<li>Public mini-leagues</li>
		<li>National and global rankings</li>
		<li>Faster updating scores (unfortunately with this one we are limited by when the statistics are provided to us, but we can work on getting fantasy team points up sooner!)</li>
		<li>Draft-style mini leagues</li>
		<li>Customised team logos/crests/jerseys (sounds fun, but might be out of our depth for now!)</li>
		<li>Being able to see other people's teams (for now we are limiting this to people you are in a private league with, for privacy reasons and also so you can't copy the global top ranked teams!)</li>
		<li>List statistics, total points and upcoming fixtures for each player on the player selection page, as well as how many fantasy teams they have been picked for</li>
		<li>Linking to other websites for league news, injury updates and match analysis</li>
		<li>Changing player prices throughout the tournament based on performance (although we also had some feedback NOT to do this, ah well!)</li>
		<li>Showing which club/team each user supports</li>
		<li>Social media integration for sharing team graphics and points</li>
		<li>Show change in user ranking from round to round</li>
		<li>A 'bench boost' round where all the players on the squad score points for one round</li>
		<li>Triple captain option for one round</li>
		<li>Be able to bookmark favourite players</li>
		<li>Be able to sort players by club in international tournaments</li>
	</ul>
	</p>

        <p><h3>Question 10. Miscellaneous other feedback!</h3></p>
	<p>Here are some of the other suggestions we received that didn't fit into the above categories!
	<ul>
		<li>Easier ways to find player statistics, rather than going through the match results page</li>
		<li>Add a name for the team owner</li>
		<li>Require fantasy teams to be set an hours or more before kick off, to avoid people seeing the starting lineups</li>
		<li>Show a breakdown of the acronyms used for scoring</li>
		<li>Show player images to help people get to know the players</li>
		<li>Go open source and invite people to submit bug fixes or features</li>
		<li>Show all players from a team without a page break in the team selector</li>
		<li>Clearer indication of who the vice captain is</li>
	</ul>
 	There were also several suggestions about how the user interface for team selection could be improved. The most popular approach was to use the graphical representation of players on the field. We'll do our best to get this feature implemented, but it is a challenging one so we can't promise it will be ready in time for our next few leagues.
	</p>

	</div>	

	<div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>


		</CompetitionWrapperWWC>
</>)

export default Feedback;
