import React from 'react';
import moment from 'moment';
import { CompetitionWrapperWWC } from "../../../components/layout/CompetionWrapperWWC23";
import BottomNavBar from '../../../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from "../../../data/competitions/wwc";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const HowToPlayWWC = () => (<>
    <div className="container">
        <h1>How to Play</h1>
        <h3>Register</h3>
        <p>To participate in the ShePlays fantasy competition you will need to register with our site. 
	Registering for ShePlays is free and easy, all you need is a valid email address, a username and a password.
	</p>
	<h3>Selecting your team</h3>
	<p> Once the World Cup squads have been annonced you will be able to start picking your fantasy team. 
	The players you select will earn points each match, 
	based on how well they play. Your team will have 15 players, and must include</p>  
	<ul>
	  <li>2 Goalkeepers</li>
	  <li>4 Defenders</li>
	  <li>4 Midfielders</li>
	  <li>3 Forwards</li>
	  <li>2 Free Choice players (any position)</li>
	</ul>
	<p>Initially your team can only have up to 3 players from any one country - this is to encourage you to learn more about the other players
	participating in the tournament.  
	</p>
		<div className=""></div><img className="rounded mx-auto d-block mb-3" src="/img/wwc/fra2.jpg" width="600"/>
	        <figcaption align="center"><a href="https://www.girlsontheball.com/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>

	<h3>Team budget and player prices</h3>
	<p>You will be managing your team with an initial budget of $8,000,000. Player prices vary from $100,000 up to $1,000,000 depending on the skill
	and experience of the player, with prices set based on playing statistics and performances at both national 
	and club level. Fantasy prices are simply an estimate of the number of points we think a player will score during the tournament, and is 
	not necessarily an indicator of the true talent or value of a player.</p>
	<p>You will have to stay within your budget when selecting your team, as well as during any player transfers you may make (see below).</p>  
	<h3>Transfers and match rounds</h3>
	<p>The World Cup consists of a group phase, followed by a knockout section. Every team at the tournament plays 3 games during the group 
	stage, before the top 16 teams advance to the knockout round.</p>
	<h5>Group Stage</h5>
	<p>The group phase is broken into 3 rounds, with every team playing once in each round. Between each round is a transfer window, where you
	may make changes to your team. During transfer windows you may trade player(s) from your squad for new player(s), as long as you stay
	within your budget and have enough players in each position. During transfer windows you can also make changes to your starting 
	eleven, or choose a new captain.</p>

	<p>During the group stage you may make 2 free transfers between rounds. You may also choose to make more than 2 player transfers in a window, however 
	each additional transfer will subtract 5 fantasy points from your total.</p>
	<h5>Knock-out Stage</h5>
	<p>As the tournament progresses and teams are knocked out, the player pool from which to select your team shrinks. To account for this, the 
	constraints on your team are relaxed with each transfer window. The number of free transfers, the maximum number of players you can select from any one team, and the
	total budget for each round are shown below.</p>
    </div>	
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Transfer Window</th>
	            <th scope="col">Round</th>
                    <th scope="col">Free Transfers</th>
	            <th scope="col">Max Players Per Nation</th>
                    <th scope="col">Budget</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1 and 2</td>
	            <td>Group Stage</td>
                    <td>2</td>
                    <td>3</td>
	            <td>$8,000,000</td>
                </tr>
	       <tr>
                    <td>3</td>
                    <td>Round of 16</td>
                    <td>5</td>
                    <td>3</td>
                    <td>$8,000,000</td>
                </tr>
               <tr>
                    <td>4</td>
                    <td>Quarter Finals</td>
                    <td>4</td>
                    <td>4</td>
                    <td>$8,500,000</td>
                </tr>
               <tr>
                    <td>5</td>
                    <td>Semi Finals</td>
                    <td>3</td>
                    <td>4</td>
                    <td>$9,000,000</td>
                </tr>
               <tr>
                    <td>6</td>
                    <td>Final/3rd Place</td>
                    <td>2</td>
                    <td>4</td>
                    <td>$9,000,000</td>
                </tr>
            </tbody>
        </table>
	<p>You can make additional transfers in each window, with 5 fantasy points subtracted from your total for every extra transfer.  
	</p>
	<h3>Selecting your captain and starting eleven</h3>
	<p>At the start of each round of matches you will choose your starting eleven players who will earn points for your team.
        If one of your starting players does not participate in the round,
        then a player from your bench will take their place. Your eleven active players must be in an allowed formation. </p>
	<ul>
	    <li>3 Defenders - 5 Midfielders - 2 Forwards (3-5-2)</li>
	    <li>3 Defenders - 4 Midfielders - 3 Forwards (3-4-3)</li>
            <li>4 Defenders - 3 Midfielders - 3 Forwards (4-3-3)</li>
            <li>4 Defenders - 4 Midfielders - 2 Forwards (4-4-2)</li>
            <li>5 Defenders - 3 Midfielders - 2 Forwards (5-3-2)</li>
	</ul>
	<p>You can also select one of your players to be your team's captain. Your captain will earn double points towards your team's total, so choose wisely!</p>
        <h5>Vice Captain</h5>
    <p>Your vice captain is the first name in your starting team below your captain. If your captain does not play in a round, then your vice captain will earn double points instead. 
    It is recommended to order your players on the Manage Team page with your favourite/most important players at the top of your list.</p>
	<h3>Scoring</h3>
	<p>Points are scored by players based on their performance in a range of statistics. Players will earn points by scoring goals and assists, and will lose 
	points if they earn a yellow or red card. They may also receive points based on various attacking/defensive/goalkeeping statistics, such as shots, 
	tackles, intercepts, saves etc. The more positive contributions a player makes to a game, the more fantasy points they are likely to score. Check 
	out the table below to see how points will be awarded.</p>
	<p><b>The probable scoring matrix is below. This may need to be modified slightly before the competition starts, based on the individual statistics 
	that are available for the tournament.</b></p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Statistic</th>
                    <th scope="col">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Minutes Played</td>
                    <td>2pts (> 60 min), 1pt (>1 min)</td>
	        </tr>
	        <tr>
                    <td>Goals</td>
                    <td>5pts</td>
                </tr>
                <tr>
                    <td>Assists</td>
                    <td>3pts</td>
                </tr>
                <tr>
                    <td>Yellow Card</td>
                    <td>-2pts</td>
                </tr>
                <tr>
                    <td>Red Card</td>
                    <td>-5pts</td>
                </tr>
                <tr>
                    <td>Clean Sheet</td>
                    <td>GK and DF = 5pts (> 60 min)<br/>
	                GK and DF = 2pts (> 1 min)</td>
                </tr>
                <tr>
                    <td>Attack Points</td>
                    <td>Based on various attacking statistics (see below)</td>
                </tr>
                <tr>
                    <td>Defense Points</td>
                    <td>Based on various defending statistics (see below)</td>
                </tr>
                <tr>
                    <td>Goalkeeper Points</td>
                    <td>Based on various goalkeeping statistics (see below)</td>
                </tr>
                <tr>
                    <td>W/D/L</td>
                    <td>1/0/0</td>
                </tr>
                <tr>
                    <td>Goal Difference</td>
                    <td>(GF-GA)/2 rounded down</td>
                </tr>
	    </tbody>
	</table>
       <h5>Attack, Defence, and Goalkeeper Points</h5>
        <p>Players may also earn fantasy points based on how they perform in a range of statistics - this is to reward players who have a large influence on the match,
        but may not get onto the score sheet. For example, a player may earn attack points by completing a large number of passes:
        <ul>
          <li> Over 40 passes = 1 Attack Point</li>
          <li> Over 55 passes = 2 Attack Points</li>
          <li> Over 70 passes = 3 Attack Points</li>
        </ul>
        Players may also earn bonus attack points for shots on target, or chances created. Similarly, goalkeepers will earn 5 bonus GK points for any penalty saved.
        For a full breakdown of the Attack/Defence/Goalkeep points, see the table below.
        </p>
        <p align="center"><b>Note that any player can earn Attack/Defence points, regardless of their position.</b></p>
        </div>

    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Attack Points</th>
                    <th scope="col">1 Point</th>
                    <th scope="col">2 Points</th>
                    <th scope="col">3 Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of passes</td>
                    <td>40</td>
                    <td>55</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>Number of crosses</td>
                    <td>3</td>
                    <td>5</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Number of shots</td>
                    <td>3</td>
                    <td>5</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Chances created</td>
                    <td colspan="3">1 point per chance created</td>
                </tr>
                <tr>
                    <td>Shots on target</td>
                    <td colspan="3">1 point per shot on target</td>
                </tr>
            </tbody>
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Defence Points</th>
                    <th scope="col">1 Point</th>
                    <th scope="col">2 Points</th>
                    <th scope="col">3 Points</th>
                </tr>
            </thead>
            <tbody>
               <tr>
                    <td>Number of tackles</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of intercepts</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of clearances</td>
                    <td>4</td>
                    <td>7</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Number of Aerial Challenges</td>
                    <td>3</td>
                    <td>4</td>
                    <td>6</td>
                </tr>
            </tbody>
            <thead className="thread-dark">
                <tr>
                    <th scope="col">GK Points</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Saves/smoother/claim</td>
                    <td colspan="3">0.5 points per save, sweeper action, and cross claimed (rounded down)</td>
                </tr>
                <tr>
                    <td>Penalty saves</td>
                    <td colspan="3">5 points per penalty save</td>
                </tr>
            </tbody>
        </table>
        <p>Note that there are no point deductions for missing a penalty, and that earning a penalty is not counted as an assist.</p>


        <h5>Extra Time and Penalty Shootouts</h5>
        <p>During the knock-out stage of the tournament, if a game is still tied after 90 minutes it will go to 
        Extra Time (2 x 15 minute halves). During Extra Time players will continue to earn fantasy points as normal.</p>
        <p>If the game is still tied after Extra Time, the match will be decided by a Penalty Shootout. During the 
        Shootout, players will earn <b>3 points if they score a goal</b>. Goalkeepers will earn <b>3 points if they save 
        a goal.</b> There are no points subtracted for missed shots, having your shot saved, or letting a goal in.</p> 
	<h5>Stats by FBref <img align="right"src="/img/fbref_wwc_logo.png" width="150" hspace="20"/></h5>
        <p>The statistics we use to calculate fantasy points for the 2023 Women's World Cup are generously provided by <a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019">FBref</a>. Visit FBref.com often to check in on the performance of your favourite players and teams.</p>
    </div>	
	<BottomNavBar/>


</>)

export default HowToPlayWWC;
