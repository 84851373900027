import React, { useState} from 'react';
import { useAuth } from "../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperWL } from '../../components/layout/CompetionWrapperWL';
import { wl } from '../../data/competitions/wl';
import BottomNavBar from '../../components/layout/MCompetionWrapperWL.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};




const WLLandingPageD = () => (<CompetitionWrapperWL competition={wl}>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>



  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wl/wl-1.png" alt="" />
	    <figcaption align="center"><a href="https://www.instagram.com/klzphotography/" title = "Kellie Lemon" target="blank"> Kellie Lemon (Instagram - @klzphotography)</a><br/>All rights reserved</figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlaysALeague</h2>
	      <p  style={{textAlign:'justify'}}>
	      We will be taking a break from running our fantasy A-League women competition this year - but don't worry, 
	      as the ALeagues have you covered with the launch of their own fantasy womens competition this season! To start 
	      building your official A-League Women's fantasy team head to <a href="https://play.keepup.com.au/" target ="_blank">
	      www.play.keepup.com.au </a>. If you need help selecting your team, you can still browse our extensive collection of 
	     player profiels, and all our fantasy stats from the last 5 years!
	      </p>
            </div>
          </div>
        </div>
        <div className="leftBox">
        <p style={{textAlign:'justify'}}>
	        We are taking a break from our fantasy A-League Women competition this season. But you can still play fantasy 
	         A-League Women over at <b><a href="https://play.keepup.com.au/" target ="_blank">
             www.play.keepup.com.au </a>. </b> 
                 Make sure you follow us on
                <a href="https://twitter.com/sheplays_au" target="_blank">
                  {" "}
                  Twitter
                </a>
                ,{" "}
                <a
                  href="https://www.instagram.com/sheplays_au/"
                  target="_blank"
                >
                  {" "}
                  Instagram
                </a>{" "}
                and{" "}
                <a
                  href="https://www.facebook.com/sheplaysfantasyfootball/"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                to keep up to date with the latest news and announcements. 

              </p>
        </div>
      </div>
  <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
        <div className="centerBox">
        <h5>Build your A-League fantasy team</h5>
        <iframe width="90%" height="255"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
        <div className="col-lg-6 order-lg-1">
        <div className="centerBox">
        <h5>Create your free ShePlays account</h5>
        <iframe width="90%" height="255" src="https://www.youtube.com/embed/LevVeCEt3bc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
      </div>
     </div>
  </section>

  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wl/wl-2.png" alt="" />
	<figcaption align="center"><a href="https://www.instagram.com/klzphotography/" title = "Kellie Lemon" target="blank"> Kellie Lemon (Instagram - @klzphotography)</a><br/>All rights reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">How to play</h2>
               <p style={{textAlign:'justify'}}>You'll be given a set budget to spend on the 15 players that will make up your fantasy team. 
               Your players will then receive points after each game based on how well they play.
               After each round of matches you will get the chance to make transfers, select your starting XI,
               and choose a captain who will earn double points in the next round. You can also set up your own
               mini-leagues to compete against your friends! For more information see the <a href="/how-to-play"
               target="blank">How To Play</a> page.</p>

            </div>
          </div>
        </div>
      </div>
  </section>

  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wl/wl-3.png" alt="" />
	<figcaption align="center"><a href="https://www.instagram.com/klzphotography/" title = "Kellie Lemon" target="blank"> Kellie Lemon (Instagram - @klzphotography)</a><br/>All rights reserved</figcaption>
  </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
	<h2 className="display-4">Player Research</h2>
              <p style={{textAlign:'justify'}}>
                 Need help deciding who to pick for your team? Or just want to learn more about your favourite players? We can help,
                 with our full set of fantasy stats from the 17/18, 18/19, 19/20, and 20/21 seasons! You can browse previous season stats
                 centers, player databases, and ShePlays leaderboard in the Past Seasons tab. We also have an <a href="/wl/all-players"
                 target="blank">All-Seasons Player Database</a> where you can find the most consistant performers every season.
                 We also have a large library of in-depth player profiles that can be accessed from the Player Database and Teams
                 pages. Here you can learn more about the playing history, international experience, awards won, and even interesting
                 personal facts about the players in the W-League.
              </p>
            </div>
          </div>
        </div>
      </div>
  </section>
  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperWL>)

const WLLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadWL text-center text-white">
      <div className="masthead-contentWL">
        <div className="container">
          <h1 className="masthead-headingWWC mb-0">ShePlays A-League Women 2021/22</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
	  We are taking a break from our fantasy A-League Women competition this season. But you can still play fantasy
                 A-League Women over at <b><a href="https://play.keepup.com.au/" target ="_blank">
             www.play.keepup.com.au </a>. </b>
          </p>
        {currentUser ? (
        <a href="/wsl/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My WSL Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}
        </div>
      </div>
    </header>
<div className="container">
<a href='/wl/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
<a href='/wl/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results
        </a>
<a href='/wl/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams
        </a>
<a href='/wl/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Players
        </a>
<a href='/wl/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News
        </a>
<a href='/wl/join-us' className="btn btn-teams btn-l rounded-pill mt-1" >
        Join Our Team
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

        <BottomNavBar />
</>     );
};


const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <WLLandingPageM /> : <WLLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}



