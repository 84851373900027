import React, { useState } from 'react';
import { useAuth } from "../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperWWC } from '../components/layout/CompetionWrapperWWC';
import BottomNavBar from '../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from '../data/competitions/wwc';
import { faSearch, faHome, faUserCircle, faTrophy, faIdCard, faCalendarAlt, faClipboard, faBookReader} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaTrophy } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { FaClipboard } from "react-icons/fa";
import { FaBookReader } from "react-icons/fa";
import { FaIdCard } from "react-icons/fa";
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const WWCLandingPageD = () => (<CompetitionWrapperWWC competition={wwc}>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>



  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/usa-js.png" alt="" />
	    <figcaption align="center">Photo by <a rel="nofollow" className="external free" href="https://www.flickr.com/photos/highwaytodistraction/40916779703/">
	    Jamie Smed</a>, <a href="https://creativecommons.org/licenses/by/2.0" title="Creative Commons Attribution 2.0">CC BY 2.0</a> </figcaption> 
	  </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Congratulations Team USA!</h2>
               <p>Team USA have claimed their fourth FIFA Women's World Cup title! They were absolutely unstoppable
                 throughout the tournament and are well-deserved in retaining their world number one ranking.</p> 
               <p>Re-live the action in France on our <a href='/wwc/fixtures'>Fixtures & Results</a> page. Browse match results, 
               along with breakdowns of each player's fantasy scores!</p>
            </div>
          </div>
        </div>
      </div>
  </section>

  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wwc-winner.png" alt="" />
            
            <figcaption align="center">Photo by ShePlays, <a href="https://creativecommons.org/licenses/by/2.0" title="Creative Commons Attribution 2.0">CC BY 2.0</a> </figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">Fantasy Teams</h2>
              <p>We had almost 2,000 teams entered in our FIFA Women's World Cup  fantasy competition! Congratulations 
                to all of our winners - especially our overall ShePlays Champion the Indomitable Drakaina's, who took out first 
                place with a massive 868 points! Check out how your team fared against the rest on our <a href='/wwc/leaderboard'>Global Leaderboard</a>.  </p>
            </div>
          </div>
        </div>
      </div>
  </section>

  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/AusChiVanEgmond.jpg" alt="" />
            <figcaption align="center">Photo by ShePlays, <a href="https://creativecommons.org/licenses/by/2.0" title="Creative Commons Attribution 2.0">CC BY 2.0</a> </figcaption>
  </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Browse your favourite players</h2>
              <p>Check out how your favourite players performed on our <a href='/wwc/players'>Players</a> page. This is a 
              great place to start planning who you may want to select on your next fantasy team! You can also view our WWC team profiles, look at our <a href='/wwc/name-game'>Name Game </a> 
               finalists, or have a read of our <a href='/wwc/feedback'>Survey Results</a>.</p>
            </div>
          </div>
        </div>
      </div>
  </section>
  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperWWC>)


const WWCLandingPageM = () => {
	const currentUser = useAuth()	
	return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadWWC text-center text-white">
      <div className="masthead-contentWWC">
        <div className="container">
          <h1 className="masthead-headingWWC mb-0">ShePlays 2019 World Cup</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
	  Relive the action in France, and get ready for WWC2023 in Australia and NZ!
          </p>
	{currentUser ? (
        <a href="/wwc/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My WWC Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}
        </div>
      </div>
    </header>
<div className="container">
<a href='/wwc/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
	How To Play 
	</a>
<a href='/wwc/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results 
        </a>
<a href='/wwc/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams 
        </a>
<a href='/wwc/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Players 
        </a>
<a href='/wwc/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard 
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News 
        </a>
<a href='/wwc/m/feedback' className="btn btn-teams btn-l rounded-pill mt-1" >
        Why Fantasy Matters
        </a>
<a href='/join-us' className="btn btn-teams btn-l rounded-pill mt-1" >
        Join Our Team
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
	    <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

	<BottomNavBar />
</>	);
};


const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 620;

  return width < breakpoint ? <WWCLandingPageM /> : <WWCLandingPageD />;
};


export default function App() {
	const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


