import "@devexpress/dx-react-grid";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";
import { CompetitionWrapperAFLW } from "../../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../../data/competitions/aflw-2020";
import data from "../../../data/aflw/2020/leaderboard";

const columns = [
  { title: "Rank", name: "rank" },
  { title: "Team Name", name: "teamName" },
  {
    title: "R1",
    name: "r1",
    getCellValue: t => t.score["round-1"] || 0
  },
  {
    title: "R2",
    name: "r2",
    getCellValue: t => t.score["round-2"] || 0
  },
  {
    title: "R3",
    name: "r3",
    getCellValue: t => t.score["round-3"] || 0
  },
  {
    title: "R4",
    name: "r4",
    getCellValue: t => t.score["round-4"] || 0
  },
  {
    title: "R5",
    name: "r5",
    getCellValue: t => t.score["round-5"] || 0
  },
  {
    title: "R6",
    name: "r6",
    getCellValue: t => t.score["round-6"] || 0
  },
  {
    title: "Total",
    name: "totalScore",
    getCellValue: t => t.score["total"] || 0
  }
];

const FilterCell = props => {
  const { column } = props;
  if (column.name === "teamName") {
    return <TableFilterRow.Cell {...props} />;
  }
  return <th />;
};

export const AFLWLeaderboard2020 = () => (
  <>
    <CompetitionWrapperAFLW competition={aflw}>
      <div className="container clearfix mt-32">
        <h1>Global Leaderboard 2020</h1>
	<p>Check out how your fantasy AFLW team went last season  
	in our global leaderboard from 2020 below! Congratulations to our inaugural ShePlaysAFLW Champion 'The Green Beans'!</p>
      </div>
      <div className="container">
        <Grid
          rows={data.teams.map((d, i) => ({ ...d, rank: i + 1 }))}
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={50} />

          <SortingState defaultSorting={[]} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table />
          <TableHeaderRow showSortingControls />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel />
        </Grid>
      </div>
      <div className="container">
        <p></p>
      </div>
    </CompetitionWrapperAFLW>
  </>
);
export default AFLWLeaderboard2020;
