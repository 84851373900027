import React from "react";
import Flag from "react-world-flags";

import moment from "moment";
import { CompetitionWrapperWL } from "../../components/layout/CompetionWrapperWL";
import { wl } from "../../data/competitions/wl";
import * as numeral from 'numeral';
import data from "../../data/wl/2019/teams/newcastle-jets.json";


const WLtransfers = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
        <h1>Transfer News</h1>
        <p>blah</p>
    </div>
    <div className="container">
      <h3>Players In</h3>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
	    <th scope="col">Nationality</th>
            <th scope="col">Age</th>
            <th scope="col">Points 2017/18</th>
            <th scope="col">Points 2018/19</th>
	    <th scope="col">Price</th>
	    <th scope="col">From</th>
          </tr>
        </thead>
        <tbody>
        {data.in.map(
          ({
            Name,
            Pos,
            Nat,
            Price,
            Points17,
            Points18,
	    Old
          }) => (
            <tr key={Name}>
              <td>{Name}</td>
              <td>{Pos}</td>
	      <td><img hspace="20" src={`/img/RoundFlags/${Nat}.png`} width="40"/></td>
              <td></td>
              <td>{Points17}</td>
              <td>{Points18}</td>
	      <td>{Price}</td>
	      <td>{Old}</td>
             </tr>
            )
          )}
          </tbody>
        </table>
      </div>
    <div className="container">
      <h3>Players Out</h3>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
	    <th scope="col">Nationality</th>
            <th scope="col">Age</th>
            <th scope="col">Points 2017/18</th>
            <th scope="col">Points 2018/19</th>
	    <th scope="col">Price</th>
	    <th scope="col">To</th>
          </tr>
        </thead>
        <tbody>
        {data.out.map(
          ({
            Name,
            Pos,
            Nat,
            Price,
            Points17,
            Points18,
	    New
          }) => (
            <tr key={Name}>
              <td>{Name}</td>
              <td>{Pos}</td>
	      <td><img hspace="20" src={`/img/RoundFlags/${Nat}.png`} width="40"/></td>
              <td></td>
              <td>{Points17}</td>
              <td>{Points18}</td>
	      <td>{Price}</td>
	      <td>{New}</td>
             </tr>
            )
          )}
          </tbody>
        </table>
      </div>
    <div className="container">
      <h3>Returning Players</h3>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
	    <th scope="col">Nationality</th>
            <th scope="col">Age</th>
            <th scope="col">Points 2017/18</th>
            <th scope="col">Points 2018/19</th>
	    <th scope="col">Price</th>
          </tr>
        </thead>
        <tbody>
        {data.resign.map(
          ({
            Name,
            Pos,
            Nat,
            Price,
            Points17,
            Points18
          }) => (
            <tr key={Name}>
              <td>{Name}</td>
              <td>{Pos}</td>
	      <td><img hspace="20" src={`/img/RoundFlags/${Nat}.png`} width="40"/></td>
              <td></td>
              <td>{Points17}</td>
              <td>{Points18}</td>
	      <td>{Price}</td>
             </tr>
            )
          )}
          </tbody>
        </table>
      </div>
</CompetitionWrapperWL>
</>
export default WLtransfers
