import React from 'react';
import numeral from 'numeral';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWL } from '../../components/layout/CompetionWrapperWL';
import { wl } from '../../data/competitions/wl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wl/teamLists/jsondatabase.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Position', name: 'Position'},
    { title: 'Player Name', name: 'Name'},
    { title: 'Team', name: 'Club'},
    /*{ title: 'Nationality', name: 'Nationality'},*/
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
    { title: 'Price', name: 'Price',
       getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: '2019/20', name: 'Points19',
       getCellValue: t => t.Points19},
    { title: '2020/21', name: 'Points20',
       getCellValue: t => t.Points20},
    { title: '2021/22', name: 'Points',
      getCellValue: t => t.Points},
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const NameFormatter = ({value, row}) => {
  if(row.Link) {
    return <a href={`/wl/players/${row.Link}`}>{value}</a>
  } else {
    return value;
  }
}

const NameTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NameFormatter}
    {...props}
  />
);


const DatabaseWL = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
	<h1>Player Database</h1>
	<p>Want to research players for your fantasy team, or are you interested in learning more about the 
           incredible athletes in the W-League? Then this is the place to be! 
	   You can search for every player from the W-League 2021/22 season
           (by name, club, or position) in our database below, where  
           you can also see the total number of points each player has earned throughout the season. 
	   For a complete breakdown of each players fantasy points, visit the <a href="/wl/stats">Stats Center</a>.</p>
	<h3>Player Profiles</h3>
	<p>
          Want to see how many attack points a player has, or are you looking to find someone who is consistently earning points every round?
          Check out our player profiles, by clicking on a players 2021/22 name! Here you will see a total breakdown of the fantasy points that player has earned each round. 
	 Some profiles will also have a bio describing their background, photos, and interesting player-facts! We'll continue to add to this collection throughout the season, so remember to come back here often to learn more about your favourite players!
	</p>
    </div>


    <div className="container">
	<h5 align="right" >- - - - - Fantasy Points - - - - -</h5>
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
	    <NameTypeProvider for={['Name']} />
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWL>
</>
export default DatabaseWL
