import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const EspvZaf = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/Spain.png" width="50" hspace="20" />Spain v South Africa<img src="img/RoundFlags/SouthAfrica.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group B</center></h5>
	<p><center><b>3 - 1</b><br/>Stade Oceane<br/>
	Le Havre<br/>
	{localDate("2019-06-08 18:00+02")}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	            <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>
<tr>
<td>Jenifer Hermoso</td>
<td>ESP</td>
<td>25</td>
<td>2</td>
<td>10</td>
<td>0</td>
<td>11</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Lucía García</td>
<td>ESP</td>
<td>11</td>
<td>1</td>
<td>5</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Maria Caldentey</td>
<td>ESP</td>
<td>10</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>5</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Marta Corredera</td>
<td>ESP</td>
<td>9</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>4</td>
<td>3</td>
<td>0</td>
<td>-2</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Thembi Kgatlana</td>
<td>ZAF</td>
<td>9</td>
<td>2</td>
<td>5</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Alexia Putellas</td>
<td>ESP</td>
<td>7</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Virginia Torrecilla</td>
<td>ESP</td>
<td>7</td>
<td>2</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Marta Torrejón</td>
<td>ESP</td>
<td>6</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Irene Paredes</td>
<td>ESP</td>
<td>6</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Andile Dlamini</td>
<td>ZAF</td>
<td>5</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>4</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Nahikari García</td>
<td>ESP</td>
<td>4</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Sandra Paños</td>
<td>ESP</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Vicky Losada</td>
<td>ESP</td>
<td>4</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>María León</td>
<td>ESP</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Aitana Bonmatí</td>
<td>ESP</td>
<td>3</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Amanda Sampedro</td>
<td>ESP</td>
<td>3</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Kholosa Biyana</td>
<td>ZAF</td>
<td>3</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>4</td>
<td>0</td>
<td>-2</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Lebohang Ramalepe</td>
<td>ZAF</td>
<td>3</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Janine van Wyk</td>
<td>ZAF</td>
<td>3</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>4</td>
<td>0</td>
<td>-2</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Linda Motlhalo</td>
<td>ZAF</td>
<td>3</td>
<td>1</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Noko Matlou</td>
<td>ZAF</td>
<td>3</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Ode Fulutudilu</td>
<td>ZAF</td>
<td>1</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Refiloe Jane</td>
<td>ZAF</td>
<td>1</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Amanda Mthandi</td>
<td>ZAF</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Busisiwe Ndimeni</td>
<td>ZAF</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Leandra Smeda</td>
<td>ZAF</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Jermaine Seoposenwe</td>
<td>ZAF</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Nothando Vilakazi</td>
<td>ZAF</td>
<td>-1</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-2</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
            </tbody>
	</table>
    </div>	

</>)

export default EspvZaf;
