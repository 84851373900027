import React, { useState, useEffect, useCallback } from "react";
import { Alert } from "mdbreact";
import // State or Local Processing Plugins
"@devexpress/dx-react-grid";
import { Label, FormGroup } from "reactstrap";
import classnames from "classnames";
import {
  PagingPanel,
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SelectionState,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import numeral from "numeral";
import _ from "lodash";

import { Input } from "reactstrap";
import { OrderTeamDynamic } from "./OrderTeamDynamic";
import { DisplayTeam } from "./DisplayTeam";
import { Auth, API } from "aws-amplify";
import { NotificationBox } from "../NotificationBox";
import moment from "moment";
import TeamScores from "./TeamScores";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

const localDate = dateString => moment(dateString).format("Do MMMM, h:mm A");
const matchLink = dateString => moment(dateString).format("Do MMMM, h:mm A");

const columns = [
  { title: "Name", name: "name" },
  { title: "Position", name: "position" },
  { title: "Club", name: "clubName" },
  { title: "Fantasy Price", name: "fantasyPrice" }
];

const PositionPicker = (sport) => ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      {Object.keys(sport.positions).map(pos => (
        <option key={pos}>{pos}</option>
      ))}
    </Input>
  </th>
);

const PriceRange = () => <th></th>;

const FilterCell = sport => props => {
  const { column } = props;
  if (column.name === "position") {
    return PositionPicker(sport)(props);
  }
  if (column.name === "name") {
    return <TableFilterRow.Cell {...props} />;
  }
  if (column.name === "clubName") {
    return <TableFilterRow.Cell {...props} />;
  }
  if (column.name === "country") {
    return <TableFilterRow.Cell {...props} />;
  }
  if (column.name === "fantasyPrice") {
    return PriceRange(props);
  }
  return <TableFilterRow.Cell {...props} />;
};

const teamCountError = (sport, selection) => {
  const count = selection.length;
  if (count !== sport.squadSize)
    return [
      `Your team must have ${sport.squadSize} players in total (you currently have ${count})`
    ];
  return [];
};

const budgetError = (selection, totalBudget) => {
  const count = selection.length;
  var budget = totalBudget;
  for (var i = 0; i < count; i++) {
    budget -= selection[i].fantasyPrice;
  }
  if (budget < 0)
    return [
      `Your team must cost less than ${numeral(totalBudget).format(
        "$0,0"
      )} (your current balance is: ${numeral(budget).format("$0,0")})`
    ];
  return [];
};

const clubErrors = (selection, perClubLimit) => {
  return _.chain(selection)
    .groupBy("clubName")
    .pickBy(arr => arr.length > perClubLimit)
    .map(
      (arr, club) =>
        `Your team cannot have more than ${perClubLimit} players from the same club (you currently have ${arr.length} from ${club})`
    )
    .value();
};

const getBudget = (selection, totalBudget) => {
  const count = selection.length;
  var budget = totalBudget;
  for (var i = 0; i < count; i++) {
    budget -= selection[i].fantasyPrice;
  }
  return [`Your balance remaining is ${numeral(budget).format("$0,0")}`];
};

const plural = (n, s) => (n === 1 ? s : `${s}s`);

const squadPosErrors = (sport, posCode, { name, squad }) => selection => {
  const count = selection.filter(player => player.position === posCode).length;
  if (count < squad.min)
    return [
      `Your team must have at least ${squad.min} ${plural(
        squad.min,
        name
      )} (you currently have ${count})`
    ];
  else if (count > squad.min + sport.freechoice)
    return [
      `Your team cannot have more than ${squad.min + sport.freechoice} ${plural(
        squad.min + sport.freechoice,
        name
      )}  (you currently have ${count})`
    ];
  return [];
};

const squadErrors = sport => selection =>
  Object.entries(sport.positions)
    .map(([posCode, pos]) => squadPosErrors(sport, posCode, pos)(selection))
    .reduce((a, b) => [...a, ...b], []);

const startingPosErrors = (sport, posCode, { name, starting }) => selection => {
  const startingCount = selection.filter(player => player.position === posCode)
    .length;
  if (startingCount < starting.min)
    return [
      `Your team must have at least ${starting.min} ${plural(
        starting.min,
        name
      )} (you currently have ${startingCount})`
    ];
  else if (startingCount > starting.max)
    return [
      `Your starting team cannot have more than ${starting.max} ${plural(
        starting.max,
        name
      )} (you currently have ${startingCount})`
    ];
  return [];
};

const startingErrors = sport => selection =>
  Object.entries(sport.positions)
    .map(([posCode, pos]) => startingPosErrors(sport, posCode, pos)(selection))
    .reduce((a, b) => [...a, ...b], []);

const nameError = teamName => {
  if (!teamName) return ["Your team must have a name"];
  return [];
};

const getTeamSelection = competitionLong => {
  try {
    return JSON.parse(
      localStorage.getItem(`${competitionLong}TeamSelection`) || "[]"
    );
  } catch (e) {
    return [];
  }
};

const unsavedChanges = {
  message: "You have unsaved changes to your team.",
  severity: "warning"
};

const getTimer = (currentRound, nextRound) => {
  return currentRound ? currentRound.closes.fromNow() : null;
};

const getScorePlayerList = (roundResults, roundTeams, r) => {
  if (roundResults && roundResults[r] && roundResults[r].players) {
    return roundResults[r].players || [];
  } else if (roundTeams && roundTeams[r]) {
    return roundTeams[r].map(playerId => ({ playerId }));
  } else {
    return [];
  }
};

const MyTeam = ({
  init,
  sport,
  rounds,
  totalBudget,
  perClubLimit,
  competition,
  season,
  teams,
  shirts,
  hashtag,
  freeTransfers,
  transferCost
}) => {
  if(init) {
    init()
  }
  const competitionLong = season ? `${competition}-${season}` : competition;
  const [isLoaded, setIsNowLoaded] = useState(null);

  const now = moment();
  const currentRound = rounds.find(
    ({ opens, closes }) => now.isAfter(opens) && now.isBefore(closes)
  );
  const nextRound = rounds.find(({ opens }) => now.isBefore(opens));
  const lastRound = rounds.filter(({ closes }) => now.isAfter(closes)).pop();

  const [roundTeams, setRoundTeams] = useState({});
  const [roundResults, setRoundResults] = useState({});
  const [players, setPlayers] = useState([]);

  const [tab, setTab] = useState(currentRound ? "manage" : lastRound.round);
  const [newPlayers, setNewPlayers] = useState([]);
  const [timer, setTimer] = useState(getTimer(currentRound, nextRound));
  const [scores, setScores] = useState({});

  const [selection, changeSelection] = useState(getTeamSelection(competitionLong));

  const [savedTeam, setSavedTeam] = useState(null);
  const [teamName, setTeamName] = useState(
    localStorage.getItem(`${competitionLong}TeamName`) || ""
  );
  const [notification, setNotification] = useState(null);

  const [errors, setErrors] = useState([]);
  const [teamErrors, setTeamErrors] = useState([]);
  const [budget, setBudget] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const roundStarted = currentRound === undefined;

  /** TODO: HOOK THIS */

  const roundErrors = roundStarted =>
    roundStarted
      ? [
          `${lastRound.name} has started! No changes can be made to your team until the next transfer window. See the Fixtures page for more information.`
        ]
      : [];

  const getErrors = (selection, teamName, roundStarted) => [
    ...roundErrors(roundStarted),
    ...squadErrors(sport)(selection),
    ...clubErrors(selection, perClubLimit),
    ...budgetError(selection, totalBudget),
    ...teamCountError(sport, selection),
    ...nameError(teamName)
  ];

  /** ^^^ */

  useEffect(() => {
    const selectedPlayers = (selection || [])
      .map(id => players.find(({ playerId }) => id === playerId))
      .filter(p => p !== undefined);

    setErrors(getErrors(selectedPlayers.slice(0, sport.squadSize), teamName, roundStarted));
    setBudget(getBudget(selectedPlayers.slice(0, sport.squadSize), totalBudget));
    setTeamErrors(startingErrors(sport)(selectedPlayers.slice(0, sport.teamSize)));
    setSelectedPlayers(selectedPlayers);
  }, [players, teamName, selection, roundStarted, sport, totalBudget]);

  useEffect(() => {
    setInterval(() => {
      const timeRemaining = getTimer(currentRound, nextRound);
      setTimer(timeRemaining);
    }, 1000);
  }, []);

  useEffect(() => {
    if (savedTeam && !errors.length && !teamErrors.length) {
      if (
        savedTeam.length !== selection.length ||
        !savedTeam.every((savedPlayer, i) => savedPlayer === selection[i])
      ) {
        setNotification(unsavedChanges);
      } else {
        if (notification === unsavedChanges) {
          setNotification(null);
        }
      }
    }
  }, [selection, savedTeam, errors, teamErrors, notification]);

  useEffect(() => {
    if (lastRound && roundTeams[lastRound.round]) {
      const np = selection
        .slice(0, sport.squadSize)
        .filter(
          id => !roundTeams[lastRound.round].some(playerId => id === playerId)
        );
      setNewPlayers(np);
    }
  }, [selection, roundTeams]);

  const onChangeSelection = useCallback(
    state => {
      changeSelection(state);
      localStorage.setItem(
        `${competitionLong}TeamSelection`,
        JSON.stringify(state)
      );
    },
    [changeSelection]
  );

  const updateTeamOrder = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newSelection = selection.slice();
        const moved = newSelection.splice(oldIndex, 1);
        newSelection.splice(newIndex, 0, moved[0]);
        onChangeSelection(newSelection);
      }
    },
    [onChangeSelection, selection]
  );

  const onDropPlayer = useCallback(
    index => {
      const newSelection = selection.slice();
      newSelection.splice(index, 1);
      onChangeSelection(newSelection);
    },
    [selection, onChangeSelection]
  );

  const resetTransfers = useCallback(
    () =>
      onChangeSelection([
        ...savedTeam,
        ...selection.filter(s => !savedTeam.includes(s))
      ]),
    [savedTeam, selection, onChangeSelection]
  );

  const loadTeam = (data, selection) => {
    changeSelection(selection);
    localStorage.setItem(
      `${competitionLong}TeamSelection`,
      JSON.stringify(selection)
    );
    setTeamName(data.teamName);
    localStorage.setItem(`${competitionLong}TeamName`, data.teamName);
    localStorage.setItem("localUserId", data.userId);
    localStorage.setItem(`${competitionLong}TeamVersion`, data.version);
  };

  const saveTeam = useCallback(async () => {
    if (currentRound) {
      const user = await Auth.currentAuthenticatedUser();
      const session = user.signInUserSession;

      const token = session.idToken && session.idToken.jwtToken;
      const headers = {
        "x-auth-token": token
      };

      const version = localStorage.getItem(`${competitionLong}TeamVersion`) || 0;

      const body = {
        players: selection,
        teamName,
        version
      };

      const result = await API.put("team", `/${competition}/team`, {
        headers,
        body
      });
      localStorage.setItem(`${competitionLong}TeamRound`, currentRound.round);
      loadTeam(result, selection);
      setSavedTeam(selection);

      setNotification({ message: "Your team has been saved." });
    }
  }, [selection, teamName]);

  useEffect(() => {
    (async () => {
      let user;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (e) {
        Auth.federatedSignIn();
      }
      const session = user.signInUserSession;

      const token = session.idToken && session.idToken.jwtToken;
      const headers = {
        "x-auth-token": token
      };

      const players = await API.get("players", `/${competition}/players`);

      setPlayers(players.map(p => ({
        ...p,
        clubName: teams[p.club] ? teams[p.club].name : p.club
      })));

      const result = await API.get("team", `/${competition}/team`, { headers });

      const savedUser = localStorage.getItem("localUserId");
      const savedVersion =
        localStorage.getItem(`${competitionLong}TeamVersion`) || 0;

      setRoundTeams(
        _.mapValues(result.players, players => players.slice(0, sport.squadSize))
      );

      setRoundResults(result.results || roundResults);

      setScores(result.score || {});

      const loadedRound =
        currentRound && result.players[currentRound.round]
          ? result.players[currentRound.round]
          : lastRound
          ? result.players[lastRound.round]
          : null;

      setSavedTeam(loadedRound);

      if (
        loadedRound &&
        (savedUser !== user.username || savedVersion < result.version)
      ) {
        loadTeam(result, loadedRound);
      }

      setIsNowLoaded(true);
    })();
  }, []);

  return (
    <>
      <div className="clearfix container">
        <b>
          Teams must be saved before the deadline:{" "}
          {localDate((currentRound || lastRound).closes)}.
        </b>{" "}
        {currentRound ? (
          <p>
            <b>
              Do not forget to save your team! Team selection closes {timer}.
            </b>
          </p>
        ) : lastRound && nextRound ? (
          <p>
            <b>
              Selection for {lastRound.name} has closed. {nextRound.name} team
              selection will open {timer}.
            </b>
          </p>
        ) : (
          <p>
            <b>Selection for {lastRound.name} has closed. Good luck!</b>
          </p>
        )}
      </div>
      <div className="container mt-3">
        <NotificationBox notification={notification} />
        {errors.length ? (
          <div className="card text-white bg-danger mb-3 pt-3 pl-3">
            {roundStarted ? (
              <>
                <h5 className="card-title">
                  Sorry, you can't change your team right now!
                </h5>
                <div className="card-text">
                  <ul>
                    {errors.map(e => (
                      <li>{e}</li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <h5 className="card-title">
                  There are a few issues with your team:
                </h5>
                <div className="card-text">
                  <ul>
                    {errors.map(e => (
                      <li>{e}</li>
                    ))}
                  </ul>
                  Use the 'Choose Players' tab to select your team.
                </div>
              </>
            )}
          </div>
        ) : teamErrors.length ? (
          <div className="card text-white bg-primary mb-3 pt-3 pl-3">
            <h5 className="card-title">
              There are a few issues with your starting {sport.teamSize}:
            </h5>
            <p className="card-text">
              <ul>
                {teamErrors.map(e => (
                  <li>{e}</li>
                ))}
              </ul>
              Use the 'Manage Team' tab to select your starting {sport.teamSize}.
            </p>
          </div>
        ) : (
          <Alert color="success">
            <h5 className="card-title">Your team is valid!</h5>
            <p className="card-text">
              Use the 'View Formation' tab see your team, and download to share.
            </p>
          </Alert>
        )}
        {lastRound && newPlayers.length > 0 && (
          <Alert
            className="clearfix"
            color={newPlayers.length < freeTransfers ? "success" : "warning"}
          >
            You have made {newPlayers.length} changes from your {lastRound.name}{" "}
            team (highlighted in yellow below).
            {newPlayers.length < freeTransfers ? (
              <span>
                {" "}
                You have {freeTransfers - newPlayers.length} free transfer
                {newPlayers.length === 1 ? "" : "s"} remaining.
              </span>
            ) : (
              <span>
                {" "}
                This will cost {(newPlayers.length - freeTransfers) * transferCost} points.
              </span>
            )}
            <button
              onClick={resetTransfers}
              className="btn btn-alert pull-right"
            >
              <FontAwesomeIcon icon={faUndo} /> Reset Transfers
            </button>
          </Alert>
        )}
        {!currentRound || errors.length || teamErrors.length ? null : (
          <button className="btn btn-success float-right" onClick={saveTeam}>
            Save Team
          </button>
        )}
        <h4>
          <b>{budget}</b>
        </h4>
      </div>
      <div className="clearfix" />
      <div className="mt-3 container">
        <div className="card">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <button
                  className={classnames({
                    "nav-link": true,
                    btn: true,
                    "btn-link": true,
                    active: tab === "manage"
                  })}
                  onClick={() => setTab("manage")}
                  href=""
                >
                  Manage Team
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={classnames({
                    "nav-link": true,
                    btn: true,
                    "btn-link": true,
                    active: tab === "add"
                  })}
                  onClick={() => setTab("add")}
                  href=""
                >
                  Choose Players
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={classnames({
                    "nav-link": true,
                    btn: true,
                    "btn-link": true,
                    active: tab === "display",
                    disabled:
                      ((errors.length || teamErrors.length) &&
                      !(
                        roundStarted &&
                        lastRound &&
                        roundTeams[lastRound.round]
                      ))
                  })}
                  onClick={() => setTab("display")}
                  disabled={
                    ((errors.length || teamErrors.length) &&
                    !(roundStarted && lastRound && roundTeams[lastRound.round]))
                      ? true
                      : null
                  }
                  href=""
                >
                  View Formation
                </button>
              </li>
              {rounds
                .filter(({ closes }) => now.isAfter(closes))
                .map(({ round, name }) => (
                  <li className="nav-item">
                    <button
                      disabled={!roundTeams[round]}
                      className={classnames({
                        "nav-link": true,
                        btn: true,
                        "btn-link": true,
                        active: tab === round
                      })}
                      onClick={() => (roundTeams[round] ? setTab(round) : null)}
                      href=""
                    >
                      {name}
                    </button>
                  </li>
                ))}
            </ul>
          </div>

          {tab === "add" || tab === null ? (
            <div className="card-body">
              <Grid
                rows={players}
                columns={columns}
                getRowId={({ playerId }) => playerId}
              >
                <PagingState defaultCurrentPage={0} pageSize={15} />
                <SortingState defaultSorting={[]} />
                <SelectionState
                  selection={selection}
                  onSelectionChange={onChangeSelection}
                />
                <FilteringState defaultFilters={[]} />

                <DataTypeProvider
                  for={["fantasyPrice"]}
                  formatterComponent={({ value }) =>
                    numeral(value).format("$0,0")
                  }
                />

                <IntegratedSorting />
                <IntegratedFiltering />
                <IntegratedPaging />
                <Table
                  cellComponent={props => {
                    return (
                      <Table.Cell
                        className={
                          props.row.injured || props.row.transferred
                            ? "injuredPlayer"
                            : undefined
                        }
                        {...props}
                      />
                    );
                  }}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow cellComponent={FilterCell(sport)} />
                <TableSelection selectByRowClick />
                <PagingPanel />
              </Grid>
            </div>
          ) : tab === "manage" ? (
            <div className="card-body">
              <FormGroup>
                <Label for="teamName">
                  <strong>Team Name</strong>
                </Label>
                <Input
                  id="teamName"
                  name="teamName"
                  placeholder="Name your team!"
                  onChange={e => {
                    setTeamName(e.target.value);

                    localStorage.setItem(
                      `${competitionLong}TeamName`,
                      e.target.value
                    );
                  }}
                  value={teamName}
                />
              </FormGroup>
              <OrderTeamDynamic
                sport={sport}
                players={selectedPlayers.map(player => ({
                  ...player,
                  isNew: newPlayers.includes(player.playerId)
                }))}
                updateTeamOrder={updateTeamOrder}
                onDropPlayer={onDropPlayer}
              />
            </div>
          ) : tab === "display" ? (
            <div className="card-body">
              <DisplayTeam
                players={
                  roundStarted
                    ? roundTeams[lastRound.round].map(id =>
                        players.find(p => p.playerId === id)
                      )
                    : selectedPlayers
                }
                hashtag={hashtag}
                shirts={shirts}
                teamName={teamName}
                sport={sport}
              />
            </div>
          ) : rounds.find(({ round }) => tab === round) ? (
            <div className="card-body">
              <TeamScores
                playerList={getScorePlayerList(
                  roundResults,
                  roundTeams,
                  tab
                ).map(result => ({
                  ...players.find(
                    ({ playerId }) => playerId === result.playerId
                  ),
                  ...result
                }))}
                transferPenalty={
                  (roundResults[tab] && roundResults[tab].transfers) || 0
                }
                total={scores[tab] || 0}
                sport={sport}
              />
            </div>
          ) : (
            <p>
              An unexpected error occurred, please try selecting a different
              tab.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default MyTeam;
