import React from 'react';
import Flag from 'react-world-flags';
import { CompetitionWrapperWL } from '../../components/layout/CompetionWrapperWL';
import { wl } from '../../data/competitions/wl';

import moment from 'moment';
import numeral from 'numeral';

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};

const Image = ({wlprofile}) => {
        if (!wlprofile.noimg){
                return <figcaption align="center">
            Photo by <a href={wlprofile.link2} >{wlprofile.cap2}</a>, <a href={wlprofile.link1} title="Creative Commons">{wlprofile.cap1}</a>
            </figcaption>
        }
        return <span> </span>
        }

const Bio = ({wlprofile}) => {
        if (!wlprofile.nobio){
                return <p>Bio written by {wlprofile.author} (<a href={wlprofile.ltwitter}>@{wlprofile.atwitter}</a>)</p>
        }
        return <span> </span>
        }

const WLProfile = ({ wlprofile }) => (<>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h1>{wlprofile.name}</h1>
	    <p> </p>
        </div>
	<div className="container">
	<p></p>
	</div>
        <div className="float-right mr-2">
	    <img src={`/img/wl/badges/${wlprofile.teamName}.png`} width="100"/>
        </div>
    </div>
        <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <div className="container">
	<p></p>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col" align="center"></th>
	        </tr>
            </thead>
	    <tbody>
	    <tr>
	        <td>Club</td>
	        <td align="center">{wlprofile.teamName}</td>
	    </tr>
	    <tr>
	        <td>Position</td>
	        <td align="center">{wlprofile.pos}</td>
	    </tr>
	    <tr>
	        <td>Nationality</td>
	        <td align="center"><img src={`/img/RoundFlags/${wlprofile.nationality}.png`} width="40"/></td>
	    </tr>
	    <tr>
	        <td>Age</td>
	        <td align="center">{AGE(wlprofile.dob)}
                  </td>
	    </tr>
	    <tr>
                <td>Social Media</td>
                <td align="center">{wlprofile.socials}</td>
            </tr>
	    <tr>
	        <td>Fantasy Price</td>
	        <td align="center">{numeral(wlprofile.price).format('$0,0')}</td>
	    </tr>
	    <tr>
	        <td>Fantasy Points</td>
	        <td align="center">{wlprofile.score}</td>
	    </tr>
	    </tbody>
	    </table>
	    </div>
	  </div>
	  </div>
	 <div className="col-sm-6 order-sm-1">
	  <div className="p-0">
	    <img className="rounded mx-auto d-block mb-3" src={wlprofile.img} width="450"/>
	   {Image({wlprofile})}
	  </div>
	 </div>
       </div>
	<p><b> Previous Club:</b> {wlprofile.club19}<br/>
	   <b>Fantasy Points 2019/20:</b> {wlprofile.total2019}</p>
	<p> </p>
	<p>{wlprofile.bio}</p>
	<p>{wlprofile.bio2}</p>
	<p>{wlprofile.bio3}</p>
	{Bio({wlprofile})}
	<p><b></b> {wlprofile.facts}</p>
      </div>

    <div className="container">
        <p> </p>
	<h4>Fantasy Points</h4>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
	            <th scope="col"></th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att</th>
                    <th scope="col">Def</th>
                    <th scope="col">GK</th>
	            <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">WDL</th>
                    <th scope="col">GD</th>
	            <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                {wlprofile.stats2017.map(({Total}) =>
                <tr>
                    <td>2017/18</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{Total}</td>
                </tr>
                )}

                {wlprofile.stats2018.map(({Total}) =>
                <tr>
                    <td>2018/19</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{Total}</td>
                </tr>
                )}

                {wlprofile.stats2019.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) => 
                <tr>
		    <td>2019/20</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>             
                    <td>{Att}</td>
		    <td>{Def}</td>
                    <td>{GK}</td>
		    <td>{Cards}</td>
		    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
		    <td>{Total}</td>
                </tr>
		)}

               {wlprofile.stats2020.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2020/21</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	    </tbody>
            <thead>
                <th scope="col" colspan='11'>Season 2021/22</th>
                <th scope="col"></th>
            </thead>
	    <tbody>
	        {wlprofile.round1.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 1</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round2.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 2</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {wlprofile.round3.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 3</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round4.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 4</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round5.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 5</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round6.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 6</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round7.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 7</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round8.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 8</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round9.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 9</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {wlprofile.round10.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 10</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round11.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 11</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round12.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 12</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round13.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 13</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wlprofile.round14.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 14</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
            </tbody>
	    <thead>
                <th scope="col" colspan='11'>Total Points</th>
	        <th scope="col">{wlprofile.score}</th>
	    </thead>
        </table>
    </div>
    <div className="container">
	<p></p>
    </div>	
    </CompetitionWrapperWL>
</>)

export default WLProfile
