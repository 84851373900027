import React from 'react';
import moment from 'moment';
import { CompetitionWrapperWSL } from "../../components/layout/CompetionWrapperWSL";
import { wsl } from "../../data/competitions/wsl-2022";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const HowToPlayWSL = () => (<>
<CompetitionWrapperWSL competition={wsl}>
    <div className="container">
        <h1>How to Play</h1>
        <h3>Create a free ShePlays account</h3>
	<p>
        Before you start building your fantasy team, you will need to create a free ShePlays account:
	<ul>
        <li>Register with a valid email address (verification needed), username and password</li>
	<li>Or log in using a facebook or gmail account</li> 
	</ul>
	</p>
	<div className="row align-items-center">
	<div className="col-sm-6 order-sm-1">
	<h3>Building your fantasy team</h3>
	<p>Once logged in, you can start selecting your team by going to the My WSL team page. Here, you'll be able to chose
	the 15 players that will make up your squad. Your team must include:
	<ul>
	  <li>2 Goalkeepers</li>
	  <li>4 Defenders</li>
	  <li>4 Midfielders</li>
	  <li>3 Forwards</li>
	  <li>2 Free Choice players (any position)</li>
	</ul>
	To be valid, your team must also:
	<ul>
	<li>Be under the $6,000,000 budget cap.</li>
	<li>Have no more than 4 players from the same club.</li>
	</ul>
	To build your squad, you can search for players in the 'Choose Players' tab, to add them to your team.

	</p>
	</div>
	<div className="col-sm-6 order-sm-2">
        <img className="rounded mx-auto d-block mb-3" src="/img/chose.png" width="100%"/>
	</div>
	</div>



<div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
        <h3>Managing your team</h3>
        <p>Once you have selected your squad you need to set your captain and starting XI. This is done on the 'Manage Team' tab of the
	My WSL page. You can 'drag and drop' your players to reorder them, to set your team each round. You can:
	<ul>
	<li>Select a captin who will earn double fantasy points during the round.</li>
	<li>Select a vice-captain, who will earn double points if your captain doesn't play.</li>
	<li>Select your starting XI who will earn fantasy points during the round. Your starting team must have 
	a minimum of 3 defenders, 3 midfielders, and 2 forwards.</li>
	<li> Your bench players will only earn fantasy points if players from your starting XI do not play.</li>
	</ul>
	When you have a valid team, the green 'Save Team' button will appear. <b>You must click this button in order to save any changes made to your 
	team!</b>
	</p>
	</div>
        <div className="col-sm-6 order-sm-1">
        <img className="rounded mx-auto d-block mb-3" src="/img/manage.png" width="100%"/>
        </div>
        </div>

<div className="row align-items-center">
        <div className="col-sm-6 order-sm-1">
        <h3>Transfers and match rounds</h3>
	<p>The WSL is played over 22 rounds, with each team playing once per round. Between each round there is a transfer window, where you can make changes to your team.
	Each round you can make:
        <ul>
        <li>One free player transfer</li>
        <li>Additional player transfers, for a cost of 5 fantasy points each</li>
        <li>You can also make any changes to your captain or starting XI</li>
        </ul>
        When you have a valid team, the green 'Save Team' button will appear. <b>You must click this button in order to save any changes made to your    
        team!</b>
        </p>
	<h5>Postponed matches</h5>
	<p>If a game is postponed, you will receive fantasy points for the match when it is played at the later date. Points will be added 
	to your initial round score, based on your initial team for that round.</p>
        </div>
        <div className="col-sm-6 order-sm-2">
	<img className="img-fluid rounded-circle" src="/img/chels2022.png" alt="" width="80%" style={{ alignment: 'center' }} />
	<figcaption align="center"><a href="https://www.flickr.com/photos/gotbphotography/albums/72177720299027065/with/52079818643/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>


        </div>
        </div>




	<h3>Fantasy Points</h3>
	<p>Each round players will earn fantasy points, based on their performance in a range of statistics. Like other fantasy competitions, players will earn 
	points by scoring goals and assists, and will lose points if they receive a yellow or red card. However, players will also earn bonus points, based on various attacking, defending, and goalkeepeing statistics, such as shots, tackles, intercepts, and saves. The more positive contributions a player makes to a game, the more fantasy points they are likely to score. 
	Any player can earn bonus points, regardless of their position.
	</p>

<div className="row align-items-start">
        <div className="col-sm-6 order-sm-1">
        <h5>ShePlays Scoring Matrix</h5>
       <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Statistic</th>
                    <th scope="col">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Minutes Played</td>
                    <td>2pts (&gt; 60 min), 1pt (&gt; 1 min)</td>
                </tr>
                <tr>
                    <td>Goals</td>
                    <td>5pts</td>
                </tr>
                <tr>
                    <td>Assists</td>
                    <td>3pts</td>
                </tr>
                <tr>
                    <td>Yellow Card</td>
                    <td>-2pts</td>
                </tr>
                <tr>
                    <td>Red Card</td>
                    <td>-5pts</td>
                </tr>
                <tr>
                    <td>Clean Sheet</td>
                    <td>GK and DF = 5pts (&gt; 60 min)<br/>
                        GK and DF = 2pts (&gt; 1 min)</td>
                </tr>
                <tr>
                    <td>Attack Points</td>
                    <td>Based on various attacking statistics (see below)</td>
                </tr>
                <tr>
                    <td>Defense Points</td>
                    <td>Based on various defending statistics (see below)</td>
                </tr>
                <tr>
                    <td>Goalkeeper Points</td>
                    <td>Based on various goal keeping statistics (see below)</td>
                </tr>
                <tr>
                    <td>W/D/L</td>
                    <td>1/0/0</td>
                </tr>
                <tr>
                    <td>Goal Difference</td>
                    <td>(GF-GA)/2 rounded down</td>
                </tr>
            </tbody>
        </table>


	</div>
        <div className="col-sm-6 order-sm-2">
	<h5>Bonus Points</h5>
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Attack Points</th>
                    <th scope="col">1 Point</th>
                    <th scope="col">2 Points</th>
                    <th scope="col">3 Points</th>
                </tr>
            </thead>

	            <tbody>
                <tr>
                    <td>Number of passes</td>
                    <td>40</td>
                    <td>55</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>Number of crosses</td>
                    <td>3</td>
                    <td>5</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Number of shots</td>
                    <td>3</td>
                    <td>5</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Chances created</td>
                    <td colspan="3">1 point per chance created</td>
                </tr>
                <tr>
                    <td>Shots on target</td>
                    <td colspan="3">1 point per shot on target</td>
                </tr>
            </tbody>
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Defence Points</th>
                    <th scope="col">1 Point</th>
                    <th scope="col">2 Points</th>
                    <th scope="col">3 Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of tackles</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of intercepts</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of clearances</td>
                    <td>4</td>
                    <td>7</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Number of Aerial Challenges</td>
                    <td>3</td>
                    <td>4</td>
                    <td>6</td>
                </tr>
            </tbody>
            <thead className="thread-dark">
                <tr>
                    <th scope="col">GK Points</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Saves/smoother/claim</td>
                    <td colspan="3">0.5 points per save, sweeper action, and cross claimed (rounded down)</td>
                </tr>
                <tr>
                    <td>Penalty saves</td>
                    <td colspan="3">5 points per penalty save</td>
                </tr>
            </tbody>
        </table>


	</div>
	</div>

	<p>Note that there are no point deductions for missing a penalty, and that earning a penalty is not counted as an assist.</p>
</div>
<div className="container">

    <h3>Stats by FBref <img align="right"src="/img/fbref_wwc_logo.png" width="250" hspace="20"/></h3>
        <p>The statistics we use to calculate fantasy points are generously provided by <a href="https://fbref.com/en/">FBref</a>. Check them out for a detailed breakdown of every players individual statistics for every match! Full stats may take a couple of days to become available, but we will do our best to 
	get total fantasy points updated as soon as possible after each round has finished, to give you time to plan your team for the following week!</p>
    </div>


                        <div className="container">
        <h5 style={{textAlign:'center'}}>
                <b>Need more help? Watch the videos below to find out how to get started! </b>

              </h5>
        </div>

                  <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
        <div className="centerBox">
        <h5>Build your WSL fantasy team</h5>
        <iframe width="90%" height="255"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
        <div className="col-lg-6 order-lg-1">
        <div className="centerBox">
        <h5>Create your free ShePlays account</h5>
        <iframe width="90%" height="255" src="https://www.youtube.com/embed/LevVeCEt3bc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
      </div>
     </div>




    <div className="container" align="center">
    <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a></p>
    </div>
    <div className="container">
        <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
        <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
    </div>	
    	
    </CompetitionWrapperWSL>

</>)

export default HowToPlayWSL;
