import React, {useState} from "react";
import { useAuth } from "../../util/withAuth";
import { CompetitionWrapperNWSL } from "../../components/layout/CompetionWrapperNWSL";
import BottomNavBar from '../../components/layout/MCompetionWrapperWL.jsx';
import { nwsl } from "../../data/competitions/nwsl-2023";
import _ from "lodash";
import MyLeagues from "../leagues/MyLeagues";



const MyNWSLLeagues = ({ route }) => {
	nwsl.init();
  return (
	  <>
    <div className="container my-3">
        <h1>NWSL Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!
      </p>
      
	  <MyLeagues route={route} competition="nwsl" />
    
    </div>

<div className="container">
<p>Want to find public mini-leagues to test your fantasy NWSL team against? Or want to learn about 
some of the organisations that are doing great work to help grow women's soccer in the USA? Then check out the open mini-leagues 
from some of our friends below!</p>

      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>

<tbody>
<tr>
<td><img hspace="20" src="/img/GreenCircle.png" width="40"/></td>
<td>ShePlays Admin Cup</td>
<td>ShePlays</td>
<td>Compete against our ShePlays NWSL admin team!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/Local134.png" width="40"/></td>
<td>Chicago Local 134 Public League</td>
<td>Local134</td>
<td>Join the Chicago Red Stars Supporters Group Today!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/SpiritSquad.jpg" width="40"/></td>
<td>Spirit Squadron</td>
<td>OG23</td>
<td>Independent Supporters Group for the Washington Spirit</td>
</tr>
<tr>
<td><img hspace="20" src="/img/pandasmall.jpg" width="40"/></td>
<td>AC Pandemonium</td>
<td>ACFCPandaPlus</td>
<td>Independent Supporters Group for Angel City FC</td>
</tr>
<tr>
<td><img hspace="20" src="/img/cloud9small.jpg" width="40"/></td>
<td>Cloud 9</td>
<td>cloud9</td>
<td>Independent Supporters Group for Gotham FC</td>
</tr>
<tr>
<td><img hspace="20" src="/img/uproar_logo.jpg" width="40"/></td>
<td>Uproar NC</td>
<td>Uproar</td>
<td>Independent Supporters Group for the North Carolina Courage</td>
</tr>
<tr>
<td><img hspace="20" src="/img/RebellionSmall.png" width="40"/></td>
<td>Rebellion 99</td>
<td>Rebellion</td>
<td>Founding Independent Supporters Group for Angel City FC</td>
</tr>
<tr>
<td><img hspace="20" src="/img/riverterssmall.jpeg" width="40"/></td>
<td>Rose City Riveters</td>
<td>Riveters</td>
<td>Supporters of NWSL's Portland Thorns FC</td>
</tr>
<tr>
<td><img hspace="20" src="/img/rovalks.jpg" width="40"/></td>
<td>Ride of the Valkyries</td>
<td>rovalks</td>
<td>We cover the Seattle Reign, all are welcome to join!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/BridgeBrigadeLogo.png" width="40"/></td>
<td>Bridge Brigade</td>
<td>BBFL</td>
<td>Bay FC Supporters Group</td>
</tr>
<tr>
<td><img hspace="20" src="/img/wososhow.png" width="40"/></td>
<td>The Women's Soccer Show</td>
<td>wososhow</td>
<td>Weekly radio podcast discussing women’s football with hosts Sara Ach & Allie Heppell</td>
</tr>
</tbody>
</table>
</div>
	  </>
  );
};

export default MyNWSLLeagues
