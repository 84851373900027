import React from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWL } from '../../../components/layout/CompetionWrapperWL';
import { wl } from '../../../data/competitions/wl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../../data/wl/2017/WLdatabase17"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Player Name', name: 'Name' },
    { title: 'Position', name: 'Pos' },
    { title: 'Club', name: 'Club' },
	/* { title: 'Nationality', name: 'Nationality' }, */
    /*{ title: '17/18 Points', name: 'Points17'},*/
    {
        title: 'Points',
        name: 'totalScore',
        getCellValue: t => t.Points17 || 0
    }
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Pos') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const WLLeaderboard18 = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
        <h1>Player Database 2017/18</h1>
	<p>Browse all of the players who participated in the W-League in our first season of ShePlays, 2017/18!  
	You can search for every player from the W-League in 2017/18 (by name, club, or position) in our database below, 
	 where you can also see the total number of points each player has earned throughout the season.
    </p>
    </div>
    <div className="container">
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWL>
</>
export default WLLeaderboard18
