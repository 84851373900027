import React from 'react';
import Flag from 'react-world-flags';
import { CompetitionWrapperNWSL } from '../../components/layout/CompetionWrapperNWSL';
import BottomNavBar from '../../components/layout/MCompetionWrapperNWSL.jsx';
import { nwsl } from '../../data/competitions/nwsl';

import moment from 'moment';
import numeral from 'numeral';

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({nwslprofile}) => {
	if (!nwslprofile.noimg){
		return (<><img className="rounded mx-auto d-block mb-3 responsive" src={nwslprofile.img} /> <figcaption align="center">
            Photo by <a href={nwslprofile.link2} >{nwslprofile.cap2}</a>, <a href={nwslprofile.link1} title="Creative Commons">{nwslprofile.cap1}</a>
            </figcaption></>);
	}
	return <span> </span>
	}

const Bio = ({nwslprofile}) => {
        if (!nwslprofile.nobio){
                return <p>Bio written by {nwslprofile.author} (<a href={nwslprofile.ltwitter}>{nwslprofile.atwitter}</a>)</p>
        }
        return <span> </span>
        }


const NWSLProfile = ({ nwslprofile }) => (<>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h2>{nwslprofile.name}</h2>
	    <p> </p>
        </div>
	<div className="container">
	<p></p>
	</div>
        <div className="float-right mr-2">
	    <img src={`/img/nwsl/badges/${nwslprofile.t3}.png`} width="80"/>
        </div>
    </div>
        <div className="container" style={{fontSize:'0.8rem'}}>
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <div className="container">
	<p></p>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col" align="center"></th>
	        </tr>
            </thead>
	    <tbody>
	    <tr>
	        <td>Position</td>
	        <td align="center">{nwslprofile.pos}</td>
	    </tr>
	    <tr>
	        <td>Nationality</td>
	        <td align="center"><img src={`/img/RoundFlags/${nwslprofile.nationality}.png`} width="30"/></td>
	    </tr>
	    <tr>
	        <td>Age</td>
	        <td align="center">{AGE(nwslprofile.dob)}
                 </td>
	    </tr>
	    <tr>
	        <td>Fantasy Price</td>
	        <td align="center">{numeral(nwslprofile.price).format('$0,0')}</td>
	    </tr>
	    <tr>
	        <td>Fantasy Points</td>
	        <td align="center">{nwslprofile.score}</td>
	    </tr>
	    </tbody>
	    </table>
	    </div>
	  </div>
	  </div>
	 <div className="col-sm-6 order-sm-1">
	  <div className="p-0">
	 {Image({nwslprofile})}
	<p></p>
	<p>
	<b>Fantasy Points 2023:</b> {nwslprofile.total2023}<br/>
	<b>Hometown:</b> {nwslprofile.hometown}<br/>
           <b>College:</b> {nwslprofile.college}</p>
        <p> </p>
	  </div>
	 </div>
       </div>
	<p style={{textAlign:'justify'}}>{nwslprofile.bio}</p>
	<p style={{textAlign:'justify'}}>{nwslprofile.bio2}</p>
	<p style={{textAlign:'justify'}}>{nwslprofile.bio3}</p>
	{Bio({nwslprofile})}
	<p><b></b> {nwslprofile.facts}</p>
      </div>

    <div className="container clearfix mt-32" style={{overflowX:'scroll'}}>
        <p> </p>
	<h4>Fantasy Points</h4>
        <table className="table table-striped" style={{fontSize:'0.7rem'}}>
            <thead className="thead-dark">
                <tr>
	            <th scope="col"></th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att</th>
                    <th scope="col">Def</th>
                    <th scope="col">GK</th>
	            <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">WDL</th>
                    <th scope="col">GD</th>
	            <th scope="col">Total</th>
                </tr>
            </thead>

            <tbody>
                {nwslprofile.stats2022.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2022</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
             </tbody>
	    <tbody>
                {nwslprofile.stats2023.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2023</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
             </tbody>
            <thead>
                <th scope="col" colspan='11'>Season 2024</th>
                <th scope="col"></th>
            </thead>
	    <tbody>
	        {nwslprofile.round1.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 1</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round2.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 2</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {nwslprofile.round3.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 3</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round4.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 4</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round5.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 5</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round6.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 6</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round7.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 7</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round8.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 8</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round9.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 9</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {nwslprofile.round10.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 10</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round11.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 11</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round12.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 12</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round13.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 13</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round14.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 14</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round15.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 15</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round16.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 16</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round17.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 17</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round18.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 18</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round19.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 19</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round20.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 20</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {nwslprofile.round21.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 21</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round22.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 22</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round23.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 23</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round24.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 24</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {nwslprofile.round25.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 25</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}

            </tbody>
	    <thead>
                <th scope="col" colspan='11'>Total Points</th>
	        <th scope="col">{nwslprofile.score}</th>
	    </thead>
        </table>
    </div>
    <div className="container">
	<p></p>
    </div>	
	<BottomNavBar/>
</>)

export default NWSLProfile
