import React from 'react';
import moment from 'moment';
import BottomNavBar from '../../components/layout/MCompetionWrapperWL.jsx';
import { CompetitionWrapperWSL } from "../../components/layout/CompetionWrapperWSL";
import { wsl } from "../../data/competitions/wsl-2021";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const Friends = () => (<>
    <div className="container">
<h3>Our Friends</h3>
<p>Want to find public mini-leagues to test your fantasy A-League Women's team against? Or want to learn about 
some of the organisations that are doing great work to help grow women's soccer here in Australia? Then check out the open mini-leagues 
from some of our friends below!</p>
</div>

<div className="container">
      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>
<tbody>
<tr>
<td><img hspace="20" src="/img/b90small.png" width="40"/></td>
<td>Beyond 90</td>
<td>B90</td>
<td>The go to place for all your A-League Women's news and analysis</td>
</tr>
<tr>
<td><img hspace="20" src="/img/vvsmall.jpeg" width="40"/></td>
<td>Victory Vikings</td>
<td>2x4oz6</td>
<td>Active Supporters group of the Melbourne Victory A-League Women</td>
</tr>
<tr>
<td><img hspace="20" src="/img/nixsmall.jpeg" width="40"/></td>
<td>Flight of the Nix!</td>
<td>66hla6</td>
<td>Wellington Phoenix Supporters group</td>
</tr>
</tbody>
</table>
</div>



    <div className="container" align="center">
    <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a></p>
    </div>
    <div className="container">
        <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
        <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
    </div>	
    	
	<BottomNavBar/>

</>)

export default Friends;
