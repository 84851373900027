import React, { useState } from 'react';
import { useAuth } from "../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperEuro } from '../../components/layout/CompetionWrapperEuro';
import { euro } from '../../data/competitions/euro';
import BottomNavBar from '../../components/layout/MCompetionWrapperEuro.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const EuroLandingPageD = () => (<CompetitionWrapperEuro competition={euro}>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/euro/euro4.png" alt="" />
	    <figcaption align="center"><a href="https://commons.wikimedia.org/wiki/File:20170803_WEURO_DEN_AUT_1807.jpg" title = "Ailura" target="blank">Ailura</a><br/><a href="https://creativecommons.org/licenses/by-sa/3.0/deed.en">CC BY 3.0 </a></figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlaysEuro</h2>
              <p style={{textAlign:'justify'}}>The 2022 UEFA European Women's Football Championship has finally arrived - and so has our ShePlaysEuro fantasy
	      football competition! This is is your chance to build your dream team from the best players in Europe, and to test 
	      yourself against your friends and the rest of the ShePlays community. Our fantasy competition is a great way to learn about the players and other teams 
	      in the championship, particularly some of the young future stars who may be less known, but make excellent fantasy picks! It'll also help you 
	      keep tabs on the competition, to know who to look out for as we go deeper into the tournament.
	      </p>
            </div>
          </div>
        </div>
	<div className="leftBox">
        <p style={{textAlign:'justify'}}>
                <b> Our team selection is now open!</b> The deadline to get your team in 
	        for round 1 is {localDate("2022-07-07 04:00+10")}. 
                 To keep up to date with the latest news, make sure you follow us on
                <a href="https://twitter.com/sheplays_au" target="_blank">
                  {" "}
                  Twitter
                </a>
                ,{" "}
                <a
                  href="https://www.instagram.com/sheplays_au/"
                  target="_blank"
                >
                  {" "}
                  Instagram
                </a>{" "}
                and{" "}
                <a
                  href="https://www.facebook.com/sheplaysfantasyfootball/"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                . <b>Watch the videos below to find out how to get started!</b>

              </p>
        </div>
      </div>

  <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
	<div className="centerBox">
	<h5>Build your Euro fantasy team</h5>
	<iframe width="90%" height="255"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
	</div>
	</div>
        <div className="col-lg-6 order-lg-1">
	<div className="centerBox">
	<h5>Create your free ShePlays account</h5>
        <iframe width="90%" height="255" src="https://www.youtube.com/embed/LevVeCEt3bc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
	</div>
	</div>
      </div>
     </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/euro/euro2.jpg" alt="" />
	<figcaption align="center"><a href="https://commons.wikimedia.org/wiki/File:20170716_WEURO_NED_NOR_3652.jpg" title = "Ailura" target="blank">Ailura</a><br/><a href="https://creativecommons.org/licenses/by-sa/3.0/deed.en">CC BY-SA 3.0 </a></figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">How to play</h2>
              <p style={{textAlign:'justify'}}>You'll start with a budget of $8M to spend on the 15 players that will make up your fantasy team. 
	       Your players will then receive points after each game based on how well they play. 
	       After each round of matches you will get the chance to make transfers, select your starting XI, 
	       and choose a captain who will earn double points in the next round. You can also set up your own 
	       mini-leagues to compete against your friends! For more information see the <a href="/euro/how-to-play" 
	       target="blank">How To Play</a> page.</p>
            </div>
          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/euro/euro3.png" alt="" />
	<figcaption align="center"><a href="https://commons.wikimedia.org/wiki/File:2021-09-21_Fussball,_Frauen,_L%C3%A4nderspiel,_Deutschland_-_Serbien_1DX_4882_by_Stepro.jpg" title = "Steffen Prößdorf" target="blank">Steffen Prößdorf</a><br/>
	<a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY 4.0 </a></figcaption>
    </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Challenge your Friends</h2>
	      <p style={{textAlign:'justify'}}>
                Every team that enters our competition will compete in our global ShePlaysEuro leaderboard, to find the ultimate UEFA Women's Euro Champion. 
	        However, it's always fun playing against your friends - so once you've selected your team, make sure you set up your own mini-league on 
	        the My Euro Leagues page. Then you can share your league code with friends, teammates, or family. If any supporter groups or organisations 
	        would like to set up their own custom public league, with your branding and personalised link code, send us an email at admin@sheplays.com.au.
	      </p>
            </div>
          </div>
        </div>
      </div>
  </section>




  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperEuro>)





const EuroLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadEuro text-center text-white">
      <div className="masthead-contentEuro">
        <div className="container">
          <h1 className="masthead-headingWWC mb-0">ShePlays Euro 2022</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
          Compete against your friends in our fantasy Euro competition!
          </p>
        {currentUser ? (
        <a href="/euro/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My Euro Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}
        </div>
      </div>
    </header>
<div className="container">
<a href='/euro/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
<a href='/euro/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results
        </a>
<a href='/euro/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams
        </a>
<a href='/euro/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Player Database
        </a>
<a href='/euro/m/stats' className="btn btn-teams btn-l rounded-pill mt-1" >
        Stats Center
        </a>
<a href='/euro/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

        <BottomNavBar />
   </>  );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 1000;

  return width < breakpoint ? <EuroLandingPageM /> : <EuroLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


