import React from "react";
import Flag from "react-world-flags";
import _ from 'lodash';

import moment from "moment";
import { CompetitionWrapperWSL } from "../../components/layout/CompetionWrapperWSL";
import BottomNavBar from "../../components/layout/MCompetionWrapperWSL.jsx";
import { wsl } from "../../data/competitions/wsl";
import * as numeral from 'numeral';
import data from "../../data/wsl/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({wslteam}) => {
	if (!wslteam.link1){
		return<span></span>
	}
	return (
	      <div>
	      <img
              className="responsive_2col"
              src={`/img/wsl/${wslteam.photo}`}
              width="550"
            />
	       <figcaption align="center">
            Photo by <a href={wslteam.link1} >{wslteam.cap1}</a>, <a href={wslteam.link2} title="Creative Commons">{wslteam.cap2}</a>
            </figcaption>
		<p> </p>
	    </div>
	    )

};

const WSLTeam = ({ wslteam }) => ( <>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h2>{wslteam.club}</h2>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={wslteam.img}
        height={60}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
	{Image({wslteam})}
      <p style={{textAlign:'justify', fontSize:'0.8rem'}}>{wslteam.bio}</p>
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <img
              className="rounded mx-auto d-block mb-3"
              src={wslteam.img1}
              width="450"
            />
          </div>
        </div>
        <div className="col-sm-6 order-sm-1">
          <div className="p-0">
            <img
              className="rounded mx-auto d-block mb-3"
              src={wslteam.img2}
              width="450"
            />
          </div>
        </div>
      </div>
    </div>

    <div className="container clearfix mt-32" style={{overflowX:'scroll'}}>
      <table className="table table-striped" style={{fontSize:'0.8rem'}}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">
              Nationality
            </th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">Points</th>
          </tr>
        </thead>
        <tbody>
          {wslteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
	      Points20,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wsl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wslteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wsl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wslteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wsl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wslteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wsl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="4">Team's Total Points:</th>
	<th scope="col">{wslteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
	<BottomNavBar/>
	</>
);

export default WSLTeam;
