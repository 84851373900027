import React from 'react';
import DynamicMatch from '../../components/matches/Match';
import { CompetitionWrapperWL } from '../../components/layout/CompetionWrapperWL';
import { wl } from '../../data/competitions/wl';


const teams = [
    { code: "ade", name: "Adelaide United" },
    { code: "bri", name: "Brisbane Roar" },
    { code: "cbr", name: "Canberra United" },
    { code: "mcy", name: "Melbourne City" },
    { code: "mvc", name: "Melbourne Victory" },
    { code: "new", name: "Newcastle Jets" },
    { code: "per", name: "Perth Glory" },
    { code: "syd", name: "Sydney FC" },
    { code: "wel", name: "Wellington Phoenix" },
    { code: "wsw", name: "Western Sydney Wanderers" }
]

const WLMatch = ({route}) => {
    return (<CompetitionWrapperWL competition={wl}>
        <DynamicMatch {...{route, teams}} comp="wl" />
    </CompetitionWrapperWL>)
}
export default WLMatch;
