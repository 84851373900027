import React from "react";
import Flag from "react-world-flags";

import moment from "moment";
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import BottomNavBar from "../../components/layout/MCompetionWrapperAFLW.jsx";
import { aflw } from "../../data/competitions/aflw-2022";
import * as numeral from 'numeral';
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};

const AFLWTeam = ({ aflwteam }) => (<>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h2>{aflwteam.name}</h2>
        <h6>Position 2022: {aflwteam.p2021}</h6>
	<h6>Coach: {aflwteam.coach}</h6>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={`/img/aflw/logo/${aflwteam.pic}`}
        height={60}
      />
      <div className="float-right mr-2">
      </div>
    </div>

    <div className="container" style={{fontSize:'0.8rem'}}>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">Age</th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">2020</th>
	    <th scope="col" align="center">2021</th>
	    <th scope="col" align="center">2022</th>
	    <th scope="col" align="center">2023</th>
          </tr>
        </thead>
        <tbody>
          {aflwteam.ruck.map(
            ({
              name,
              pos,
              nat,
              price,
              dob,
	      h,
              no,
	      p17,
	      p18,
	      p21,
	      p20,
	      p22,
	      total,
	      club2020
            }) => (
              <tr key={name}>
                <td>{no}</td>
                <td>{name}</td>
                <td>{pos}</td>
                <td>
		    {AGE(dob)}
                </td>
                <td>{numeral(price).format()}</td>
		<td>{p20}</td>
		<td>{p21}</td>
		<td>{p22}</td>
		<td>{total}</td>
              </tr>
            )
          )}
          {aflwteam.def.map(
            ({
              name,
              pos,
              nat,
              price,
              dob,
              h,
              no,
              p17,
              p18,
              p21,
              p20,
              p22,
              total,
              club2020
            }) => (
              <tr key={name}>
                <td>{no}</td>
                <td>{name}</td>
                <td>{pos}</td>
                <td>
                    {AGE(dob)}
                </td>
                <td>{numeral(price).format()}</td>
                <td>{p20}</td>
                <td>{p21}</td>
                <td>{p22}</td>
                <td>{total}</td>
              </tr>
            )
          )}
          {aflwteam.mid.map(
            ({
              name,
              pos,
              nat,
              price,
              dob,
              h,
              no,
              p17,
              p18,
              p21,
              p20,
              p22,
              total,
              club2020
            }) => (
              <tr key={name}>
                <td>{no}</td>
                <td>{name}</td>
                <td>{pos}</td>
                <td>
                    {AGE(dob)}
                </td>
                <td>{numeral(price).format()}</td>
                <td>{p20}</td>
                <td>{p21}</td>
                <td>{p22}</td>
                <td>{total}</td>
              </tr>
            )
          )}
          {aflwteam.fwd.map(
            ({
              name,
              pos,
              nat,
              price,
              dob,
              h,
              no,
              p17,
              p18,
              p21,
              p20,
              p22,
              total,
              club2020
            }) => (
              <tr key={name}>
                <td>{no}</td>
                <td>{name}</td>
                <td>{pos}</td>
                <td>
                    {AGE(dob)}
                </td>
                <td>{numeral(price).format()}</td>
                <td>{p20}</td>
                <td>{p21}</td>
                <td>{p22}</td>
                <td>{total}</td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="10"></th>
	<th scope="col"></th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
        <BottomNavBar/>
        </>

);

export default AFLWTeam;
