import React from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import Flag from 'react-world-flags';
import { Input } from "reactstrap";
import "@devexpress/dx-react-grid";
import { CompetitionWrapperEuro } from '../../components/layout/CompetionWrapperEuro';
import { euro } from '../../data/competitions/euro';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/euro/teamLists/jsondatabase.json"
import moment from 'moment';


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Position', name: 'Position'},
    { title: 'Player Name', name: 'Name'},
    { title: 'Team', name: 'Nationality'},
    { title: 'Club', name: 'Club'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
    { title: 'Price', name: 'Price',
       getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: '2022', name: 'Points',
      getCellValue: t => t.Points},
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return PositionPicker(props);
    }
    return <th />
};

const NameFormatter = ({value, row}) => {
  if(row.Link2) {
    return <a href={`/euro/players/${_.kebabCase(row.Link)}`}>{value}</a>
  } else {
    return value;
  }
}

const NameTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NameFormatter}
    {...props}
  />
);



const DatabaseEuro = () => <>
<CompetitionWrapperEuro competition={euro}>
    <div className="container clearfix mt-32">
	<h1>Player Database</h1>
	<p>Want to research players for your fantasy team, or are you interested in learning more about the incredible athletes in the Euro? Then this is the place to be! 
	   You can search for every player competing in the Euro 2022 season
           (by name, club, position, or nationality) in our database below.  
	   For a complete breakdown of each players fantasy points, visit the <a href="/euro/stats">Stats Center</a>.</p>
    </div>


    <div className="container">
	<h5 align="right" >Fantasy Points</h5>
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <NameTypeProvider for={['Name']} />
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperEuro>
</>
export default DatabaseEuro
