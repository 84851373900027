import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  "Adelaide United": {
      player: "/img/wl/shirts/ade.png",
      gk: "/img/wl/shirts/adeGK.png",
  },
  "Brisbane Roar": {
      player: "/img/wl/shirts/bri.png",
      gk: "/img/wl/shirts/briGK.png",
  },
  "Canberra United": {
      player: "/img/wl/shirts/cbr.png",
      gk: "/img/wl/shirts/cbrGK.png",
  },
  "Melbourne City": {
      player: "/img/wl/shirts/mcy.png",
      gk: "/img/wl/shirts/mcyGK.png",
  },
  "Melbourne Victory": {
      player: "/img/wl/shirts/mvc.png",
      gk: "/img/wl/shirts/mvcGK.png",
  },
  "Newcastle Jets": {
      player: "/img/wl/shirts/new.png",
      gk: "/img/wl/shirts/newGK.png",
  },
  "Perth Glory": {
      player: "/img/wl/shirts/per.png",
      gk: "/img/wl/shirts/perGK.png",
  },
  "Sydney FC": {
      player: "/img/wl/shirts/syd.png",
      gk: "/img/wl/shirts/sydGK.png",
  },
  "Western Sydney Wanderers": {
      player: "/img/wl/shirts/wsw.png",
      gk: "/img/wl/shirts/wswGK.png",
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+00"),
    closes: moment("2020-12-29 15:35:00+11")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2020-12-30 20:35:00+11"),
    closes: moment("2021-01-02 18:05:00+11")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2021-01-03 20:05:00+11"),
    closes: moment("2021-01-07 18:05:00+11")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2021-01-10 18:05:00+11"),
    closes: moment("2021-01-14 18:05:00+11")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2021-01-17 20:35:00+11"),
    closes: moment("2021-01-21 18:05:00+11")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2021-01-24 18:00:00+11"),
    closes: moment("2021-01-29 18:05:00+11")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2021-01-31 20:00:00+11"),
    closes: moment("2021-02-04 18:00:00+11")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2021-02-07 22:00:00+11"),
    closes: moment("2021-02-11 18:00:00+11")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2021-02-14 18:00:00+11"),
    closes: moment("2021-02-18 18:00:00+11")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2021-02-21 19:05:00+11"),
    closes: moment("2021-02-25 18:00:00+11")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2021-02-28 18:05:00+11"),
    closes: moment("2021-03-04 18:05:00+11")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2021-03-08 23:35:00+11"),
    closes: moment("2021-03-11 18:05:00+11")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2021-03-14 23:05:00+11"),
    closes: moment("2021-03-19 20:35:00+11")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2021-03-21 18:05:00+11"),
    closes: moment("2021-03-25 18:05:00+11")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2021-03-28 20:05:00+11"),
    closes: moment("2021-04-04 18:05:00+11")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const wl = {
  shortName: "wl",
  name: "W-League",
  sport: soccer,
  totalBudget: 550000,
  minPlayerPrice: 15000,
  maxPlayerPrice: 80000,
  perClubLimit: 5,
  hashtag: "ShePlaysDub",
  shirts,
  init,
  rounds,
  season: "2021"
};
