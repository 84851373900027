import React from 'react';
import numeral from 'numeral';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { Input } from "reactstrap";
import { CompetitionWrapperAFLW } from '../../components/layout/CompetionWrapperAFLW';
import { aflw } from '../../data/competitions/aflw-2021';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/aflw/playerProfiles/statsList.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Pos', name: 'pos'},
    { title: 'Player Name', name: 'name'},  
    { title: ' ', name: 'teamName',
    getCellValue: t => <img src={`/img/aflw/logo/${t.teamName}.png`} width="35"/>},
    { title: 'Price', name: 'price'},
    { title: 'Played', name: 'Played'},
    { title: 'CB', name: 'CB'},
    { title: 'BM', name: 'BM'},
    { title: 'DF', name: 'Def'},
    { title: 'SB', name: 'SC'},
    { title: 'Avg', name: 'Total'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>RUCK</option>
      <option>DEF</option>
      <option>MID</option>
      <option>FWD</option>
    </Input>
  </th>
);



const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'pos') {
        return PositionPicker(props);
    }
    return <th />
};

const StatsAFLW = () => <>
<CompetitionWrapperAFLW competition={aflw}>
    <div className="container clearfix mt-32">
	<h1>Stats Center</h1>
	<p>Welcome to the Stats Center! Here you can compare full fantasy point breakdowns for every player in the league. 
	You can search for players or filter by position using the search boxes at the top of the table. Clicking on one of the column headings will sort the table by that attribute. Analyse which players are the best defenders in the league, or who is raking in the most fantasy points, and much more!</p>
    </div>


    <div className="container">
        <Grid rows={data.Players.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperAFLW>
</>
export default StatsAFLW
