import React, { useState } from 'react';
import { useAuth } from "../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperNWSL } from '../../components/layout/CompetionWrapperNWSL';
import { nwsl } from '../../data/competitions/nwsl';
import BottomNavBar from '../../components/layout/MCompetionWrapperNWSL.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const NWSLLandingPageD = () => {
const currentUser = useAuth()
return(<CompetitionWrapperNWSL competition={nwsl}>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/nwslRodman.png" alt="" />
	    <figcaption align="center">Peyton Skeels<br/>All Rights Reserved</figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlaysNWSL</h2>
              <p style={{textAlign:'justify'}}>
              Welcome to our ShePlays NWSL League! This is where you can find all of the information you might need to help build your NWSL dream team.
              </p>

              <p style={{textAlign:'justify'}}>
              Team selection for the 2024 season is now open! The deadline to get your team in for round 1 is {localDate("2024-03-16 16:00:00+00")}. To start building
              your team, create a free ShePlays account, and then click on the My NWSL Team button below.
              </p>
             <div className="centerBox">
              {currentUser ? (
        <a href="/nwsl/my-team" className="btn btn-secondary btn-xl rounded-pill mt-1" style={{color:'white'}}>
          My NWSL Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-xl rounded-pill mt-1" style={{color:'white'}}>
          Log In </a>)}
            </div>
            </div>
          </div>
        </div>

      </div>

</section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/nwslSharples.png" alt="" />
	    <figcaption align="center">Peyton Skeels<br/>All Rights Reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">How to play</h2>
<p style={{textAlign:'justify'}}>
                Once you have created an account you can start building your NWSL dream team on our team selector:
                <ul>
                <li> Select the 15 players who will make up your dream team - but stay within the budget cap of $3,300,000! </li>
                <li> Each week, chose your starting XI, captain, and vice captain, who will earn fantasy points during NWSL matches.</li>
                <li> Improve your team during the transfer windows by bringing new players in, to help your team climb to the top of the leaderboards!</li>
                </ul>
                </p>
            </div>
          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/nwslLavelle.png" alt="" />
	    <figcaption align="center">Peyton Skeels<br/>All Rights Reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Player Research</h2>
             <p style={{textAlign:'justify'}}>
             We have plenty of resources to help you learn more about the players in the league, and to help you  chose the best players
                for your team:
                <ul>
                <li>A collection of detailed Player Profiles can be found on our <a href="/nwsl/databse" target="blank">Player Database</a>.</li>
                <li>Our <a href="/nwsl/stats" target="blank">Stats Center</a> provides a fantasy point break down of every player in the league.</li>
                <li>We also have a full collection of fantasy NWSL stats from all of our previous seasons.</li>
                </ul>
                </p>
            </div>
          </div>
        </div>
      </div>
  </section>




  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperNWSL>);
};





const NWSLLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadNWSL text-center text-white">
      <div className="masthead-contentNWSL">
        <div className="container">
          <h1 className="masthead-headingWWC mb-0">ShePlays NWSL 2023</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
          Compete against your friends in our fantasy NWSL competition!
          </p>
        {currentUser ? (
        <a href="/nwsl/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My NWSL Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}
        </div>
      </div>
    </header>
<div className="container">
<a href='/nwsl/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
<a href='/nwsl/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results
        </a>
<a href='/nwsl/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams
        </a>
<a href='/nwsl/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Players
        </a>
<a href='/nwsl/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News
        </a>
<a href='/nwsl/join-us' className="btn btn-teams btn-l rounded-pill mt-1" >
        Join Our Team
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

        <BottomNavBar />
   </>  );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 1000;

  return width < breakpoint ? <NWSLLandingPageM /> : <NWSLLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


