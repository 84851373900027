import React, { useState } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import Flag from 'react-world-flags';
import { Input } from "reactstrap";
import "@devexpress/dx-react-grid";
import { CompetitionWrapperNWSL } from '../../components/layout/CompetionWrapperNWSL';
import BottomNavBar from '../../components/layout/MCompetionWrapperNWSL.jsx';
import { nwsl } from '../../data/competitions/nwsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow, TableColumnResizing,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/nwsl/teamLists/jsondatabase.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

export default () => {


const columns = [
    { title: 'Pos', name: 'Position'},
    { title: 'Player Name', name: 'Name'},
    { title: 'Team', name: 'Club'},
    { title: 'Nationality', name: 'Nationality'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
    { title: 'Price', name: 'Price',
       getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: 'Points', name: 'Points',
      getCellValue: t => t.Points},
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return PositionPicker(props);
    }
    return <th />
};

const NameFormatter = ({value, row}) => {
  if(row.Link) {
    return <a href={`/nwsl/m/players/${_.kebabCase(row.Link)}`}>{value}</a>
  } else {
    return value;
  }
}

const NameTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NameFormatter}
    {...props}
  />
);

const [defaultColumnWidths] = useState([
        {columnName: 'Position', width: 80},
        {columnName: 'Name', width:200},
        {columnName: 'Club', width:150},
        {columnName: 'Nationality', width:100},
        {columnName: 'Price', width:100},
        {columnName: 'Points', width:100}

]);
const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);


return ( <>
    <div className="container clearfix mt-32">
    </div>


    <div className="container" style={{fontSize:'0.7rem'}}>
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <NameTypeProvider for={['Name']} />
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
	    <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
	<BottomNavBar/>
</>

);
};
