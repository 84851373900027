import React from "react";
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../data/competitions/aflw-2023";
import { useState, useEffect } from "react";
import { API } from 'aws-amplify';

const teams = aflw.teams;

const AFLWMatch = ({ route }) => {
  const competition = "aflw";
  const [data, setData] = useState({
    points: []
  });

  const matchId = route.url.pathname.split("/")[3];

  useEffect(() => {
    (async () => {
      const result = await API.get(
        "matches",
        `/${competition}/matches/${matchId}`
      );
      setData(result);
      console.log(result);
    })();
  }, []);

  return (
    <CompetitionWrapperAFLW competition={aflw}>
      <div className="container">
        <div className="text-center">
          {data.teamA ? (
            <>
              <h1>
                <img
                  src={`/img/${competition}/logo/${data.teamA}.png`}
                  width="50"
                  hspace="50"
                />{" "}
                {teams[data.teamA].name} v {teams[data.teamB].name}{" "}
                <img
                  src={`/img/${competition}/logo/${data.teamB}.png`}
                  width="50"
                  hspace="50"
                />
              </h1>
              <p>
                <b>
                  {data.scoreA} - {data.scoreB}
                </b>
                <br />
                {data.penaltyScoreA || data.penaltyScoreB ? (
                  <i>
                    {data.penaltyScoreA} - {data.penaltyScoreB} Penalties
                  </i>
                ) : null}
                <br />
              </p>
            </>
          ) : (
            <h1>Loading...</h1>
          )}
        </div>

        <p>
          Below is a breakdown of the fantasy points earned by each player
          during the match. <br />
          To see a breakdown of how the bonus points are calculated go to the{" "}
          <a href={`/${competition}/how-to-play`}> Rules &amp; Scoring </a> page.
          <br />
        </p>
      </div>
      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Team</th>
              <th scope="col">Total</th>
              <th scope="col">Contested Ball</th>
              <th scope="col">Ball Movement</th>
              <th scope="col">Pressure/Defence</th>
              <th scope="col">Scoreboard Impact</th>
              <th scope="col">Clangers</th>
            </tr>
          </thead>
          <tbody>
            {data.points.map(
              ({
                name,
                playerId,
                team,
                contestedBall,
                ballMovement,
                defence,
                score,
                clangers,
                total
              }) => (
                <tr key={playerId}>
                  <td>{name}</td>
                  <td align='center'><img  src={`/img/aflw/logo/${team}.png`}
		      height="30"/></td>
                  <td>{total}</td>
                  <td>{contestedBall || ""}</td>
                  <td>{ballMovement || ""}</td>
                  <td>{defence || ""}</td>
                  <td>{score || ""}</td>
                  <td>{clangers || ""}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </CompetitionWrapperAFLW>
  );
};
export default AFLWMatch;
