import React from 'react';
import DynamicMatch from '../../components/matches/MatchM';
import { CompetitionWrapperNWSL } from '../../components/layout/CompetionWrapperNWSL';
import BottomNavBar from "../../components/layout/MCompetionWrapperNWSL.jsx";
import { nwsl } from '../../data/competitions/nwsl';

const teams = [
                { code: "lac", name: "Angel City FC"},
                { code: "utr", name: "Utah Royals FC"},
                { code: "bay", name: "Bay FC"},
                { code: "chi", name: "Chicago Red Stars"},
                { code: "hou", name: "Houston Dash"},
                { code: "kcc", name: "Kansas City Current"},
                { code: "njy", name: "NJ-NY Gotham FC"},
                { code: "ncc", name: "North Carolina Courage"},
                { code: "rgn", name: "Seattle Reign FC"},
                { code: "orl", name: "Orlando Pride"},
                { code: "por", name: "Portland Thorns" },
                { code: "lou", name: "Racing Louisville FC"},
                { code: "sdw", name: "San Diego Wave"},
                { code: "was", name: "Washington Spirit"}];

const NWSLMatch = ({route}) => {
    return (<>
        <DynamicMatch {...{route, teams}} comp="nwsl" />
	    <BottomNavBar/>
    </>)
}
export default NWSLMatch;
