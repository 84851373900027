import React from 'react';
import moment from'moment';
import Carousel from 'react-bootstrap/Carousel'
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import MtestWrapper from "../../components/layout/MtestWrapper";
import BottomNavBar from "../../components/layout/Ctest";
import { wwc } from "../../data/competitions/wwc";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const DesktopComponent = () => (<>
<CompetitionWrapperWWC competition={wwc}>
    <div className="container">
        <h1>ShePlays Name Game</h1>
        <p>Thank you to everyone who submitted their fantasy team in to our Name Game Competition! We received a lot of excellent entries,
        making it very difficult to come up with a shortlist. Eventually we were able to agree on our four favourite names, and we then put it to a vote, 
        with our followers on twitter deciding the overall winner... </p>
	<img className="rounded mx-auto d-block mb-3" src="/img/namegame2.png" width="500"/>
        <p>Congratulations to our Name Game Champion, Morgan a Feeling! As a prize for coming up with the best team name, we've sent out a custom lego minfig of their favourite 
	player - Jenni Hermoso!</p><img className="rounded mx-auto d-block mb-3" src="/img/wwc-name-lego.jpg" width="400"/>
	<p>A honourable mention must be made to Rapinoe Noir, which was also an EXCELLENT
         name. We can't wait to see what names everyone comes up with next time!</p>
</div>	
	<div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>
</CompetitionWrapperWWC>
	
</>)

const MobileComponent = () => (<>
	    <head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
    </head>

    <header className="mastheadHome text-center text-white">
      <div className="masthead-contentHome">
        <div className="container" style={{height:'500px'}}>
	<h1> </h1>
        </div>
      </div>
    </header>


<Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/AusChi2-Kerr.jpg'
      alt="First slide"
    />
    <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/AusChi2-Kerr.jpg'
      alt="Second slide"
    />

    <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/AusChi2-Kerr.jpg'
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

    <div className="container" style={{marginLeft: '60px'}}>
        <h3>ShePlays Name Game</h3>
        <p>Welcome to our mobile site!</p>
        <p>Thank you to everyone who submitted their fantasy team in to our Name Game Competition! We received a lot of excellent entries,
        making it very difficult to come up with a shortlist. Eventually we were able to agree on our four favourite names, and we then put it to a vote,
        with our followers on twitter deciding the overall winner... </p>
        <img className="rounded mx-auto d-block mb-3" src="/img/namegame2.png" width="100"/>
        <p>Congratulations to our Name Game Champion, Morgan a Feeling! As a prize for coming up with the best team name, we've sent out a custom lego minfig of their favourite
        player - Jenni Hermoso!</p><img className="rounded mx-auto d-block mb-3" src="/img/wwc-name-lego.jpg" width="400"/>
        <p>A honourable mention must be made to Rapinoe Noir, which was also an EXCELLENT
         name. We can't wait to see what names everyone comes up with next time!</p>
</div>
        <div className="container" align="center">
  <h5>Support ShePlays</h5>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>
	<BottomNavBar />
</>)

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 2620;

  return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
};


export default function App() {
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}
