import React, { useState } from 'react';
import numeral from 'numeral';
import { Input } from "reactstrap";
import Flag from 'react-world-flags';
import BottomNavBar from '../../../components/layout/MCompetionWrapperAFLW.jsx';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperAFLW } from '../../../components/layout/CompetionWrapperAFLW';
import { aflw } from '../../../data/competitions/aflw-2021';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow, TableColumnResizing,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../../data/aflw/2020/stats/statsList.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

export default () => {

const columns = [
    { title: 'Pos', name: 'pos'},
    { title: 'Player Name', name: 'name'},  
    { title: ' ', name: 'teamName',
    getCellValue: t => <img src={`/img/aflw/logo/${t.teamName}.png`} width="35"/>},
    { title: 'Contested Ball', name: 'CB'},
    { title: 'Ball Movement', name: 'BM'},
    { title: 'Defence', name: 'Def'},
    { title: 'Score', name: 'SC'},
    { title: 'Total', name: 'Total'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>RUCK</option>
      <option>DEF</option>
      <option>MID</option>
      <option>FWD</option>
    </Input>
  </th>
);



const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'pos') {
        return PositionPicker(props);
    }
    return <th />
};

const [defaultColumnWidths] = useState([
        {columnName: 'pos', width: 80},
        {columnName: 'name', width:200},
        {columnName: 'teamName', width:100},
        {columnName: 'CB', width:80},
        {columnName: 'BM', width:80},
        {columnName: 'Def', width:80},
        {columnName: 'SC', width:80},
        {columnName: 'Total', width:120}

]);
const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

return ( <>

    <div className="container clearfix mt-32">
   </div>

    <div className="container" style={{fontSize:'0.7rem'}}>
        <Grid rows={data.Players.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
        <BottomNavBar/>

</>
);
};


