import React from 'react';
import moment from 'moment';
import { CompetitionWrapperNWSL } from "../../components/layout/CompetionWrapperNWSL";
import { nwsl } from "../../data/competitions/nwsl-2022";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const HowToPlayNWSL = () => (<>
<CompetitionWrapperNWSL competition={nwsl}>
    <div className="container">
        <h1>How to Play</h1>
        <h3>Register</h3>
        <p>To participate in the ShePlays fantasy competition you will need to register with our site. 
	Registering for ShePlays is free and easy, all you need is a valid email address, a username and a password.
	</p>
	<h3>Selecting your team</h3>
	<p> Team selection is now open!  
	The players you select will earn points for each match 
	based on how well they play. Your team will have 15 players, and must include:</p>  
	<ul>
	  <li>2 Goalkeepers</li>
	  <li>4 Defenders</li>
	  <li>4 Midfielders</li>
	  <li>3 Forwards</li>
	  <li>2 Free Choice players (any position)</li>
	</ul>
	<p>Your team can only have up to four players from any one club - this is to encourage you to learn more about the other players in the league, and to help ensure your team is different to everyone else's. If you are competing against your friends, it can be fun to take turns picking players, choosing players that your friends don't already have in their team.</p>
		<div className="" align="center"><img className="responsive-image-medium" src="/img/nwsl/Sonnett_Esther.jpg" width="80%" align="center"/>
	<figcaption align="center">Photo by Peyton Skeels, All rights reserved.</figcaption></div>
	<h3>Team budget and player prices</h3>
	<p>You can now view player prices on the Teams and Players pages! Player prices vary from $75,000 up to $500,000 depending on the skill and experience of the player, with prices set based on playing statistics and performances at both club and national level where applicable. Fantasy prices are simply an indication of the number of fantasy points we think a player might score during the season, and are not necessarily an indicator of the true talent or value of a player.</p>
	<p>You will have a budget of $3,300,000 to spend on your fantasy dream team! You will have to stay within your budget when selecting your team, as well as during any player transfers you may make (see below).</p>  
	<h3>Transfers and match rounds</h3>
	<p>The NWSL is played over 25 rounds, with each team usually playing once per round. Check out the <a href='/nwsl/fixtures'>Fixtures & Results</a> page to see who each team 
    is playing against each week.</p>
	
	<p>Between each round of matches is a transfer window, where you
	may make changes to your team. During transfer windows you may trade player(s) from your squad for new player(s), as long as you stay
	within your budget and have enough players in each position. During transfer windows you can also make changes to your starting 
	eleven, or choose a new captain. You are allowed to make one transfer in each window at no cost. You may also choose to make more 
    than 1 player transfer in a window, however each additional transfer will subtract 5 fantasy points from your total.</p>
    </div>
    
	
    <div className="container">
	<h3>Selecting your captain and starting eleven</h3>
	<p>At the start of each round of matches you will choose your starting eleven players who will earn points for your team.
        If one of your starting players does not participate in the round,
        then a player from your bench will take their place. Your eleven active players must be in an allowed formation. </p>
	<ul>
	    <li>3 Defenders - 5 Midfielders - 2 Forwards (3-5-2)</li>
	    <li>3 Defenders - 4 Midfielders - 3 Forwards (3-4-3)</li>
            <li>4 Defenders - 3 Midfielders - 3 Forwards (4-3-3)</li>
            <li>4 Defenders - 4 Midfielders - 2 Forwards (4-4-2)</li>
            <li>5 Defenders - 3 Midfielders - 2 Forwards (5-3-2)</li>
	</ul>
	<p>You can also select one of your players to be your team's captain. Your captain will earn double points towards your team's total, so choose wisely!</p>
        <h5>Vice Captain</h5>
    <p>Your vice captain is the first name in your starting team below your captain. If your captain does not play in a round, then your vice captain will earn double points instead. 
    It is recommended to order your players on the Manage Team page with your favourite/most important players at the top of your list.</p>
	<h3>Scoring</h3>
    <p>Each round players will earn fantasy points, based on their performance in a range of statistics. Like other fantasy competitions, players will earn 
    points by scoring goals and assists, and will lose points if they receive a yellow or red card. However, players will also earn bonus points, based on various attacking, defending, and goalkeepeing statistics, such as shots, tackles, intercepts, and saves. The more positive contributions a player makes to a game, the more fantasy points they are likely to score. 
    Any player can earn bonus points, regardless of their position.
    </p>

<div className="row align-items-start">
        <div className="col-sm-6 order-sm-1">
        <h5>ShePlays Scoring Matrix</h5>
       <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Statistic</th>
                    <th scope="col">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Minutes Played</td>
                    <td>2pts (&gt; 60 min), 1pt (&gt; 1 min)</td>
                </tr>
                <tr>
                    <td>Goals</td>
                    <td>5pts</td>
                </tr>
                <tr>
                    <td>Assists</td>
                    <td>3pts</td>
                </tr>
                <tr>
                    <td>Yellow Card</td>
                    <td>-2pts</td>
                </tr>
                <tr>
                    <td>Red Card</td>
                    <td>-5pts</td>
                </tr>
                <tr>
                    <td>Clean Sheet</td>
                    <td>GK and DF = 5pts (&gt; 60 min)<br/>
                        GK and DF = 2pts (&gt; 1 min)</td>
                </tr>
                <tr>
                    <td>Attack Points</td>
                    <td>Based on various attacking statistics (see below)</td>
                </tr>
                <tr>
                    <td>Defense Points</td>
                    <td>Based on various defending statistics (see below)</td>
                </tr>
                <tr>
                    <td>Goalkeeper Points</td>
                    <td>Based on various goal keeping statistics (see below)</td>
                </tr>
                <tr>
                    <td>W/D/L</td>
                    <td>1/0/0</td>
                </tr>
                <tr>
                    <td>Goal Difference</td>
                    <td>(GF-GA)/2 rounded down</td>
                </tr>
            </tbody>
        </table>


    </div>
        <div className="col-sm-6 order-sm-2">
    <h5>Bonus Points</h5>
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Attack Points</th>
                    <th scope="col">1 Point</th>
                    <th scope="col">2 Points</th>
                    <th scope="col">3 Points</th>
                </tr>
            </thead>

                <tbody>
                <tr>
                    <td>Number of passes</td>
                    <td>40</td>
                    <td>55</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>Number of crosses</td>
                    <td>3</td>
                    <td>5</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Number of shots</td>
                    <td>3</td>
                    <td>5</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Chances created</td>
                    <td colspan="3">1 point per chance created</td>
                </tr>
                <tr>
                    <td>Shots on target</td>
                    <td colspan="3">1 point per shot on target</td>
                </tr>
            </tbody>
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Defence Points</th>
                    <th scope="col">1 Point</th>
                    <th scope="col">2 Points</th>
                    <th scope="col">3 Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of tackles</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of intercepts</td>
                    <td>2</td>
                    <td>3</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Number of clearances</td>
                    <td>4</td>
                    <td>7</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Number of Aerial Challenges</td>
                    <td>3</td>
                    <td>4</td>
                    <td>6</td>
                </tr>
                <tr>
                    <td>Number of Duels</td>
                    <td>5</td>
                    <td>7</td>
                    <td>10</td>
                </tr>
            </tbody>
            <thead className="thread-dark">
                <tr>
                    <th scope="col">GK Points</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Saves/smoother/claim</td>
                    <td colspan="3">0.5 points per save, sweeper action, and cross claimed (rounded down)</td>
                </tr>
                <tr>
                    <td>Penalty saves</td>
                    <td colspan="3">5 points per penalty save</td>
                </tr>
            </tbody>
        </table>


    </div>
    </div>

    <p>Note that there are no point deductions for missing a penalty, and that earning a penalty is not counted as an assist.</p>
</div>
<div className="container">
	
    <h3>Stats by FBref <img align="right"src="/img/fbref_wwc_logo.png" width="250" hspace="20"/></h3>
        <p>The statistics we use to calculate fantasy points are generously provided by <a href="https://fbref.com/en/">FBref</a>. Check them out for a detailed breakdown of every players individual statistics for every match! Full stats may take a couple of days to become available, but we will do our best to 
	get total fantasy points updated as soon as possible after each round has finished, to give you time to plan your team for the following week!</p>
    </div>

    <div className="container" align="center">
    <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a></p>
    </div>
    <div className="container">
        <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
        <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
    </div>	
    	
    </CompetitionWrapperNWSL>

</>)

export default HowToPlayNWSL;
