import React from 'react';
import moment from 'moment';
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../data/competitions/aflw-2021";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const HowToPlayAFLW = () => (<>
<CompetitionWrapperAFLW competition={aflw}>
    <div className="container">
        <h1>How to Play</h1>
        <h3>Register</h3>
        <p>To participate in the ShePlays fantasy competition you will need to register with our site. 
	Registering for ShePlays is free and easy, you can sign up with your email, Facebook or Google account.
	</p>
	<h3>Selecting your team</h3>
	<p> Team selection will open this weekend! You will then be able to start picking your fantasy team. 
	The players you select will earn points for each match 
	based on how well they play. Your team will have 21 players, and must include:</p>  
	<ul>
	  <li>2 Rucks</li>
	  <li>6 Defenders</li>
	  <li>6 Midfielders</li>
	  <li>6 Forwards</li>
	  <li>1 Free Choice player (any position)</li>
	</ul>
	<p>Your team can only have up to 4 players from any one club - this is to encourage you to learn more about the other players
	participating in the tournament, and to help ensure your team is different to everyone else's. If you are competing against your 
	friends, it can be fun to take turns picking players, choosing players that your friends don't already have in their team.</p>
		<div className=""></div><img className="responsive" src="/img/aflw/AFLWHowToPlay.jpg" width="400"/>
	<figcaption align="center">Photo by <a href="https://commons.wikimedia.org/wiki/File:Adelaide_AFLW.3.jpg" title="User:Flickerd">Flickerd</a>, 
	<a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution 4.0"> CC BY-SA 4.0</a>
            </figcaption>
	<h3>Team budget and player prices</h3>
	<p>You can now view player prices on the Teams and Players pages! Player prices vary from $15,000 upto $60,000 depending on the skill
	and experience of the player, with prices set based on playing statistics and performances at both national 
	and club level. Fantasy prices are simply an indication of the number of fantasy points we think a player might score during the tournament, and is 
	not necessarily an indicator of the true talent or value of a player. Once we have finalised all player prices, we will announce the budget you'll have to spend on your team. You will have to stay within the budget when selecting your team, as well as during any player transfers you may make.</p>  
	<h3>Transfers and match rounds</h3>
	<p>AFLW is played over 10 rounds, with the 18 teams playing in 2022. Check out the <a href='/aflw/fixtures'>Fixtures & Results</a> page to see who each team 
    is playing against each week.</p>
	
	<p>Between each round of matches is a transfer window, where you
	may make changes to your team. During transfer windows you may trade player(s) from your squad for new player(s), as long as you stay
	within your budget and have enough players in each position. During transfer windows you can also make changes to your starting 
	16, or choose a new captain. You are allowed to make 2 transfers in each window at no cost. You may also choose to make more 
    than 2 player transfers in a window, however each additional transfer will subtract fantasy points from your total.</p>
    </div>
    
	
    <div className="container">
	<h3>Selecting your captain and starting sixteen</h3>
	<p>At the start of each round of matches you will choose your starting sixteen players who will earn points for your team.
        If one of your starting players does not participate in the round,
        then a player from your bench will take their place. Your sixteen active players must include: </p>
	<ul>
	    <li>5 Defenders - 5 Midfielders - 5 Forwards - 1 Ruck (5-5-5-1)</li>
	</ul>
	<p>You can also select one of your players to be your team's captain. Your captain will earn double points towards your team's total, so choose wisely!</p>
        <h5>Vice Captain</h5>
    <p>Your vice captain is the first name in your starting team below your captain. If your captain does not play in a round, then your vice captain will earn double points instead. 
    It is recommended to order your players on the Manage Team page with your favourite/most important players at the top of your list.</p>
	<h3>Scoring</h3>
	<p>Points are scored by players based on their performance in a range of statistics. Players will earn points by scoring goals, behinds, and assists, and will lose 
	points if they miss a shot or turn the ball over. They can also earn points based on various attacking/defensive statistics, such as marks, intercepts, and clearances. 
	The more positive contributions a player makes to a game, the more fantasy points they are likely to score.</p>
	<p><b>We will confirm the scoring matrix once we have confirmed which statistics will be available, but it is likely to be similar to the scoring system below!</b></p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Statistic</th>
                    <th scope="col">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Disposal-efficiency weighted meters-gained</td>
                    <td>1pt/5m</td>
	        </tr>
	        <tr>
                    <td>Contested possession</td>
                    <td>3pts</td>
                </tr>
                <tr>
                    <td>Clearance</td>
                    <td>5pts</td>
                </tr>
                <tr>
                    <td>Inside-50</td>
                    <td>3pts</td>
                </tr>
                <tr>
                    <td>Hitout</td>
                    <td>1pt</td>
                </tr>
                <tr>
                    <td>Mark</td>
                    <td>2pts<br/>
	            +6pts if contested<br/>
	            +6pts if inside-50</td>
                </tr>
                <tr>
                    <td>Tackle</td>
                    <td>4pts<br/>
	                +4pts if inside-50</td>
                </tr>
	        <tr>
	           <td>One-percenter</td>
	           <td>4pts</td>
	        </tr>
                <tr>
                    <td>Intercept</td>
                    <td>4pts</td>
                 </tr>
	        <tr>
	           <td>Goal</td>
	           <td>12pts</td>
	       </tr>
	       <tr>
	           <td>Behind</td>
	           <td>1 pt</td>
	       </tr>
	       <tr>
	           <td>Non-scoring shot</td>
	           <td>-1pt</td>
	       </tr>
                <tr>
                   <td>Goal assist</td>
                   <td>6pts</td>
                 </tr>
                 <tr>
	           <td>Score involvement</td>
	           <td>3pts</td>
	        </tr>
	        <tr>
	           <td>Turnover</td>
	           <td>-1pt</td>
	       </tr>
	       <tr>
	           <td>Clanger</td>
	           <td>-3pts</td>
	        </tr>
	    </tbody>
	</table>
    </div>

    <div className="container" align="center">
    <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a></p>
    </div>
    <div className="container">
        <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
        <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
    </div>	
    	
    </CompetitionWrapperAFLW>

</>)

export default HowToPlayAFLW;
