import React from 'react';
import moment from'moment';
import BottomNavBar from '../../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const FeedbackM = () => (<>
		
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h3>Why Fantasy Sports Matter</h3>
        <p style={{fontSize:'0.8rem'}}>After the World Cup we sent out a survey to all users asking for feedback on the Fantasy League. Read on below to see the results!</p>
<a href='https://blog.sheplays.com.au/why-you-should-give-fantasy-w-league-a-go/' target='blank' className="btn btn-teams btn-l rounded-pill mt-1" >
        Why we started ShePlays
        </a>

	<p style={{marginTop:'0.5cm'}}><h4>Q1. What is your nationality?</h4></p>
</div>
<div align="center"><img className="responsive" src="/img/Nationality.png"  alt="nationalities-graph" />
</div>
<div className="container">
	<p><h4>Q2. What is your gender?</h4></p>	
</div>

<div align="center"><img className="responsive" src="/img/Gender.png" alt="gender-graph" />
</div>

<div className="container">
        <p><h4>Q3. Had you played fantasy sport before?</h4></p>
</div>

<div align="center"><img className="responsive" src="/img/Fantasy.png" alt="fantasy-graph" />
</div>

<div className="container">
        <p><h4>Q4. How much had you watched or followed professional women's football?</h4></p>
</div>

<div align="center"><img className="responsive" src="/img/Supporter.png" alt="supporter-graph" />
</div>

<div className="container">
        <p><h4>Q5. Did participating the the ShePlays Fantasy League make you more interested in following the results of Women's World Cup?</h4></p>
</div>

<div align="center"><img className="responsive" src="/img/Results.png" alt="results-graph" />
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>


	<BottomNavBar/>

</>)

export default FeedbackM;
