export const soccer = {
  teamSize: 11,
  squadSize: 15,
  freeChoice: 2,
  positions: {
    GK: {
      name: "goalkeeper",
      starting: {
        min: 1,
        max: 1
      },
      squad: {
        min: 2
      }
    },
    DF: {
      name: "defender",
      starting: {
        min: 3,
        max: 5
      },
      squad: {
        min: 4
      }
    },
    MF: {
      name: "midfielder",
      starting: {
        min: 3,
        max: 5
      },
      squad: {
        min: 4
      }
    },
    FW: {
      name: "forward",
      starting: {
        min: 2,
        max: 3
      },
      squad: {
        min: 3
      }
    }
  }
};
