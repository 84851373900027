import { afl } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const teams = {
  ADE: {
    name: "Adelaide Crows",
    player: "/img/aflw/shirts/ADE.png",
  },
  BRL: {
    name: "Brisbane Lions",
    player: "/img/aflw/shirts/BRL.png",
  },
  CAR: {
    name: "Carlton",
    player: "/img/aflw/shirts/CAR.png",
  },
  COL: {
    name: "Collingwood",
    player: "/img/aflw/shirts/COL.png",
  },
  FRE: {
    name: "Fremantle",
    player: "/img/aflw/shirts/FRE.png",
  },
  GEE: {
    name: "Geelong Cats",
    player: "/img/aflw/shirts/GEE.png",
  },
  GCS: {
    name: "Gold Coast Suns",
    player: "/img/aflw/shirts/GCS.png",
  },
  GWS: {
    name: "GWS Giants",
    player: "/img/aflw/shirts/GWS.png",
  },
  MEL: {
    name: "Melbourne",
    player: "/img/aflw/shirts/MEL.png",
  },
  NTH: {
    name: "North Melbourne",
    player: "/img/aflw/shirts/NTH.png",
  },
  RIC: {
    name: "Richmond",
    player: "/img/aflw/shirts/RIC.png",
  },
  STK: {
    name: "St Kilda",
    player: "/img/aflw/shirts/STK.png",
  },
  WCE: {
    name: "West Coast Eagles",
    player: "/img/aflw/shirts/WCE.png",
  },
  WBD: {
    name: "Western Bulldogs",
    player: "/img/aflw/shirts/WBD.png",
  },
};

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+00"),
    closes: moment("2021-01-28 18:15:00+11"),
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2021-01-31 18:10:00+11"),
    closes: moment("2021-02-05 18:45:00+11"),
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2021-02-07 19:10:00+11"),
    closes: moment("2021-02-12 18:10:00+11"),
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2021-02-14 19:10:00+11"),
    closes: moment("2021-02-19 17:10:00+11"),
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2021-02-21 19:10:00+11"),
    closes: moment("2021-02-26 18:10:00+11")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2021-02-28 19:10:00+11"),
    closes: moment("2021-03-05 16:10:00+11")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2021-03-07 18:40:00+11"),
    closes: moment("2021-03-12 17:40:00+11")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2021-03-15 19:10:00+11"),
    closes: moment("2021-03-19 16:10:00+11")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2021-03-21 20:10:00+11"),
    closes: moment("2021-03-26 16:10:00+11")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2021-03-28 20:10:00+11"),
    closes: moment("2021-04-03 12:05:00+11")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2021-04-03 17:10:00+11"),
    closes: moment("2021-04-10 12:05:00+11")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2021-04-11 17:10:00+11"),
    closes: moment("2021-04-17 14:00:00+10")
  }

];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const aflw = {
  shortName: "aflw",
  competition: "aflw",
  name: "AFLW",
  sport: afl,
  totalBudget: 700000,
  minPlayerPrice: 15000,
  maxPlayerPrice: 60000,
  perClubLimit: 21,
  transferCost: 100,
  freeTransfers: 21,
  hashtag: "ShePlaysAFLW",
  init,
  teams,
  rounds,
  shirts: teams,
  season: "2021",
};
