import React, { useState } from 'react';
import { useAuth } from "../../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperWWC } from '../../../components/layout/CompetionWrapperWWC23';
import { wwc } from '../../../data/competitions/wwc';
import BottomNavBar from '../../../components/layout/MCompetionWrapperWWC.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const WWCLandingPageD = () => (<CompetitionWrapperWWC competition={wwc}>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wwc/kerr2.png" alt="" />
	    <figcaption align="center"><a href="https://www.flickr.com/photos/184624454@N06/albums/72157719963805838" title = "Nicole Blinco" target="blank">ShePlays</a><br/>All rights reserved</figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlays World Cup</h2>
              <p style={{textAlign:'justify'}}> 
	       The FIFA Women's World Cup is back, with the 2023 tournament being hosted down here in Australia and New Zealand. Our ShePlays 
	       fantasy World Cup competition is also back for 2023, with team selection opening soon! This is your chance to build your 
	       dream team from players competing at the 2023 World Cup, and to test yourself against your friends, family, and the rest of 
	       the ShePlays community. Our fantasy competition is a great way to learn more about the players and teams competing in the tournament, 
	       and to make sure you don't miss out on any of the action.
	      </p>
            </div>
          </div>
        </div>
	<div className="leftBox">
        <p style={{textAlign:'justify'}}>
                <b> Our fantasy World Cup team selection is now open!</b> The deadline to get your team in 
	        for round 1 is {localDate("2023-07-20 17:00+10")}. 
                 Make sure you follow us on
                <a href="https://twitter.com/sheplays_au" target="_blank">
                  {" "}
                  Twitter
                </a>
                ,{" "}
                <a
                  href="https://www.instagram.com/sheplays_au/"
                  target="_blank"
                >
                  {" "}
                  Instagram
                </a>{" "}
                and{" "}
                <a
                  href="https://www.facebook.com/sheplaysfantasyfootball/"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                to keep up to date with the latest news and announcements. Watch the videos below to find out how to get started!

              </p>
        </div>
      </div>

  <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
	<div className="centerBox">
	<h5>Build your Women's World Cup fantasy team</h5>
	<iframe width="90%" height="255"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
	</div>
	</div>
        <div className="col-lg-6 order-lg-1">
	<div className="centerBox">
	<h5>Create your free ShePlays account</h5>
        <iframe width="90%" height="255" src="https://www.youtube.com/embed/LevVeCEt3bc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
	</div>
	</div>
      </div>
     </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wwc/spain1.png" alt="" />
	<figcaption align="center"><a href="https://www.girlsontheball.com/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">How to play</h2>
              <p style={{textAlign:'justify'}}>You'll be given a set budget to spend on the 15 players that will make up your fantasy team. 
	       Your players will then receive points after each game based on how they play. 
	       After each round of matches you will get the chance to make transfers, select your starting XI, 
	       and choose a captain who will earn double points in the next round. You can also set up your own 
	       mini-leagues to compete against your friends! For more information see the <a href="/wwc/how-to-play" 
	       target="blank">How To Play</a> page.</p>
            </div>
          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wwc/usa1.png" alt="" />
	<figcaption align="center"><a href="https://www.girlsontheball.com/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Player Research</h2>
	      <p style={{textAlign:'justify'}}>
                 Need help deciding who to pick for your team? We have plenty of helpful resources that can help you learn more about the 
	         teams and players. Browse our complete set of fantasy stats from our <a href="/wwc/19" target="blank">2019 World Cup</a> or 
	         our <a href="/euro/" target="blank"> 2022 Euro's</a> fantasy competitions. Or you can browse the fantasy stats and detailed 
	         player profiles we have across our regular <a href="/wsl" target="blank">WSL</a>, <a href="/nwsl" target="blank">NWSL</a>, or 
	         <a href="/wl" target="blank"> W-League</a>, competitions. We've been running fantasy competitions for women's football since 
	         2017, so we have plenty of data to help you find the potential stars of the 2023 tournament!
	      </p>
            </div>
          </div>
        </div>
      </div>
  </section>




  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperWWC>)





const WWCLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadWWC2 text-center text-white">
      <div className="masthead-contentWWC2">
        <div className="container">
          <h1 className="masthead-headingWWC2 mb-0">ShePlaysWWC 2023</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
          Compete against your friends in our fantasy Women's World Cup competition! Team selection will open soon!
          </p>
        {currentUser ? (
        <a href="/wwc/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My World Cup Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}
        </div>
      </div>
    </header>
<div className="container">
<a href='/wwc/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
<a href='/wwc/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results
        </a>
<a href='/wwc/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams
        </a>
<a href='/wwc/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Players
        </a>
<a href='/wwc/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

        <BottomNavBar />
</>     );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <WWCLandingPageM /> : <WWCLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


