import React from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWL } from '../../../components/layout/CompetionWrapperWL';
import { wl } from '../../../data/competitions/wl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../../data/wl/2018/wlgleaderboard18"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Fantasy Team', name: 'Name' },
    {
        title: 'Total Points 18/19',
        name: 'Points',
        getCellValue: t => t.Points || 0
    }
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const WLGLeaderboard18 = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
        <h1>Global Leaderboard 2018/19</h1>
	<p><b>Congratualtions to our inaugural ShePlays champion Rach Talks Soccer! </b><br/>
	Check out how your W-League fantasy team fared against everyone else in our global leaderboard below.</p>
    </div>
    <div className="container">
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWL>
</>
export default WLGLeaderboard18
