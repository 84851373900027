import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

export function useAuth() {
    const [currentUser, setCurrentUserT] = useState(null)

    const setCurrentUser = (e) => {
        setCurrentUserT(e)
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(setCurrentUser)
        const listener = ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    if(data && data.attributes) setCurrentUser(data)
                    else Auth.currentAuthenticatedUser().then(setCurrentUser)
                    break;
                case "signOut":
                    setCurrentUser(null);
                    break;
                default:
                    break;
            }
        };
        Hub.listen("auth", listener)
        return () => Hub.remove("auth", listener)
    }, [])
    return currentUser
}