import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const NorvNga = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/Norway.png" width="50" hspace="20" />Norway v Nigeria<img src="img/RoundFlags/Nigeria.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group A</center></h5>
	<p><center><b>3 - 0</b><br/>Stade Auguste-Delaune<br/>
	Reims<br/>
	{localDate("2019-06-08 21:00+02")}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	            <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>
<tr>
<td>Guro Reiten</td>
<td>NOR</td>
<td>16</td>
<td>2</td>
<td>5</td>
<td>3</td>
<td>4</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Caroline Graham Hansen</td>
<td>NOR</td>
<td>11</td>
<td>2</td>
<td>0</td>
<td>3</td>
<td>4</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Ingrid Wold</td>
<td>NOR</td>
<td>10</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Ingrid Hjelmseth</td>
<td>NOR</td>
<td>10</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Maria Thórisdóttir</td>
<td>NOR</td>
<td>10</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Lisa-Marie Utland</td>
<td>NOR</td>
<td>10</td>
<td>2</td>
<td>5</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Maren Mjelde</td>
<td>NOR</td>
<td>10</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Ngozi Ebere</td>
<td>NGA</td>
<td>7</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Kristine Minde</td>
<td>NOR</td>
<td>6</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Vilde Bøe Risa</td>
<td>NOR</td>
<td>6</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Ingrid Syrstad Engen</td>
<td>NOR</td>
<td>5</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Isabell Herlovsen</td>
<td>NOR</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Elise Thorsnes</td>
<td>NOR</td>
<td>4</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Osinachi Ohale</td>
<td>NGA</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Rita Chikwelu</td>
<td>NGA</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Asisat Oshoala</td>
<td>NGA</td>
<td>4</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>3</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Francisca Ordega</td>
<td>NGA</td>
<td>3</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>4</td>
<td>0</td>
<td>0</td>
<td>-2</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Frida Maanum</td>
<td>NOR</td>
<td>3</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Emilie Haavi</td>
<td>NOR</td>
<td>3</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Ngozi Okobi</td>
<td>NGA</td>
<td>2</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Oluehi Tochukwu</td>
<td>NGA</td>
<td>2</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Onome Ebi</td>
<td>NGA</td>
<td>2</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Uchenna Kanu</td>
<td>NGA</td>
<td>1</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Halimatu Ayinde</td>
<td>NGA</td>
<td>1</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Chidinma Okeke</td>
<td>NGA</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Faith Michael</td>
<td>NGA</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Chinaza Uchendu</td>
<td>NGA</td>
<td>0</td>
<td>1</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
<tr>
<td>Desire Oparanozie</td>
<td>NGA</td>
<td>-1</td>
<td>2</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>0</td>
<td>-2</td>
<td>0</td>
<td>0</td>
<td>-1</td>
</tr>
            </tbody>
	</table>
    </div>	

</>)

export default NorvNga;
