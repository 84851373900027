import React from 'react';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import { wwc } from "../../data/competitions/wwc";
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/leaderboard"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Team Name', name: 'teamName' },
    {
        title: 'R1', 
        name: 'r1',
        getCellValue: t => t.score["round-1"] || 0
    },
    {
        title: 'R2',
        name: 'r2',
        getCellValue: t => t.score["round-2"] || 0
    },
    {
        title: 'R3',
        name: 'r3',
        getCellValue: t => t.score["round-3"] || 0
    },
    {
        title: 'R4',
        name: 'r4',
        getCellValue: t => t.score["round-4"] || 0
    },
    {
        title: 'R5',
        name: 'r5',
        getCellValue: t => t.score["round-5"] || 0
    },
    {
        title: 'R6',
        name: 'r6',
        getCellValue: t => t.score["round-6"] || 0
    },
    {
        title: 'R7',
        name: 'r7',
        getCellValue: t => t.score["round-7"] || 0
    },
    {
        title: 'Total',
        name: 'totalScore',
        getCellValue: t => t.score["total"] || 0
    }
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'teamName') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const Leaderboard = () => <>
<CompetitionWrapperWWC competition={wwc}>
    <div className="container clearfix mt-32">
        <h1>Global Leaderboard</h1>
	<p><b>Congratualtions to our FIFA WWC ShePlays champion the Indomitable Drakanina's! </b>
    <br/>We wanted to do something a little bit different and creative for our prizes... so we decided to design Lego minifigs of our Round and Overall winner's favourite players!</p>
    <img className="rounded mx-auto d-block mb-3" src="/img/wwc-lego.png" width="700"/>
    <p>
    Check out how your Women's World Cup fantasy team fared agaisnt everyone else 
    in our global leaderboard below!</p>
    </div>
    <div className="container">
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWWC>
</>
export default Leaderboard
