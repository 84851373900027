/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "sheplays-client-20190505113948-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d2g23j7bx2r92e.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-southeast-2:8cff719b-3801-454e-a3fc-0c9c804efdc0",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_RjM6nQnX8",
    "aws_user_pools_web_client_id": "5bet988keipo5ud606a6vt0kqs",
    "oauth": {
        "domain": "sheplays-prod.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://sheplays.com.au/",
        "redirectSignOut": "https://sheplays.com.au/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "wsl-leagues-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wsl-matches-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wsl-players-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wsl-teams-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wl-leagues-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wl-matches-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wl-players-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "wl-teams-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "aflw-leagues-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "aflw-matches-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "aflw-players-prod",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "aflw-teams-prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "leagues",
            "endpoint": "https://w0zrzoaltc.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "matches",
            "endpoint": "https://kqkiiodeng.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "players",
            "endpoint": "https://yftmqgn5hc.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "wslteams",
            "endpoint": "https://g60k4ttgaj.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "wslteam",
            "endpoint": "https://2tg1vl11l0.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "team",
            "endpoint": "https://us6s70fhsc.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ],
    "Auth": {
        "hiddenDefaults": [
            "username"
        ],
        "signUpFields": [
            {
                "label": "Email",
                "key": "username",
                "required": true,
                "displayOrder": 1,
                "type": "email",
                "custom": false
            },
            {
                "label": "Password",
                "key": "password",
                "required": true,
                "displayOrder": 2,
                "type": "password",
                "custom": false
            }
        ]
    }
};


export default awsmobile;
