import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import moment from 'moment';
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const Fixtures = () => {
    const [matches, setMatches] = useState([])

    useEffect(() => (async () => {
        const matches = await API.get('matches','/matches')
        setMatches(matches)
    })(), [])

    const matchLink = (matchId, placeholder) => {
        const match = matches.find(m => m.matchId === matchId)
        if(match) {
            if(match.penaltyScoreA || match.penaltyScoreB) {
                return <a href={`/match/${matchId}/${match.teamA}v${match.teamB}`}>{match.scoreA} - {match.scoreB} <i>({match.penaltyScoreA} - {match.penaltyScoreB})</i></a>
            }
            return <a href={`/match/${matchId}/${match.teamA}v${match.teamB}`}>{match.scoreA} - {match.scoreB}</a>
        }
        else return localDate(placeholder)
    }

    return (<>
    <CompetitionWrapperWWC competition={wwc}>
    <div className="container">
        <h1>Fixtures and Results</h1>
	<p>View the results from all 52 matches of the World Cup below. Click on a score to see a detailed break down of each players fantasy points from that match!</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Round 1</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
	            <th scope="col"></th>
	            <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">France <img src="/img/RoundFlags/France.png" width="50" hspace="20" /></td> 
	            <td className="align-middle" align="center"><a href="/fra-kor">4 - 0</a></td> 
	            <td align="left"><img src="/img/RoundFlags/Korea.png" width="50" hspace="20"/> Korea</td>
                    <td>Group A</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Germany <img src="/img/RoundFlags/Germany.png" width="50" hspace="20" /></td>
	            <td className="align-middle" align="center"><a href="/deu-chn">1 - 0</a></td>
	            <td align="left"><img src="/img/RoundFlags/China.png" width="50" hspace="20"/> China</td>
                    <td>Group B</td>
                </tr>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">Spain <img src="/img/RoundFlags/Spain.png" width="50" hspace="20" /></td> 
                    <td className="align-middle" align="center"><a href="/esp-zaf">3 - 1</a></td>  
                    <td align="left"><img src="/img/RoundFlags/SouthAfrica.png" width="50" hspace="20"/> South Africa</td>
                    <td>Group B</td>
                </tr>
                <tr>
                    <td>Stade Auguste-Delaune<br/>Reims</td>
                    <td align="right">Norway <img src="/img/RoundFlags/Norway.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href="/nor-nga">3 - 0</a></td>
                    <td align="left"><img src="/img/RoundFlags/Nigeria.png" width="50" hspace="20"/> Nigeria</td>
                    <td>Group A</td>
                </tr>
                <tr>
                    <td>Stade du Hainaut<br/>Valenciennes</td>
                    <td align="right">Australia <img src="/img/RoundFlags/Australia.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href="/aus-ita">1 - 2</a></td>
                    <td align="left"><img src="/img/RoundFlags/Italy.png" width="50" hspace="20"/> Italy</td>
                    <td>Group C</td>
                </tr>
                <tr>
                    <td>Stade des Alpes<br/>Grenoble</td>
                    <td align="right">Brazil <img src="/img/RoundFlags/Brazil.png" width="50" hspace="20" /></td>   
                    <td className="align-middle" align="center"><a href ="/bra-jam">3 - 0</a></td>
                    <td align="left"><img src="/img/RoundFlags/Jamaica.png" width="50" hspace="20"/> Jamaica</td>
                    <td>Group C</td>
                </tr>
                <tr>
                    <td>Stade de Nice<br/>Nice</td>
                    <td align="right">England <img src="/img/RoundFlags/England.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href ="/eng-sco">2 - 1</a></td>
                    <td align="left"><img src="/img/RoundFlags/Scotland.png" width="50" hspace="20"/> Scotland</td>
                    <td>Group D</td>
	        </tr>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">Argentina <img src="/img/RoundFlags/Argentina.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href="/arg-jpn">0 - 0</a></td>
                    <td align="left"><img src="/img/RoundFlags/Japan.png" width="50" hspace="20"/> Japan</td>
                    <td>Group D</td>
                </tr>
                <tr>
                    <td>Stade de la Mosson<br/>Montpellier</td>
                    <td align="right">Canada <img src="/img/RoundFlags/Canada.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href="/can-cam">1 - 0</a></td>
                    <td align="left"><img src="/img/RoundFlags/Cameroon.png" width="50" hspace="20"/> Cameroon</td>
                    <td>Group E</td>
                </tr>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">New Zealand <img src="/img/RoundFlags/NewZealand.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href ="/nzl-ned">0 - 1</a></td>
                    <td align="left"><img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20"/> Netherlands</td>
                    <td>Group E</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Chile <img src="/img/RoundFlags/Chile.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href ="/chi-swe">0 - 2</a></td>
                    <td align="left"><img src="/img/RoundFlags/Sweden.png" width="50" hspace="20"/> Sweden</td>
                    <td>Group F</td>
                </tr>
                <tr>
                    <td>Stade Auguste-Delaune<br/>Reims</td>
                    <td align="right">USA <img src="/img/RoundFlags/USA.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center"><a href ="/usa-tha">13 - 0</a></td>
                    <td align="left"><img src="/img/RoundFlags/Thailand.png" width="50" hspace="20"/> Thailand</td>
                    <td>Group F</td>
                </tr>
            </tbody>
        </table>
	<p>All times in your browser's local time (GMT {moment().format("Z")}).</p>
    </div>
    <div className="card text-white bg-secondary">
        <div className="container">
            <p align="center"><br/> <b> 
            Transfer Window 1</b> </p>
        </div>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Round 2</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
	            <th scope="col"></th>
	            <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Stade des Alpes<br/>Grenoble</td>
                    <td align="right">Nigeria <img src="/img/RoundFlags/Nigeria.png" width="50" hspace="20" /></td> 
	            <td className="align-middle" align="center">{matchLink("6ba02f51", "2019-06-12 15:00+02")}</td> 
	            <td align="left"><img src="/img/RoundFlags/Korea.png" width="50" hspace="20"/> Korea</td>
                    <td>Group A</td>
                </tr>
                <tr>
                    <td>Stade du Hainaut<br/>Valenciennes</td>
                    <td align="right">Germany <img src="/img/RoundFlags/Germany.png" width="50" hspace="20" /></td>
	            <td className="align-middle" align="center">{matchLink("592287ff", "2019-06-12 18:00+02")}</td>
	            <td align="left"><img src="/img/RoundFlags/Spain.png" width="50" hspace="20"/> Spain</td>
                    <td>Group B</td>
                </tr>
                <tr>
                    <td>Stade de Nice<br/>Nice</td>
                    <td align="right">France <img src="/img/RoundFlags/France.png" width="50" hspace="20" /></td> 
                    <td className="align-middle" align="center">{matchLink("f5ad060b", "2019-06-12 21:00+02")}</td>  
                    <td align="left"><img src="/img/RoundFlags/Norway.png" width="50" hspace="20"/> Norway</td>
                    <td>Group A</td>
                </tr>
                <tr>
                    <td>Stade de la Mosson<br/>Montpellier</td>
                    <td align="right">Australia <img src="/img/RoundFlags/Australia.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("2983dc91", "2019-06-13 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Brazil.png" width="50" hspace="20"/> Brazil</td>
                    <td>Group C</td>
                </tr>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">South Africa <img src="/img/RoundFlags/SouthAfrica.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("1e1a2328", "2019-06-13 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/China.png" width="50" hspace="20"/> China</td>
                    <td>Group B</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Japan <img src="/img/RoundFlags/Japan.png" width="50" hspace="20" /></td>   
                    <td className="align-middle" align="center">{matchLink("744c1666", "2019-06-14 15:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Scotland.png" width="50" hspace="20"/> Scotland</td>
                    <td>Group D</td>
                </tr>
                <tr>
                    <td>Stade Auguste-Delaune<br/>Reims</td>
                    <td align="right">Jamaica <img src="/img/RoundFlags/Jamaica.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("f76877ce", "2019-06-14 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Italy.png" width="50" hspace="20"/> Italy</td>
                    <td>Group C</td>
	        </tr>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">England <img src="/img/RoundFlags/England.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("0c795288", "2019-06-14 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Argentina.png" width="50" hspace="20"/> Argentina</td>
                    <td>Group D</td>
                </tr>
                <tr>
                    <td>Stade de Hainaut<br/>Valenciennes</td>
                    <td align="right">Netherlands <img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("3980d38b", "2019-06-15 15:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Cameroon.png" width="50" hspace="20"/> Cameroon</td>
                    <td>Group E</td>
                </tr>
                <tr>
                    <td>Stade des Alpes<br/>Grenoble</td>
                    <td align="right">Canada <img src="/img/RoundFlags/Canada.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("df1cae02", "2019-06-15 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/NewZealand.png" width="50" hspace="20"/> New Zealand</td>
                    <td>Group E</td>
                </tr>
                <tr>
                    <td>Stade de Nice<br/>Nice</td>
                    <td align="right">Sweden <img src="/img/RoundFlags/Sweden.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("ea6ec5cd", "2019-06-16 15:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Thailand.png" width="50" hspace="20"/> Thailand</td>
                    <td>Group F</td>
                </tr>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">USA <img src="/img/RoundFlags/USA.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("cdbb5afc", "2019-06-16 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Chile.png" width="50" hspace="20"/> Chile</td>
                    <td>Group F</td>
                </tr>
            </tbody>
        </table>
        <p>All times in your browser's local time (GMT {moment().format("Z")}).</p>
    </div>	
    <div className="card text-white bg-secondary">
        <div className="container">
            <p align="center"><br/> <b> 
            Transfer Window 2</b> </p>
        </div>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Round 3</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
	            <th scope="col"></th>
	            <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">China <img src="/img/RoundFlags/China.png" width="50" hspace="20" /></td> 
	            <td className="align-middle" align="center">{matchLink("abbcb5e7", "2019-06-17 18:00+02")}</td> 
	            <td align="left"><img src="/img/RoundFlags/Spain.png" width="50" hspace="20"/> Spain</td>
                    <td>Group B</td>
                </tr>
                <tr>
                    <td>Stade de la Mosson<br/>Montpellier</td>
                    <td align="right">South Africa <img src="/img/RoundFlags/SouthAfrica.png" width="50" hspace="20" /></td>
	            <td className="align-middle" align="center">{matchLink("b2ce87ab", "2019-06-17 18:00+02")}</td>
	            <td align="left"><img src="/img/RoundFlags/Germany.png" width="50" hspace="20"/> Germany</td>
                    <td>Group B</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Nigeria <img src="/img/RoundFlags/Nigeria.png" width="50" hspace="20" /></td> 
                    <td className="align-middle" align="center">{matchLink("67242adc", "2019-06-17 21:00+02")}</td>  
                    <td align="left"><img src="/img/RoundFlags/France.png" width="50" hspace="20"/> France</td>
                    <td>Group A</td>
                </tr>
                <tr>
                    <td>Stade Auguste-Delaune<br/>Reims</td>
                    <td align="right">Korea <img src="/img/RoundFlags/Korea.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("bb955371", "2019-06-17 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Norway.png" width="50" hspace="20"/> Norway</td>
                    <td>Group A</td>
                </tr>
                <tr>
                    <td>Stade des Alpes<br/>Grenoble</td>
                    <td align="right">Jamaica <img src="/img/RoundFlags/Jamaica.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("6c429230", "2019-06-18 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Australia.png" width="50" hspace="20"/> Australia</td>
                    <td>Group C</td>
                </tr>
                <tr>
                    <td>Stade du Hainaut<br/>Valenciennes</td>
                    <td align="right">Italy <img src="/img/RoundFlags/Italy.png" width="50" hspace="20" /></td>   
                    <td className="align-middle" align="center">{matchLink("878fe9e5", "2019-06-18 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Brazil.png" width="50" hspace="20"/> Brazil</td>
                    <td>Group C</td>
                </tr>
                <tr>
                    <td>Stade de Nice<br/>Nice</td>
                    <td align="right">Japan <img src="/img/RoundFlags/Japan.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("8b494a1d", "2019-06-19 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/England.png" width="50" hspace="20"/> England</td>
                    <td>Group D</td>
	        </tr>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">Scotland <img src="/img/RoundFlags/Scotland.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("04973c41", "2019-06-19 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Argentina.png" width="50" hspace="20"/> Argentina</td>
                    <td>Group D</td>
                </tr>
                <tr>
                    <td>Stade de la Mosson<br/>Montpellier</td>
                    <td align="right">Cameroon <img src="/img/RoundFlags/Cameroon.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("b43fd361", "2019-06-20 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/NewZealand.png" width="50" hspace="20"/> New Zealand</td>
                    <td>Group E</td>
                </tr>
                <tr>
                    <td>Stade Auguste-Delaune<br/>Reims</td>
                    <td align="right">Netherlands <img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("01c3c2bd", "2019-06-20 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Canada.png" width="50" hspace="20"/> Canada</td>
                    <td>Group E</td>
                </tr>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">Sweden <img src="/img/RoundFlags/Sweden.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("8a5d5205", "2019-06-20 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/USA.png" width="50" hspace="20"/> USA</td>
                    <td>Group F</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Thailand <img src="/img/RoundFlags/Thailand.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("43b79746", "2019-06-20 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Chile.png" width="50" hspace="20"/> Chile</td>
                    <td>Group F</td>
                </tr>
            </tbody>
        </table>
        <p>All times in your browser's local time (GMT {moment().format("Z")}).</p>
    </div>	
    <div className="card text-white bg-secondary">
        <div className="container">
            <p align="center"> <br/><b>
            Transfer Window 3</b> <br/></p>
        </div>
    </div>
    <div className="container">
	<h3>Knock-out stage</h3>
	<p> After the group stage, the top 16 sides will progress to the knock-out stage of the tournament. The knock-out stage is also broken into 
	3 rounds:</p>  
	<ul>
	  <li>Round of 16</li>
	  <li>Quarter Finals</li>
	  <li>Semi Finals + 3rd Place + Final</li>
	</ul>
	<p><b>In the last round of matches, 4 teams will play 2 matches each.</b> The knock-out stage is played over 11 matchdays, with rest days between 
	each round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Round 4</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
	            <th scope="col"></th>
	            <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Stade des Alpes<br/>Grenoble</td>
                    <td align="right">Germany <img src="/img/RoundFlags/Germany.png" width="50" hspace="20" /></td> 
	            <td className="align-middle" align="center">{matchLink("b4443953", "2019-06-22 17:30+02")}</td> 
	            <td align="left"><img src="/img/RoundFlags/Nigeria.png" width="50" hspace="20"/>Nigeria</td>
                    <td>R16-1</td>
                </tr>
                <tr>
                    <td>Stade de Nice<br/>Nice</td>
                    <td align="right">Norway <img src="/img/RoundFlags/Norway.png" width="50" hspace="20" /></td>
	            <td className="align-middle" align="center">{matchLink("8562e8f5", "2019-06-22 21:00+02")}</td>
	            <td align="left"><img src="/img/RoundFlags/Australia.png" width="50" hspace="20"/>Australia</td>
                    <td>R16-2</td>
                </tr>
                <tr>
                    <td>Stade du Hainaut<br/>Valenciennes</td>
                    <td align="right">England<img src="/img/RoundFlags/England.png" width="50" hspace="20" /></td> 
                    <td className="align-middle" align="center">{matchLink("b70afd08", "2019-06-23 17:30+02")}</td>  
                    <td align="left"><img src="/img/RoundFlags/Cameroon.png" width="50" hspace="20"/>Cameroon</td>
                    <td>R16-3</td>
                </tr>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">France <img src="/img/RoundFlags/France.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("0e1ce7f4", "2019-06-23 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Brazil.png" width="50" hspace="20"/>Brazil</td>
                    <td>R16-4</td>
                </tr>
                <tr>
                    <td>Stade Auguste-Delaune<br/>Reims</td>
                    <td align="right">Spain <img src="/img/RoundFlags/Spain.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("5b6a858b", "2019-06-24 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/USA.png" width="50" hspace="20"/>USA</td>
                    <td>R16-5</td>
                </tr>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">Sweden<img src="/img/RoundFlags/Sweden.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("aff382c7", "2019-06-24 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Canada.png" width="50" hspace="20"/>Canada</td>
                    <td>R16-6</td>
                </tr>
                <tr>
                    <td>Stade de la Mosson<br/>Montpellier</td>
                    <td align="right">Italy <img src="/img/RoundFlags/Italy.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("4588266f", "2019-06-25 18:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/China.png" width="50" hspace="20"/>China</td>
                    <td>R16-7</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Netherlands<img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("fc025317", "2019-06-25 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Japan.png" width="50" hspace="20"/>Japan</td>
                    <td>R16-8</td>
                </tr>
            </tbody>
        </table>
        <p>All times in your browser's local time (GMT {moment().format("Z")}).</p>
    </div>		
    <div className="card text-white bg-secondary">
        <div className="container">
            <p align="center"><br/> <b> 
            Transfer Window 4</b><br/> </p>
        </div>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Round 5</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Stade Oceane<br/>Le Havre</td>
                    <td align="right">Norway<img src="/img/RoundFlags/Norway.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("6b4d5c34", "2019-06-27 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/England.png" width="50" hspace="20"/>England</td>
                    <td>Q-1</td>
                </tr>
                <tr>
                    <td>Parc des Princes<br/>Paris</td>
                    <td align="right">France<img src="/img/RoundFlags/France.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("7deafa04", "2019-06-28 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/USA.png" width="50" hspace="20"/>USA</td>
                    <td>Q-2</td>
                </tr>
                <tr>
                    <td>Stade du Hainaut<br/>Valenciennes</td>
                    <td align="right">Italy<img src="/img/RoundFlags/Italy.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("dd39bf02", "2019-06-29 15:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20"/>Netherlands</td>
                    <td>Q-3</td>
                </tr>
                <tr>
                    <td>Roazhon Park<br/>Rennes</td>
                    <td align="right">Germany<img src="/img/RoundFlags/Germany.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("c811a391", "2019-06-29 18:30+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Sweden.png" width="50" hspace="20"/>Sweden</td>
                    <td>Q-4</td>
                </tr>
            </tbody>
        </table>
        <p>All times in your browser's local time (GMT {moment().format("Z")}).</p>
    </div>
    <div className="card text-white bg-secondary">
        <div className="container">
            <p align="center"> <br/><b>
            Transfer Window 5</b><br/> </p>
        </div>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Round 6</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Stade de Lyon<br/>Lyon</td>
                    <td align="right">England<img src="/img/RoundFlags/England.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("4770375d", "2019-07-02 21:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/USA.png" width="50" hspace="20"/>USA</td>
                    <td>SF-1</td>
                </tr>
                <tr>
                    <td>Stade de Lyon<br/>Lyon</td>
                    <td align="right">Netherlands<img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("58cb7f65", "2019-07-03 15:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Sweden.png" width="50" hspace="20"/>Sweden</td>
                    <td>SF-2</td>
                </tr>
                <tr>
                    <td>Stade de Nice<br/>Nice</td>
                    <td align="right">England<img src="/img/RoundFlags/England.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("74aef87d", "2019-07-06 17:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Sweden.png" width="50" hspace="20"/>Sweden</td>
                    <td>3rd Place</td>
                </tr>
                <tr>
                    <td>Stade de Lyon<br/>Lyon</td>
                    <td align="right">USA<img src="/img/RoundFlags/USA.png" width="50" hspace="20" /></td>
                    <td className="align-middle" align="center">{matchLink("c358061f", "2019-07-07 17:00+02")}</td>
                    <td align="left"><img src="/img/RoundFlags/Netherlands.png" width="50" hspace="20"/>Netherlands</td>
                    <td>Final</td>
                </tr>
            </tbody>
        </table>
        <p>All times in your browser's local time (GMT {moment().format("Z")}).</p>
    </div>
    </CompetitionWrapperWWC>

</>)
}

export default Fixtures;
