import React from "react";
import Flag from "react-world-flags";
import _ from 'lodash';

import moment from "moment";
import { CompetitionWrapperWWC } from "../../../components/layout/CompetionWrapperWWC";
import BottomNavBar from "../../../components/layout/MCompetionWrapperWWC.jsx";
import { wwc } from "../../../data/competitions/wwc";
import * as numeral from 'numeral';
import data from "../../../data/wwc/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({wwcteam}) => {
	if (!wwcteam.link1){
		return<span></span>
	}
	return (
	      <div>
	      <img
              className="responsive_2col"
              src={`/img/nwsl/${wwcteam.photo}`}
              width="550"
            />
	       <figcaption align="center">
            Photo by <a href={wwcteam.link1} >{wwcteam.cap1}</a>, <a href={wwcteam.link2} title="Creative Commons">{wwcteam.cap2}</a>
            </figcaption>
		<p> </p>
	    </div>
	    )

};

const WWCTeam = ({ wwcteam }) => ( <>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h2>{wwcteam.team}</h2>
        <h6>Group: {wwcteam.group} <br/>FIFA Ranking: {wwcteam.rank} </h6>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={`/img/RoundFlags/${wwcteam.team}.png`}
        height={60}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
	{Image({wwcteam})}
      <span style={{textAlign:'justify', fontSize:'0.8rem'}}>{wwcteam.bio1}<br/></span>
      <span style={{textAlign:'justify', fontSize:'0.8rem'}}> {wwcteam.bio2} <br/></span>
      <span style={{textAlign:'justify', fontSize:'0.8rem'}}><br/></span>
      <span style={{fontSize:'0.8rem'}}>Team bio written by {wwcteam.auth}. </span>
    </div>



    <div className="container">

    <div className="card text-white bg-secondary">
        <div className="container">
            <strong className="text-uppercase" style={{fontSize:'0.8rem'}}>Players to watch: </strong>
            <span style={{fontSize:'0.8rem'}}>{wwcteam.ptw}</span>
        </div>
    </div>
	<p />

    <div className="container clearfix mt-32" style={{overflowX:'scroll'}}>
      <table className="table table-striped" style={{fontSize:'0.8rem'}}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">
              Club Team
            </th>
            <th scope="col" align="center">Age</th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">Fantasy Points</th>
          </tr>
        </thead>
        <tbody>
          {wwcteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
	      Points20,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
		  <td>{numeral(Price).format()} </td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wwcteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
		    <td>{numeral(Price).format()} </td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wwcteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
		    <td>{numeral(Price).format()} </td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wwcteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td>{Name}</td>
                <td>{Position}</td>
                <td>{Club}</td>
                <td>
		    {AGE(DOB)}
                </td>
		    <td>{numeral(Price).format()} </td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="6">Team's Total Points:</th>
	<th scope="col">{wwcteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>
</div>


    <div className="container">
      <p />
    </div>
	        <BottomNavBar/>
        </>

);

export default WWCTeam;
