import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useAuth } from "../../util/withAuth";

export const CompetitionWrapperWL = ({ competition, children }) => {
  const [oldDropdown, setOldDropdown] = useState(false, []);
  const [teamDropdown, setTeamDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const currentUser = useAuth()

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wl">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
              Rules & Scoring
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
              Fixtures & Results
              </a>
            </li>

            <li className="nav-item">
              <Dropdown
                isOpen={teamDropdown}
                toggle={() => setTeamDropdown(!teamDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Teams
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={`/${competition.shortName}/teams/ade`}>
                    Adelaide United
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/bri`}>
                    Brisbane Roar
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/cbr`}>
                    Canberra United
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/mcy`}>
                    Melbourne City
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/mvc`}>
                    Melbourne Victory
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/new`}>
                    Newcastle Jets
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/per`}>
                    Perth Glory
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/syd`}>
                    Sydney FC
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/teams/wel`}>
                    Wellington Phoenix
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/teams/wsw`}>
                    Western Sydney Wanderers
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>

            <li className="nav-item">
              <Dropdown
                isOpen={playerDropdown}
                toggle={() => setPlayerDropdown(!playerDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Players
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={`/${competition.shortName}/database`}>
                    Player Database
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/stats`}>
                  Stats Center  
	          </DropdownItem>
                </DropdownMenu>
	       </Dropdown>
	      </li>



	    <li className="nav-item">
	    <Dropdown
	      isOpen={oldDropdown}
	      toggle={() => setOldDropdown(!oldDropdown)}
	    >
	    <DropdownToggle className="nav-link" caret>
                 Past Seasons 
                </DropdownToggle>
                <DropdownMenu>
	          <DropdownItem href={`/${competition.shortName}/all-players`}>
                    All-Seasons Player Database
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/17/players`}>
                    Player Database 2017/18
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/18/players`}>
                    Player Database 2018/19
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/19/players`}>
                    Player Database 2019/20
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/20/players`}>
                    Player Database 2020/21
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/19/stats`}>
                    Stats Center 2019/20
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/20/stats`}>
                    Stats Center 2020/21
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/18/leaderboard`}>
                    Leaderboard 2018/19
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/19/leaderboard`}>
                    Leaderboard 2019/20
                  </DropdownItem>
                  <DropdownItem href={`/${competition.shortName}/20/leaderboard`}>
                    Leaderboard 2020/21
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </li>
	    <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/leaderboard`}
              >
              Leaderboard
              </a>
            </li>


            {currentUser &&
            <li className="nav-item">
              <a className="nav-link" href={`/${competition.shortName}/my-team`}>
                My Team
              </a>
            </li>
            }
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
