import React from 'react';

const Footer = () => <footer className="py-5 bg-black">
    <div className="container">
        <p className="m-0 text-center text-white small">Copyright &copy; ShePlays 2019</p>
	<p className="m-0 text-right text-white small"><a href='/privacy'>Privacy Policy</a></p>
	<p className="m-0 text-right text-white small"><a href='/terms-of-service'>Terms of Service</a></p>
    </div>
</footer>

export default Footer
