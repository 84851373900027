import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const defaultCountry = { name: "Unknown", image: "Blank"}

const getTeamCode = (name, teams) => (teams.find(t => t.name === name) || {code: "Unknown"}).code;


const DynamicMatch = ({ route, teams, comp }) => {
    const competition = comp === "wl" ? "wleague" : comp;
    const [data, setData] = useState({
        points: []
    })

    const matchId = route.url.pathname.split('/')[3];
    const idA = getTeamCode(data.teamA, teams);
    const idB = getTeamCode(data.teamB, teams);

    useEffect(() => { (async () => {
        const result = await API.get('matches', `/${competition}/matches/${matchId}`)
        setData(result)
        console.log(result)
    })() }, [])

    return (<>
        <div className="container">
        <div className="text-center">
        {data.teamA ? <>
                    <h1><img src={`/img/${comp}/badges/${idA}.png`} width="50" hspace="20" /> {data.teamA} v {data.teamB} <img src={`/img/${comp}/badges/${idB}.png`} width="50" hspace="20"/></h1>
                    <p>
                        <b>{data.scoreA} - {data.scoreB}</b><br/>
                        {(data.penaltyScoreA || data.penaltyScoreB) ? <i>{data.penaltyScoreA} - {data.penaltyScoreB} Penalties</i> : null}<br/>
                    </p>
        </> : <h1>Loading...</h1>}
        </div>

        <p>Below is a breakdown of the fantasy points earned by each player during the match. <br/> 
           To see a breakdown of how the bonus points are calculated go to the <a href={`/${comp}/how-to-play`}> Rules &amp; Scoring </a> page.<br/>
           </p>
        </div>
        <div className="container">
            <table className="table table-striped">
                <thead className="thread-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Club</th>
                        <th scope="col">Total</th>
                        <th scope="col">MP</th>
                        <th scope="col">G</th>
                        <th scope="col">A</th>
                        <th scope="col">Att.</th>
                        <th scope="col">Def.</th>
                        <th scope="col">GK</th>
                        <th scope="col">Cards</th>
                        <th scope="col">CS</th>
                        <th scope="col">W/D/L</th>
                        <th scope="col">GD</th>
                    </tr>
                </thead>
                <tbody>

                {data.points.map(({assists, attack, cards, cleanSheet, club, defense, defence, goalDifference, goals, keeper, matchPlayed, name, playerId, total, win, penaltiesSaved, penaltiesScored}) => 
                    <tr key={playerId}>
                        <td>{name}</td>
                        <td align='center'><img src={`/img/badges/${club}.png`}
                            height="30"/></td>
                        <td>{total}</td>
                        <td>{matchPlayed || ""}</td>
                        <td>{goals || ""}</td>
                        <td>{assists || ""}</td>
                        <td>{attack || ""}</td>
                        <td>{defense || defence || ""}</td>
                        <td>{keeper || ""}</td>
                        <td>{cards || ""}</td>
                        <td>{cleanSheet || ""}</td>
                        <td>{win || ""}</td>
                        <td>{goalDifference || ""}</td>
                    </tr>
                )}
                </tbody>
        </table>
        </div>	
    </>)
}
export default DynamicMatch;
