import React from 'react';
import BottomNavBar from "../../components/layout/MCompetionWrapperWSL.jsx";

const ourTeam = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>

<div className="container">
	<h3>Join our team!</h3>
	<p style={{fontSize:'0.8rem'}}>
	ShePlays is run by a team of friendly volunteers from all around the 
	world, who are passionate fans of women's sports, and are motivated to help 
	it grow. We're really proud of what we have managed to achieve so far - since 
	our first fantasy W-League competition in 2017 we have now run 9 women's fantasy 
	leagues, across the W-League, FAWSL, AFLW, and the Women's FIFA Worls Cup. 
	However, we know there is a large untapped market for more women's sports, and there 
	is a lot more we can achieve with ShePlays to make it bigger and better! 
	</p>
	<p style={{fontSize:'0.8rem'}}>
	In order to provide more fantasy leagues, give a higher quality user experience 
	and reach even more fans of women's sports we need to grow our team. To take ShePlays 
	to the next level, we will need help from like-minded, motivated fans, who are 
	keen to see women's sport continue to grow! If this sounds like you, check out our 
	team openings below.
	</p>
	<h4>Team Openings</h4>
	<p style={{fontSize:'0.8rem'}}>
	There are a lot of tasks we need help with to keep ShePlays running (and free, for 
	everyone to enjoy!). Click on the links below to find out more about 
	each role, and how to apply. 
	</p>
	<div className="row align-items-center">
        <div className="col-lg-6 order-lg-1">
        <div className="p-5">
	<h5>FAWSL</h5>
	<p style={{fontSize:'0.8rem'}}> 
	Want to join our ShePlays-FAWSL team? Check out the open opportunities below:
	<ul>
	<li><a href="volunteers/WSL-SocialMedia.pdf" download rel="noopener noreferrer" target
        ="_blank">Social Media Officer</a></li>
	<li><a href="volunteers/WSL-TeamExpert.pdf" download rel="noopener noreferrer" target
        ="_blank">Team Expert</a></li>
	</ul>
	</p>
	<h5>W-League</h5>
        <p style={{fontSize:'0.8rem'}}>
        Want to join our ShePlays-WLeague team? Check out the open opportunities below:
        <ul>
	<li><a href="volunteers/WL-Manager.pdf" download rel="noopener noreferrer" target
        ="_blank">League Manager</a></li>
        <li><a href="volunteers/WL-SocialMedia.pdf" download rel="noopener noreferrer" target
        ="_blank">Social Media Officer</a></li>
        <li><a href="volunteers/WL-TeamExpert.pdf" download rel="noopener noreferrer" target
        ="_blank">Team Expert</a></li>
        <li><a href="volunteers/WL-StatsGuru.pdf" download rel="noopener noreferrer" target
        ="_blank">Stats Guru</a></li>
        </ul>
        </p>
	<h5>AFLW</h5>
        <p style={{fontSize:'0.8rem'}}>
        Want to join our ShePlays-AFLW team? Check out the open opportunities below:
        <ul>
        <li><a href="volunteers/AFLW-SocialMedia.pdf" download rel="noopener noreferrer" target
        ="_blank">Social Media Officer</a></li>
        <li><a href="volunteers/AFLW-TeamExpert.pdf" download rel="noopener noreferrer" target
        ="_blank">Team Expert</a></li>
        <li><a href="volunteers/AFLW-StatsGuru.pdf" download rel="noopener noreferrer" target
        ="_blank">Stats Guru</a></li>
        </ul>
        </p>
        <h5>NWSL</h5>
        <p style={{fontSize:'0.8rem'}}>
        Want to be a part of our ShePlays-NWSL team? Check out the open opportunities below:
        <ul>
        <li><a href="volunteers/NWSL-SocialMedia.pdf" download rel="noopener noreferrer" target
        ="_blank">Social Media Officer</a></li>
        <li><a href="volunteers/NWSL-TeamExpert.pdf" download rel="noopener noreferrer" target
        ="_blank">Team Expert</a></li>
        <li><a href="volunteers/NWSL-StatsGuru.pdf" download rel="noopener noreferrer" target
        ="_blank">Stats Guru</a></li>
        </ul>
        </p>
        <h5>UEFA Euro 2022</h5>
        <p style={{fontSize:'0.8rem'}}>
        Want to be a part of our ShePlays-Euro team? Check out the open opportunities below:
        <ul>
	<li><a href="volunteers/Euro-Manager.pdf" download rel="noopener noreferrer" target
        ="_blank">League Manager</a></li>
        <li><a href="volunteers/Euro-SocialMedia.pdf" download rel="noopener noreferrer" target
        ="_blank">Social Media Officer</a></li>
        <li><a href="volunteers/Euro-TeamExpert.pdf" download rel="noopener noreferrer" target
        ="_blank">Team Expert</a></li>
        <li><a href="volunteers/Euro-StatsGuru.pdf" download rel="noopener noreferrer" target
        ="_blank">Stats Guru</a></li>
        </ul>
        </p>
        </div>
        </div>
        <div className="col-lg-6 order-lg-2">
        <div className="p-5">
        </div>
        </div>
        </div>
        <h3>Essentials</h3>
        <p style={{fontSize:'0.8rem'}}>
        There are only a few essential pre-requisites to joining our team:
        <ul>
        <li>You are a fan of women's sport</li>
        <li>You are a good communicator and can be reliably contacted</li>
        <li>You are able to contribute to a positive, supportive environment</li>
        <li>You are over the age of 16</li>
        <li>You have a reliable internet connection and a personal computer</li>
        </ul>
        </p>

</div>
	    <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>
	<BottomNavBar/>

</>)

export default ourTeam;
