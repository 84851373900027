import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const teams = [
                { code: "arg", name: "Argentina"},
                { code: "aus", name: "Australia"},
                { code: "bra", name: "Brazil"},
                { code: "cmr", name: "Cameroon"},
                { code: "can", name: "Canada"},
                { code: "chl", name: "Chile"},
                { code: "chn", name: "China"},
                { code: "eng", name: "England"},
                { code: "fra", name: "France" },
                { code: "deu", name: "Germany"},
                { code: "ita", name: "Italy"},
                { code: "jam", name: "Jamaica"},
                { code: "jpn", name: "Japan"},
                { code: "kor", name: "Korea"},
                { code: "nld", name: "Netherlands"},
                { code: "nzl", name: "New Zealand" },
                { code: "nga", name: "Nigeria"},
                { code: "nor", name: "Norway"},
                { code: "sco", name: "Scotland"},
                { code: "zaf", name: "South Africa"},
                { code: "esp", name: "Spain"},
                { code: "swe", name: "Sweden"},
                { code: "tha", name: "Thailand"},
                { code: "usa", name: "United States"}];

export const CompetitionWrapperWWC = ({ competition, children }) => {
  const [teamDropdown, setTeamDropdown] = useState(false, []);

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wwc flex-column">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
                Rules &amp; Scoring
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
                Fixtures &amp; Results
              </a>
            </li>
            <li className="nav-item">
              <Dropdown
                isOpen={teamDropdown}
                toggle={() => setTeamDropdown(!teamDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Teams
                </DropdownToggle>
                <DropdownMenu>
                  {teams.map(team => (
                    <DropdownItem
                      key={team.code}
                      href={`/${competition.shortName}/teams/${team.code ||
                        team.code}`.toLowerCase()}
                    >
                      {team.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/players`}
              >
                Players
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/leaderboard`}
              >
                Leaderboard
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/feedback`}
              >
                Survey Results
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/name-game`}
              >
                Name Game
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
