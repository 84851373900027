import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const HowToPlay = () => (<>
    <div className="container">
        <h1>How to Play</h1>
	<h3>What is fantasy football</h3>
	<p style={{textAlign:'justify'}}>Fantasy football is a fun way to follow the action in your favourite sports league, and to learn more about the incredible athletes involved.
        At the start of the season you are given a budget to spend on players. The players you choose then earn fantasy
        points based on how well they perform in each game. After each round you can make transfers (swapping players from your team for someone
        new), make changes to your starting side (players on your bench will only earn you points if someone in your starting team doesn't play), or change
        your captain (one player in your team who will earn double points each round). The fantasy points from all of your active players (minus any transfer costs - see below)
        are added together to give you your round score. By predicting which players you think will perform best, you can try to earn more fantasy points than
        your friends and family! </p>
	    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-1">
	<img className="rounded mx-auto d-block mb-3" src="/img/htp1.jpg" width="400"/>
	<figcaption align="center">Photo by <a href="https://commons.wikimedia.org/wiki/File:Adelaide_AFLW.3.jpg" title="User:Flickerd">Flickerd</a>, 
	<a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution 4.0"> CC BY-SA 4.0</a>
            </figcaption>
	</div></div>
	<div className="col-lg-6 order-lg-1">
            <div className="p-1">

	<p style={{textAlign:'justify'}}>Playing fantasy football is a great way to engage with women's sports. You'll quickly find how addictive it can become, and you'll soon find yourself
        watching games that don't involve your favourite team just so you can check on how your players are doing (and scout for anyone you may want to add in
        the next transfer window). It will also help you learn about more of the players in the league, particularly some of the younger players who are not
        as well known as the established national team stars. Once you have selected your team, you will be able to set up your own mini-leagues, where
        you can compete against your friends, family, or work colleagues! Make sure you spread the word and try to get others involved. Help us to lift the visibility of women's sport,
        while also having a lot of fun!</p>
	</div></div></div></div>
        <h3>Register</h3>
        <p>To participate in the ShePlays fantasy competition you will need to register with our site. Registering for ShePlays is free and easy, you can sign up with your email, Facebook or Google account. 
	</p>
	<h3>Selecting your team</h3>
	<p style={{textAlign:'justify'}}>We will be running multiple fantasy competitions over the (Australian) summer. By registering with our site you can play in as many (or few) competitons as 
	you'd like. Once team selection has opened you will be able to start building your dream team for any of the competitions we are running on the 'My Team' page. 
	For more information about the specific rules/dates for each league, go to the corresponding home-page in the 'competitions' drop down menu.</p>
	<h3>Transfer windows and scoring</h3>
	<p style={{textAlign:'justify'}}>Transfer windows occur between each round of matches, and give you the opportunity to make changes to your team. This is an important part of fantasy sports, making sure 
    your team stays competitive and on form. In each fantasy league you will be allowed a set number of transfers per week at no additional 
	cost, as long as your new team still meets the budget and formation constraints. Additional transfers can also be made each week, but this will cost you fantasy 
	points from your total score. The specific rules/costs/allowances for each competition can be found on the relevent 'Rules & Scoring' page.</p>

	<p style={{textAlign:'justify'}}>Points are scored by players based on their performance in a range of statistics. The more positive contributions a player makes to a game, the more fantasy points they are likely to score. See the relevant 'Rules & Scoring' page for the detailed scoring matrix of each league.</p>
        
    <div className=""></div><img className="responsive" src="/img/htp2.JPG" width="200"/>    
	<figcaption align="center">Photo by ShePlays, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a></figcaption>

	<h3>Stay positive!</h3>
	<p style={{textAlign:'justify'}}>Our number one rule is to keep things positive! ShePlays is a place to celebrate women in sport, in a safe, fun environment. Please keep your 
	team/mini-league names tasteful, keeping in mind that a wide variety of users view our site. If you have any questions/feedback feel free to 
	get in touch with us at <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a> (or <a href="mailto:aflw@sheplays.com.au">aflw@sheplays.com.au</a> for AFLW), and don't forget to follow us on <a href="https://www.facebook.com/sheplaysfantasyfootball/">Facebook</a> and <a href="https://twitter.com/sheplays_au"> Twitter</a> to keep up to date with the latest news and announcements. Good luck! </p>
    </div>	
    	
        <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>


</>)

export default HowToPlay;
