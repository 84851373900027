import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import moment from "moment";
import { CompetitionWrapperWWC } from "../../../components/layout/CompetionWrapperWWC23";
import BottomNavBar from "../../../components/layout/MCompetionWrapperWWC.jsx";
import { wwc } from "../../../data/competitions/wwc";
import data from "../../../data/wwc/fixtures/rounds/fixtureList.json";
import currentData from "../../../data/wwc/fixtures/rounds/currentRound.json";
import nextData from "../../../data/wwc/fixtures/rounds/nextRound.json";

const localDate = dateString => moment(dateString).format("Do MMMM, h:mm A");

const WWCFixtures = () => {
  const [matches, setMatches] = useState([]);

  useEffect(
    () =>
      (async () => {
        const matches = await API.get("matches", "/wwc/matches");
        setMatches(matches);
      })(),
    []
  );

  const matchLink = (matchId, placeholder) => {
    const match = matches.find(m => m.matchId === matchId);
    if (match) {
      if (match.penaltyScoreA || match.penaltyScoreB) {
        return (
          <a href={`/wwc/match/${matchId}/${match.teamA}v${match.teamB}/m`}>
            {match.scoreA} - {match.scoreB}{" "}
            <i>
              ({match.penaltyScoreA} - {match.penaltyScoreB})
            </i>
          </a>
        );
      }
      return (
        <a href={`/wwc/match/${matchId}/${match.teamA}v${match.teamB}/m`}>
          {match.scoreA} - {match.scoreB}
        </a>
      );
    } else return localDate(placeholder);
  };

  return (<>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Current Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {currentData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
			  city,
			  group,
			  gshort,
			  stage,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Next Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {nextData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
			  city,
                          group,
			  gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>


      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 1</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round1.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
			  city,
                          group,
			  gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 1</b>{" "}
          </p>
        </div>
      </div>


      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 2</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round2.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          city,
                          group,
                          gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 2</b>{" "}
          </p>
        </div>
      </div>

	        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 3</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round3.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          city,
                          group,
                          gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 3</b>{" "}
          </p>
        </div>
      </div>

	        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 4</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round4.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          city,
                          group,
                          gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 4</b>{" "}
          </p>
        </div>
      </div>

	        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 5</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round5.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          city,
                          group,
                          gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 5</b>{" "}
          </p>
        </div>
      </div>

	        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 6</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round6.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          city,
                          group,
                          gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 6</b>{" "}
          </p>
        </div>
      </div>

	        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 7</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round7.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          city,
                          group,
                          gshort,
                          stage,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{gshort}</td>
                  <td align="right"> <img hspace="10" src={`/img/RoundFlags/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/RoundFlags/${ap}`} width="40"/> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 7</b>{" "}
          </p>
        </div>
      </div>




	<BottomNavBar/>
        </>


  );
};

export default WWCFixtures;

