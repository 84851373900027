import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"; 
import { useAuth } from "../../util/withAuth";

const teams = [
                { code: "aut", name: "Austria"},
                { code: "bel", name: "Belgium"},
                { code: "dnk", name: "Denmark"},
                { code: "eng", name: "England"},
                { code: "fin", name: "Finland"},
                { code: "fra", name: "France"},
                { code: "deu", name: "Germany"},
                { code: "isl", name: "Iceland"},
                { code: "ita", name: "Italy" },
                { code: "nld", name: "Netherlands"},
                { code: "nir", name: "Northern Ireland"},
                { code: "nor", name: "Norway"},
                { code: "prt", name: "Portugal" },
                { code: "esp", name: "Spain"},
                { code: "swe", name: "Sweden"},
                { code: "che", name: "Switzerland"}];

export const CompetitionWrapperEuro = ({ competition, children }) => {
  const [teamDropdown, setTeamDropdown] = useState(false, []); 
  const [newsDropdown, setNewsDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const [oldDropdown, setoldDropdown] = useState(false, []);
  const currentUser = useAuth()

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wsl">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
                Rules &amp; Scoring
              </a>
            </li>
           <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
              Fixtures & Results
              </a>
            </li> 
            <li className="nav-item">
            <Dropdown
              isOpen={teamDropdown}
              toggle={() => setTeamDropdown(!teamDropdown)}
            >
              <DropdownToggle className="nav-link" caret>
                Teams
              </DropdownToggle>
              <DropdownMenu>
                {teams.map(team => (
                  <DropdownItem
                    key={team.code}
                    href={`/${competition.shortName}/teams/${team.code ||
                      team.code}`.toLowerCase()}
                  >
                    {team.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            </li>
	     <li className="nav-item">
             <a
              className="nav-link"
              href="/euro/database"
             >
              Player Database
             </a>
            </li>
	   <li className="nav-item">
             <a
              className="nav-link"
              href="/euro/stats"
             >
              Stats Center
             </a>
            </li>
	    <li className="nav-item">
             <a
              className="nav-link"
              href="/euro/leaderboard"
             >
              Leaderboard
             </a>
            </li>
            {currentUser &&
            <li className="nav-item">
              <a className="nav-link" href={`/${competition.shortName}/my-team`}>
                My Team
              </a>
            </li>
            }
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
