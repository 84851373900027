import React from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import Flag from 'react-world-flags';
import { Input } from "reactstrap";
import "@devexpress/dx-react-grid";
import { CompetitionWrapperNWSL } from '../../../components/layout/CompetionWrapperNWSL';
import { nwsl } from '../../../data/competitions/nwsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../../data/nwsl/2023/teamLists/jsondatabase.json"
import moment from 'moment';


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Position', name: 'Position'},
    { title: 'Player Name', name: 'Name'},
    { title: 'Team', name: 'Club'},
    { title: 'Nationality', name: 'Nationality'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
    { title: 'Price', name: 'Price',
       getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: '2022', name: 'Points22',
      getCellValue: t => t.Points22},
    { title: '2023', name: 'Points',
      getCellValue: t => t.Points},
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return PositionPicker(props);
    }
    return <th />
};

const NameFormatter = ({value, row}) => {
  if(row.Link) {
    return <a href={`/nwsl/players/${_.kebabCase(row.Link)}`}>{value}</a>
  } else {
    return value;
  }
}

const NameTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NameFormatter}
    {...props}
  />
);



const DatabaseNWSL = () => <>
<CompetitionWrapperNWSL competition={nwsl}>
    <div className="container clearfix mt-32">
	<h1>2023 Player Database</h1>
	<p>Want to research players for your fantasy team, or are you interested in learning more about the incredible athletes in the NWSL? Then this is the place to be! 
	   You can search for every player competing in the NWSL 2023 season
           (by name, club, position, or nationality) in our database below.  
	   For a complete breakdown of each players fantasy points, visit the <a href="/nwsl/stats">Stats Center</a>.</p>
	<h3>Player Profiles</h3>
	<p>
          Want to see how many attack points a player has, or are you looking to find someone who is consistently earning points every round?
          Check out our player profiles, by clicking on a players name! Here you will see a total breakdown of the fantasy points that player has earned each round. 
	 Some profiles will also have a bio describing their background, playing history, photos, interesting player-facts, or even information on their pets! We'll continue to add to this collection throughout the season, so remember to come back here often to learn more about your favourite players!
	</p>
    </div>


    <div className="container">
	<h5 align="right" >- - - - Fantasy Points - - - -</h5>
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <NameTypeProvider for={['Name']} />
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperNWSL>
</>
export default DatabaseNWSL
