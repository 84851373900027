import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"; 
import { useAuth } from "../../util/withAuth";

const teams = [
                { code: "lac", name: "Angel City FC"},
	        { code: "bay", name: "Bay FC"},
                { code: "chi", name: "Chicago Red Stars"},
                { code: "hou", name: "Houston Dash"},
                { code: "kcc", name: "Kansas City Current"},
                { code: "njy", name: "NJ/NY Gotham FC"},
                { code: "ncc", name: "North Carolina Courage"},
                { code: "rgn", name: "Seattle Reign FC"},
                { code: "orl", name: "Orlando Pride"},
                { code: "por", name: "Portland Thorns" },
                { code: "lou", name: "Racing Louisville FC"},
                { code: "sdw", name: "San Diego Wave"},
	        { code: "utr", name: "Utah Royals FC"},
                { code: "was", name: "Washington Spirit"}];

export const CompetitionWrapperNWSL = ({ competition, children }) => {
  const [teamDropdown, setTeamDropdown] = useState(false, []); 
  const [newsDropdown, setNewsDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const [oldDropdown, setoldDropdown] = useState(false, []);
  const currentUser = useAuth()

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wsl">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
                Rules &amp; Scoring
              </a>
            </li>
           <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
              Fixtures & Results
              </a>
            </li> 
            <li className="nav-item">
            <Dropdown
              isOpen={teamDropdown}
              toggle={() => setTeamDropdown(!teamDropdown)}
            >
              <DropdownToggle className="nav-link" caret>
                Teams
              </DropdownToggle>
              <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
                {teams.map(team => (
                  <DropdownItem
                    key={team.code}
                    href={`/${competition.shortName}/teams/${team.code ||
                      team.code}`.toLowerCase()}
                  >
                    {team.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown
                isOpen={playerDropdown}
                toggle={() => setPlayerDropdown(!playerDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Players
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href="/nwsl/database">Player Database</DropdownItem>
	          <DropdownItem href="/nwsl/stats">Stats Center</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown
                isOpen={oldDropdown}
                toggle={() => setoldDropdown(!oldDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Past Seasons
                </DropdownToggle>
                <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
	          <DropdownItem header>2023 Season</DropdownItem>
	          <DropdownItem href="/nwsl/23/database">Player Database</DropdownItem>
                  <DropdownItem href="/nwsl/23/stats">Stats Center</DropdownItem>
                  <DropdownItem href="/nwsl/23/leaderboard">Leaderboard</DropdownItem>
	          <DropdownItem header>2022 Season</DropdownItem>
                  <DropdownItem href="/nwsl/22/database">Player Database</DropdownItem>
                  <DropdownItem href="/nwsl/22/stats">Stats Center</DropdownItem>
	          <DropdownItem href="/nwsl/22/leaderboard">Leaderboard</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>

	    <li className="nav-item">
             <a
              className="nav-link"
              href="/nwsl/leaderboard"
             >
              Leaderboard
             </a>
            </li>
	    <li className="nav-item">
	     <a 
	      className="nav-link"
	      href="https://blog.sheplays.com.au/"
	     >
	      Latest News
	     </a>
	    </li>
            {currentUser &&
            <li className="nav-item">
              <a className="nav-link" href={`/${competition.shortName}/my-team`}>
                My Team
              </a>
            </li>
            }
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
