import moment from "moment";
import React, {useState} from "react";
import { useAuth } from "../../util/withAuth";
import numeral from "numeral";
import MyTeamDynamic from "../../components/team/MyTeamDynamic";
import data from "../../data/aflw/fixtures/rounds/fixtureList.json";
import currentData from "../../data/aflw/fixtures/rounds/currentRound";
import nextData from "../../data/aflw/fixtures/rounds/nextRound";
import BottomNavBar from '../../components/layout/MCompetionWrapperAFLW.jsx';
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../data/competitions/aflw-2023";
require("numeral/locales/en-au");
const matchLink = dateString => moment(dateString).format("Do MMMM, h:mm A");

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const MyAFLWTeamD = () => {
  numeral.locale("en-au");
  return (
    <CompetitionWrapperAFLW competition={aflw}>
      <div className="container">
        <h1>My Team</h1>
	  <p>
        Want to select your squad? You've come to the right place! This is the
        page where you can choose and edit your AFLW fantasy team!
        </p>

        <div className="centerBox">
          <h5>Building your AFLW fantasy team</h5>
        <iframe width="560" height="315"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

</div>

        <MyTeamDynamic {...aflw} />
      
	  <div className="container mt-3">
            <h4>Next Round's Fixtures</h4>
      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col" colspan="3">Next Round</th>
            </tr>
          </thead>
          <tbody>
          {nextData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
			  tv,
                          date
                }) => (
                <tr key={home}>
                  <td align="right">{home} <img hspace="10" src={`/img/aflw/logo/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/aflw/logo/${ap}`} width="40"/> {away}</td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
        <p>
          All times in your browser's local time (GMT {moment().format("Z")}).
        </p>
        </div>

               <h3>Selecting your players</h3>
        <p>
        Use the ‘Choose Players’ tab to select the 21 players that will make up
        your squad. You can browse the list, search by the player name, or filter
        the list by position or team. When you see the player you want, tick the
        box to the left of their name. They will then appear under the ‘Manage
        Team’ tab. If you change your mind, simply click on the x next to their
        fantasy price. If your team is over budget, or you do not fulfil the
        position requirements, an error message will appear.
        </p>
        <h3>Manage your team</h3>
        <p>
        Once you have picked your players, you then need to manage your team! This
        includes picking a Captain (who will earn double points) and a Vice
        Captain (who will earn double points if your captain doesn’t play). To
        re-order players, simply click on the drag handle (3 lined next to a
        players name) and pull them higher or lower on your list. If your vice captain also
        doesnt't play then your captain will be the next highest player on your list.
        </p>


        <h4>Rules</h4>
        <p>
          You will select 21 players, which must include 2 Rucks, 6 Defenders, 6
          Midfielders, 6 Forwards and 1 Free Choice player (any position). There
          are a few other rules as well:
        </p>
        <ul>
          <li>
            You have a total budget of{" "}
            {numeral(aflw.totalBudget).format("$0,0")}, individual player prices
            range from {numeral(aflw.minPlayerPrice).format("$0,0")} to{" "}
            {numeral(aflw.maxPlayerPrice).format("$0,0")}
          </li>
          <li>
            You can only have up to {aflw.perClubLimit} players from one club
          </li>
          <li>
            You will select a captain for your team, who will earn double points
          </li>
          <li>
            You will choose your starting 16 (which includes your captain) and
            your bench (the remaining 5 players)
          </li>
          <li>
            Your starting 16 must include one ruck, and 5 players in each
            section of the field
          </li>
        </ul>
      </div>
    </CompetitionWrapperAFLW>
  );
};

const MyAFLWTeamM = () => {
  return (<>
      <div className="container">
        <h3>My Team</h3>
        <p style={{fontSize:'0.8rem'}}>
          Welcome to the team selector! To learn how to get started building your fantasy AFLW team watch the video below, or check the <a href='/aflw/m/how-to-play'> How to Play</a> page.
        </p>

        <MyTeamDynamic {...aflw} />

              <div className="container">
                  <p></p>
              </div>


        <h4>Next rounds fixtures</h4>
        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Next Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {nextData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/aflw/logo/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/aflw/logo/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
        <p style={{fontSize:'0.8rem'}}>
          All times in your browser's local time (GMT {moment().format("Z")}).
        </p>
        </div>
        <p style={{textAlign:'center',color:'#4e579b'}}>Build your fantasy AFLW team!</p>
        <iframe width="100%" height="255" src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>


        </div>

        <BottomNavBar/>
        </>
  );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <MyAFLWTeamM /> : <MyAFLWTeamD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


