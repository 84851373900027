export default [
  require("./playerProfiles/players/katrina-gorry"),
  require("./playerProfiles/players/naomi-thomas-chinnama"),
  require("./playerProfiles/players/elizabeth-eddy"),
  require("./playerProfiles/players/rosie-galea"),
  require("./playerProfiles/players/melina-ayres"),
  require("./playerProfiles/players/ayesha-norrie"),
  require("./playerProfiles/players/taren-king"),
  require("./playerProfiles/players/chelsee-washington"),
  require("./playerProfiles/players/miranda-templeman"),
  require("./playerProfiles/players/larissa-crummer"),
  require("./playerProfiles/players/isabella-whitton"),
  require("./playerProfiles/players/charlotte-lancaster"),
  require("./playerProfiles/players/rie-kitano"),
  require("./playerProfiles/players/ella-abdul-massih"),
  require("./playerProfiles/players/mackenzie-hawkesby"),
  require("./playerProfiles/players/ellie-brush"),
  require("./playerProfiles/players/alexandra-huynh"),
  require("./playerProfiles/players/saskia-vosper"),
  require("./playerProfiles/players/leticia-mckenna"),
  require("./playerProfiles/players/emily-van-egmond"),
  require("./playerProfiles/players/lauren-allan"),
  require("./playerProfiles/players/zoe-mcmeeken"),
  require("./playerProfiles/players/annalee-grove"),
  require("./playerProfiles/players/katie-offer"),
  require("./playerProfiles/players/cortnee-vine"),
  require("./playerProfiles/players/paige-satchell"),
  require("./playerProfiles/players/lia-privitelli"),
  require("./playerProfiles/players/mia-bailey"),
  require("./playerProfiles/players/annabel-martin"),
  require("./playerProfiles/players/rebekah-stott"),
  require("./playerProfiles/players/holly-mcqueen"),
  require("./playerProfiles/players/leah-davidson"),
  require("./playerProfiles/players/melissa-barbieri"),
  require("./playerProfiles/players/elizabeth-anton"),
  require("./playerProfiles/players/ava-pritchard"),
  require("./playerProfiles/players/bethany-mason-jones"),
  require("./playerProfiles/players/holly-mcnamara"),
  require("./playerProfiles/players/emma-ilijoski"),
  require("./playerProfiles/players/sofia-sakalis"),
  require("./playerProfiles/players/claudia-bunge"),
  require("./playerProfiles/players/teigen-allen"),
  require("./playerProfiles/players/lynn-williams"),
  require("./playerProfiles/players/alexia-karrys-stahl"),
  require("./playerProfiles/players/courtney-newbon"),
  require("./playerProfiles/players/amy-jackson"),
  require("./playerProfiles/players/sarah-morgan"),
  require("./playerProfiles/players/isabel-hodgson"),
  require("./playerProfiles/players/aideen-keane"),
  require("./playerProfiles/players/elizabeth-ralston"),
  require("./playerProfiles/players/bethany-gordon"),
  require("./playerProfiles/players/courtney-nevin"),
  require("./playerProfiles/players/angelique-hristodoulou"),
  require("./playerProfiles/players/kate-taylor"),
  require("./playerProfiles/players/claire-coelho"),
  require("./playerProfiles/players/shay-hollman"),
  require("./playerProfiles/players/tori-tumeth"),
  require("./playerProfiles/players/meisha-westland"),
  require("./playerProfiles/players/ella-tonkin"),
  require("./playerProfiles/players/tijan-mckenna"),
  require("./playerProfiles/players/maja-markovski"),
  require("./playerProfiles/players/sarah-cain"),
  require("./playerProfiles/players/kyra-cooney-cross"),
  require("./playerProfiles/players/brianna-edwards"),
  require("./playerProfiles/players/gema-simon"),
  require("./playerProfiles/players/margaux-chauvet"),
  require("./playerProfiles/players/paige-hayward"),
  require("./playerProfiles/players/jemma-house"),
  require("./playerProfiles/players/olivia-price"),
  require("./playerProfiles/players/hannah-brewer"),
  require("./playerProfiles/players/caitlin-karic"),
  require("./playerProfiles/players/melissa-maizels"),
  require("./playerProfiles/players/shadeene-evans"),
  require("./playerProfiles/players/georgia-boric"),
  require("./playerProfiles/players/keeley-richards"),
  require("./playerProfiles/players/te-reremoana-(mona)-walker"),
  require("./playerProfiles/players/georgina-worth"),
  require("./playerProfiles/players/allira-toby"),
  require("./playerProfiles/players/hannah-jones"),
  require("./playerProfiles/players/chloe-middleton"),
  require("./playerProfiles/players/harriet-withers"),
  require("./playerProfiles/players/erica-halloway"),
  require("./playerProfiles/players/bryleeh-henry"),
  require("./playerProfiles/players/talitha-kramer"),
  require("./playerProfiles/players/lisa-de-vanna"),
  require("./playerProfiles/players/rhianna-pollicina"),
  require("./playerProfiles/players/michelle-heyman"),
  require("./playerProfiles/players/kayla-morrison"),
  require("./playerProfiles/players/emily-condon"),
  require("./playerProfiles/players/chloe-knott"),
  require("./playerProfiles/players/ally-haran"),
  require("./playerProfiles/players/clare-hunt"),
  require("./playerProfiles/players/chantelle-symes"),
  require("./playerProfiles/players/marisa-van-der-meer"),
  require("./playerProfiles/players/kahli-johnson"),
  require("./playerProfiles/players/alex-chidiac"),
  require("./playerProfiles/players/tahlia-franco"),
  require("./playerProfiles/players/lauren-keir"),
  require("./playerProfiles/players/emma-checker"),
  require("./playerProfiles/players/kim-carroll"),
  require("./playerProfiles/players/hayley-taylor-young"),
  require("./playerProfiles/players/grace-taranto"),
  require("./playerProfiles/players/emily-hodgson"),
  require("./playerProfiles/players/jessika-nash"),
  require("./playerProfiles/players/deborah-anne-de-la-harpe"),
  require("./playerProfiles/players/nanako-sasaki"),
  require("./playerProfiles/players/cyera-hintzen"),
  require("./playerProfiles/players/charlotte-mclean"),
  require("./playerProfiles/players/margot-robinne"),
  require("./playerProfiles/players/tyla-jay-vlajnic"),
  require("./playerProfiles/players/fiona-worts"),
  require("./playerProfiles/players/sarah-hunter"),
  require("./playerProfiles/players/lucy-johnson"),
  require("./playerProfiles/players/natasha-prior"),
  require("./playerProfiles/players/francesca-iermano"),
  require("./playerProfiles/players/ashleigh-sykes"),
  require("./playerProfiles/players/ashlee-brodigan"),
  require("./playerProfiles/players/teigan-collister"),
  require("./playerProfiles/players/jada-whyman"),
  require("./playerProfiles/players/georgia-beaumont"),
  require("./playerProfiles/players/tara-andrews"),
  require("./playerProfiles/players/nikola-orgill"),
  require("./playerProfiles/players/alexia-apostolakis"),
  require("./playerProfiles/players/lara-gooch"),
  require("./playerProfiles/players/demi-koulizakis"),
  require("./playerProfiles/players/reona-omiya"),
  require("./playerProfiles/players/matilda-mcnamara"),
  require("./playerProfiles/players/aleeah-davern"),
  require("./playerProfiles/players/paige-zois"),
  require("./playerProfiles/players/sarah-carroll"),
  require("./playerProfiles/players/mie-leth-jans"),
  require("./playerProfiles/players/charlize-rule"),
  require("./playerProfiles/players/cassidy-davis"),
  require("./playerProfiles/players/kirsty-fenton"),
  require("./playerProfiles/players/shea-connors"),
  require("./playerProfiles/players/isabella-shuttleworth"),
  require("./playerProfiles/players/bianca-galic"),
  require("./playerProfiles/players/natasha-brough"),
  require("./playerProfiles/players/sunny-franco"),
  require("./playerProfiles/players/grace-wisniewski"),
  require("./playerProfiles/players/rachel-lowe"),
  require("./playerProfiles/players/natasha-rigby"),
  require("./playerProfiles/players/cushla-rue"),
  require("./playerProfiles/players/caitlin-cooper"),
  require("./playerProfiles/players/hannah-wilkinson"),
  require("./playerProfiles/players/claudia-mihocic"),
  require("./playerProfiles/players/heidi-dennis"),
  require("./playerProfiles/players/sally-james"),
  require("./playerProfiles/players/bella-habuda"),
  require("./playerProfiles/players/laura-hughes"),
  require("./playerProfiles/players/chelsie-dawber"),
  require("./playerProfiles/players/emilia-murray"),
  require("./playerProfiles/players/annabel-haffenden"),
  require("./playerProfiles/players/hana-lowry"),
  require("./playerProfiles/players/isabella-wallhead"),
  require("./playerProfiles/players/remy-siemsen"),
  require("./playerProfiles/players/sophie-harding"),
  require("./playerProfiles/players/libby-copus-brown"),
  require("./playerProfiles/players/cristina-esposito"),
  require("./playerProfiles/players/jessie-rasschaert"),
  require("./playerProfiles/players/sham-khamis"),
  require("./playerProfiles/players/kaitlyn-torpey"),
  require("./playerProfiles/players/kayla-sharples"),
  require("./playerProfiles/players/morgan-aquino"),
  require("./playerProfiles/players/sheridan-gallagher"),
  require("./playerProfiles/players/sophie-magus"),
  require("./playerProfiles/players/grace-jale"),
  require("./playerProfiles/players/natalie-tobin"),
  require("./playerProfiles/players/emma-stanbury"),
  require("./playerProfiles/players/nia-stamatopoulos"),
  require("./playerProfiles/players/mikayla-vidmar"),
  require("./playerProfiles/players/ally-green"),
  require("./playerProfiles/players/catherine-zimmerman"),
  require("./playerProfiles/players/gemma-craine"),
  require("./playerProfiles/players/jordan-jasnos"),
  require("./playerProfiles/players/chloe-lincoln"),
  require("./playerProfiles/players/chelsea-blissett"),
  require("./playerProfiles/players/alana-jancevski"),
  require("./playerProfiles/players/sarah-willacy"),
  require("./playerProfiles/players/mary-stanic-floody"),
  require("./playerProfiles/players/abbey-green"),
  require("./playerProfiles/players/alana-murphy"),
  require("./playerProfiles/players/lily-alfeld"),
  require("./playerProfiles/players/alyssa-whinham"),
  require("./playerProfiles/players/casey-dumont"),
  require("./playerProfiles/players/tiana-jaber"),
  require("./playerProfiles/players/polly-doran"),
  require("./playerProfiles/players/grace-wilson"),
  require("./playerProfiles/players/sophia-varley"),
  require("./playerProfiles/players/mackenzie-barry"),
  require("./playerProfiles/players/josie-morley"),
  require("./playerProfiles/players/jamilla-rankin"),
  require("./playerProfiles/players/cannon-clough"),
  require("./playerProfiles/players/holly-caspers"),
  require("./playerProfiles/players/marie-markussen"),
  require("./playerProfiles/players/taylor-ray"),
  require("./playerProfiles/players/darcey-malone"),
  require("./playerProfiles/players/emma-robers"),
  require("./playerProfiles/players/lanni-mcdougall"),
  require("./playerProfiles/players/maria-jose-rojas"),
  require("./playerProfiles/players/ellen-gett"),
  require("./playerProfiles/players/grace-maher"),
  require("./playerProfiles/players/brooke-hendrix"),
  require("./playerProfiles/players/mariel-hecher"),
  require("./playerProfiles/players/sadie-lawrence"),
  require("./playerProfiles/players/tiffany-eliadis"),
  require("./playerProfiles/players/melindaj-barbieri"),
  require("./playerProfiles/players/natalie-tathem"),
  require("./playerProfiles/players/coco-majstorovic"),
  require("./playerProfiles/players/meaghan-mcelligott"),
  require("./playerProfiles/players/karly-roestbakken"),
  require("./playerProfiles/players/dylan-holmes"),
  require("./playerProfiles/players/susan-phonsongkham"),
  require("./playerProfiles/players/isabella-accardo"),
  require("./playerProfiles/players/hollie-palmer"),
  require("./playerProfiles/players/winonah-heatley"),
  require("./playerProfiles/players/isabella-foletta"),
  require("./playerProfiles/players/danika-matos"),
  require("./playerProfiles/players/meleri-mullan"),
  require("./playerProfiles/players/kelli-brown"),
  require("./playerProfiles/players/anna-margraf"),
  require("./playerProfiles/players/ashlie-crofts"),
  require("./playerProfiles/players/sasha-grove"),
  require("./playerProfiles/players/poppie-hooks"),
  require("./playerProfiles/players/isabel-gomez"),
  require("./playerProfiles/players/princess-ibini"),
  require("./playerProfiles/players/leia-varley"),
  require("./playerProfiles/players/leena-khamis"),
  require("./playerProfiles/players/malia-steinmetz"),
]