import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Auth, API } from "aws-amplify";
import { useNavigation } from "react-navi";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button
} from "reactstrap";
import { NotificationBox } from "../../components/NotificationBox";

const MyLeagues = ({ route, competition }) => {
  const compShort = competition === "wleague" ? "wl" : competition;
  const [leagues, setLeagues] = useState(undefined);
  const [leagueCode, setLeagueCode] = useState(undefined);
  const [leagueName, setLeagueName] = useState(undefined);
  const [leagueDescription, setLeagueDescription] = useState(undefined);
	const [creatingLeague, setCreatingLeague] = useState(false);
  const [notification, setNotification] = useState(null);
	
	let navigation = useNavigation();

  const joinLeague = async code => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      Auth.federatedSignIn();
    }

    const session = user.signInUserSession;

    const token = session.idToken && session.idToken.jwtToken;
    const headers = {
      "x-auth-token": token
    };
    if (code) {
      try {
        await API.put("team", `/${competition}/team/join-league/${code}`, { headers });
        window.history.replaceState({}, document.title, `/${compShort}/my-leagues`);
      } catch (e) {
        if (e.response.status === 404) {
          setNotification({
            message: "Invalid league code.",
            severity: "danger"
          });
        } else {
          setNotification({
            message:
              "Couldn't join league. Make sure you have a team selected and saved.",
            severity: "danger"
          });
        }
        console.log(JSON.parse(JSON.stringify(e)));
      }
    }
    const result = await API.get("team", `/${competition}/team`, { headers });
    setLeagues(result.leagues || []);
  };
  
  const createLeague = async (name, description) => {
		if (creatingLeague) {
			console.log("Already creating league; bypassing request...");
			return;
		}
		
		setCreatingLeague(true);
		let user;
		try {
			user = await Auth.currentAuthenticatedUser();
		} catch (e) {
			Auth.federatedSignIn();
		}
		
		const session = user.signInUserSession;

    const token = session.idToken && session.idToken.jwtToken;
    const headers = {
      "x-auth-token": token
    };
		
		if (name && description)	{
			const body = { name, description };
			
			try {
				let code = "";
				await API.put("leagues", `/${competition}/leagues/create`, { headers, body })
					.then(response => { code = response.code; });
				
				console.log(`${code}`);
				
				// Auto-join the league that was created.
				await API.put("team", `/${competition}/team/join-league/${code}`, { headers });
				
				window.history.replaceState({}, document.title, "/my-leagues");
				
        navigation.navigate(`/${compShort}/league/${code}`);
			} catch (e) {
				setCreatingLeague(false);
				setNotification({
					message: "Couldn't create league. Make sure you have a team selected and saved.",
					severity: "danger"
				});
				
				console.log(JSON.parse(JSON.stringify(e)));
			}
		}
	}

  useEffect(() => {
    joinLeague(route.url.query.join);
  }, []);
  if (!leagues) {
    return (
      <div className="container">
        {" "}
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div className="container my-3">
      <h3>My Leagues</h3>
      <NotificationBox notification={notification} />
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {leagues.map(({ code, name, description }) => (
            <tr key={name}>
              <td>
                <a href={`/${compShort}/league/${code}`}>{name}</a>
              </td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </table>
	  
	  <h3>Create a new League</h3>
	  <p>
		Create you own league and play with your friends! Please note that you need
		to select your team{" "} <b>before</b> you can create a league. Once you've
		created a league, you can send the league code to your friends so they can join! 
                Simply enter a league name and description below, then hit Create!
	  </p>
      <InputGroup>
        <Input
          id="leagueName"
          name="leagueName"
          placeholder="League Name (required)"
          onChange={e => {
            setLeagueName(e.target.value);
          }}
        />
        <Input
          id="leagueDescription"
          name="leagueDescription"
          placeholder="League Description (required)"
          onChange={e => {
            setLeagueDescription(e.target.value);
          }}
        />
        <InputGroupAddon addonType="append">
          <Button
            color="secondary"
            onClick={() => createLeague(leagueName, leagueDescription, setLeagues)}
          >
            Create
          </Button>
        </InputGroupAddon>
      </InputGroup>
			<p/>
      <h3>Join a league</h3>
      <p>
        If you have been sent a league code for a private league you can enter
        it here to join! Please note that you need to select your team{" "}
        <b>before</b> you can join a league. Once you have joined a league it will appear in the table above. 
        Click on the league name to view the mini-league!
      </p>
      <InputGroup>
        <Input
          id="leagueCode"
          name="leagueCode"
          placeholder="League Code"
          onChange={e => {
            setLeagueCode(e.target.value);
          }}
        />
        <InputGroupAddon addonType="append">
          <Button
            color="secondary"
            onClick={() => joinLeague(leagueCode, setLeagues)}
          >
            Join
          </Button>
        </InputGroupAddon>
      </InputGroup>
	  <p>
	  </p>
      <h3>Mini leagues for organisations and groups</h3>
      <p>
          If you would like a specific-personalized league code for your organisation or group, send us an
                email at <a href="mailto:marran@sheplays.com.au"> marran@sheplays.com.au </a> with your 
                league name and desired link code, and we will be able to set that up for you!
      </p>
    </div>
  );
};

export default MyLeagues;
