import React from "react";
import Flag from "react-world-flags";
import _ from 'lodash';

import moment from "moment";
import { CompetitionWrapperUWCL } from "../../components/layout/CompetionWrapperUWCL";
import BottomNavBar from "../../components/layout/MCompetionWrapperUWCL.jsx";
import { uwcl } from "../../data/competitions/uwcl";
import * as numeral from 'numeral';
import data from "../../data/uwcl/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({uwclteam}) => {
	if (!uwclteam.link1){
		return<span></span>
	}
	return (
	      <div>
	      <img
              className="responsive_2col"
              src={`/img/uwcl/${uwclteam.photo}`}
              width="550"
            />
	       <figcaption align="center">
            Photo by <a href={uwclteam.link1} >{uwclteam.cap1}</a>, <a href={uwclteam.link2} title="Creative Commons">{uwclteam.cap2}</a>
            </figcaption>
		<p> </p>
	    </div>
	    )

};

const UWCLTeam = ({ uwclteam }) => ( <>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h2>{uwclteam.club}</h2>
	<h5>Group {uwclteam.group}</h5>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={`/img/uwcl/badges/${uwclteam.code}.png`}
        height={100}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
	{Image({uwclteam})}
      <p style={{textAlign:'justify', fontSize:'0.8rem'}}>{uwclteam.bio1}</p>
	<p style={{textAlign:'justify', fontSize:'0.8rem'}}>{uwclteam.bio2}</p>
	<p style={{textAlign:'justify', fontSize:'0.8rem'}}>Players to watch: {uwclteam.ptw}<br/>
			Bio written by: {uwclteam.author}</p>
    </div>

    <div className="container clearfix mt-32" style={{overflowX:'scroll'}}>
      <table className="table table-striped" style={{fontSize:'0.8rem'}}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">
              Nationality
            </th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">Points</th>
          </tr>
        </thead>
        <tbody>
          {uwclteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/uwcl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {uwclteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/uwcl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {uwclteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/uwcl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {uwclteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/uwcl/m/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="4">Team's Total Points:</th>
	<th scope="col">{uwclteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
	<BottomNavBar/>
	</>
);

export default UWCLTeam;
