import React from 'react';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../components/layout/CompetionWrapperWWC";
import BottomNavBar from '../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from "../data/competitions/wwc";

import moment from 'moment';
import numeral from 'numeral';

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const DTeam = ({ team }) => (<>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h2>{team.country}</h2>
            <h3>{team.teamName}</h3>
        </div>
	<div className="container">
	<p></p>
	</div>
        <Flag className="float-right country-flag" code={team.countryCode} height={50} />
    </div>
    <div className="container">
        <p style={{fontSize:'0.8rem'}}>{team.bio}</p>
        <table className="table table-striped" style={{fontSize:'0.7rem'}}>
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Position</th>
                    <th scope="col">Age</th>
                    <th scope="col">Fantasy Price</th>
                </tr>
            </thead>
            <tbody>
                {team.players.map(({name, position, dob, league, club, fantasyPrice}) => 
                <tr key={name}>
                    <td>{name}</td>
                    <td>{position}</td>
                    <td>{moment().diff(moment(dob), 'years')}</td>
                    <td>{numeral(fantasyPrice).format('$0,0')}</td>
                </tr>
                )}
            </tbody>
        </table>
    </div>
    <div className="card text-white bg-secondary">
        <div className="container">
            <strong className="text-uppercase">Player to watch: </strong>
            <span>{team.keyPlayer}</span>
        </div>
    </div>
    <div className="container">
	<p></p>
    </div>	
	<BottomNavBar/>
</>)

const MTeam = ({ team }) => (<>
<CompetitionWrapperWWC competition={wwc}>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h1>{team.country}</h1>
            <h2>{team.teamName}</h2>
        </div>
        <div className="container">
        <p></p>
        </div>
        <Flag className="float-right country-flag" code={team.countryCode} height={100} />
        <div className="float-right mr-2">
            <h3>Group {team.group}</h3>
            <h3>FIFA ranking: {team.rank}</h3>
        </div>
    </div>
    <div className="container">
        <p>{team.bio}</p>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Position</th>
                    <th scope="col">Age</th>
                    <th scope="col">Club</th>
                    <th scope="col">League</th>
                    <th scope="col">Fantasy Price</th>
                </tr>
            </thead>
            <tbody>
                {team.players.map(({name, position, dob, league, club, fantasyPrice}) =>
                <tr key={name}>
                    <td>{name}</td>
                    <td>{position}</td>
                    <td>{moment().diff(moment(dob), 'years')}</td>
                    <td>{club}</td>
                    <td>{league}</td>
                    <td>{numeral(fantasyPrice).format('$0,0')}</td>
                </tr>
                )}
            </tbody>
        </table>
    </div>
    <div className="card text-white bg-secondary">
        <div className="container" style={{fontSize:'0.8rem'}}>
            <strong className="text-uppercase">Player to watch: </strong>
            <span>{team.keyPlayer}</span>
        </div>
    </div>
    <div className="container">
        <p></p>
    </div>
    </CompetitionWrapperWWC>
</>)


const MyComponent = ({team}) => {
  const { width } = useViewport();
  const breakpoint = 620;

  return width < breakpoint ? <MTeam /> : <DTeam />;
};

export default DTeam

