import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { Alert, Collapse, Card, CardBody } from 'reactstrap';
import _ from "lodash"
import { soccer } from '../../data/sports';

const PlayerRow = ({ name, points, isCaptain, played }) => (<tr className={isCaptain ? "table-success" : (played ? "table-primary" : "")}>
    <td>{name}</td>
    <td>{points} {isCaptain ? " × 2" : ""}</td>
</tr>)

export const TeamScores = ({ playerList, total, transferPenalty, sport}) => {   
    sport = sport || soccer;
    playerList = playerList || [];
    
    return <table className="table w-100">
        <thead className="thead-dark">
            <th>Name</th>
            <th>Score</th>
        </thead>

        <thead>
            <tr>
                <th scope="col" colSpan={2}>Captain</th>
            </tr>
        </thead>
        <tbody>
            {playerList.slice(0, 1).map(PlayerRow)}
        </tbody>
        <thead>
            <tr>
                <th scope="col" colSpan={2}>Starting Lineup</th>
            </tr>
        </thead>
        <tbody>
            {playerList.slice(1, sport.teamSize).map(PlayerRow)}
        </tbody>
        <thead>
            <tr>
                <th scope="col" colSpan={2}>Bench</th>
            </tr>
        </thead>
        <tbody>
            {playerList.slice(sport.teamSize, sport.squadSize).map(PlayerRow)}
            {transferPenalty !== undefined ? <tr>
                <th>Transfers</th>
                <th>{transferPenalty}</th>
            </tr> : null}
            { total !== undefined ? <tr>
                <th>Total</th>
                <th>{(total || 0)}</th>
            </tr> : null }
        </tbody>
    </table>
}
export default TeamScores;