import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const AusvIta = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/Australia.png" width="50" hspace="20" />Australia v Italy<img src="img/RoundFlags/Italy.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group C</center></h5>
	<p><center><b>1 - 2</b><br/>Stade du Hainaut<br/>
	Valenciennes<br/>
	{localDate("2019-06-09 13:00+02")}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	            <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>
	<tr>
<td>Barbara Bonansea</td>
<td>ITA</td>
<td>16</td>
<td>2</td>
<td>10</td>
<td></td>
<td>3</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Sam Kerr</td>
<td>AUS</td>
<td>13</td>
<td>2</td>
<td>5</td>
<td></td>
<td>6</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Ellie Carpenter</td>
<td>AUS</td>
<td>8</td>
<td>2</td>
<td></td>
<td></td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Valentina Cernoia</td>
<td>ITA</td>
<td>7</td>
<td>2</td>
<td></td>
<td>3</td>
<td>2</td>
<td>1</td>
<td></td>
<td>-2</td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Alia Guagni</td>
<td>ITA</td>
<td>7</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>3</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Caitlin Foord</td>
<td>AUS</td>
<td>6</td>
<td>2</td>
<td></td>
<td></td>
<td>4</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Hayley Raso</td>
<td>AUS</td>
<td>6</td>
<td>2</td>
<td></td>
<td></td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Laura Giuliani</td>
<td>ITA</td>
<td>5</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Valentina Bergamaschi</td>
<td>ITA</td>
<td>5</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Steph Catley</td>
<td>AUS</td>
<td>5</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>2</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Manuela Giugliano</td>
<td>ITA</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Elena Linari</td>
<td>ITA</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Chloe Logarzo</td>
<td>AUS</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Emily van Egmond</td>
<td>AUS</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Cristiana Girelli</td>
<td>ITA</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td>-2</td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Ilaria Mauro</td>
<td>ITA</td>
<td>3</td>
<td>1</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Alanna Kennedy</td>
<td>AUS</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Elise Kellond-Knight</td>
<td>AUS</td>
<td>3</td>
<td>1</td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Aurora Galli</td>
<td>ITA</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Daniela Sabatino</td>
<td>ITA</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Sara Gama</td>
<td>ITA</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td>-2</td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Elisa Bartoli</td>
<td>ITA</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Valentina Giacinti</td>
<td>ITA</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>1</td>
<td> </td>
</tr>
<tr>
<td>Lydia Williams</td>
<td>AUS</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Clare Polkinghorne</td>
<td>AUS</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Tameka Butt</td>
<td>AUS</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Katrina Gorry</td>
<td>AUS</td>
<td>2</td>
<td>1</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td> </td>
<td> </td>
</tr>
<tr>
<td>Lisa De Vanna</td>
<td>AUS</td>
<td>-1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>-2</td>
<td> </td>
<td> </td>
<td> </td>
</tr>
            </tbody>
	</table>
    </div>	

</>)

export default AusvIta;
