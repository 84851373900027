import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../../../components/layout/CompetionWrapperWWC23";
import BottomNavBar from '../../../components/layout/MCompetionWrapperWWC23.jsx';
import { wwc } from "../../../data/competitions/euro";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: 'arg', name: 'Argentina'},
                { code: 'aus', name: 'Australia'},
                { code: 'bra', name: 'Brazil'},
                { code: 'can', name: 'Canada'},
                { code: 'chn', name: 'China'},
                { code: 'col', name: 'Colombia'},
                { code: 'cri', name: 'Costa Rica'},
                { code: 'dnk', name: 'Denmark'},
                { code: 'eng', name: 'England'},
                { code: 'fra', name: 'France'},
                { code: 'deu', name: 'Germany'},
                { code: 'hti', name: 'Haiti'},
                { code: 'irl', name: 'Ireland'},
                { code: 'ita', name: 'Italy'},
                { code: 'jam', name: 'Jamaica'},
                { code: 'jpn', name: 'Japan'},
                { code: 'mar', name: 'Morocco'},
                { code: 'nld', name: 'Netherlands'},
                { code: 'nzl', name: 'New Zealand'},
                { code: 'nga', name: 'Nigeria'},
                { code: 'nor', name: 'Norway'},
                { code: 'pan', name: 'Panama'},
                { code: 'phl', name: 'Philippines'},
                { code: 'prt', name: 'Portugal'},
                { code: 'zaf', name: 'South Africa'},
                { code: 'kor', name: 'South Korea'},
                { code: 'esp', name: 'Spain'},
                { code: 'swe', name: 'Sweden'},
                { code: 'che', name: 'Switzerland'},
                { code: 'usa', name: 'USA'},
                { code: 'vnm', name: 'Vietnam'},
                { code: 'zmb', name: 'Zambia'}];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/wwc/m/teams/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/RoundFlags/${team.name}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
