import React from 'react';
import Flag from 'react-world-flags';
import { CompetitionWrapperWL } from '../../../components/layout/CompetionWrapperWL';
import { wl } from '../../../data/competitions/wl';

import moment from 'moment';
import numeral from 'numeral';

const WLTeam = ({ wlteam }) => (<>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h1>{wlteam.country}</h1>
            <h3>League Position: {wlteam.teamName}</h3>
        </div>
	<div className="container">
	<p></p>
	</div>
    <img className="rounded mx-auto d-block mb-3" align="right" src={wlteam.countryCode}  height={150} />
        <div className="float-right mr-2">
            <h3></h3>
            <h3></h3>
        </div>
    </div>
        <div className="container">
	<p>{wlteam.bio}</p>
	<p>Champions: {wlteam.champ}<br/>
	Premiers: {wlteam.prem}</p>
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <img className="rounded mx-auto d-block mb-3" src={wlteam.img1} width="450"/>
            <figcaption align="center">
            Photo by <a href={wlteam.link1} >{wlteam.cap1}</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
	    </figcaption>
	  </div>
	  </div>
	 <div className="col-sm-6 order-sm-1">
	  <div className="p-0">
	    <img className="rounded mx-auto d-block mb-3" src={wlteam.img2} width="450"/>
        <figcaption align="center">
            Photo by <a href={wlteam.link2} >{wlteam.cap2}</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
	    </figcaption>
	  </div>
	 </div>
       </div>
      </div>

    <div className="container">
        <p></p>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Position</th>
                    <th scope="col">Nationality</th>
                    <th scope="col">Club</th>
                    <th scope="col">Fantasy Points (18/19)</th>
                    <th scope="col">Fantasy Price (18/19)</th>
                </tr>
            </thead>
            <tbody>
                {wlteam.players.map(({Name, Position, Nationality, Club, Price, Points}) => 
                <tr key={Name}>
                    <td>{Name}</td>
                    <td>{Position}</td>
                    <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>             
                    <td>{Club}</td>
		    <td align="center">{Points}</td>
                    <td>{numeral(Price).format('$0,0')}</td>
                </tr>
                )}
            </tbody>
        </table>
    </div>
    <div className="container">
	<p></p>
    </div>	
    </CompetitionWrapperWL>
</>)

export default WLTeam
