import React from 'react';
import moment from'moment';
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const NameGame = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
<CompetitionWrapperWWC competition={wwc}>
    <div className="container">
        <h1>ShePlays Name Game</h1>
        <p>Thank you to everyone who submitted their fantasy team in to our Name Game Competition! We received a lot of excellent entries,
        making it very difficult to come up with a shortlist. Eventually we were able to agree on our four favourite names, and we then put it to a vote, 
        with our followers on twitter deciding the overall winner... </p>
	<img className="rounded mx-auto d-block mb-3" src="/img/namegame2.png" width="500"/>
        <p>Congratulations to our Name Game Champion, Morgan a Feeling! As a prize for coming up with the best team name, we've sent out a custom lego minfig of their favourite 
	player - Jenni Hermoso!</p><img className="rounded mx-auto d-block mb-3" src="/img/wwc-name-lego.jpg" width="400"/>
	<p>A honourable mention must be made to Rapinoe Noir, which was also an EXCELLENT
         name. We can't wait to see what names everyone comes up with next time!</p>
</div>	
	<div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>


</CompetitionWrapperWWC>
	
</>)

export default NameGame;
