import React, { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useNavigation } from "react-navi";
import { useAuth } from "../../util/withAuth";

const competitions = {
  uwcl: "UWCL",
  wsl: "WSL",
  wwc: "World Cup",
  wl: "A-League",
  arc: "Archive",
  aflw: "AFLW"
};

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};



const DesktopNavbar = () => {
  const currentUser = useAuth();
  const [userDropdown, setUserDropdown] = useState(false, []);
  const [competitionDropdown, setCompetitionDropdown] = useState(false, []);
  const navigation = useNavigation();

  const toggleUserDropdown = () => {
    setUserDropdown(!userDropdown);
  };
  const toggleCompetitionDropdown = () => {
    setCompetitionDropdown(!competitionDropdown);
  };

  const competitionCode = navigation
    .getCurrentValue()
    .url.pathname.split("/")[1];
  const competition = competitions[competitionCode];

  return (
    <nav className="navbar navbar-expand-lg navbar-dark navbar-custom">
      <div className="container">
        <ul className="navbar-nav mr-5">
          <li className="nav-item">
            <a className="nav-link-white" href="/">
              <FontAwesomeIcon icon={faHome} />
            </a>
          </li>
          <li className="nav-item">
            <Dropdown
              isOpen={competitionDropdown}
              toggle={toggleCompetitionDropdown}
            >
              {competition ? (
                <DropdownToggle className="nav-link competition-dropdown" caret>
                  {competition}
                </DropdownToggle>
              ) : (
                <DropdownToggle className="nav-link-white" caret>
                  Competitions
                </DropdownToggle>
              )}
              <DropdownMenu>
	        <DropdownItem href="/uwcl">UWCL</DropdownItem>
	        <DropdownItem href="/wsl">WSL</DropdownItem>
	        <DropdownItem href="/nwsl">NWSL</DropdownItem>
                <DropdownItem href="/wwc">World Cup</DropdownItem>
	        <DropdownItem href="/arc">Archive</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="nav-item">
            <a className="nav-link-white" href="/how-to-play">
              How to Play
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link-white" href="/about">
              About
            </a>
          </li>
	  <li className="nav-item">
            <a className="nav-link-white" href="/join-us">
              Our Team
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link-white" href="https://blog.sheplays.com.au/">
              Latest News
            </a>
          </li>
        </ul>
        <hr className="d-xs" />
        <ul className="navbar-nav ml-auto">
          {currentUser ? (
            <Dropdown isOpen={userDropdown} toggle={() => toggleUserDropdown()}>
              <DropdownToggle caret>
                {(currentUser.attributes || (currentUser.signInUserSession && currentUser.signInUserSession.idToken.payload) || {}).name}
              </DropdownToggle>
              <DropdownMenu>
		<DropdownItem header>UWCL</DropdownItem>
                <DropdownItem href="/uwcl/my-team">My UWCL Team</DropdownItem>
                <DropdownItem href="/uwcl/my-leagues">My UWCL Leagues</DropdownItem>
                <DropdownItem divider />
		<DropdownItem header>WSL</DropdownItem>
                <DropdownItem href="/wsl/my-team">My WSL Team</DropdownItem>
                <DropdownItem href="/my-leagues">My WSL Leagues</DropdownItem>
                <DropdownItem divider />
		<DropdownItem header>NWSL</DropdownItem>
                <DropdownItem href="/nwsl/my-team">My NWSL Team</DropdownItem>
                <DropdownItem href="/nwsl/my-leagues">My NWSL Leagues</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>World Cup</DropdownItem>
                <DropdownItem href="/wwc/my-team">My World Cup Team</DropdownItem>
                <DropdownItem href="/wwc/my-leagues">My World Cup Leagues</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => Auth.signOut()}>
                  Sign Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <>
              <li className="nav-item">
                <a
                  onClick={() => Auth.federatedSignIn()}
                  className="nav-link-white"
                  href="#"
                >
                  Log In/Register
                </a>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

const MobileNavbar = () => {
  const currentUser = useAuth();
  const [userDropdown, setUserDropdown] = useState(false, []);
  const [competitionDropdown, setCompetitionDropdown] = useState(false, []);
  const navigation = useNavigation();

  const toggleUserDropdown = () => {
    setUserDropdown(!userDropdown);
  };
  const toggleCompetitionDropdown = () => {
    setCompetitionDropdown(!competitionDropdown);
  };

  const competitionCode = navigation
    .getCurrentValue()
    .url.pathname.split("/")[1];
  const competition = competitions[competitionCode];


  return (
    <nav className="navbar navbar-expand-lg navbar-dark navbar-custom">
      <div className="container">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Dropdown
              isOpen={competitionDropdown}
              toggle={toggleCompetitionDropdown}
            >
              {competition ? (
                <DropdownToggle className="nav-link competition-dropdown-mobile" caret>
                  {competition}
                </DropdownToggle>
              ) : (
                <DropdownToggle className="nav-link-white" caret>
                  Competitions
                </DropdownToggle>
              )}
              <DropdownMenu>
	        <DropdownItem href="/uwcl">UWCL</DropdownItem>
	        <DropdownItem href="/wsl">WSL</DropdownItem>
	        <DropdownItem href="/nwsl">NWSL</DropdownItem>
                <DropdownItem href="/wwc">World Cup</DropdownItem>
	        <DropdownItem href="/arc">Archive</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
	  </ul>
	  <li className="nav-item" style={{margin: "auto"}}>
          <a href='/' style={{color:'white', fontFamily: "Lobster", fontSize:'1.4rem' }}>ShePlays</a>
          </li>
        <ul className="navbar-nav ml-auto">
          {currentUser ? (
            <Dropdown isOpen={userDropdown} toggle={() => toggleUserDropdown()}>
              <DropdownToggle caret>
                {(currentUser.attributes || (currentUser.signInUserSession && currentUser.signInUserSession.idToken.payload) || {}).name}
              </DropdownToggle>
              <DropdownMenu>
		<DropdownItem header>UWCL</DropdownItem>
                <DropdownItem href="/uwcl/my-team">My UWCL Team</DropdownItem>
                <DropdownItem href="/uwcl/my-leagues">My UWCL Leagues</DropdownItem>
                <DropdownItem divider />
		<DropdownItem header>WSL</DropdownItem>
                <DropdownItem href="/wsl/my-team">My WSL Team</DropdownItem>
                <DropdownItem href="/my-leagues">My WSL Leagues</DropdownItem>
                <DropdownItem divider />
		<DropdownItem header>NWSL</DropdownItem>
                <DropdownItem href="/nwsl/my-team">My NWSL Team</DropdownItem>
                <DropdownItem href="/nwsl/my-leagues">My NWSL Leagues</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>World Cup</DropdownItem>
                <DropdownItem href="/wwc/my-team">My World Cup Team</DropdownItem>
                <DropdownItem href="/wwc/my-leagues">My World Cup Leagues</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => Auth.signOut()}>
                  Sign Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <>
              <li className="nav-item" >
                <a
                  onClick={() => Auth.federatedSignIn()}
                  href="#"
		  style={{color:'white'}}
                >
                  Log In
                </a>
              </li>
            </>
          )}
        </ul>


</div>
</nav>
  );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <MobileNavbar /> : <DesktopNavbar />;
};


export default function App() {
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


