import React from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { Input } from "reactstrap";
import { CompetitionWrapperWL } from '../../components/layout/CompetionWrapperWL';
import { wl } from '../../data/competitions/wl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wl/all-players"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Player Name', name: 'Name' },
    { title: 'Position', name: 'Position' },
    /*{ title: 'Club', name: 'Club' },*/
	/* { title: 'Nationality', name: 'Nationality' }, */
    { title: '17/18', name: 'Points17'},
    { title: '18/19', name: 'Points18'},
    { title: '19/20', name: 'Points19'},
    { title: '20/21', name: 'Points20'},
    {
        title: 'Total',
        name: 'totalScore',
        getCellValue: t => t.TotalShePlays || 0
    }
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);


const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return PositionPicker(props);
    }
    return <th />
};

const allPlayers = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
        <h1>Player Database - All Seasons</h1>
	<p>Browse all of the players who participated in the W-League in during the last 3 seasons that we have ran 
	our ShePlays fantasy competition!  
	You can search for every player from the W-League in 2017/18, 2018/19, and 2019/20 (by name, club, or position) in our database below, 
	 where you can also see the total number of points each player has earned throughout each season.
    </p>
    </div>
    <div className="container">
       <h5 align="right" >- - - - - - - - - - - Fantasy Points - - - - - - - - - - -</h5> 
        <Grid rows={data.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWL>
</>
export default allPlayers
