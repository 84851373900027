import React from 'react';
import numeral from 'numeral';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWSL } from '../../../components/layout/CompetionWrapperWSL';
import { wsl } from '../../../data/competitions/wsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../../data/wsl/2019/jsondatabase.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Position', name: 'Position'},
    { title: 'Player Name', name: 'Name'},  
    { title: 'Team', name: 'Club'},
    { title: 'Nationality', name: 'Nationality'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
    { title: 'Price', name: 'Price',
       getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: 'Points', name: 'Points',
       getCellValue: t => t.Points},
    /*{ title: 'Profile', name: 'Link',
      getCellValue: t => <a href={`/wsl/players/${t.Link}`}>Click here!</a> },*/
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const ProfilesWSL = () => <>
<CompetitionWrapperWSL competition={wsl}>
    <div className="container clearfix mt-32">
	<h1>Player Database 2019/20</h1>
	<p>Want to research players for your fantasy team, or are you interested in learning more about the incredible athletes in the WSL? Then this is the place to be! 
	   You can search for every player from the FAWSL in 2019/20
           (by name, club, position, or nationality) in our database below, where  
           you can also see the total number of points each player has earned throughout the season. 
	   For a complete breakdown of each players fantasy points, visit the <a href="/wsl/19/stats">Stats Center</a>.</p>
    </div>


    <div className="container">
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWSL>
</>
export default ProfilesWSL
