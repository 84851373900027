import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  "Angel City FC": {
      name: "Angel City FC",
      player: "/img/nwsl/shirts/angelcity_primary.png",
      gk: "/img/nwsl/shirts/angelcity_gk.png",
  },
  "Chicago Red Stars": {
      name: "Chicago Red Stars",
      player: "/img/nwsl/shirts/chicago_primary.png",
      gk: "/img/nwsl/shirts/chicago_gk.png",
  },
  "Houston Dash": {
      name: "Houston Dash",
      player: "/img/nwsl/shirts/houston_primary.png",
      gk: "/img/nwsl/shirts/houston_gk.png",
  },
  "Kansas City Current": {
      name: "Kansas City Current",
      player: "/img/nwsl/shirts/kansascity_primary.png",
      gk: "/img/nwsl/shirts/kansascity_gk.png",
  },
  "NJ/NY Gotham FC": {
      name: "NJ/NY Gotham FC",
      player: "/img/nwsl/shirts/gotham_primary.png",
      gk: "/img/nwsl/shirts/gotham_gk.png",
  },
  "North Carolina Courage": {
      name: "North Carolina Courage",
      player: "/img/nwsl/shirts/carolina_primary.png",
      gk: "/img/nwsl/shirts/carolina_gk.png",
  },
  "Seattle Reign FC": {
      name: "Seattle Reign FC",
      player: "/img/nwsl/shirts/reign_primary.png",
      gk: "/img/nwsl/shirts/reign_gk.png",
  },
  "Orlando Pride": {
      name: "Orlando Pride",
      player: "/img/nwsl/shirts/orlando_primary.png",
      gk: "/img/nwsl/shirts/orlando_gk.png",
  },
  "Portland Thorns": {
      name: "Portland Thorns",
      player: "/img/nwsl/shirts/portland_primary.png",
      gk: "/img/nwsl/shirts/portland_gk.png",
  },
  "Racing Louisville FC": {
      name: "Racing Louisville FC",
      player: "/img/nwsl/shirts/louisville_primary.png",
      gk: "/img/nwsl/shirts/louisville_gk.png",
  },
  "San Diego Wave FC": {
      name: "San Diego Wave FC",
      player: "/img/nwsl/shirts/sandiego_primary.png",
      gk: "/img/nwsl/shirts/sandiego_gk.png",
  },
  "Washington Spirit": {
      name: "Washington Spirit",
      player: "/img/nwsl/shirts/washington_primary.png",
      gk: "/img/nwsl/shirts/washington_gk.png",
  },
  "Bay FC": {
      name: "Bay FC",
      player: "/img/nwsl/shirts/bayfc_primary.png",
      gk: "/img/nwsl/shirts/bayfc_gk.png",
  },
  "Utah Royals FC": {
      name: "Utah Royals FC",
      player: "/img/nwsl/shirts/utah_primary.png",
      gk: "/img/nwsl/shirts/utah_gk.png",
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2024-03-16 16:00:00+00")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2024-03-18 17:00:00+00"),
    closes: moment("2024-03-23 00:00:00+00")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2024-03-25 00:00:00+00"),
    closes: moment("2024-03-29 23:00:00+00")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2024-03-31 23:00:00+00"),
    closes: moment("2024-04-13 00:00:00+00")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2024-04-15 00:00:00+00"),
    closes: moment("2024-04-20 00:00:00+00")
  },
  {       
    name: "Round6", 
    round: "round-6",
    opens: moment("2024-04-22 00:00:00+00"),
    closes: moment("2024-04-26 23:30:00+00")
  },  
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2024-04-28 23:30:00+00"),
    closes: moment("2024-05-01 23:00:00+00")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2024-05-03 23:00:00+00"),
    closes: moment("2024-05-09 00:00:00+00")
  },
  {
    name: "Round9", 
    round: "round-9",
    opens: moment("2024-05-11 00:00:00+00"),
    closes: moment("2024-05-18 00:00:00+00")
  },    
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2024-05-20 00:00:00+00"),
    closes: moment("2024-05-24 02:00:00+00")
  },
  { 
    name: "Round11",
    round: "round-11",
    opens: moment("2024-05-26 02:00:00+00"),
    closes: moment("2024-06-08 00:00:00+00") 
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2024-06-10 00:00:00+00"),
    closes: moment("2024-06-15 00:00:00+00")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2024-06-17 00:00:00+00"),
    closes: moment("2024-06-19 23:00:00+00")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2024-06-21 23:00:00+00"),
    closes: moment("2024-06-29 00:00:00+00")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2024-07-01 00:00:00+00"),
    closes: moment("2024-07-06 02:00:00+00")
  },
  {
    name: "Round16",
    round: "round-16",
    opens: moment("2024-07-08 02:00:00+00"),
    closes: moment("2024-08-24 00:00:00+00")
  },
  {
    name: "Round17",
    round: "round-17",
    opens: moment("2024-08-26 00:00:00+00"),
    closes: moment("2024-08-31 02:00:00+00")
  },
  {
    name: "Round18",
    round: "round-18",
    opens: moment("2024-09-02 02:00:00+00"),
    closes: moment("2024-09-07 02:00:00+00")
  },
  {
    name: "Round19",
    round: "round-19",
    opens: moment("2024-09-09 02:00:00+00"),
    closes: moment("2024-09-13 23:00:00+00")
  },
  {
    name: "Round20",
    round: "round-20",
    opens: moment("2024-09-15 23:00:00+00"),
    closes: moment("2024-09-21 00:00:00+00")
  },
  {
    name: "Round21",
    round: "round-21",
    opens: moment("2024-09-23 00:00:00+00"),
    closes: moment("2024-09-28 02:00:00+00")
  },
  {
    name: "Round22",
    round: "round-22",
    opens: moment("2024-09-30 02:00:00+00"),
    closes: moment("2024-10-05 00:00:00+00")
  },
  {
    name: "Round23",
    round: "round-23",
    opens: moment("2024-10-07 00:00:00+00"),
    closes: moment("2024-10-12 02:00:00+00")
  },
  {
    name: "Round24",
    round: "round-24",
    opens: moment("2024-10-14 02:00:00+00"),
    closes: moment("2024-10-19 02:00:00+00")
  },
  {
    name: "Round25",
    round: "round-25",
    opens: moment("2024-10-21 02:00:00+00"),
    closes: moment("2024-11-02 01:30:00+00")
  },
  {
    name: "Round26",
    round: "round-26",
    opens: moment("2024-11-04 01:30:00+00"),
    closes: moment("2024-11-10 22:30:00+00")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const nwsl = {
  shortName: "nwsl",
  competition: "nwsl",
  name: "NWSL",
  sport: soccer,
  totalBudget: 3300000,
  minPlayerPrice: 75000,
  maxPlayerPrice: 400000,
  perClubLimit: 4,
  hashtag: "ShePlaysNWSL",
  shirts,
  init,
  rounds,
  freeTransfers: 1,
  transferCost: 5,
  season: "2024",
  teams: shirts
};
