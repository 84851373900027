import React from "react";
import Flag from "react-world-flags";

import moment from "moment";
import { CompetitionWrapperWL } from "../../../components/layout/CompetionWrapperWL";
import { wl } from "../../../data/competitions/wl";
import * as numeral from 'numeral';

const WLteams19 = ({data}) => <>
<CompetitionWrapperWL competition={wl}>
        <div className="container clearfix mt-2">
        <div className="float-left">
            <h1>{data.name}</h1>
            <h3>2018/19 season: {data.last}</h3>
        </div>
        <div className="container">
        <p></p>
        </div>
    <img className="rounded mx-auto d-block mb-3" align="right" src={data.badge}  height={150} />
        <div className="float-right mr-2">
            <h3></h3>
            <h3></h3>
        </div>
    </div>
       <div className="container">
        <p>{data.bio}</p>
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <img className="rounded mx-auto d-block mb-3" src={data.img1} width="450"/>
            <figcaption align="center">
            Photo by <a href={data.link1} >{data.cap1}</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
            </figcaption>
          </div>
          </div>
         <div className="col-sm-6 order-sm-1">
          <div className="p-0">
            <img className="rounded mx-auto d-block mb-3" src={data.img2} width="450"/>
        <figcaption align="center">
            Photo by <a href={data.link2} >{data.cap2}</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
            </figcaption>
          </div>
         </div>
       </div>
	<p></p>
	<h4 align="center">{data.name} squad 2019/20 
        </h4> 
	<p>Browse {data.name} players below. You can see their position, age, nationality, and price, as well as the fantasy points 
           they earned in our 2017/18 and 2018/19 ShePlaysDub fantasy W-League competitions. We'll also keep their points scored during the 
           2019/20 season up to date too! Don't forget to check out our friends at <a href={data.b90L}>Beyond90</a> to keep up to date 
	   with all the latest W-League news.</p>
      </div>


       <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-4 order-sm-2">
          <div className="p-0">
	    <h5 align="center">Want to know more about {data.name}? Check out their season preview from our
        friends at <a href={data.b90L}>Beyond90</a></h5>
            <a href={data.b90L}><img hspace="0" align="center" src={`/img/wl/B90/${data.b90I}`} width="400" /></a>
          </div>
          </div>
         <div className="col-sm-7 order-sm-1">
          <div className="p-0">
            <img className="rounded mx-auto d-block mb-3" src={`/img/wl/formations/${data.form}`}width="650"/>
          </div>
         </div>
       </div>
      </div>

    <div className="container">
      <h4>Players In</h4>
      <h5 align="right" >- - - - - - - Fantasy Points - - - - - - -</h5>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Nationality</th>
            <th scope="col">Age</th>
            <th scope="col">Price</th>
            <th scope="col">From</th>
            <th scope="col" className="text-center">17/18</th>
            <th scope="col" className="text-center">18/19</th>
            <th scope="col" className="text-center">19/20</th>
          </tr>
        </thead>
        <tbody>
        {data.in.map(
          ({
            Name,
            Pos,
            Nat,
            Price,
            DOB,
	    Points19,
            Points17,
            Points18,
            Old
          }) => (
            <tr key={Name}>
              <td>{Name}</td>
              <td>{Pos}</td>
              <td><img hspace="20" src={`/img/RoundFlags/${Nat}.png`} width="40"/></td>
              <td>{moment().diff(
                    moment(moment(DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"),
                    "years"
                  )}</td>
              <td>{numeral(Price).format()}</td>
              <td>{Old}</td>
              <td align="center">{Points17}</td>
              <td align="center">{Points18}</td>
              <td align="center">{Points19}</td>
             </tr>
            )
          )}
          </tbody>
        </table>
      </div>
    <div className="container">
      <h4>Returning Players</h4>
      <h5 align="right" >- - - - - - - Fantasy Points - - - - - - -</h5>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Nationality</th>
            <th scope="col">Age</th>
            <th scope="col">Price</th>
            <th scope="col" className="text-center">17/18</th>
            <th scope="col" className="text-center">18/19</th>
            <th scope="col" className="text-center">19/20</th>
          </tr>
        </thead>
        <tbody>
        {data.resign.map(
          ({
            Name,
            Pos,
            Nat,
            Price,
            DOB,
	    Points19,
            Points17,
            Points18,
	    New
          }) => (
            <tr key={Name}>
              <td>{Name}</td>
              <td>{Pos}</td>
              <td><img hspace="20" src={`/img/RoundFlags/${Nat}.png`} width="40"/></td>
              <td>{moment().diff(
                    moment(moment(DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"),
                    "years"
                  )}</td>
              <td>{numeral(Price).format()}</td>
              <td align="center">{Points17}</td>
              <td align="center">{Points18}</td>
              <td align="center">{Points19}</td>
             </tr>
            )
          )}
          </tbody>
        </table>
      </div>

</CompetitionWrapperWL>
</>
export default WLteams19
