import React, { useState } from 'react';
import { useAuth } from "../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import Carousel from 'react-bootstrap/Carousel'
import moment from 'moment';
import { CompetitionWrapperWSL } from '../../components/layout/CompetionWrapperWSL';
import { wsl } from '../../data/competitions/wsl';
import BottomNavBar from '../../components/layout/MCompetionWrapperWSL.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const WSLLandingPageD = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wl/wl-1.png" alt="" />
	 <figcaption align="center"><a href="https://www.instagram.com/klzphotography/" title = "Kellie Lemon" target="blank"> Kellie Lemon (Instagram - @klzphotography)</a><br/>All rights reserved</figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlays Archive</h2>
              <p style={{textAlign:'justify'}}>Welcome to our ShePlays Archive! This is where you can browse all of 
	      the stats, fantasy points, and player profiles, from any of our previous ShePlays fantasy competitions. 
	      This includes our W-League, AFLW, Euros, and 2019 World Cup leagues. We've been running women's fantasy football 
	      since 2016, so we have plenty of data available for you to dive through!
	      </p>
            </div>
          </div>
        </div>
      </div>

  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wl/wl22.jpg" alt="" />
	<figcaption align="center"><a href="https://www.instagram.com/klzphotography/" title = "Kellie Lemon" target="blank"> Kellie Lemon (Instagram - @klzphotography)</a><br/>All rights reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">W-League</h2>
              <p style={{textAlign:'justify'}}>
	       This is our original fantasy competition - ShePlays started in Australia in 2016, with the 
	       launch of our first W-League fantasy competition. Player stats and fantasy points are available 
	       from 5 seaons from 2017 through to 2022. We also have a great collection of detailed player profiles 
	       you can read through - so if you're looking to brush up on your Australian women's soccer knowledge, 
	       this is a great place to start!</p>
	<div className="centerBox">
              <a
                href="/wl"
                className="btn btn-wleague btn-xl rounded-pill mt-1"
              >
                ShePlays W-League
              </a>
        </div>
	</div>

          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/aflw/AFLW41.png" alt="" />
	<figcaption align="center">Photo by <a href="https://commons.wikimedia.org/wiki/File:Kate_Dempsey_kicks_06.02.21.jpg#/media/File:Kate_Dempsey_kicks_06.02.21.jpg">DustyNail</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
            </figcaption>
    </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">AFLW</h2>
	      <p style={{textAlign:'justify'}}>
	       Our ShePlays AFLW competition was our first fantasy league outside of soccer, focusing on the women's Australian Football League (Aussie Rules). 
	       We ran our AFLW fantasy league from 2020 through to 2023, and found it was a great way to introduce the sport to a new audience. You can browse our 
	       full player statistics from the 4 seasons, to see what players you might want to keep an eye on in future years.
	      </p>
	       <div className="centerBox">
              <a
                href="/aflw"
                className="btn btn-aflw btn-xl rounded-pill mt-1"
              >
                ShePlays AFLW
              </a>
        </div>

            </div>
          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="/img/euro/euro3.png" alt="" />
	<figcaption align="center"><a href="https://commons.wikimedia.org/wiki/File:2021-09-21_Fussball,_Frauen,_L%C3%A4nderspiel,_Deutschland_-_Serbien_1DX_4882_by_Stepro.jpg" title = "Steffen Prößdorf" target="blank">Steffen Prößdorf</a>
        <br/><a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">
        CC BY-SA 4.0</a></figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">2022 Euros</h2>
              <p style={{textAlign:'justify'}}>
	      In 2022 we ran our first fantasy league for the UEFA European Women's Foobtall Championship. All of our statistics and fantasy points from the
	      championship are still available, including both the group stages and knockout rounds. Checking which players excelled in England is a great way to 
	      give youreslf a headstart when it comes to picking your ShePlays fantasy team for the 2023 FIFA Women's World Cup! 
	     </p> 
	<div className="centerBox">
              <a
                href="/euro"
                align="center"
                className="btn btn-euro btn-xl rounded-pill mt-1"
              >
                ShePlays Euro
              </a>
        </div>
            </div>
          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="/img/home3.jpg" alt="" />
	<figcaption align="center">ShePlays
        <br/><a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">
        CC BY-SA 4.0</a></figcaption>
    </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">2019 World Cup</h2>
              <p style={{textAlign:'justify'}}>
              The 2019 Women's World Cup in France was our first major international tournament, with fans getting involved from all around the world. With the World Cup 
	      heading down under to Australia and New Zealand in 2023, it's the perfect time to start researching which players were the best value picks from France. All 
	      of our stats from the torunament are available to browse below. Our 2023 fantasy competition will be opening soon, so keep an eye out for it! 
              </p>
	<div className="centerBox">
              <a
                href="/wwc"
                className="btn btn-wwc btn-xl rounded-pill mt-1"
              >
                ShePlays World Cup
              </a>
        </div>
            </div>
          </div>
        </div>
      </div>
  </section>


  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</>)


const WSLLandingPageM = () => (
  <>
    <head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
    </head>


<Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100 bg-image"
      src='/img/arc2-dark.png'
      alt="First slide"
    />
    <Carousel.Caption style={{backgroundColor:`linearGradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2))`}}>
      <h3>ShePlays Archive</h3>
      <p style={{fontSize:'0.8rem'}}>Browse the statistics,fantasy points, and player profiles from any of our previous leagues.</p>

    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/alw-dark.png'
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>ShePlays W-League</h3>
        <p style={{fontSize:'0.8rem'}}>Our original fantasy competition! Browse statistics and player profiles from the 2017-2022 seasons.</p>
                <a
            href="/wl"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays W-League
          </a>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/aflw-dark.png'
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>ShePlays AFLW</h3>
        <p style={{fontSize:'0.8rem'}}>Our AFLW (Aussie Rules) competition. Browse statisitcs from 2020-2023 seasons.</p>
                <a
            href="/aflw"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays AFLW
          </a>
    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/euro/euro5d.jpg'
      alt="Second slide"
    />
    <Carousel.Caption>
      <h3>ShePlays Euro 2022</h3>
      <p style={{fontSize:'0.8rem'}}>Browse all of our statistics and fantasy points from the 2022 UEFA European Women's Football Championship.</p>
                <a
            href="/euro"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays Euro
          </a>
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/wwc-dark.png'
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>ShePlays World Cup 2019</h3>
      <p>Relive the action from our fantasy torunament from France 2019, as we countdown to 2023.</p>
                <a
            href="/wwc"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays World Cup
          </a>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        <div className="container">
     <a href='/wl' className="btn btn-teams btn-l rounded-pill mt-1" >
       ShePlays W-League 
        </a>
             <a href='/aflw' className="btn btn-teams btn-l rounded-pill mt-1" >
        ShePlays AFLW
        </a>
     <a href='/euro' className="btn btn-teams btn-l rounded-pill mt-1" >
        ShePlays Women's Euros 2022
        </a>
	<a href='/wwc' className="btn btn-teams btn-l rounded-pill mt-1" >
        ShePlays Women's World Cup 2019
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>

        </div>
        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>
</>
);




const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <WSLLandingPageM /> : <WSLLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


