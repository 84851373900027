import React, {useState} from "react";
import { useAuth } from "../../util/withAuth";
import { CompetitionWrapperEuro } from "../../components/layout/CompetionWrapperEuro";
import BottomNavBar from '../../components/layout/MCompetionWrapperEuro.jsx';
import { euro } from "../../data/competitions/euro";
import _ from "lodash";
import MyLeagues from "../leagues/MyLeagues";



const MyEuroLeagues = ({ route }) => {
	euro.init();
  return (
	  <>
    <div className="container my-3">
        <h1>Euro Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!
      </p>
      
	  <MyLeagues route={route} competition="euro" />
    
    </div>

<div className="container">
<p>Want to find public mini-leagues to test your fantasy Euro team against? Check out our public leagues below! If you 
are a supporter group or organisation that would like your own custom public league send us an email at admin@sheplays.com.au.
 
</p>

      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>

<tbody>
<tr>
<td><img hspace="20" src="/img/GreenCircle.png" width="40"/></td>
<td>ShePlays</td>
<td>sheplays</td>
<td>Take on the ShePlays admin team!</td>
</tr>

<tr>
<td><img hspace="20" src="/img/stbsmall.jpg" width="40"/></td>
<td>She Talks Ball</td>
<td>STB2022</td>
<td>Sharing stories of women in sports, with @radhalathgupta</td>
</tr>

<tr>
<td><img hspace="20" src="/img/box2boxC.jpg" width="40"/></td>
<td>Box to Box</td>
<td>BoxToBox</td>
<td>Tactical womens football podcast by @alexibaceta23 @jessyjph @KunAbd talking, analysing and making bad jokes</td>
</tr>

<tr>
<td><img hspace="20" src="/img/fe2022C.jpg" width="40"/></td>
<td>Football Engine</td>
<td>FE2022</td>
<td>Try your best at beating members of the @footballengine1 team</td>
</tr>

<tr>
<td><img hspace="20" src="/img/hollyC.jpg" width="40"/></td>
<td>Holly Shand's League</td>
<td>HOLLY</td>
<td>FPL Content Creator & Pundit</td>
</tr>

</tbody>
</table>
</div>
	  </>
  );
};

export default MyEuroLeagues
