import React, { useState, useEffect } from 'react';
import BottomNavBar from '../../components/layout/MCompetionWrapperWWC.jsx';
import { API } from 'aws-amplify';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const countries = {
    THA: { name: "Thailand", image: "Thailand" },
    NED: { name: "Netherlands", image: "Netherlands" },
    BRA: { name: "Brazil", image: "Brazil" },
    SWE: { name: "Sweden", image: "Sweden" },
    CAN: { name: "Canada", image: "Canada" },
    AUS: { name: "Australia", image: "Australia" },
    USA: { name: "United States", image: "USA" },
    NZL: { name: "New Zealand", image: "NewZealand" },
    CMR: { name: "Cameroon", image: "Cameroon" },
    ENG: { name: "England", image: "England" },
    SCO: { name: "Scotland", image: "Scotland" },
    ITA: { name: "Italy", image: "Italy" },
    JPN: { name: "Japan", image: "Japan" },
    JAM: { name: "Jamaica", image: "Jamaica" },
    ARG: { name: "Argentina", image: "Argentina" },
    CHI: { name: "Chile", image: "Chile" },
    NOR: { name: "Norway", image: "Norway" },
    ESP: { name: "Spain", image: "Spain" },
    CHN: { name: "China PR", image: "China" },
    RSA: { name: "South Africa", image: "SouthAfrica" },
    GER: { name: "Germany", image: "Germany" },
    FRA: { name: "France", image: "France" },
    NGA: { name: "Nigeria", image: "Nigeria" },
    KOR: { name: "Korea Republic", image: "Korea" }
}

const defaultCountry = { name: "Unknown", image: "Blank"}

const DynamicMatch = ({route}) => {
    const [data, setData] = useState({
        points: []
    })

    const matchId = route.url.pathname.split('/')[2];
    const countryA = countries[data.teamA] || defaultCountry
    const countryB = countries[data.teamB] || defaultCountry

    useEffect(() => { (async () => {
        const result = await API.get('matches',`/matches/${matchId}`)
        setData(result)
        console.log(result)
    })() }, [])

    return (<>
        <div className="container">
        <div className="text-center">
        {data.teamA ? <>
                    <h1><img src={`/img/RoundFlags/${countryA.image}.png`} width="50" hspace="20" />{countryA.name} v {countryB.name}<img src={`/img/RoundFlags/${countryB.image}.png`} width="50" hspace="20"/></h1>
                    <h5>Group {data.group || "?"}</h5>
                    <p>
                        <b>{data.scoreA} - {data.scoreB}</b><br/>
                        {(data.penaltyScoreA || data.penaltyScoreB) ? <i>{data.penaltyScoreA} - {data.penaltyScoreB} Penalties</i> : null}<br/>
                        {data.venue}<br/>
        {data.city}<br/>
        {localDate(data.time)}</p>
        </> : <h1>Loading...</h1>}
        </div>

        <p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
        <a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
        <br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
        </div>
        <div className="container">
            <table className="table table-striped">
                <thead className="thread-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Country</th>
                        <th scope="col">Total</th>
                        <th scope="col">MP</th>
                        <th scope="col">G</th>
                        <th scope="col">A</th>
                        <th scope="col">Att.</th>
                        <th scope="col">Def.</th>
                        <th scope="col">GK</th>
                        <th scope="col">Cards</th>
                        <th scope="col">CS</th>
                        <th scope="col">W/D/L</th>
                        <th scope="col">GD</th>
                        <th scope="col">PS</th>
                        <th scope="col">PG</th>
                    </tr>
                </thead>
                <tbody>

                {data.points.map(({assists, attack, cards, cleanSheet, country, defense, goalDifference, goals, keeper, matchPlayed, name, playerId, total, win, penaltiesSaved, penaltiesScored}) => 
                    <tr key={playerId}>
                        <td>{name}</td>
                        <td>{country}</td>
                        <td>{total}</td>
                        <td>{matchPlayed || ""}</td>
                        <td>{goals || ""}</td>
                        <td>{assists || ""}</td>
                        <td>{attack || ""}</td>
                        <td>{defense || ""}</td>
                        <td>{keeper || ""}</td>
                        <td>{cards || ""}</td>
                        <td>{cleanSheet || ""}</td>
                        <td>{win || ""}</td>
                        <td>{goalDifference || ""}</td>
                        <td>{penaltiesSaved || ""}</td>
                        <td>{penaltiesScored || ""}</td>
                    </tr>
                )}
                </tbody>
        </table>
        </div>	
    </>)
}
export default DynamicMatch;
