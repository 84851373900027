import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-gb");

const shirts = {
  'Ajax': {
      name: 'Ajax',
      player: '/img/uwcl/shirts/Ajax_200px.png',
      gk: '/img/uwcl/shirts/Ajax_GK.png',
  },
  'Barcelona': {
      name: 'Barcelona',
      player: '/img/uwcl/shirts/Barcelona_200px.png',
      gk: '/img/uwcl/shirts/Barcelona_GK_200px.png',
  },
  'Bayern Munich': {
      name: 'Bayern Munich',
      player: '/img/uwcl/shirts/Bayern_200px.png',
      gk: '/img/uwcl/shirts/Bayern_GK_200px.png',
  },
  'Benfica': {
      name: 'Benfica',
      player: '/img/uwcl/shirts/Benfica_200px.png',
      gk: '/img/uwcl/shirts/Benfica_GK.png',
  },
  'Brann': {
      name: 'Brann',
      player: '/img/uwcl/shirts/Brann_200px.png',
      gk: '/img/uwcl/shirts/Brann_GK.png',
  },
  'Chelsea': {
      name: 'Chelsea',
      player: '/img/uwcl/shirts/Chelsea_200px.png',
      gk: '/img/uwcl/shirts/Chelsea_GK_200px.png',
  },
  'Eintracht Frankfurt': {
      name: 'Eintracht Frankfurt',
      player: '/img/uwcl/shirts/Frankfurt_200px.png',
      gk: '/img/uwcl/shirts/Frankfurt_GK_200px.png',
  },
  'Häcken': {
      name: 'Häcken',
      player: '/img/uwcl/shirts/Hacken_200px.png',
      gk: '/img/uwcl/shirts/Hacken_GK.png',
  },
  'Lyon': {
      name: 'Lyon',
      player: '/img/uwcl/shirts/Lyon_200px.png',
      gk: '/img/uwcl/shirts/Lyon_GK_200px.png',
  },
  'Paris FC': {
      name: 'Paris FC',
      player: '/img/uwcl/shirts/Paris.png',
      gk: '/img/uwcl/shirts/Paris_GK.png',
  },
  'Paris Saint-Germain': {
      name: 'Paris Saint-Germain',
      player: '/img/uwcl/shirts/PSG_200px.png',
      gk: '/img/uwcl/shirts/PSG_GK_200px.png',
  },
  'Real Madrid': {
      name: 'Real Madrid',
      player: '/img/uwcl/shirts/Madrid_200px.png',
      gk: '/img/uwcl/shirts/Madrid_GK_200px.png',
  },
  'Roma': {
      name: 'Roma',
      player: '/img/uwcl/shirts/Roma_200px.png',
      gk: '/img/uwcl/shirts/Roma_GK_200px.png',
  },
  'Rosengård': {
      name: 'Rosengård',
      player: '/img/uwcl/shirts/Rosengard_200px.png',
      gk: '/img/uwcl/shirts/Rosengard_GK.png',
  },
  'Slavia Prague': {
      name: 'Slavia Prague',
      player: '/img/uwcl/shirts/Slavia_Praha_200px.png',
      gk: '/img/uwcl/shirts/Slavia_Praha_GK_200px.png',
  },
  'St. Pölten': {
      name: 'St. Pölten',
      player: '/img/uwcl/shirts/Polten_200px.png',
      gk: '/img/uwcl/shirts/Polten_GK.png',
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/uwcl/shirts/PNA.png",
    gk: "/img/uwcl/shirts/PNAGK.png"
  },
  "Player Not Available": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
};

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2023-11-15 05:45:00+13")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2023-11-17 06:45:00+13"),
    closes: moment("2023-11-23 05:45:00+13")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2023-11-25 06:45:00+13"),
    closes: moment("2023-12-14 05:45:00+13")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2023-12-16 06:45:00+13"),
    closes: moment("2023-12-21 05:45:00+13")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2023-12-23 06:45:00+13"),
    closes: moment("2024-01-25 05:45:00+13")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2024-01-27 06:45:00+13"),
    closes: moment("2024-01-31 05:45:00+13")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2024-02-02 06:45:00+13"),
    closes: moment("2024-03-19 05:45:00+13")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2024-03-21 06:45:00+13"),
    closes: moment("2024-03-27 05:45:00+13")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2024-03-29 06:45:00+13"),
    closes: moment("2024-04-20 23:30:00+13")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2024-04-23 00:30:00+13"),
    closes: moment("2024-04-28 04:30:00+13")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2024-04-30 05:30:00+13"),
    closes: moment("2024-05-26 04:00:00+13")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2025-05-26 09:00:00+13"),
    closes: moment("2025-05-31 09:00:00+13")
  }

];


const init = () => {
  numeral.locale("en-gb");
  numeral.defaultFormat("$0,0");
};

export const uwcl = {
  shortName: "uwcl",
  competition: "uwcl",
  name: "UEFA Womens Champions League",
  sport: soccer,
  totalBudget: 9000000,
  minPlayerPrice: 100000,
  maxPlayerPrice: 1000000,
  perClubLimit: 7,
  hashtag: "ShePlaysUWCL",
  shirts,
  init,
  rounds,
  freeTransfers: 4,
  transferCost: 5,
  teams: shirts,
  season: "2023"
};

