import React from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWSL } from '../../components/layout/CompetionWrapperWSL';
import { wsl } from '../../data/competitions/wsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wsl/teamLists/transfer.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Player Name', name: 'Name' },
    { title: 'Out', name: 'OldClub'},
    { title: '', name: '',
	    getCellValue: t => <span style={{ color: 'green' }}><b>&#8594;</b></span>},
    { title: 'In', name: 'NewClub' },
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'OldClub') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'NewClub') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const TransfersWSL = () => <>
<CompetitionWrapperWSL competition={wsl}>
    <div className="container clearfix mt-32">
        <h1>Transfer Center</h1>
	<p>The WSL transfer window closes in September, so you will need to keep an eye on the latest 
        transfer news while selecting your team! We will try to keep this page up to date, so you 
        can check which players will be active in the WSL this season. You can search for players by name, or you can search by club 
        to see what players your favourite team have gained and lost.
    </p>
    </div>
    <div className="container">
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWSL>
</>
export default TransfersWSL
