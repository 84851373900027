import React, { useState} from 'react';
import { useAuth } from "../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperAFLW } from '../../components/layout/CompetionWrapperAFLW';
import { aflw } from '../../data/competitions/aflw-2021';
import BottomNavBar from '../../components/layout/MCompetionWrapperAFLW.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

const AFLWLandingPageD = () => (<CompetitionWrapperAFLW competition={aflw}>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>



  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/aflw/AFLW41.png" alt="" />
	    <figcaption align="center">Photo by <a href="https://commons.wikimedia.org/wiki/File:Kate_Dempsey_kicks_06.02.21.jpg#/media/File:Kate_Dempsey_kicks_06.02.21.jpg">DustyNail</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
            </figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlays AFLW</h2>
	      <p style={{textAlign:'justify'}}>Our ShePlays AFLW competition is back for 2023! 
	         With 4 new AFLW teams to chose from, this season should be bigger than ever. 
	         This is your chance to build your dream team of AFLW players, and to test 
	         yourself against your friends and the rest of the ShePlays community.  
	         Our fantasy competition is a great way to learn 
	         about the players and teams in the league, particularly some of the young future stars 
	         who may be less known, but make excellent fantast picks!  
	</p>
	</div>
	</div>
	</div>
	<div className="leftBox">
        <p style={{textAlign:'justify'}}>
              <b>Team selection is now open! The deadline to get your team in before round 1 is {localDate("2022-08-25 18:10+10")}. </b>
	       You can browse our fantasy stats from our 2020, 2021, and 2022 competitions to help select your team.
	      
                Make sure you follow us on
                <a href="https://twitter.com/ShePlaysAFLW" target="_blank">
                  {" "}
                  Twitter
                </a>
                ,{" "}
                <a
                  href="https://www.instagram.com/sheplays_au/"
                  target="_blank"
                >
                  {" "}
                  Instagram
                </a>{" "}
                and{" "}
                <a
                  href="https://www.facebook.com/sheplaysfantasyfootball/"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                to keep up to date with the latest news and announcements.
              </p>
            </div>
	    </div>
  <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
        <div className="centerBox">
        <h5>Build your AFLW fantasy team</h5>
        <iframe width="90%" height="255"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
        <div className="col-lg-6 order-lg-1">
        <div className="centerBox">
        <h5>Create your free ShePlays account</h5>
        <iframe width="90%" height="255" src="https://www.youtube.com/embed/LevVeCEt3bc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
      </div>
     </div>
  </section>



  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/aflw/AFLW3.jpg" alt="" />
	    <figcaption align="center">Photo by <a href="https://commons.wikimedia.org/wiki/File:Daisy_Bateman_19.01.19.jpg">DustyNail</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
            </figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">How to play</h2>
	               <p style={{textAlign:'justify'}}>You'll be given a set budget to spend on the 21 players that will make up your fantasy team.
               Your players will then receive points after each game based on how well they play.
               After each round of matches you will get the chance to make transfers, select your starting XI,
               and choose a captain who will earn double points in the next round. You can also set up your own
               mini-leagues to compete against your friends! For more information see the <a href="/how-to-play"
               target="blank">How To Play</a> page.</p>

            </div>
          </div>
        </div>
      </div>
  </section>

  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/aflw/AFLW44.png" alt="" />
	    <figcaption align="center">Photo by <a href="https://commons.wikimedia.org/wiki/File:Hore_kicks_(2)_06.02.21.jpg#/media/File:Hore_kicks_(2)_06.02.21.jpg">DustyNail</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>
	    </figcaption>
  </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Player Research</h2>
	<p style={{textAlign:'justify'}}>
                 Need help deciding who to pick for your team? Or just want to learn more about your favourite players? We can help,
                 with our full set of fantasy stats from the 2020, 2021, and 2022 seasons! You can browse previous season stats
                 centers, player databases, and ShePlays leaderboard in the Past Seasons tab. 
              </p>

            </div>
          </div>
        </div>
      </div>
  </section>
  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperAFLW>)

const AFLWLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadAFLW text-center text-white">
      <div className="masthead-contentAFLW">
        <div className="container">
          <h1 className="masthead-headingWWC mb-0">ShePlays AFLW 2022</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
          Compete against your friends in our fantasy AFLW competition!
          </p>
        {currentUser ? (
        <a href="/aflw/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My AFLW Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}
        </div>
      </div>
    </header>
<div className="container">
<a href='/aflw/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
<a href='/aflw/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results
        </a>
<a href='/aflw/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams
        </a>
<a href='/aflw/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Players
        </a>
<a href='/aflw/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News
        </a>
<a href='/aflw/join-us' className="btn btn-teams btn-l rounded-pill mt-1" >
        Join Our Team
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

        <BottomNavBar />
</>     );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 1000;

  return width < breakpoint ? <AFLWLandingPageM /> : <AFLWLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


