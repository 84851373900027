import React from "react";
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../data/competitions/aflw-2023";
import BottomNavBar from "../../components/layout/MCompetionWrapperAFLW.jsx";
import { useState, useEffect } from "react";
import { API } from 'aws-amplify';

const teams = aflw.teams;

const AFLWMatch = ({ route }) => {
  const competition = "aflw";
  const [data, setData] = useState({
    points: []
  });

  const matchId = route.url.pathname.split("/")[3];

  useEffect(() => {
    (async () => {
      const result = await API.get(
        "matches",
        `/${competition}/matches/${matchId}`
      );
      setData(result);
      console.log(result);
    })();
  }, []);

  return (<>
      <div className="container">
        <div className="text-center">
          {data.teamA ? (
            <>
              <h3>
                <img
                  src={`/img/${competition}/logo/${data.teamA}.png`}
                  width="40"
                  hspace="40"
                />{""}
                 {data.scoreA} - {data.scoreB} {""}
                <img
                  src={`/img/${competition}/logo/${data.teamB}.png`}
                  width="40"
                  hspace="40"
                />
              </h3>
            </>
          ) : (
            <h1>Loading...</h1>
          )}
        </div>

      </div>
      <div className="container">
        <table className="table table-striped" style={{fontSize:'0.8rem'}}>
          <thead className="thread-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Team</th>
              <th scope="col">Total</th>
              <th scope="col">Contested Ball</th>
              <th scope="col">Ball Movement</th>
              <th scope="col">Pressure/Defence</th>
              <th scope="col">Scoreboard Impact</th>
              <th scope="col">Clangers</th>
            </tr>
          </thead>
          <tbody>
            {data.points.map(
              ({
                name,
                playerId,
                team,
                contestedBall,
                ballMovement,
                defence,
                score,
                clangers,
                total
              }) => (
                <tr key={playerId}>
                  <td>{name}</td>
                  <td align='center'><img  src={`/img/aflw/logo/${team}.png`}
		      height="30"/></td>
                  <td>{total}</td>
                  <td>{contestedBall || ""}</td>
                  <td>{ballMovement || ""}</td>
                  <td>{defence || ""}</td>
                  <td>{score || ""}</td>
                  <td>{clangers || ""}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
	  <BottomNavBar/>
	  </>
  );
};
export default AFLWMatch;
