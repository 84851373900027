export const afl = {
  teamSize: 16,
  squadSize: 21,
  freeChoice: 1,
  positions: {
    RUCK: {
      name: "ruck",
      starting: {
        min: 1,
        max: 1
      },
      squad: {
        min: 2
      }
    },
    DEF: {
      name: "defender",
      starting: {
        min: 5,
        max: 5
      },
      squad: {
        min: 6
      }
    },
    MID: {
      name: "midfielder",
      starting: {
        min: 5,
        max: 5
      },
      squad: {
        min: 6
      }
    },
    FWD: {
      name: "forward",
      starting: {
        min: 5,
        max: 5
      },
      squad: {
        min: 6
      }
    }
  }
};
