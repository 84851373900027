import "@devexpress/dx-react-grid";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";
import { CompetitionWrapperAFLW } from "../../components/layout/CompetionWrapperAFLW";
import { aflw } from "../../data/competitions/aflw-2021";
import data from "../../data/aflw/leaderboard";

const columns = [
  { title: "Rank", name: "rank" },
  { title: "Team Name", name: "teamName" },
  {
    title: "Total",
    name: "totalScore",
    getCellValue: t => t.score["total"] || 0
  },
  {
    title: "R10",
    name: "r10",
    getCellValue: t => t.score["round-10"] || 0
  },
  {
    title: "R9",
    name: "r9",
    getCellValue: t => t.score["round-9"] || 0
  },
  {
    title: "R8",
    name: "r8",
    getCellValue: t => t.score["round-8"] || 0
  },
  {
    title: "R7",
    name: "r7",
    getCellValue: t => t.score["round-7"] || 0
  },
  {
    title: "R6",
    name: "r6",
    getCellValue: t => t.score["round-6"] || 0
  },
  {
    title: "R5",
    name: "r5",
    getCellValue: t => t.score["round-5"] || 0
  },
  {
    title: "R4",
    name: "r4",
    getCellValue: t => t.score["round-4"] || 0
  },
  {
    title: "R3",
    name: "r3",
    getCellValue: t => t.score["round-3"] || 0
  },
  {
    title: "R2",
    name: "r2",
    getCellValue: t => t.score["round-2"] || 0
  },
  {
    title: "R1",
    name: "r1",
    getCellValue: t => t.score["round-1"] || 0
  }
];

const FilterCell = props => {
  const { column } = props;
  if (column.name === "teamName") {
    return <TableFilterRow.Cell {...props} />;
  }
  return <th />;
};

const widths = {
  rank: 80,
  teamName: 300,
  default: 120
}

export const AFLWLeaderboard = () => (
  <>
    <CompetitionWrapperAFLW competition={aflw}>
      <div className="container clearfix mt-32">
        <h1>Global Leaderboard</h1>
	<p>Check out how your team is going against the rest of the ShePlays community on our global leaderboard!</p> 
      </div>
      <div className="container" style={{overflowX: 'scroll'}}>
        <Grid container item xs
          rows={data.teams.map((d, i) => ({ ...d, rank: i + 1 }))}
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={50} />

          <SortingState defaultSorting={[]} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={columns.map(({name}) => ({
		  width: widths[name] || widths.default,
		  columnName: name
	  }))}/>
          <TableHeaderRow showSortingControls />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel />
        </Grid>
      </div>
      <div className="container">
        <p></p>
      </div>
    </CompetitionWrapperAFLW>
  </>
);
export default AFLWLeaderboard;
