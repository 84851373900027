import React from 'react';



const About = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1>ShePlays</h1>
	<p>ShePlays is a registered Not-For-Profit Incorporated Association in NSW Australia (INC2200165). 
	We are registered as a Registrable Australian Body with ASIC, ARBN: 657 721 974, and our Australian 
	Business Number is ABN: 24 657 721 974.</p>
	<p>ShePlays was created in 2016 to address gender inequality in sport by promoting women's professional sporting leagues, increasing fan involvement, and encouraging grass-roots participation. We started by creating a fantasy 
	football competition for the Australian women's soccer league (W-League) in 2017, after realising that 
	fantasy sporting competitions were not available for women's sports fans. We have continued to grow and now run fantasy competitions 
       for the FAWSL (England), AFLW (Australia), NWSL (America), and Women's World Cups. ShePlays is free to play and welcoming to fans of all backgrounds. Whether you’re a long-time supporter or haven’t even chosen a team yet, you’re welcome at ShePlays! </p>

	<img className="responsive" src="img/about.jpg" alt="kennedy-header-2019-grand-final" />
	
	<p> </p>

	<h3>Statistics</h3>
        <p>The statistics we use to calculate fantasy points are generously provided by <a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019">FBref</a> and <a href="https://statsbomb.com/">StatsBomb</a>. These organisations are some of the few that have coverage of women's sporting leagues, so please show them your support!</p>

	<img className="responsive_small" src="img/fbref_wwc_logo.png" width="20%" alt="FBref" />
	
	<p> </p>

	<img className="responsive_small" src="img/StatsBombLogo.jpg" width="20%" alt="StatsBomb" />

	<p> </p>

	<h3>Our Story</h3>
	<p> The founders of ShePlays are avid Matildas fans and passionate supporters of women’s sport. While following the Australian W-League in 2016 we realised there was no publicly available fantasy competition, so we resolved to make our own. We quickly realised that there were almost no fantasy leagues for women’s professional sporting leagues in Australia, or elsewhere in the world. Statistics and information on anyone but the most elite, international players were difficult (and sometimes impossible) to find. </p>
	<p>We've since been joined by a fantastic team of volunteers from around the world. The ShePlays admin team is made up of passionate, enthusiastic and hard-working fans of fantasy and women's sport. We love being a part of this movement to grow interest and engagement in women's leagues, and we love sharing our passion with all of you.</p>
	<p>Fantasy leagues are just one of the ways that sports fans engage in their favourite leagues, and just one of the ways that talented athletes gain more exposure. We believe that running a fantasy competition is a fun way that we can help to increase interest in women’s leagues and promote the talents and successes of the players. Our aim is to provide a website and build a community that does just that. We want to grow ShePlays into a hub for information, statistics and coverage on female sporting leagues, and build detailed profiles of professional sportswomen. ShePlays is committed to being a positive, welcoming and supportive platform for sports fans from all over the world. We will keep our fantasy league free to play so that there is no barrier for people to participate. </p>
	<p> We are not affiliated with or endorsed by the Westfield W-League, Football Federation Australia, Barclays FAWSL, FIFA, or NAB AFLW. </p>

	<h3>Why this is important</h3>
	<p>We firmly believe that female athletes are worthy of equal recognition, equal pay, equal opportunity and equal media coverage to their male counterparts.</p>
	<p>Women’s sport has been experiencing a significant increase in attention and growth in investment in recent years.
	<ul>
	<li>According to a 2019 Commonwealth Bank survey, 53% of Australians are now following women’s sports either by attending watching or attending sporting matches (a 48% increase from last year).</li>
	<li>The Women’s Big Bash League produces the fourth-highest ratings for a domestic sporting league in Australia.</li>
	<li>Sam Kerr generated more media requests for Perth Glory FC in the lead up to the 2019 W-League grand final than the rest of the women’s and men’s team combined.</li>
	<li>The 2019 AFLW grand final drew a crowd of 53,034 to Adelaide Oval, setting a new record for a domestic women’s sporting match in Australia.</li>
	<li>Abroad, the Spanish La Liga Femenina has been smashing attendance records, with 60,739 people at a club match between Atletico Madrid and Barcelona.</li>
	</ul>
	The common arguments against increased investment in and coverage of women’s sport are quickly falling flat.
	</p>
	<p>However, there is still much more work to do.
	<ul>
        <li>A 2019 Suncorp study showed that 50% of girls in Australia quit sport by the age of 15, compared to 30% of boys.</li>
	<li>Women’s sport makes up, at best, 10% of sporting media coverage in Australia.</li>
	<li>Players from the United States women’s national soccer team (four-time Women’s World Cup winners and four-time Olympic gold medalists) have had to file a gender discrimination lawsuit against the United States Soccer Federation in a bid to achieve pay equality with their male counterparts.</li>
	<li>Only 0.4% of the total commercial investment in sport goes into women’s sport.</li>
	</ul>
	It is an all-too-common belief that women’s professional sporting leagues are not as exciting, as skillful or as marketable as men’s. At ShePlays, we believe this is a harmful misconception. It perpetuates under-investment, low exposure, poor pay conditions, and other barriers to professionalism that stifle marketability. It's a self-fulfilling prophecy. However, the vast, mostly untapped market of women’s sport is slowly being revealed thanks to inspiring female athletes who have captured the hearts and minds of their respective nations, and supporters who have put pressure on governing bodies to increase investment in the women’s game. We’re excited to be a part of this movement.</p>
	<h3>Contact</h3>
	<p>If you have a question or would like to give us feedback send us an email at:
	<ul>
	<li><a href="mailto:uk@sheplays.com.au">uk@sheplays.com.au</a> for questions relating to ShePlaysWSL</li>
	<li><a href="mailto:peyton@sheplays.com.au">peyton@sheplays.com.au</a> for questions relating to ShePlaysNWSL</li>
	<li><a href="mailto:marran@sheplays.com.au">marran@sheplays.com.au</a> for questions relating to ShePlays World Cup</li>
	</ul>
	Make sure to follow us on <a href="https://www.facebook.com/sheplaysfantasyfootball/">Facebook</a> and <a href="https://twitter.com/sheplays_au"> Twitter</a> to keep up to date with the latest news and announcements.</p>

	</div>
	<div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a> 
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>

</>)

export default About;
