import React from 'react';
import numeral from 'numeral';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { Input } from "reactstrap";
import { CompetitionWrapperWL } from '../../../components/layout/CompetionWrapperWL';
import { wl } from '../../../data/competitions/wl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../../data/wl/2019/auto-profiles/jsondatabase.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Position', name: 'Position'},
    { title: 'Player Name', name: 'Name'},  
    { title: 'Team', name: 'Club'},
    { title: 'Nationality', name: 'Nationality'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
    { title: 'Price', name: 'Price',
       getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: 'Points', name: 'Points',
       getCellValue: t => t.Points},
    /*{ title: 'Profile', name: 'Link',
      getCellValue: t => <a href={`/wl/players/${t.Link}`}>Click here!</a> },
	 { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);


const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Position') {
        return PositionPicker(props);
    }
    return <th />
};

const WLdatabase = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
	<h1>Player Database 2019/20</h1>
	<p>Want to research players for your fantasy team, or are you interested in learning more about the incredible athletes in the W-League? Then this is the place to be! You can search 
           every player from the W-League in 2019/20 (by name, club, position, or nationality) in our database below, where you can also see the total number of points each player has earned 
           throughout the season. 
           For a complete breakdown of each players fantasy points, visit the <a href="/wl/19/stats">Stats Center</a>.</p>
    </div>


    <div className="container">
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWL>
</>
export default WLdatabase
