import React from 'react';

const ourTeam = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>

<div className="container">
	<h1>Join our team!</h1>
	<p>
	ShePlays is run by a team of friendly volunteers from all around the 
	world, who are passionate fans of women's sports, and are motivated to help 
	it grow. We're really proud of what we have managed to achieve so far - since 
	our first fantasy W-League competition in 2017 we have now run 9 women's fantasy 
	leagues, across the W-League, FAWSL, AFLW, and the Women's FIFA Worls Cup. 
	However, we know there is a large untapped market for more women's sports, and there 
	is a lot more we can achieve with ShePlays to make it bigger and better! 
	</p>
	<p>
	In order to provide more fantasy leagues, give a higher quality user experience 
	and reach even more fans of women's sports we need to grow our team. To take ShePlays 
	to the next level, we will need help from like-minded, motivated fans, who are 
	keen to see women's sport continue to grow! If this sounds like you, check out our 
	team openings below.
	</p>
	<h3>Team Openings</h3>
	<p>
	There are a lot of tasks we need help with to keep ShePlays running (and free, for 
	everyone to enjoy!). Click on the links below to find out more about 
	each role, and how to apply. 
	</p>
	<h5>
	Sorry, we are not currently taking on new team members, while we are working on 
	some changes behind the scenes - but we'll be set up to take on new volunteers soon! 
	Check this space!
	</h5>
        <h3>Essentials</h3>
        <p>
        There are only a few essential pre-requisites to joining our team:
        <ul>
        <li>You are a fan of women's sport</li>
        <li>You are a good communicator and can be reliably contacted</li>
        <li>You are able to contribute to a positive, supportive environment</li>
        <li>You are over the age of 16</li>
        <li>You have a reliable internet connection and a personal computer</li>
        </ul>
        </p>

	<h3>Contact</h3>
        <p>If you have a question or would like to give us feedback send us an email at:
        <ul>
        <li><a href="mailto:uk@sheplays.com.au">uk@sheplays.com.au</a> for questions relating to ShePlaysWSL</li>
        <li><a href="mailto:peyton@sheplays.com.au">peyton@sheplays.com.au</a> for questions relating to ShePlaysNWSL</li>
        <li><a href="mailto:marran@sheplays.com.au">marran@sheplays.com.au</a> for questions relating to ShePlays World Cup</li>
        </ul>
</p>

</div>
	    <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
        <div className="container">
            <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
        </div>

</>)

export default ourTeam;
