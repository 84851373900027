import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperNWSL } from "../../components/layout/CompetionWrapperNWSL";
import BottomNavBar from '../../components/layout/MCompetionWrapperNWSL.jsx';
import { nwsl } from "../../data/competitions/nwsl";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: "lac", name: "Angel City"},
	        { code: "bay", name: "Bay FC"},
                { code: "chi", name: "Chicago Red Stars"},
                { code: "hou", name: "Houston Dash"},
                { code: "kcc", name: "Kansas City Current"},
                { code: "njy", name: "NJ/NY Gotham FC"},
                { code: "ncc", name: "North Carolina Courage"},
                { code: "rgn", name: "Seattle Reign FC"},
                { code: "orl", name: "Orlando Pride"},
                { code: "por", name: "Portland Thorns" },
                { code: "lou", name: "Racing Louisville FC"},
                { code: "sdw", name: "San Diego Wave"},
	        { code: "utr", name: "Utah Royals FC"},
                { code: "was", name: "Washington Spirit"}];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/nwsl/m/teams/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/nwsl/badges/${team.code}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
