import React from "react";
import Flag from "react-world-flags";

import moment from "moment";
import { CompetitionWrapperWL } from "../../components/layout/CompetionWrapperWL";
import { wl } from "../../data/competitions/wl";
import * as numeral from 'numeral';
import data from "../../data/wl/teamLists/jsondatabase.json";
require('numeral/locales/en-au');

numeral.locale('en-au');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
	const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
	if (Number.isNaN(n)) {
		return '';
	}
        return n;
};


const WLTeam = ({ wlteam }) => (
  <CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h1>{wlteam.country}</h1>
        <h3>{wlteam.teamName} </h3>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={wlteam.countryCode}
        height={150}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
      <p>{wlteam.bio1}</p>
      <p>{wlteam.bio2}</p>
      <p>{wlteam.bioAuth}</p>
      <p>WL Champions: {wlteam.champ}<br/>WL Premiers: {wlteam.prem}</p>
      <p><b>Click on a players name to view their profile page!</b></p>
    </div>


    <div className="container">
      <p />
	<h5 align="right" >- - - - - - - - - - - Fantasy Points - - - - - - - - - - -</h5>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">Age</th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">
              Nationality
            </th>
	    <th scope="col" align="center">17/18</th>
	    <th scope="col" align="center">18/19</th>
            <th scope="col" align="center">19/20</th>
	    <th scope="col" align="center">20/21</th>
	    <th scope="col" align="center">21/22</th>
          </tr>
        </thead>
        <tbody>
          {wlteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
	      Points17,
	      Points18,
              Points19,
	      Points20,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wl/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
                <td> {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
		<td>{Points17}</td>
		<td>{Points18}</td>
                <td>{Points19}</td>
		<td>{Points20}</td>
		<td>{Points}</td>
              </tr>
            )
          )}
	{wlteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points17,
              Points18,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wl/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>{Points17}</td>
                <td>{Points18}</td>
                <td>{Points19}</td>
                <td>{Points20}</td>
                <td>{Points}</td>
              </tr>
            )
          )}
	{wlteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points17,
              Points18,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wl/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>{Points17}</td>
                <td>{Points18}</td>
                <td>{Points19}</td>
                <td>{Points20}</td>
                <td>{Points}</td>
              </tr>
            )
          )}
	{wlteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points17,
              Points18,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wl/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>{Points17}</td>
                <td>{Points18}</td>
                <td>{Points19}</td>
                <td>{Points20}</td>
                <td>{Points}</td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="10">Team's Total Points:</th>
	<th scope="col">{wlteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
  </CompetitionWrapperWL>
);

export default WLTeam;
