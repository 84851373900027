import React, { useState} from 'react';
import BottomNavBar from '../../components/layout/MCompetionWrapperWWC.jsx';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import { wwc } from "../../data/competitions/wwc";
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow, TableColumnResizing,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/leaderboard"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

export default () => {

const columns = [
    { title: 'Rank', name: 'rank'},
    { title: 'Team Name', name: 'teamName'},
    {
        title: 'Total',
        name: 'totalScore',
        getCellValue: t => t.score["total"] || 0
    }
]

const [defaultColumnWidths] = useState([
	{columnName: 'rank', width: 100},
	{columnName: 'teamName', width:200},
	{columnName: 'totalScore', width:100}
]);
const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'teamName') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

return ( <>
    <div className="container clearfix mt-32">
        <h3>ShePlays WWC Leaderboard</h3>
    <img className="rounded mx-auto d-block mb-3" src="/img/wwc-lego.png" width="300"/>
    </div>
    <div className="container" style={{width:'100%'}}>
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns} >
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
	    <TableColumnResizing
            columnWidths={columnWidths}
	    onColumnWidthsChange={setColumnWidths}
	    />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
	<BottomNavBar/>
	</>
);
};
