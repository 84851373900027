import React, { useState } from 'react';
import numeral from 'numeral';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperNWSL } from '../../components/layout/CompetionWrapperNWSL';
import BottomNavBar from '../../components/layout/MCompetionWrapperNWSL.jsx';
import { Input } from "reactstrap";
import { nwsl } from '../../data/competitions/nwsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow, TableColumnResizing,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/nwsl/playerProfiles/statsList.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

export default () => {

const columns = [
    { title: 'Pos', name: 'pos'},
    { title: 'Player Name', name: 'name'},  
    { title: ' ', name: 'teamName',
    getCellValue: t => <img src={`/img/nwsl/badges/${t.teamCode}.png`} width="35"/>},
    { title: 'MP', name: 'MP'},
    { title: 'G', name: 'G'},
    { title: 'A', name: 'A'},
    { title: 'Att', name: 'Att'},
    { title: 'Def', name: 'Def'},
    { title: 'GK', name: 'GK'},
    { title: 'CS', name: 'CS'},
    { title: 'Total', name: 'Total'},
    /*{ title: 'Age', name: 'DOB',
      getCellValue: t => moment().diff(moment(moment(t.DOB, "DD-MM-YYYY")).format("MM-DD-YYYY"), "years")},*/
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);


const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'pos') {
        return PositionPicker(props);
    }
    return <th />
};

const [defaultColumnWidths] = useState([
        {columnName: 'pos', width: 80},
        {columnName: 'name', width:200},
        {columnName: 'teamName', width:100},
        {columnName: 'MP', width:80},
        {columnName: 'G', width:80},
        {columnName: 'A', width:80},
        {columnName: 'Att', width:80},
        {columnName: 'Def', width:80},
        {columnName: 'GK', width:80},
        {columnName: 'CS', width:80},
        {columnName: 'Total', width:120}

]);
const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);


return ( <>
    <div className="container clearfix mt-32">
   </div>


    <div className="container" style={{fontSize:'0.7rem'}}>
        <Grid rows={data.Players.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
	    <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
	<BottomNavBar/>
</>
);
};
