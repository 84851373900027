import "@devexpress/dx-react-grid";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";
import { CompetitionWrapperWL } from "../../../components/layout/CompetionWrapperWL";
import { wl } from "../../../data/competitions/wl";
import data from "../../../data/wl/2019/leaderboard";

const columns = [
  { title: "Rank", name: "rank" },
  { title: "Team Name", name: "teamName" },
  {
    title: "8",
    name: "r8",
    getCellValue: t => t.score["round-8"] || 0
  },
  {
    title: "9",
    name: "r9",
    getCellValue: t => t.score["round-9"] || 0
  },
  {
    title: "10",
    name: "r10",
    getCellValue: t => t.score["round-10"] || 0
  },
  {
    title: "11",
    name: "r11",
    getCellValue: t => t.score["round-11"] || 0
  },
  {
    title: "12",
    name: "r12",
    getCellValue: t => t.score["round-12"] || 0
  },
  {
    title: "13",
    name: "r13",
    getCellValue: t => t.score["round-13"] || 0
  },
  {
    title: "14",
    name: "r14",
    getCellValue: t => t.score["round-14"] || 0
  },
  {
    title: "Total",
    name: "totalScore",
    getCellValue: t => t.score["total"] || 0
  }
];

const FilterCell = props => {
  const { column } = props;
  if (column.name === "teamName") {
    return <TableFilterRow.Cell {...props} />;
  }
  return <th />;
};

export const WLLeaderboard = () => (
  <>
    <CompetitionWrapperWL competition={wl}>
      <div className="container clearfix mt-32">
        <h1>Global Leaderboard 2019/20</h1>
      </div>
      <div className="container">
        <Grid
          rows={data.teams.map((d, i) => ({ ...d, rank: i + 1 }))}
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={50} />

          <SortingState defaultSorting={[]} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table />
          <TableHeaderRow showSortingControls />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel />
        </Grid>
      </div>
      <div className="container">
        <p></p>
      </div>
    </CompetitionWrapperWL>
  </>
);
export default WLLeaderboard;
