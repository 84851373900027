import React from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWL } from '../../components/layout/CompetionWrapperWL';
import { wl } from '../../data/competitions/wl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wl/2019/teams/list.json"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Player Name', name: 'Name' },
    { title: 'Out', name: 'ClubOld'},
    { title: '', name: '',
	    getCellValue: t => <span style={{ color: 'green' }}><b>&#8594;</b></span>},
    { title: 'In', name: 'Club' },
	/* { title: 'Nationality', name: 'Nationality' }, */
   
    
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'Name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'ClubOld') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'Club') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};

const listWL = () => <>
<CompetitionWrapperWL competition={wl}>
    <div className="container clearfix mt-32">
        <h1>Transfer News</h1>
     <p>
        Here you can keep up to date with all the latest W-League transfer news! Browse the list below to see what Teams have 
        made the biggest moves in the offseason. You can find complete team lists (including re-signed players) on each Team page. 
	We will try to keep this page up to date, so you 
        can check that all of your players will be active this season. You can search for players by name, or you can search by club 
        to see what players your favourite team have gained and lost.
    </p>
    </div>
    <div className="container">
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={50}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWL>
</>
export default listWL
