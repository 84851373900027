import React from 'react';
import { Input } from "reactstrap";
import numeral from 'numeral';
import Flag from 'react-world-flags';
import BottomNavBar from '../../components/layout/MCompetionWrapperWWC.jsx';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import { wwc } from "../../data/competitions/wwc";
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wwcleaderboard"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Country',
      name: 'country' ,
      getCellValue: t => t.country},
    { title: 'Player Name', name: 'name' },
    { title: 'Position', name: 'position' },
    {
        title: 'Points',
        name: 'totalScore',
        getCellValue: t => t.Points || 0
    },
    { title: 'Price', 
      name: 'Price',
      getCellValue: t => numeral(t.Price).format('$0,0')}
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);


const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'country') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'position') {
        return PositionPicker(props); 
    }
    return <th />
};


const WWCLeaderboardM = () => <>
    <div className="container clearfix mt-32">
    </div>
    <div className="container" style={{fontSize:'0.7rem'}}>
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
	<BottomNavBar/>
</>
export default WWCLeaderboardM
