import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import moment from "moment";
import { CompetitionWrapperNWSL } from "../../components/layout/CompetionWrapperNWSL";
import BottomNavBar from "../../components/layout/MCompetionWrapperNWSL.jsx";
import { nwsl } from "../../data/competitions/nwsl";
import data from "../../data/nwsl/fixtures/rounds/fixtureList.json";
import currentData from "../../data/nwsl/fixtures/rounds/currentRound.json";
import nextData from "../../data/nwsl/fixtures/rounds/nextRound.json";

const localDate = dateString => moment(dateString).format("Do MMMM, h:mm A");

const NWSLFixtures = () => {
  const [matches, setMatches] = useState([]);

  useEffect(
    () =>
      (async () => {
        const matches = await API.get("matches", "/nwsl/matches");
        setMatches(matches);
      })(),
    []
  );

  const matchLink = (matchId, placeholder) => {
    const match = matches.find(m => m.matchId === matchId);
    if (match) {
      if (match.penaltyScoreA || match.penaltyScoreB) {
        return (
          <a href={`/nwsl/match/${matchId}/${match.teamA}v${match.teamB}/m`}>
            {match.scoreA} - {match.scoreB}{" "}
            <i>
              ({match.penaltyScoreA} - {match.penaltyScoreB})
            </i>
          </a>
        );
      }
      return (
        <a href={`/nwsl/match/${matchId}/${match.teamA}v${match.teamB}/m`}>
          {match.scoreA} - {match.scoreB}
        </a>
      );
    } else return localDate(placeholder);
  };

  return (<>
      <div className="container">
      </div>

      <div className="container" style={{fontSize:'1.0rem'}}>
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Current Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {currentData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>

      <div className="container" style={{fontSize:'1.0rem'}}>
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Next Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {nextData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>


      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 1</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round1.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 1</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 2</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round2.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 2</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 3</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round3.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 3</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 4</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round4.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 4</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 5</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round5.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 5</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 6</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round6.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 6</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 7</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round7.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 7</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 8</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round8.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 8</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 9</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round9.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 9</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 10</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round10.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 10</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 11</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round11.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 11</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 12</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round12.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 12</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 13</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round13.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 13</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 14</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round14.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 14</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 15</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round15.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 15</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 16</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round16.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 16</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 17</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round17.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 17</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 18</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round18.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 18</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 19</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round19.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 19</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 20</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round20.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 20</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 21</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round21.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 21</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 22</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round22.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 22</b>{" "}
          </p>
        </div>
      </div>



      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 23</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round23.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 23</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 24</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round24.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 24</b>{" "}
          </p>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Round 25</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {data.round25.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/nwsl/badges/${hp}`} width="40"/></td>
                  <td align="center">{matchLink(`${id}`, `${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/nwsl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
      </div>
      <div className="card text-white bg-secondary">
        <div className="container">
          <p align="center">
            <br /> <b>Transfer Window 25</b>{" "}
          </p>
        </div>
      </div>


	<BottomNavBar/>
	</>

  );
};

export default NWSLFixtures;
