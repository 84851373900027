import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  'Argentina': {
      name: 'Argentina',
      player: '/img/wwc/shirts/Argentina.png',
      gk: '/img/wwc/shirts/Argentina_GK.png',
  },
  'Australia': {
      name: 'Australia',
      player: '/img/wwc/shirts/Australia.png',
      gk: '/img/wwc/shirts/Australia_GK.png',
  },
  'Brazil': {
      name: 'Brazil',
      player: '/img/wwc/shirts/Brazil.png',
      gk: '/img/wwc/shirts/Brazil_GK.png',
  },
  'Canada': {
      name: 'Canada',
      player: '/img/wwc/shirts/Canada.png',
      gk: '/img/wwc/shirts/Canada_GK.png',
  },
  'China': {
      name: 'China',
      player: '/img/wwc/shirts/China.png',
      gk: '/img/wwc/shirts/China_GK.png',
  },
  'Colombia': {
      name: 'Colombia',
      player: '/img/wwc/shirts/Colombia.png',
      gk: '/img/wwc/shirts/Colombia_GK.png',
  },
  'Costa Rica': {
      name: 'Costa Rica',
      player: '/img/wwc/shirts/Costa Rica.png',
      gk: '/img/wwc/shirts/Costa Rica_GK.png',
  },
  'Denmark': {
      name: 'Denmark',
      player: '/img/wwc/shirts/Denmark.png',
      gk: '/img/wwc/shirts/Denmark_GK.png',
  },
  'England': {
      name: 'England',
      player: '/img/wwc/shirts/England.png',
      gk: '/img/wwc/shirts/England_GK.png',
  },
  'France': {
      name: 'France',
      player: '/img/wwc/shirts/France.png',
      gk: '/img/wwc/shirts/France_GK.png',
  },
  'Germany': {
      name: 'Germany',
      player: '/img/wwc/shirts/Germany.png',
      gk: '/img/wwc/shirts/Germany_GK.png',
  },
  'Haiti': {
      name: 'Haiti',
      player: '/img/wwc/shirts/Haiti.png',
      gk: '/img/wwc/shirts/Haiti_GK.png',
  },
  'Ireland': {
      name: 'Ireland',
      player: '/img/wwc/shirts/Ireland.png',
      gk: '/img/wwc/shirts/Ireland_GK.png',
  },
  'Italy': {
      name: 'Italy',
      player: '/img/wwc/shirts/Italy.png',
      gk: '/img/wwc/shirts/Italy_GK.png',
  },
  'Jamaica': {
      name: 'Jamaica',
      player: '/img/wwc/shirts/Jamaica.png',
      gk: '/img/wwc/shirts/Jamaica_GK.png',
  },
  'Japan': {
      name: 'Japan',
      player: '/img/wwc/shirts/Japan.png',
      gk: '/img/wwc/shirts/Japan_GK.png',
  },
  'Morocco': {
      name: 'Morocco',
      player: '/img/wwc/shirts/Morocco.png',
      gk: '/img/wwc/shirts/Morocco_GK.png',
  },
  'Netherlands': {
      name: 'Netherlands',
      player: '/img/wwc/shirts/Netherlands.png',
      gk: '/img/wwc/shirts/Netherlands_GK.png',
  },
  'New Zealand': {
      name: 'New Zealand',
      player: '/img/wwc/shirts/New Zealand.png',
      gk: '/img/wwc/shirts/New Zealand_GK.png',
  },
  'Nigeria': {
      name: 'Nigeria',
      player: '/img/wwc/shirts/Nigeria.png',
      gk: '/img/wwc/shirts/Nigeria_GK.png',
  },
  'Norway': {
      name: 'Norway',
      player: '/img/wwc/shirts/Norway.png',
      gk: '/img/wwc/shirts/Norway_GK.png',
  },
  'Panama': {
      name: 'Panama',
      player: '/img/wwc/shirts/Panama.png',
      gk: '/img/wwc/shirts/Panama_GK.png',
  },
  'Philippines': {
      name: 'Philippines',
      player: '/img/wwc/shirts/Philippines.png',
      gk: '/img/wwc/shirts/Philippines_GK.png',
  },
  'Portugal': {
      name: 'Portugal',
      player: '/img/wwc/shirts/Portugal.png',
      gk: '/img/wwc/shirts/Portugal_GK.png',
  },
  'South Africa': {
      name: 'South Africa',
      player: '/img/wwc/shirts/South Africa.png',
      gk: '/img/wwc/shirts/South Africa_GK.png',
  },
  'South Korea': {
      name: 'South Korea',
      player: '/img/wwc/shirts/South Korea.png',
      gk: '/img/wwc/shirts/South Korea_GK.png',
  },
  'Spain': {
      name: 'Spain',
      player: '/img/wwc/shirts/Spain.png',
      gk: '/img/wwc/shirts/Spain_GK.png',
  },
  'Sweden': {
      name: 'Sweden',
      player: '/img/wwc/shirts/Sweden.png',
      gk: '/img/wwc/shirts/Sweden_GK.png',
  },
  'Switzerland': {
      name: 'Switzerland',
      player: '/img/wwc/shirts/Switzerland.png',
      gk: '/img/wwc/shirts/Switzerland_GK.png',
  },
  'USA': {
      name: 'USA',
      player: '/img/wwc/shirts/USA.png',
      gk: '/img/wwc/shirts/USA_GK.png',
  },
  'Vietnam': {
      name: 'Vietnam',
      player: '/img/wwc/shirts/Vietnam.png',
      gk: '/img/wwc/shirts/Vietnam_GK.png',
  },
  'Zambia': {
      name: 'Zambia',
      player: '/img/wwc/shirts/Zambia.png',
      gk: '/img/wwc/shirts/Zambia_GK.png',
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2023-07-20 06:00:00+00")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2023-07-21 06:00:00+00"),
    closes: moment("2023-07-25 04:30:00+00")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2023-07-26 04:30:00+00"),
    closes: moment("2023-07-30 06:00:00+00")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2023-07-31 06:00:00+00"),
    closes: moment("2023-08-05 04:00:00+00")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2023-08-06 13:00:00+00"),
    closes: moment("2023-08-11 00:00:00+00")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2023-08-12 12:30:00+00"),
    closes: moment("2023-08-15 08:00:00+00")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2023-08-16 12:00:00+00"),
    closes: moment("2023-08-19 08:00:00+00")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2023-08-20 12:00:00+00"),
    closes: moment("2023-08-27 10:00:00+00")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const wwc = {
  shortName: "wwc",
  competition: "wwc",
  name: "Women's World Cup",
  sport: soccer,
  totalBudget: 9000000,
  minPlayerPrice: 200000,
  maxPlayerPrice: 1000000,
  perClubLimit: 4,
  hashtag: "ShePlaysWWC",
  shirts,
  init,
  rounds,
  freeTransfers: 2,
  transferCost: 5,
  season: "2023",
  teams: shirts
};
