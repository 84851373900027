import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import BottomNavBar from '../../components/layout/MCompetionWrapperWWC.jsx';
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: "arg", name: "Argentina"},
                { code: "aus", name: "Australia"},
                { code: "bra", name: "Brazil"},
                { code: "cmr", name: "Cameroon"},
                { code: "can", name: "Canada"},
                { code: "chl", name: "Chile"},
                { code: "chn", name: "China"},
                { code: "eng", name: "England"},
                { code: "fra", name: "France" },
                { code: "deu", name: "Germany"},
                { code: "ita", name: "Italy"},
                { code: "jam", name: "Jamaica"},
                { code: "jpn", name: "Japan"},
                { code: "kor", name: "Korea"},
                { code: "nld", name: "Netherlands"},
                { code: "nzl", name: "New Zealand" },
                { code: "nga", name: "Nigeria"},
                { code: "nor", name: "Norway"},
                { code: "sco", name: "Scotland"},
                { code: "zaf", name: "South Africa"},
                { code: "esp", name: "Spain"},
                { code: "swe", name: "Sweden"},
                { code: "tha", name: "Thailand"},
                { code: "usa", name: "United States"}];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/wwc/teams/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<Flag className="float-right country-flag" code={team.code} height={20} />
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
