import React from 'react';
import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle, faTrophy, faIdCard, faCalendarAlt, faClipboard, faBookReader} from '@fortawesome/free-solid-svg-icons';

//import icons from react icons
import { FaList, FaRegHeart } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";
import { FaTrophy } from "react-icons/fa";
import { IoMdTrophy } from "react-icons/io";
import { GrTrophy } from "react-icons/gr";
import { FaRegIdCard } from "react-icons/fa";
import { FaRegClipboard } from "react-icons/fa";
import { GoCalendar } from "react-icons/go";
import { FaBookReader } from "react-icons/fa";


const tabs = [{
  route: "/aflw/m/fixtures",
  icon: faCalendarAlt,
  label: "Fixtures"
},{
  route: "/aflw/teams",
  icon: faClipboard,
  label: "Teams"
},{
  route: "/aflw/m/players",
  icon: faIdCard,
  label: "Players"
},{
  route: "/aflw/m/leaderboard",
  icon: faTrophy,
  label: "Leaderboard"
},{
  route: "https://blog.sheplays.com.au/",
  icon: faBookReader,
  label: "News"
}]

const BottomNavBar = (props) => {
return (
    <div>
      {/* Top Bar*/}
<nav className="navbar navbar-expand-md navbar-light sticky-top" role="navigation">
       ....
      </nav>
     
      {/* Bottom Tab Navigator*/}
      <nav className="navbar fixed-bottom navbar-light bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {
              tabs.map((tab, index) =>(
                <NavItem key={`tab-${index}`}>
                  <div to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
	             <a href={tab.route} style={{color:"#4e579b"}}><FontAwesomeIcon size="lg" icon={tab.icon}/></a>
                      <div className="bottom-tab-label"><a href={tab.route} style={{color:'#55575b'}}>{tab.label}</a></div>
                    </div>
                  </div>
                </NavItem>
              ))
            }
          </div>
        </Nav>
      </nav>
    </div>
  )
};

export default BottomNavBar
