import React from 'react';
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const BravJam = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        <h1><center><img src="img/RoundFlags/Brazil.png" width="50" hspace="20" />Brazil v Jamaica<img src="img/RoundFlags/Jamaica.png" width="50" hspace="20"/></center></h1>
	<h5><center>Group C</center></h5>
	<p><center><b>3 - 0</b><br/>Stade des Alpes<br/>
	Grenoble<br/>
	{localDate("2019-06-09 15:30+02")}</center></p>
	<p>Below is a breakdown of the fantasy points earned by each player during the match. To see the full game statistics visit  
	<a href="https://fbref.com/en/comps/106/Womens-World-Cup-Stats?utm_source=sheplays&utm_medium=fantasy&utm_campaign=wwc-2019"> FBref.com</a>.
	<br/>Fantasy team scores will be calculated and uploaded at the end of the round.</p>
    </div>
    <div className="container">
        <table className="table table-striped">
            <thead className="thread-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
	            <th scope="col">Total</th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att.</th>
                    <th scope="col">Def.</th>
                    <th scope="col">GK</th>
                    <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">W/D/L</th>
                    <th scope="col">GD</th>
                </tr>
            </thead>
            <tbody>
<tr>	
<td>Cristiane</td>
<td>BRA</td>
<td>23</td>
<td>2</td>
<td>15</td>
<td></td>
<td>4</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Andressa Alves</td>
<td>BRA</td>
<td>18</td>
<td>2</td>
<td></td>
<td>6</td>
<td>6</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Mônica</td>
<td>BRA</td>
<td>11</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Kathellen</td>
<td>BRA</td>
<td>10</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Bárbara</td>
<td>BRA</td>
<td>10</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Tamires</td>
<td>BRA</td>
<td>10</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Leticia Santos</td>
<td>BRA</td>
<td>10</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td>5</td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Thaísa</td>
<td>BRA</td>
<td>9</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>4</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Debinha</td>
<td>BRA</td>
<td>9</td>
<td>2</td>
<td></td>
<td></td>
<td>5</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Khadija Shaw</td>
<td>JAM</td>
<td>7</td>
<td>2</td>
<td></td>
<td></td>
<td>5</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Ludmila</td>
<td>BRA</td>
<td>5</td>
<td>1</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Beatriz</td>
<td>BRA</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Geyse</td>
<td>BRA</td>
<td>4</td>
<td>1</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Formiga</td>
<td>BRA</td>
<td>4</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td>-2</td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Deneisha Blackwood</td>
<td>JAM</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Dominique Bond-Flasza</td>
<td>JAM</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Sydney Schneider</td>
<td>JAM</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Trudi Carter</td>
<td>JAM</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Havana Solaun</td>
<td>JAM</td>
<td>3</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Allyson Swaby</td>
<td>JAM</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Chantelle Swaby</td>
<td>JAM</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Marlo Sweatman</td>
<td>JAM</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Cheyna Williams</td>
<td>JAM</td>
<td>2</td>
<td>2</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Daiane</td>
<td>BRA</td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>-2</td>
<td></td>
<td>1</td>
<td>1</td>
</tr>
<tr>
<td>Jody Brown</td>
<td>JAM</td>
<td>1</td>
<td>1</td>
<td></td>
<td></td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Konya Plummer</td>
<td>JAM</td>
<td>1</td>
<td>2</td>
<td></td>
<td></td>
<td></td>
<td>2</td>
<td></td>
<td>-2</td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Tiffany Cameron</td>
<td>JAM</td>
<td>0</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
<tr>
<td>Chinyelu Asher</td>
<td>JAM</td>
<td>0</td>
<td>1</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td> </td>
<td>-1</td>
</tr>
            </tbody>
	</table>
    </div>	

</>)

export default BravJam;
