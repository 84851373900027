import React from 'react';
import DynamicMatch from '../../../components/matches/MatchM';
import { CompetitionWrapperWWC } from '../../../components/layout/CompetionWrapperWWC';
import BottomNavBar from "../../../components/layout/MCompetionWrapperWWC.jsx";
import { wwc } from '../../../data/competitions/wwc';

const teams = [
                { code: 'ARG', name: 'Argentina'},
                { code: 'AUS', name: 'Australia'},
                { code: 'BRA', name: 'Brazil'},
                { code: 'CAN', name: 'Canada'},
                { code: 'CHN', name: 'China'},
                { code: 'COL', name: 'Colombia'},
                { code: 'CRI', name: 'Costa Rica'},
                { code: 'DNK', name: 'Denmark'},
                { code: 'ENG', name: 'England'},
                { code: 'FRA', name: 'France'},
                { code: 'DEU', name: 'Germany'},
                { code: 'HTI', name: 'Haiti'},
                { code: 'IRL', name: 'Ireland'},
                { code: 'ITA', name: 'Italy'},
                { code: 'JAM', name: 'Jamaica'},
                { code: 'JPN', name: 'Japan'},
                { code: 'MAR', name: 'Morocco'},
                { code: 'NLD', name: 'Netherlands'},
                { code: 'NZL', name: 'New Zealand'},
                { code: 'NGA', name: 'Nigeria'},
                { code: 'NOR', name: 'Norway'},
                { code: 'PAN', name: 'Panama'},
                { code: 'PHL', name: 'Philippines'},
                { code: 'PRT', name: 'Portugal'},
                { code: 'ZAF', name: 'South Africa'},
                { code: 'KOR', name: 'South Korea'},
                { code: 'ESP', name: 'Spain'},
                { code: 'SWE', name: 'Sweden'},
                { code: 'CHE', name: 'Switzerland'},
                { code: 'USA', name: 'USA'},
                { code: 'VNM', name: 'Vietnam'},
                { code: 'ZMB', name: 'Zambia'}];

const WWCMatch = ({route}) => {
    return (<>
        <DynamicMatch {...{route, teams}} comp="wwc" />
            <BottomNavBar/>
    </>)
}
export default WWCMatch;
