import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-gb");

const shirts = {
  Arsenal: {
    name: "Arsenal",
    player: "/img/wsl/shirts/Arsenal.png",
    gk: "/img/wsl/shirts/ArsenalGK.png"
  },
  "Birmingham City": {
    name: "Birmingham City",
    player: "/img/wsl/shirts/Birmingham.png",
    gk: "/img/wsl/shirts/BirminghamGK.png"
  },
  "Brighton & Hove Albion": {
    name: "Brighton & Hove Albion",
    player: "/img/wsl/shirts/Brighton.png",
    gk: "/img/wsl/shirts/BrightonGK.png"
  },
  "Bristol City": {
    name: "Bristol City",
    player: "/img/wsl/shirts/Bristol.png",
    gk: "/img/wsl/shirts/BristolGK.png"
  },
  Chelsea: {
    name: "Chelsea",
    player: "/img/wsl/shirts/Chelsea.png",
    gk: "/img/wsl/shirts/ChelseaGK.png"
  },
  Everton: {
    name: "Everton",
    player: "/img/wsl/shirts/Everton.png",
    gk: "/img/wsl/shirts/EvertonGK.png"
  },
  Liverpool: {
    name: "Liverpool",
    player: "/img/wsl/shirts/Liverpool.png",
    gk: "/img/wsl/shirts/LiverpoolGK.png"
  },
  "Manchester City": {
    name: "Manchester City",
    player: "/img/wsl/shirts/ManCity.png",
    gk: "/img/wsl/shirts/ManCityGK.png"
  },
  "Manchester United": {
    name: "Manchester United",
    player: "/img/wsl/shirts/ManUnited.png",
    gk: "/img/wsl/shirts/ManUnitedGK.png"
  },
  Reading: {
    name: "Reading",
    player: "/img/wsl/shirts/Reading.png",
    gk: "/img/wsl/shirts/ReadingGK.png"
  },
  Tottenham: {
    name: "Tottenham",
    player: "/img/wsl/shirts/Tottenham.png",
    gk: "/img/wsl/shirts/TottenhamGK.png"
  },
  "West Ham United": {
    name: "West Ham United",
    player: "/img/wsl/shirts/WestHam.png",
    gk: "/img/wsl/shirts/WestHamGK.png"
  },
  "Aston Villa": {
    name: "Aston Villa",
    player: "/img/wsl/shirts/AstonVilla.png",
    gk: "/img/wsl/shirts/AstonVillaGK.png"
  },
  "Leicester City": {
    name: "Leicester City",
    player: "/img/wsl/shirts/Leicester.png",
    gk: "/img/wsl/shirts/LeicesterGK.png"
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
};

const rounds = [
  {
    name: "Round 1",
    round: "round-1",
    opens: moment("2021-08-13 00:00+00"),
    closes: moment("2021-09-03 17:45+00")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2021-09-05 11:00:00+00"),
    closes: moment("2021-09-11 09:30:00+00")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2021-09-12 19:45:00+00"),
    closes: moment("2021-09-25 09:30:00+00")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2021-09-26 19:45:00+00"),
    closes: moment("2021-10-02 09:30:00+00")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2021-10-03 19:45:00+00"),
    closes: moment("2021-10-09 11:30:00+00")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2021-10-10 16:00:00+00"),
    closes: moment("2021-11-06 11:30:00+00")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2021-11-07 16:00:00+00"),
    closes: moment("2021-11-13 12:30:00+00")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2021-11-14 17:00:00+00"),
    closes: moment("2021-11-20 10:30:00+00")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2021-11-21 17:00:00+00"),
    closes: moment("2021-12-11 11:15:00+00")
  },
 {
    name: "Round10",
    round: "round-10",
    opens: moment("2021-12-12 20:00:00+00"),
    closes: moment("2021-12-19 11:00:00+00")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2021-12-19 20:45:00+00"),
    closes: moment("2022-01-09 11:00:00+00")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2022-01-09 17:00:00+00"),
    closes: moment("2022-01-15 12:00:00+00")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2022-01-16 17:00:00+00"),
    closes: moment("2022-01-23 11:00:00+00")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2022-01-23 17:00:00+00"),
    closes: moment("2022-02-05 22:30:00+11")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2022-02-07 07:00:00+11"),
    closes: moment("2022-02-12 05:45:00+11")
  },
  {
    name: "Round16",
    round: "round-16",
    opens: moment("2022-02-14 03:00:00+11"),
    closes: moment("2022-03-05 21:30:00+11")
  },
  {
    name: "Round17",
    round: "round-17",
    opens: moment("2022-03-07 04:00:00+11"),
    closes: moment("2022-03-12 10:00:00+11")
  },
  {
    name: "Round18",
    round: "round-18",
    opens: moment("2022-03-13 16:00:00+00"),
    closes: moment("2022-03-26 11:30:00+00")
  },
  {
    name: "Round19",
    round: "round-19",
    opens: moment("2022-03-27 16:00:00+00"),
    closes: moment("2022-04-01 17:00:00+00")
  },
  {
    name: "Round20",
    round: "round-20",
    opens: moment("2022-04-03 19:00:00+00"),
    closes: moment("2022-04-23 22:00:00+10")
  },
  {
    name: "Round21",
    round: "round-21",
    opens: moment("2022-04-24 18:00:00+00"),
    closes: moment("2022-04-30 19:30:00+10")
  },
  {
    name: "Round22",
    round: "round-22",
    opens: moment("2022-05-08 20:50:00+10"),
    closes: moment("2022-05-08 21:00:00+10")
  },
  {
    name: "Round23",
    round: "round-23",
    opens: moment("2022-05-11 12:30:00+00"),
    closes: moment("2022-05-15 10:30:00+00")
  }
];

const init = () => {
  numeral.locale("en-gb");
  numeral.defaultFormat("$0,0");
};

export const wsl = {
  shortName: "wsl",
  competition: "wsl",
  name: "Women's Super League",
  sport: soccer,
  totalBudget: 5500000,
  minPlayerPrice: 150000,
  maxPlayerPrice: 800000,
  perClubLimit: 5,
  hashtag: "ShePlaysWSL",
  shirts,
  init,
  rounds,
  freeTransfers: 1,
  transferCost: 5,
  teams: shirts,
  season: "2021"
};
