import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  "Angel City FC": {
      name: "Angel City FC",
      player: "/img/wl/shirts/ade.png",
      gk: "/img/wl/shirts/adeGK.png",
  },
  "Chicago Red Stars": {
      name: "Chicago Red Stars",
      player: "/img/wl/shirts/bri.png",
      gk: "/img/wl/shirts/briGK.png",
  },
  "Houston Dash": {
      name: "Houston Dash",
      player: "/img/wl/shirts/cbr.png",
      gk: "/img/wl/shirts/cbrGK.png",
  },
  "Kansas City Current": {
      name: "Kansas City Current",
      player: "/img/wl/shirts/mcy.png",
      gk: "/img/wl/shirts/mcyGK.png",
  },
  "NJ/NY Gotham FC": {
      name: "NJ/NY Gotham FC",
      player: "/img/wl/shirts/mvc.png",
      gk: "/img/wl/shirts/mvcGK.png",
  },
  "North Carolina Courage": {
      name: "North Carolina Courage",
      player: "/img/wl/shirts/new.png",
      gk: "/img/wl/shirts/newGK.png",
  },
  "OL Reign": {
      name: "OL Reign",
      player: "/img/wl/shirts/per.png",
      gk: "/img/wl/shirts/perGK.png",
  },
  "Orlando Pride": {
      name: "Orlando Pride",
      player: "/img/wl/shirts/syd.png",
      gk: "/img/wl/shirts/sydGK.png",
  },
  "Portland Thorns": {
      name: "Portland Thorns",
      player: "/img/wl/shirts/wsw.png",
      gk: "/img/wl/shirts/wswGK.png",
  },
  "Racing Louisville FC": {
      name: "Racing Louisville FC",
      player: "/img/wl/shirts/wel.png",
      gk: "/img/wl/shirts/welGK.png",
  },
  "San Diego Wave FC": {
      name: "San Diego Wave FC",
      player: "/img/wl/shirts/wel.png",
      gk: "/img/wl/shirts/welGK.png",
  },
  "Washington Spirit": {
      name: "Washington Spirit",
      player: "/img/wl/shirts/wel.png",
      gk: "/img/wl/shirts/welGK.png",
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2022-04-29 21:30:00-5")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2022-05-01 21:00:00-5"),
    closes: moment("2022-05-07 18:00:00-5")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2022-05-08 22:00:00-5"),
    closes: moment("2022-05-13 21:00:00-5")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2022-05-15 19:00:00-5"),
    closes: moment("2022-05-18 18:00:00-5")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2022-05-22 21:00:00-5"),
    closes: moment("2022-05-25 21:00:00-5")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2022-05-30 17:00:00-5"),
    closes: moment("2022-06-01 18:30:00-5")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2022-06-04 22:30:00-5"),
    closes: moment("2022-06-07 21:00:00-5")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2022-06-13 00:00:00-5"),
    closes: moment("2022-06-18 09:30:00+10")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2022-06-19 19:00:00-5"),
    closes: moment("2022-07-01 19:30:00-5")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2022-07-03 19:00:00-5"),
    closes: moment("2022-07-08 18:00:00-5")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2022-07-10 20:00:00-5"),
    closes: moment("2022-07-15 18:30:00-5")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2022-07-17 21:00:00-5"),
    closes: moment("2022-07-29 18:30:00-5")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2022-07-31 21:00:00-5"),
    closes: moment("2022-08-02 19:00:00-5")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2022-08-07 21:00:00-5"),
    closes: moment("2022-08-10 18:30:00-5")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2022-08-14 22:00:00-5"),
    closes: moment("2022-08-17 19:30:00-5")
  },
  {
    name: "Round16",
    round: "round-16",
    opens: moment("2022-08-21 00:00:00-5"),
    closes: moment("2022-08-26 18:00:00-5")
  },
  {
    name: "Round17",
    round: "round-17",
    opens: moment("2022-08-28 21:00:00-5"),
    closes: moment("2022-09-09 18:00:00-5")
  },
  {
    name: "Round18",
    round: "round-18",
    opens: moment("2022-09-11 21:00:00-5"),
    closes: moment("2022-09-14 19:00:00-5")
  },
  {
    name: "Round19",
    round: "round-19",
    opens: moment("2022-09-18 19:00:00-5"),
    closes: moment("2022-09-21 18:00:00-5")
  },
  {
    name: "Round20",
    round: "round-20",
    opens: moment("2022-09-25 22:00:00-5"),
    closes: moment("2022-09-30 20:30:00-5")
  },
  {
    name: "Round21",
    round: "round-21",
    opens: moment("2022-10-02 20:00:00-5"),
    closes: moment("2022-10-09 18:00:00-5")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const nwsl = {
  shortName: "nwsl",
  competition: "nwsl",
  name: "NWSL",
  sport: soccer,
  totalBudget: 3300000,
  minPlayerPrice: 75000,
  maxPlayerPrice: 400000,
  perClubLimit: 5,
  hashtag: "ShePlaysNWSL",
  shirts,
  init,
  rounds,
  freeTransfers: 1,
  transferCost: 5,
  season: "2023",
  teams: shirts
};
