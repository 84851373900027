import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  "Angel City FC": {
      name: "Angel City FC",
      player: "/img/nwsl/shirts/angelcity.png",
      gk: "/img/nwsl/shirts/angelcitygk.png",
  },
  "Chicago Red Stars": {
      name: "Chicago Red Stars",
      player: "/img/nwsl/shirts/chicago.png",
      gk: "/img/nwsl/shirts/chicagogk.png",
  },
  "Houston Dash": {
      name: "Houston Dash",
      player: "/img/nwsl/shirts/houston.png",
      gk: "/img/nwsl/shirts/houstongk.png",
  },
  "Kansas City Current": {
      name: "Kansas City Current",
      player: "/img/nwsl/shirts/kansas.png",
      gk: "/img/nwsl/shirts/kansasgk.png",
  },
  "NJ/NY Gotham FC": {
      name: "NJ/NY Gotham FC",
      player: "/img/nwsl/shirts/gotham.png",
      gk: "/img/nwsl/shirts/gothamgk.png",
  },
  "North Carolina Courage": {
      name: "North Carolina Courage",
      player: "/img/nwsl/shirts/northcarolina.png",
      gk: "/img/nwsl/shirts/northcarolinagk.png",
  },
  "OL Reign": {
      name: "OL Reign",
      player: "/img/nwsl/shirts/reign.png",
      gk: "/img/nwsl/shirts/reigngk.png",
  },
  "Orlando Pride": {
      name: "Orlando Pride",
      player: "/img/nwsl/shirts/orlando.png",
      gk: "/img/nwsl/shirts/orlandogk.png",
  },
  "Portland Thorns": {
      name: "Portland Thorns",
      player: "/img/nwsl/shirts/portland.png",
      gk: "/img/nwsl/shirts/portlandgk.png",
  },
  "Racing Louisville FC": {
      name: "Racing Louisville FC",
      player: "/img/nwsl/shirts/louisville.png",
      gk: "/img/nwsl/shirts/louisvillegk.png",
  },
  "San Diego Wave FC": {
      name: "San Diego Wave FC",
      player: "/img/nwsl/shirts/sandiego.png",
      gk: "/img/nwsl/shirts/sandiegogk.png",
  },
  "Washington Spirit": {
      name: "Washington Spirit",
      player: "/img/nwsl/shirts/washington.png",
      gk: "/img/nwsl/shirts/washingtongk.png",
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2022-04-29 21:30:00-05")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2022-05-01 21:00:00-05"),
    closes: moment("2022-05-07 12:00:00+10")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2022-05-09 12:00:00+10"),
    closes: moment("2022-05-14 12:30:00+10")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2022-05-15 19:00:00-05"),
    closes: moment("2022-05-18 18:00:00-05")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2022-05-22 21:00:00-05"),
    closes: moment("2022-05-25 21:00:00-05")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2022-05-30 17:00:00-05"),
    closes: moment("2022-06-01 18:30:00-05")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2022-06-04 22:30:00-05"),
    closes: moment("2022-06-07 21:00:00-05")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2022-06-13 00:00:00-05"),
    closes: moment("2022-06-18 09:30:00+10")
  },
  {
    name: "Round9",
    round: "round-9",
    opens: moment("2022-06-19 19:00:00-05"),
    closes: moment("2022-07-01 19:30:00-05")
  },
  {
    name: "Round10",
    round: "round-10",
    opens: moment("2022-07-03 19:00:00-05"),
    closes: moment("2022-07-08 18:00:00-05")
  },
  {
    name: "Round11",
    round: "round-11",
    opens: moment("2022-07-10 20:00:00-05"),
    closes: moment("2022-07-15 18:30:00-05")
  },
  {
    name: "Round12",
    round: "round-12",
    opens: moment("2022-07-17 21:00:00-05"),
    closes: moment("2022-07-29 18:30:00-05")
  },
  {
    name: "Round13",
    round: "round-13",
    opens: moment("2022-07-31 21:00:00-05"),
    closes: moment("2022-08-02 19:00:00-05")
  },
  {
    name: "Round14",
    round: "round-14",
    opens: moment("2022-08-07 21:00:00-05"),
    closes: moment("2022-08-10 18:30:00-05")
  },
  {
    name: "Round15",
    round: "round-15",
    opens: moment("2022-08-14 22:00:00-05"),
    closes: moment("2022-08-17 19:30:00-05")
  },
  {
    name: "Round16",
    round: "round-16",
    opens: moment("2022-08-21 00:00:00-05"),
    closes: moment("2022-08-26 18:00:00-05")
  },
  {
    name: "Round17",
    round: "round-17",
    opens: moment("2022-08-28 21:00:00-05"),
    closes: moment("2022-09-09 18:00:00-05")
  },
  {
    name: "Round18",
    round: "round-18",
    opens: moment("2022-09-11 21:00:00-05"),
    closes: moment("2022-09-14 19:00:00-05")
  },
  {
    name: "Round19",
    round: "round-19",
    opens: moment("2022-09-18 19:00:00-05"),
    closes: moment("2022-09-21 18:00:00-05")
  },
  {
    name: "Round20",
    round: "round-20",
    opens: moment("2022-09-25 22:00:00-05"),
    closes: moment("2022-09-30 20:30:00-05")
  },
  {
    name: "Round21",
    round: "round-21",
    opens: moment("2022-10-02 20:00:00-05"),
    closes: moment("2022-10-09 18:00:00-05")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const nwsl = {
  shortName: "nwsl",
  competition: "nwsl",
  name: "NWSL",
  sport: soccer,
  totalBudget: 3200000,
  minPlayerPrice: 75000,
  maxPlayerPrice: 400000,
  perClubLimit: 4,
  hashtag: "ShePlaysNWSL",
  shirts,
  init,
  rounds,
  freeTransfers: 1,
  transferCost: 5,
  season: "2022",
  teams: shirts
};
