import React, { useState, useEffect } from "react";
import { Alert } from 'reactstrap';

export const NotificationBox = ({notification}) => {
    const [visible, setVisible] = useState()

    useEffect(() => {
        console.log(notification)
        setVisible(notification !== undefined)
    }, [notification])

    return notification ? <Alert className="mt-3" color={notification.severity || "info"} isOpen={visible} toggle={() => setVisible(false)}>
        {notification.message}
    </Alert> : null;
}