import React from "react";
import Flag from "react-world-flags";
import _ from 'lodash';

import moment from "moment";
import { CompetitionWrapperNWSL } from "../../components/layout/CompetionWrapperNWSL";
import { nwsl } from "../../data/competitions/nwsl";
import * as numeral from 'numeral';
import data from "../../data/nwsl/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({nwslteam}) => {
	if (!nwslteam.link1){
		return<span></span>
	}
	return (
	      <div>
	      <img
              className="responsive_2col"
              src={`/img/nwsl/${nwslteam.photo}`}
              width="550"
            />
	       <figcaption align="center">
            Photo by <a href={nwslteam.link1} >{nwslteam.cap1}</a>, <a href={nwslteam.link2} title="Creative Commons">{nwslteam.cap2}</a>
            </figcaption>
		<p> </p>
	    </div>
	    )

};

const NWSLTeam = ({ nwslteam }) => (
  <CompetitionWrapperNWSL competition={nwsl}>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h1>{nwslteam.club}</h1>
        <h5>{nwslteam.stadium} <br/>{nwslteam.city} </h5>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={nwslteam.badge}
        height={150}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
	{Image({nwslteam})}
      <p style={{textAlign:'justify'}}>{nwslteam.bio}</p>
      <p style={{textAlign:'justify'}}>{nwslteam.bio2}</p>
      <p> Coach: {nwslteam.coach} <br/> 
      NWSL 2023: {nwslteam.lastSeason} <br/>
      Profile by {nwslteam.cap2}</p>
      <p><b>Click on a players name to view their profile page!</b></p>
    </div>

    <div className="container">
      <p />
	<h5 align="right" >Fantasy Points</h5>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">
              Nationality
            </th>
            <th scope="col" align="center">Age</th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">2022</th>
	    <th scope="col" align="center">2023</th>
	    <th scope="col" align="center">2024</th>
          </tr>
        </thead>
        <tbody>
          {nwslteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
	      Points20,
	      Points22,
	      Points23,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/nwsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points22}</b></td>
		    <td><b>{Points23}</b></td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {nwslteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
	      Points22,
	      Points23,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/nwsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points22}</b></td>
		    <td><b>{Points23}</b></td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {nwslteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
	      Points22,
	      Points23,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/nwsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points22}</b></td>
		    <td><b>{Points23}</b></td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {nwslteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
	      Points22,
	      Points23,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/nwsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
		<td><b>{Points22}</b></td>
		    <td><b>{Points23}</b></td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="8">Team's Total Points:</th>
	<th scope="col">{nwslteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
  </CompetitionWrapperNWSL>
);

export default NWSLTeam;
