import React from 'react';
import { Authenticator } from 'aws-amplify-react';
import Amplify, { Auth, Hub } from 'aws-amplify';

const amplifyConfig = {
  hiddenDefaults: ['username'],
  signUpFields: [
    {
      label: 'Email',
      key: 'username', // !!!
      required: true,
      displayOrder: 1,
      type: 'email',
      custom: false
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      custom: false
    }
  ]
};

class LandingPage extends React.Component {
  state = { user: null };

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.setState({ user: data });
          console.log("SIGNED IN AS "+JSON.stringify(data))
          break;
        case "signOut":
          this.setState({ user: null });
          break;
      }
    });
  }
  
  render() {
    Auth.currentAuthenticatedUser().then(u => console.log(JSON.stringify(u), u));
    return <div> Welcome to ShePlays!  </div>
  }
}
export default LandingPage;
