import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import BottomNavBar from '../../components/layout/MCompetionWrapperAFLW.jsx';
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
  {code: "ade", name: "Adelaide Crows"},
  {code: "bri", name: "Brisbane Lions"},
  {code: "car", name: "Carlton Blues"},
  {code: "col", name: "Collingwood Magpies"},
  {code: "ess", name: "Essendon Bombers"},
  {code: "fre", name: "Fremantle Dockers"},
  {code: "gee", name: "Geelong Cats"},
  {code: "gol", name: "Gold Coast Suns"},
  {code: "gws", name: "GWS Giants"},
  {code: "haw", name: "Hawthorn Hawks"},
  {code: "mel", name: "Melbourne Demons"},
  {code: "nor", name: "North Melbourne Kangaroos"},
  {code: "por", name: "Port Adelaide"},
  {code: "ric", name: "Richmond Tigers"},
  {code: "stk", name: "St Kilda Saints"},
  {code: "syd", name: "Sydney Swans"},
  {code: "wce", name: "West Coast Eagles"},
  {code: "wes", name: "Western Bulldogs"}];


const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
        {teams.map(team=> (
        <a
                href={`/aflw/teams/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
                {team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/aflw/logo/${team.code}.png`}/>
              </a>
        ))}
        </div>

<BottomNavBar/>

</>)

export default Mteams;
