import React from 'react';

const PrivateLeague = () => (<>
	<div className="container">
	    <h1>Private Leagues</h1>
	    <p>Want to play against your friends, family, teammates, or colleagues? Fill out the form below to set up your own private league. 
	    You'll then recieve a code which your friends can use to join!</p>
	<form id="contact-form" method="post">
	    <h4>Request a private league</h4>
	    <h6>League Name:</h6>
	    <input type="text" id="name-input" placeholder="Enter your private league name here"/>
	    <h6>Your Email:</h6>
	    <input type="text" id="email-input" placeholder="Enter your email address here"/>
	    <p>   (Make sure you enter the same email address you use to login to the site!)</p>
	    <h6>Your team name:</h6>
	    <input type="text" id="email-input" placeholder="Enter your team name here"/>
	    <p></p>
	    <button type="button" onClick="submitToAPI(event)" className="btn btn-secondary btn-xl rounded-pill mt-5">Submit</button>
	</form>
	</div>
</>)

export default PrivateLeague;
