import React from 'react';
  
const TermsOfService = () => (<>
    <div className="container">
	<h1>Terms of Service</h1>
	<p>Welcome to ShePlays! We’re very happy that you’re here. Please read this page carefully before using our Website. If you continue to use this Website you are agreeing to comply with the following Terms of Use. Together with the Privacy Policy, these Terms describe your relationship with ShePlays and your use of this Website. If you disagree with any part of these Terms, please do not use our Website. </p>
	<h4>Summary</h4>
	<p><ul>
	<li>You may only have one account per person and you must be a human to have an account.</li>
	<li>Your country of residence may have a minimum legal age for signing up to this Website, we do not permit any Users who are below the relevant minimum legal age.</li>
	<li>You are responsible for keeping your account secure.</li>
	<li>We encourage you to use, share and create new work from the photos, images, graphics and text on this Website as long as you attribute us and you share your work under a Creative Commons licence. The Website code and Fantasy League functionality is copyright © ShePlays and may not be copied or shared.</li>
	<li>We expect you to conduct yourself in a friendly, positive way while you participate in the Fantasy League. This is a free, fan-run league offered in good faith, and we expect that users treat each other and the ShePlays administrators with respect. We expect that you act honestly and legally in your dealings with us.</li>
	<li>We may cancel your account at any time.</li>
	<li>We will use emails to stay in touch with users.</li>
	<li>The Fantasy League is provided as-is, we do not make any warranties about the quality, accuracy or reliability of the Website and the Fantasy League.</li>
	<li>We will not be liable for damages or losses arising from your participation in or inability to participate in the Fantasy League.</li>
	<li>We will notify you if there are any substantial changes to these Terms.</li>
	</ul></p>
	<h4>Definitions</h4>
	<p>The term ‘ShePlays’, ‘us’ or ‘we’ refers to the administrators of this website, ShePlays Fantasy League. Our Australian Business Number is 83 622 698 460.</p>
	<p>The term ‘you’ or ‘the User’ refers to the individual person, company or organisation that has visited or has registered for an account with ShePlays.</p>
	<p>The ‘Service’ refers to the Fantasy League that is managed by ShePlays, and all related software, products and services provided by ShePlays.</p>
	<p>The ‘Agreement’ refers, collectively, to all of the terms, conditions and notices referenced in this document (the ‘Terms of Service’ or ‘Terms’) and the ShePlays Privacy Policy.</p>
	<p>The ‘Website’ refers to the ShePlays website located at <a href="https://www.sheplays.com.au">www.sheplays.com.au</a>, and all content, services and products provided by ShePlays through the Website.</p>
        <p>‘Content’ refers to the content featured of displayed throughout the website, including without limitation text, data, articles, images, photographs, graphics, features and other material available on the Website or otherwise available through the Service.</p>
	<p>Your ‘Account’ represents your legal relationship with ShePlays. It is your authorisation to log into and use the Service, and it serves as your identity on ShePlays.</p>
	<h4>Account Terms</h4>
	<h6>Required information</h6>
	<p>To have an Account with ShePlays you must provide a valid email address and password during the registration process. You must also provide a username, or if you sign up with a Facebook or Google account your username will be the name associated with that account. For more information about the information we require or collect please refer to our Privacy Policy.</p>
	<h6>Account requirements and eligibility</h6>
	<p><ul>
	<li>You must be a human to create an Account. Accounts registered by ‘bots’ or other automated methods are not permitted.</li>
	<li>One person may not maintain more than one Account. If you create multiple accounts you will not be eligible for any prizes associated with the Fantasy League.</li>
	<li>Australia’s Privacy Act sets no minimum age at which an individual can make decisions regarding his or her personal information, so there is no minimum age for participation in the Fantasy League. However, if you are a resident of a country outside of Australia there may be a minimum age at which you can provide your personal information to a website operator such as ShePlays. You are responsible for complying with your country’s laws, and we do not permit Users to make an Account with ShePlays if they are below the relevant minimum age in their country of residence. If we learn of a User in contravention of the law in their country of residence, we will terminate that User’s Account immediately. If you are under the age of 13 we encourage you to obtain your parent or guardian’s consent before registering with our Website.</li></ul></p>
	<h6>Account security</h6>
	<p>You are responsible for keeping your Account secure while you use our Service.
	<ul>
	<li>You are responsible for maintaining the security of your Account and password. ShePlays cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
	<li>You will promptly notify ShePlays if you become aware of any unauthorised use of, or access to, our Service through your account, including any unauthorised use of your password or Account.</li>
	</ul></p>
	<h4>Intellectual Property notice</h4>
	<h6>ShePlays’ rights to Content</h6>
	<p>ShePlays retains ownership of all intellectual property rights related to the Website and the Service. We reserve all rights that are not expressly granted to you under this Agreement or by law.<ul>
	<li>The Website code and functionality that drives the ShePlays Fantasy League platform is copyright © ShePlays. All rights reserved. You may not duplicate, copy, or reuse any portion of the code without the express written permission of ShePlays</li>
	<li>The photos, images, graphics and text on the Website is licenced under a Creative Commons Attribution-ShareAlike 4.0 International Licence. You may create derivative works under the condition that you provide attribution to ShePlays and you make the derivative work available under the same licence conditions. Note that this licence does not grant any trademark permissions.</li>
	<li>These Terms of Service are licenced under a Creative Commons Zero Licence.</li></ul></p>
	<h4>Compliance</h4>
	<h6>Content restrictions</h6>
	<p>You agree that you will not under any circumstances upload, post, host or transmit any content that:<ul>
	<li>Is unlawful or promotes unlawful activities</li>
	<li>Is or contains sexually obscene content</li>
	<li>Is libelous, defamatory or fraudulent</li>
	<li>Is discriminatory or abusive toward any individual or group</li>
	<li>Gratuitously depicts or glorifies violence, including violent images</li>
	<li>Contains or installs any active malware or exploits</li>
	<li>Infringes on any proprietary right of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights</li></ul></p>
	<h6>Conduct restrictions</h6>
	<p>While using this Website, you agree that you will not under any circumstances:<ul>
	<li>Harass, abuse, threaten, or incite violence towards any individual or group, including ShePlays administrators or other ShePlays Users</li>
	<li>Attempt to disrupt or tamper with the ShePlays Website in ways that could harm our Service, to place undue burden on ShePlays’ servers through automated means, or to access ShePlays’ Service in ways that exceed your authorization</li>
	<li>Violate the privacy of any third party, such as by posting another person's personal information without consent.</li></ul></p>
	<h6>Services Usage Limits</h6>
	<p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without ShePlays’ express written permission.</p>
	<h6>User Protection</h6>
	<p>You agree not to engage in activity that significantly harms our Users. We will resolve disputes in favor of protecting our Users as a whole.</p>
	<h4>Cancellation and Termination</h4>
	<h6>Account cancellation</h6>
	<p>Please refer to our Privacy Policy for Account cancellation guidelines.</p>
	<h6>ShePlays may terminate</h6>
	<p>ShePlays has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice. ShePlays reserves the right to refuse service to anyone for any reason at any time.</p>
	<h4>Communications with ShePlays</h4>
	<p>You consent to receive communications from us via the email address you have submitted or via the Service.</p>
	<h4>Disclaimer of Warranties</h4>
	<p>ShePlays provides the Website and the Service for free and ‘as is’ without warranty of any kind. ShePlays does not warrant that the Service will meet your requirements or expectations; that the service will be uninterrupted, timely, secure, or error-free; that information provided through the Service is accurate, reliable or correct, that any defects or errors will be corrected, that the Service will be available at any particular time or location, or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.</p>
	<h4>Limitation of Liability</h4>
	<p>You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from<ul>
	<li>your use or inability to use the Service;</li>
	<li>any modification, suspension or discontinuance of the Service;</li>
	<li>the Service generally or the software or systems that make the Service available;</li>
	<li>unauthorized access to or alterations of your transmissions or data;</li>
	<li>statements or conduct of any third party on the Service;</li>
	<li>any other user interactions that you input or receive through your use of the Service; or</li>
	<li>any other matter relating to the Service.</li></ul></p>
	<p>Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.</p>
	<h4>Release and Indemnification</h4>
	<p>If you have a dispute with one or more Users, you agree to release ShePlays from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
	<p>You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement.</p>
	<h4>Changes to these Terms</h4>
	<p>We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users by email of material changes to this Agreement, at least 30 days prior to the change taking effect. For non-material modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service.</p>
	<p>We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.</p>
	<h4>Miscellaneous</h4>
	<h6>Governing Law</h6>
	<p>This Agreement between you and ShePlays and any access to our Website or the Service are governed by the federal laws of Australia and the state laws of New South Wales. Any disputes about this Website, its Content or your dealings with ShePlays must be determined by courts having jurisdiction in New South Wales, Australia.</p>
	<p>We do not warrant that this Service or the Content of this Website complies with the laws of any country outside Australia. If you reside outside Australia you accept responsibility for ensuring compliance for all laws that apply to you.</p>
	<h6>Terms of Service summary</h6>
	<p>The summary of the Terms of Service provided at the top of this page is designed to help you understand the Agreement and is not legally binding.</p>
	<h6>Severability, No Waiver, and Survival</h6>
	<p>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of ShePlays to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.</p>
	<h4>Contact us</h4>
	<p>If you have any questions or concerns about our Terms of Service please contact us at <a href="mailto:admin@sheplays.com.au">admin@sheplays.com.au</a>.</p>
        <p><center><i>Effective date 1 June 2019</i></center></p>	


    </div>

</>)

export default TermsOfService;
