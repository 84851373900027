import React from 'react';
import numeral from 'numeral';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import { wwc } from "../../data/competitions/wwc";
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wwcleaderboard"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Player Name', name: 'name' },
    { title: 'Position', name: 'position' },
    { title: 'Country', 
      name: 'country' ,
      getCellValue: t => t.country}, 
    { title: 'Price', 
      name: 'Price',
      getCellValue: t => numeral(t.Price).format('$0,0')},
    { title: 'Age', name: 'Age'},
    {
        title: 'Points',
        name: 'totalScore',
        getCellValue: t => t.Points || 0
    }
]

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'country') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'position') {
        return <TableFilterRow.Cell {...props} />;
    }
    return <th />
};



const WWCLeaderboard = () => <>
<CompetitionWrapperWWC competition={wwc}>
    <div className="container clearfix mt-32">
        <h1>Player Leaderboard</h1>
	<p>Here you can view the fantasy scores for each of the 552 players who were in France for the World Cup. Check out how your favourite 
        players performed, and start looking for players that you may want to pick for your next fantasy team!
    </p>
    </div>
    <div className="container">
        
        <Grid rows={data.teams.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWWC>
</>
export default WWCLeaderboard
