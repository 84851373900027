import React from 'react';
import DynamicMatch from '../../components/matches/MatchM';
import { CompetitionWrapperEuro } from '../../components/layout/CompetionWrapperEuro';
import BottomNavBar from "../../components/layout/MCompetionWrapperEuro.jsx";
import { euro } from '../../data/competitions/euro';

const teams = [
                { code: "aut", name: "Austria"},
                { code: "bel", name: "Belgium"},
                { code: "dnk", name: "Denmark"},
                { code: "eng", name: "England"},
                { code: "fin", name: "Finland"},
                { code: "fra", name: "France"},
                { code: "deu", name: "Germany"},
                { code: "isl", name: "Iceland"},
                { code: "ita", name: "Italy" },
                { code: "nld", name: "Netherlands"},
                { code: "nir", name: "Northern Ireland"},
                { code: "nor", name: "Norway"},
                { code: "prt", name: "Portugal" },
                { code: "esp", name: "Spain"},
                { code: "swe", name: "Sweden"},
                { code: "che", name: "Switzerland"}];

const EuroMatch = ({route}) => {
    return (<>
        <DynamicMatch {...{route, teams}} comp="euro" />
	    <BottomNavBar/>
    </>)
}
export default EuroMatch;
