import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useAuth } from "../../util/withAuth";

const teams = [
                { code: 'ARG', name: 'Argentina'},
                { code: 'AUS', name: 'Australia'},
                { code: 'BRA', name: 'Brazil'},
                { code: 'CAN', name: 'Canada'},
                { code: 'CHN', name: 'China'},
                { code: 'COL', name: 'Colombia'},
                { code: 'CRI', name: 'Costa Rica'},
                { code: 'DNK', name: 'Denmark'},
                { code: 'ENG', name: 'England'},
                { code: 'FRA', name: 'France'},
                { code: 'DEU', name: 'Germany'},
                { code: 'HTI', name: 'Haiti'},
                { code: 'IRL', name: 'Ireland'},
                { code: 'ITA', name: 'Italy'},
                { code: 'JAM', name: 'Jamaica'},
                { code: 'JPN', name: 'Japan'},
                { code: 'MAR', name: 'Morocco'},
                { code: 'NLD', name: 'Netherlands'},
                { code: 'NZL', name: 'New Zealand'},
                { code: 'NGA', name: 'Nigeria'},
                { code: 'NOR', name: 'Norway'},
                { code: 'PAN', name: 'Panama'},
                { code: 'PHL', name: 'Philippines'},
                { code: 'PRT', name: 'Portugal'},
                { code: 'ZAF', name: 'South Africa'},
                { code: 'KOR', name: 'South Korea'},
                { code: 'ESP', name: 'Spain'},
                { code: 'SWE', name: 'Sweden'},
                { code: 'CHE', name: 'Switzerland'},
                { code: 'USA', name: 'USA'},
                { code: 'VNM', name: 'Vietnam'},
                { code: 'ZMB', name: 'Zambia'}];

export const CompetitionWrapperWWC = ({ competition, children }) => {
  const [teamDropdown, setTeamDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const currentUser = useAuth()



  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wwc flex-column">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
                Rules &amp; Scoring
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
                Fixtures &amp; Results
              </a>
            </li>
            <li className="nav-item">
              <Dropdown
                isOpen={teamDropdown}
                toggle={() => setTeamDropdown(!teamDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Teams
                </DropdownToggle>
                <DropdownMenu>
                  {teams.map(team => (
                    <DropdownItem
                      key={team.code}
                      href={`/${competition.shortName}/teams/${team.code ||
                        team.code}`.toLowerCase()}
                    >
                      {team.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item">
	                  <Dropdown
                isOpen={playerDropdown}
                toggle={() => setPlayerDropdown(!playerDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Players
                </DropdownToggle>
                <DropdownMenu>
	        <DropdownItem href="/wwc/database">Player Database</DropdownItem>
                <DropdownItem href="/wwc/stats">Stats Center</DropdownItem>
                </DropdownMenu>
              </Dropdown>  
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/leaderboard`}
              >
                Leaderboard
              </a>
            </li>
	            <li className="nav-item">
             <a
              className="nav-link"
              href="https://blog.sheplays.com.au/"
             >
              Latest News
             </a>
            </li>
            {currentUser &&
            <li className="nav-item">
              <a className="nav-link" href={`/${competition.shortName}/my-team`}>
                My Team
              </a>
            </li>
            }
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
