import React from 'react';
import Flag from 'react-world-flags';
import { CompetitionWrapperWSL } from '../../components/layout/CompetionWrapperWSL';
import { wsl } from '../../data/competitions/wsl';

import moment from 'moment';
import numeral from 'numeral';

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({wslprofile}) => {
	if (!wslprofile.noimg){
		return <figcaption align="center">
            Photo by <a href={wslprofile.link2} >{wslprofile.cap2}</a>, <a href={wslprofile.link1} title="Creative Commons">{wslprofile.cap1}</a>
            </figcaption>
	}
	return <span> </span>
	}

const Bio = ({wslprofile}) => {
        if (!wslprofile.nobio){
                return <p>Bio written by {wslprofile.author} (<a href={wslprofile.authTwitterL}>{wslprofile.authTwitter}</a>)</p>
        }
        return <span> </span>
        }


const WSLProfile = ({ wslprofile }) => (<>
<CompetitionWrapperWSL competition={wsl}>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h1>{wslprofile.name}</h1>
	    <p> </p>
        </div>
	<div className="container">
	<p></p>
	</div>
        <div className="float-right mr-2">
	    <img src={`/img/wsl/badges-team/${wslprofile.teamCode}.png`} width="100"/>
        </div>
    </div>
        <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <div className="container">
	<p></p>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col" align="center"></th>
	        </tr>
            </thead>
	    <tbody>
	    <tr>
	        <td>Club</td>
	        <td align="center">{wslprofile.teamName}</td>
	    </tr>
	    <tr>
	        <td>Position</td>
	        <td align="center">{wslprofile.pos}</td>
	    </tr>
	    <tr>
	        <td>Nationality</td>
	        <td align="center"><img src={`/img/RoundFlags/${wslprofile.nationality}.png`} width="40"/></td>
	    </tr>
	    <tr>
	        <td>Age</td>
	        <td align="center">{AGE(wslprofile.dob)}
                 </td>
	    </tr>
	    <tr>
                <td>Social Media</td>
                <td align="center">{wslprofile.socials}</td>
            </tr>
	    <tr>
	        <td>Fantasy Price</td>
	        <td align="center">{numeral(wslprofile.price).format('$0,0')}</td>
	    </tr>
	    <tr>
	        <td>Fantasy Points</td>
	        <td align="center">{wslprofile.score}</td>
	    </tr>
	    </tbody>
	    </table>
	    </div>
	  </div>
	  </div>
	 <div className="col-sm-6 order-sm-1">
	  <div className="p-0">
	    <img className="rounded mx-auto d-block mb-3" src={wslprofile.img} width="450"/>
	 {Image({wslprofile})}
	  </div>
	 </div>
       </div>
	<p><b> Previous Club:</b> {wslprofile.prevClub}<br/>
	</p>
	<p> </p>
	<p style={{textAlign:'justify'}}>{wslprofile.bio}</p>
	<p style={{textAlign:'justify'}}>{wslprofile.bio2}</p>
	<p style={{textAlign:'justify'}}>{wslprofile.bio3}</p>
	{Bio({wslprofile})}
	<p><b></b> {wslprofile.facts}</p>
      </div>

    <div className="container">
        <p> </p>
	<h4>Fantasy Points</h4>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
	            <th scope="col"></th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att</th>
                    <th scope="col">Def</th>
                    <th scope="col">GK</th>
	            <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">WDL</th>
                    <th scope="col">GD</th>
	            <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                {wslprofile.stats2019.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) => 
                <tr>
		    <td>2019/20</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>             
                    <td>{Att}</td>
		    <td>{Def}</td>
                    <td>{GK}</td>
		    <td>{Cards}</td>
		    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
		    <td>{Total}</td>
                </tr>
		)}
	    </tbody>
	    <tbody>
                {wslprofile.stats2020.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2020/21</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
            </tbody>
	    <tbody>
                {wslprofile.stats2021.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2021/22</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
            </tbody>
            <tbody>
                {wslprofile.stats2022.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2022/23</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
            </tbody>
            <tbody>
                {wslprofile.stats2023.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2023/24</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
            </tbody>

            <thead>
                <th scope="col" colspan='11'>Season 2024/25</th>
                <th scope="col"></th>
            </thead>
	    <tbody>
	        {wslprofile.round1.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 1</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round2.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 2</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {wslprofile.round3.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 3</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round4.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 4</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round5.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 5</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round6.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 6</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round7.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 7</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round8.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 8</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round9.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 9</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {wslprofile.round10.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 10</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round11.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 11</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round12.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 12</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round13.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 13</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round14.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 14</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round15.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 15</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round16.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 16</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round17.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 17</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round18.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 18</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round19.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 19</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round20.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 20</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round21.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 21</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {wslprofile.round22.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 22</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}

            </tbody>
	    <thead>
                <th scope="col" colspan='11'>Total Points</th>
	        <th scope="col">{wslprofile.score}</th>
	    </thead>
        </table>
    </div>
    <div className="container">
	<p></p>
    </div>	
    </CompetitionWrapperWSL>
</>)

export default WSLProfile
