import React from "react";
import Flag from "react-world-flags";

import moment from "moment";
import { CompetitionWrapperWL } from "../../components/layout/CompetionWrapperWL";
import BottomNavBar from "../../components/layout/MCompetionWrapperWL.jsx";
import { wl } from "../../data/competitions/wl";
import * as numeral from 'numeral';
import data from "../../data/wl/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
	const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
	if (Number.isNaN(n)) {
		return '';
	}
        return n;
};


const Image = ({wlteam}) => {
        if (!wlteam.photo){
                return<span></span>
        }
        return (
              <div>
              <img
              className="responsive_2col"
              src={`/img/wl/${wlteam.photo}`}
              width="550"
            />
               <figcaption align="center">
            Photo by <a href={wlteam.link1} >{wlteam.cap1}</a>, <a href={wlteam.link2} title="Creative Commons">{wlteam.cap2}</a>
            </figcaption>
                <p> </p>
            </div>
            )

};



const WLTeam = ({ wlteam }) => ( <>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h2>{wlteam.country}</h2>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={wlteam.countryCode}
        height={60}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
	{Image({wlteam})}
      <p style={{textAlign:'justify',fontSize:'0.8rem'}}>{wlteam.bio1}</p>
      <p style={{textAlign:'justify',fontSize:'0.8rem'}}>{wlteam.bio2}</p>
      <p style={{textAlign:'justify',fontSize:'0.8rem'}}>{wlteam.bioAuth}</p>
    </div>


    <div className="container clearfix mt-32" style={{overflowX:'scroll'}}>
      <table className="table table-striped" style={{fontSize:'0.8rem'}}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
	   <th scope="col" align="center">
              Nationality
            </th>
            <th scope="col" align="center">Price</th>
	    <th scope="col" align="center">Points</th>
          </tr>
        </thead>
        <tbody>
          {wlteam.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
	      Points17,
	      Points18,
              Points19,
	      Points20,
	      Points,
              DOB,
              bio,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wl/m/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
		<td>{numeral(Price).format()}</td>
		<td>{Points}</td>
              </tr>
            )
          )}
	{wlteam.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points17,
              Points18,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wl/m/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
                <td>{Points}</td> 
              </tr>
            )
          )}
	{wlteam.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points17,
              Points18,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wl/m/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
                <td>{Points}</td>
              </tr>
            )
          )}
	{wlteam.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points17,
              Points18,
              Points19,
              Points20,
              Points,
              DOB,
              bio,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td><a href={`/wl/m/players/${Link}`}>{Name}</a></td>
                <td>{Position}</td>
		<td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="30"/></td>
                <td>{numeral(Price).format()}</td>
                <td>{Points}</td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="4">Team's Total Points:</th>
	<th scope="col">{wlteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
	<BottomNavBar/>
        </>

);

export default WLTeam;
