import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperWWC } from "../../components/layout/CompetionWrapperWWC";
import BottomNavBar from '../../components/layout/MCompetionWrapperWL.jsx';
import { wwc } from "../../data/competitions/wwc";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
                { code: "ade", name: "Adelaide United"},
                { code: "bri", name: "Brisbane Roar"},
                { code: "cbr", name: "Canberra United"},
                { code: "mcy", name: "Melbourne City"},
                { code: "mvc", name: "Melbourne Victory"},
                { code: "new", name: "Newcastle Jets"},
                { code: "per", name: "Perth Glory"},
                { code: "syd", name: "Sydney FC"},
                { code: "wel", name: "Wellington Phoenix" },
                { code: "wsw", name: "Western Sydney Wanderers"}];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/wl/teams/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/badges/${team.code}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
