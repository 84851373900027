import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-au");

const shirts = {
  "Austria": {
      name: "Austria",
      player: "/img/euro/shirts/aut.png",
      gk: "/img/euro/shirts/autG.png",
  },
  "Belgium": {
      name: "Belgium",
      player: "/img/euro/shirts/bel.png",
      gk: "/img/euro/shirts/belG.png",
  },
  "Denmark": {
      name: "Denmark",
      player: "/img/euro/shirts/den.png",
      gk: "/img/euro/shirts/denG.png",
  },
  "England": {
      name: "England",
      player: "/img/euro/shirts/eng.png",
      gk: "/img/euro/shirts/engG.png",
  },
  "France": {
      name: "France",
      player: "/img/euro/shirts/fra.png",
      gk: "/img/euro/shirts/fraG.png",
  },
  "Finland": {
      name: "Finland",
      player: "/img/euro/shirts/fin.png",
      gk: "/img/euro/shirts/finG.png",
  },
  "Germany": {
      name: "Germany",
      player: "/img/euro/shirts/ger.png",
      gk: "/img/euro/shirts/gerG.png",
  },
  "Iceland": {
      name: "Iceland",
      player: "/img/euro/shirts/isl.png",
      gk: "/img/euro/shirts/islG.png",
  },
  "Italy": {
      name: "Italy",
      player: "/img/euro/shirts/ita.png",
      gk: "/img/euro/shirts/itaG.png",
  },
  "Netherlands": {
      name: "Netherlands",
      player: "/img/euro/shirts/nld.png",
      gk: "/img/euro/shirts/nldG.png",
  },
  "Northern Ireland": {
      name: "Northern Ireland",
      player: "/img/euro/shirts/nir.png",
      gk: "/img/euro/shirts/nirG.png",
  },
  "Norway": {
      name: "Norway",
      player: "/img/euro/shirts/nor.png",
      gk: "/img/euro/shirts/norG.png",
  },
  "Portugal": {
      name: "Portugal",
      player: "/img/euro/shirts/prt.png",
      gk: "/img/euro/shirts/prtG.png",
  },
  "Spain": {
      name: "Spain",
      player: "/img/euro/shirts/esp.png",
      gk: "/img/euro/shirts/espG.png",
  },
  "Sweden": {
      name: "Sweden",
      player: "/img/euro/shirts/swe.png",
      gk: "/img/euro/shirts/sweG.png",
  },
  "Switzerland": {
      name: "Switzerland",
      player: "/img/euro/shirts/che.png",
      gk: "/img/euro/shirts/cheG.png",
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
}

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-08-24 12:00+01"),
    closes: moment("2022-07-07 04:00:00+10")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2022-07-11 07:00:00+10"),
    closes: moment("2022-07-12 01:00:00+10")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2022-07-15 07:00:00+10"),
    closes: moment("2022-07-16 04:00:00+10")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2022-07-19 07:00:00+10"),
    closes: moment("2022-07-21 04:00:00+10")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2022-07-24 07:00:00+10"),
    closes: moment("2022-07-27 04:00:00+10")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2022-07-28 07:00:00+10"),
    closes: moment("2022-08-01 01:00:00+10")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2022-08-01 04:00:00+10"),
    closes: moment("2022-08-08 02:00:00+10")
  }
];


const init = () => {
  numeral.locale("en-au");
  numeral.defaultFormat("$0,0");
};

export const euro = {
  shortName: "euro",
  competition: "euro",
  name: "EURO",
  sport: soccer,
  totalBudget: 9000000,
  minPlayerPrice: 200000,
  maxPlayerPrice: 1000000,
  perClubLimit: 8,
  hashtag: "ShePlaysEuro",
  shirts,
  init,
  rounds,
  freeTransfers: 2,
  transferCost: 5,
  season: "2022",
  teams: shirts
};
