import "@devexpress/dx-react-grid";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";
import { CompetitionWrapperWSL } from "../../../components/layout/CompetionWrapperWSL";
import { wsl } from "../../../data/competitions/wsl";
import data from "../../../data/wsl/2020/leaderboard";

const columns = [
  { title: "Rank", name: "rank"},
  { title: "Team Name", name: "teamName" },
  {
    title: "Total",
    name: "total",
    getCellValue: t => t.score["total"] || 0
  },
  {
    title: "R22",
    name: "r22",
    getCellValue: t => t.score["round-22"] || 0
  },
  {
    title: "R21",
    name: "r21",
    getCellValue: t => t.score["round-21"] || 0
  },
  {
    title: "R20",
    name: "r20",
    getCellValue: t => t.score["round-20"] || 0
  },
  {
    title: "R19",
    name: "r19",
    getCellValue: t => t.score["round-19"] || 0
  },
  {
    title: "R18",
    name: "r18",
    getCellValue: t => t.score["round-18"] || 0
  },
  {
    title: "R17",
    name: "r17",
    getCellValue: t => t.score["round-17"] || 0
  },
  {
    title: "R16",
    name: "r16",
    getCellValue: t => t.score["round-16"] || 0
  },
  {
    title: "R15",
    name: "r15",
    getCellValue: t => t.score["round-15"] || 0
  },
  {
    title: "R14",
    name: "r14",
    getCellValue: t => t.score["round-14"] || 0
  },
  {
    title: "R13",
    name: "r13",
    getCellValue: t => t.score["round-13"] || 0
  },
  {
    title: "R12",
    name: "r12",
    getCellValue: t => t.score["round-12"] || 0
  },
  {
    title: "R11",
    name: "r11",
    getCellValue: t => t.score["round-11"] || 0
  },
  {
    title: "R10",
    name: "r10",
    getCellValue: t => t.score["round-10"] || 0
  },
  {
    title: "R9",
    name: "r9",
    getCellValue: t => t.score["round-9"] || 0
  },
  {
    title: "R8",
    name: "r8",
    getCellValue: t => t.score["round-8"] || 0
  },
  {
    title: "R7",
    name: "r7",
    getCellValue: t => t.score["round-7"] || 0
  },
  {
    title: "R6",
    name: "r6",
    getCellValue: t => t.score["round-6"] || 0
  },
  {
    title: "R5",
    name: "r5",
    getCellValue: t => t.score["round-5"] || 0
  },
  {
    title: "R4",
    name: "r4",
    getCellValue: t => t.score["round-4"] || 0
  },
  {
    title: "R3",
    name: "r3",
    getCellValue: t => t.score["round-3"] || 0
  },
  {
    title: "R2",
    name: "r2",
    getCellValue: t => t.score["round-2"] || 0
  },
  {
    title: "R1",
    name: "r1",
    getCellValue: t => t.score["round-1"] || 0
  }
];

const FilterCell = props => {
  const { column } = props;
  if (column.name === "teamName") {
    return <TableFilterRow.Cell {...props} />;
  }
  return <th />;
};

const widths = {
  rank: 80,
  teamName: 300,
  default: 120
}

export const WSLLeaderboard = () => (
  <>
    <CompetitionWrapperWSL competition={wsl}>
      <div className="container clearfix mt-32">
        <h1>ShePlays Global Leaderboard 2020/21</h1>
	<p>Check out how your team went against the rest of the ShePlays community during the 2020/21 season on our global leaderboard! This 
	season thanks to our friends at KitLocker we also have vouchers up for grabs for the winner of each round. So 
	make sure you check out their latest range at <a href="https://adtr.co/r0pjyJ"
        target="_blank">KitLocker</a>.</p>
	<a href="https://adtr.co/r0pjyJ"
	target="_blank">
	<img className="responsive_large" src="/img/KitLocker1.jpg" width="100%" alt="kennedy-header-2019-grand-final" />
	</a>
      </div>
      <div className="container" style={{overflowX: 'scroll'}}>
        <Grid container item xs 
          rows={data.teams.map((d, i) => ({ ...d, rank: i + 1 }))}
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={50} />

          <SortingState defaultSorting={[]} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={columns.map(({name}) => ({
            width: widths[name] || widths.default,
            columnName: name
          }))}/>
          <TableHeaderRow showSortingControls />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel />
        </Grid>
      </div>
      <div className="container">
        <p></p>
      </div>
    </CompetitionWrapperWSL>
  </>
);
export default WSLLeaderboard;
