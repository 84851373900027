import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useAuth } from "../../util/withAuth";

export const CompetitionWrapperAFLW = ({ competition, children }) => {
  const [oldDropdown, setOldDropdown] = useState(false, []);
  const [teamDropdown, setTeamDropdown] = useState(false, []);
  const [pastDropdown, setPastDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const currentUser = useAuth()

const teams = [
  {code: "ade", name: "Adelaide Crows"},
  {code: "bri", name: "Brisbane Lions"},
  {code: "car", name: "Carlton Blues"},
  {code: "col", name: "Collingwood Magpies"},
  {code: "ess", name: "Essendon Bombers"},
  {code: "fre", name: "Fremantle Dockers"},
  {code: "gee", name: "Geelong Cats"},
  {code: "gol", name: "Gold Coast Suns"},
  {code: "gws", name: "GWS Giants"},
  {code: "haw", name: "Hawthorn Hawks"},
  {code: "mel", name: "Melbourne Demons"},
  {code: "nor", name: "North Melbourne Kangaroos"},
  {code: "por", name: "Port Adelaide"},
  {code: "ric", name: "Richmond Tigers"},
  {code: "stk", name: "St Kilda Saints"},
  {code: "syd", name: "Sydney Swans"},
  {code: "wce", name: "West Coast Eagles"},
  {code: "wes", name: "Western Bulldogs"},
]

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wl">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
              Rules & Scoring
              </a>
            </li>
	    <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
              Fixtures & Results
              </a>
            </li>

	  <li className="nav-item">
            <Dropdown
              isOpen={teamDropdown}
              toggle={() => setTeamDropdown(!teamDropdown)}
            >
              <DropdownToggle className="nav-link" caret>
                Teams
              </DropdownToggle>
              <DropdownMenu>
                {teams.map(team => (
                  <DropdownItem
                    key={team.code}
                    href={`/${competition.shortName}/teams/${team.code ||
                      team.code}`.toLowerCase()}
                  >
                    {team.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/stats`}
              >
              Stats Center
              </a>
            </li>

	  <li className="nav-item">
            <Dropdown
              isOpen={oldDropdown}
              toggle={() => setOldDropdown(!oldDropdown)}
            >
            <DropdownToggle className="nav-link" caret>
                 Past Seasons
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={`/${competition.shortName}/2020/stats`}>
                    Stats Center 2020
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/2021/stats`}>
                    Stats Center 2021
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/2022/stats`}>
                    Stats Center 2022
                  </DropdownItem>
	         <DropdownItem href={`/${competition.shortName}/2020/leaderboard`}>
                    Leaderboard 2020
                  </DropdownItem>
	          <DropdownItem href={`/${competition.shortName}/2021/leaderboard`}>
                    Leaderboard 2021
                  </DropdownItem>
	         <DropdownItem href={`/${competition.shortName}/2022/leaderboard`}>
                    Leaderboard 2022
                  </DropdownItem>
                </DropdownMenu>
	      </Dropdown>
	    </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/leaderboard`}
              >
              Leaderboard
              </a>
            </li>	  


          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
